import firebase from "config/firebase";
// import rollbar from "config/rollbar";
import * as Sentry from "@sentry/react";
import {
  LOCAL_STORAGE_REFERRER_DATA,
  LOCAL_STORAGE_REFERRER_AUTHORITY,
  LOCAL_STORAGE_REFERRER,
} from "../constants";

async function getReferrers() {
  try {
    const arr = [];
    const coll = await firebase
      .firestore()
      .collection("settings")
      .doc("referrers")
      .collection("list")
      .get();
    if (!coll || coll.empty) {
      // rollbar.error("coll is empty", coll);
      Sentry.captureMessage("coll is empty", {
        level: Sentry.Severity.Error,
        extra: { coll },
      });
      return arr;
    }
    const docs = coll.docs;

    if (!docs) {
      // rollbar.error("coll has no docs", coll);
      Sentry.captureMessage("coll has no docs", {
        level: Sentry.Severity.Error,
        extra: { coll },
      });
      return arr;
    }

    docs.forEach((doc) => {
      arr.push(doc.data());
    });
    return arr;
  } catch (e) {
    console.error(e);
    // rollbar.error(e, "getReferrers");
    Sentry.captureException(e);
    return [];
  }
}

export default async function retrieveReferrers() {
  let data = checkLocalStore("referrers");
  if (data) return data;
  data = await getReferrers();
  setLocalStore("referrers", data);
  return data;
}

async function fetchReferrerData() {
  try {
    const doc = await firebase
      .firestore()
      .collection("settings")
      .doc("providerData")
      .get();

    if (!doc.exists) {
      // rollbar.error("doc does not exist fetch referrer data", doc);
      Sentry.captureMessage("doc does not exist fetch referrer data", {
        level: Sentry.Severity.Error,
        extra: { doc },
      });
      return {};
    }

    const data = doc.data();
    return data;
  } catch (e) {
    console.error(e);
    // rollbar.error(e, "fetchReferrerData");
    Sentry.captureException(e);
    return {};
  }
}

export async function retreiveReferrersData() {
  let data = checkLocalStore(LOCAL_STORAGE_REFERRER_DATA);
  if (data) return data;
  data = await fetchReferrerData();
  setLocalStore(LOCAL_STORAGE_REFERRER_DATA, data);
  return data;
}

function setLocalStore(name, data, expiry) {
  try {
    if (!expiry) {
      expiry = new Date().getTime() + 1000 * 60 * 60 * 24; // 1 day
    }
    localStorage.setItem(name, JSON.stringify({ data: data, expires: expiry }));
  } catch (e) {}
}

function checkLocalStore(name) {
  try {
    const local = localStorage.getItem(name);
    if (local) {
      // check expiry
      const obj = JSON.parse(local);
      const hasExpired = new Date().getTime() > obj.expires;
      if (!hasExpired) {
        return obj.data;
      }
    }
    return null;
  } catch (e) {
    return null;
  }
}

export function getAuthority() {
  let authority;
  try {
    authority = localStorage.getItem(LOCAL_STORAGE_REFERRER_AUTHORITY);
  } catch (_) {}
  return authority;
}

export function setAuthority(authority) {
  try {
    localStorage.setItem(LOCAL_STORAGE_REFERRER_AUTHORITY, authority);
  } catch (_) {}
}

export function getReferrer() {
  let referrer;
  try {
    referrer = localStorage.getItem(LOCAL_STORAGE_REFERRER);
  } catch (_) {}
  return referrer;
}
