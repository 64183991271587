import React, { useState, createContext, useEffect } from "react";
import {
  ThemeProvider as MUITheme,
  createTheme as createMuiTheme,
} from "@material-ui/core/styles";
import firebase from "config/firebase";

import defaultTheme from "config/defaultTheme";
import { breakpoints } from "config/defaultTheme/breakpoints";
import { fonts } from "config/defaultTheme/font";
import resolveThemeName from "config/resolveThemeName";

import { LOCAL_STORAGE_THEME } from "../../constants";
import { precacheImages } from "util/precacheImages";
import { recursiveReplaceStr } from "util/transformers";

import { cloneDeep } from "lodash";

export const ThemeContext = createContext();

const overrides = (font) => ({
  MuiCssBaseline: {
    "@global": {
      "@font-face": [font.data],
    },
  },
  MuiContainer: {
    root: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  MuiButton: {
    sizeLarge: {
      height: 36,
      minWidth: 200,
      [`@media (min-width:${breakpoints.sm}px)`]: {
        minWidth: 300,
      },
    },
    label: {
      fontSize: "14px",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.14",
      letterSpacing: "1.25px",
    },
  },
  MuiTypography: {
    root: {
      whiteSpace: "pre-wrap",
    },
  },
});

const Theme = ({ children }) => {
  const [state, setState] = useState({
    copy: {},
    theme: defaultTheme,
    flow: {},
    themeName: "",
    isLoading: true,
    dataInit: null,
  });

  useEffect(() => {
    // fetch theme config and set
    async function init() {
      const providerName = resolveThemeName();
      if (!providerName) return;
      const themeData = await fetchTheme(providerName);
      if (!themeData) return;
      console.log("data theme", themeData);
      const theme = createTheme(themeData.theme);
      setState((prev) => ({
        ...prev,
        copy: processCopy(themeData.copy),
        themeName: providerName,
        theme,
        dataInit: themeData.data,
        isLoading: false,
      }));
      precacheImages(providerName);
    }

    init();
    /* eslint-disable-next-line */
  }, []);

  async function fetchTheme(providerName) {
    try {
      const local = localStorage.getItem(LOCAL_STORAGE_THEME);
      if (local) {
        // check expiry
        const obj = JSON.parse(local);
        const hasExpired = new Date().getTime() > obj.expires;
        if (!hasExpired) {
          return obj.data;
        }
      }

      const settingsCollection = firebase
        .firestore()
        .collection("settings")
        .doc("themes")
        .collection("theme");
      const doc = await settingsCollection.doc(providerName).get();
      const data = doc.data();
      const expiry = new Date().getTime() + 1000 * 60 * 60 * 24; // 1 day
      localStorage.setItem(
        LOCAL_STORAGE_THEME,
        JSON.stringify({ data, expires: expiry })
      );
      return data;
    } catch (error) {
      return null;
    }
  }

  function processCopy(copy) {
    // line break chars stored in firestore as str come back as \\n - need to process globally here
    let clone = cloneDeep(copy);
    clone = recursiveReplaceStr(clone, /\\n/g, "\n");
    return clone;
  }

  function createTheme(data) {
    const font = fonts[data.fonts?.main] || {};
    const theme = createMuiTheme({
      spacing: 1,
      palette: createPalette(data.colors),
      typography: {
        fontFamily: font.name || "WorkSans",
        h2: {
          fontFamily: font.name || "WorkSans",
          fontSize: 28,
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: 1.2,
          letterSpacing: 0.15,
          color: "#142b2c",
          [`@media (min-width:${breakpoints.md}px)`]: {
            fontSize: 40,
          },
        },
        h4: {
          [`@media (max-width:${breakpoints.sm}px)`]: {
            fontSize: "1.8rem",
          },
        },
        h6: {
          [`@media (max-width:${breakpoints.sm}px)`]: {
            fontSize: "1.1rem",
          },
        },
      },
      overrides: overrides(font),
    });
    return theme;
  }

  function createPalette(colors) {
    return {
      primary: {
        main: colors.primary,
        dark: colors.primary_dark,
      },
      secondary: {
        main: colors.secondary,
        light: colors.secondary_light,
        dark: colors.secondary_dark,
      },
      info: {
        main: colors.info,
      },
      text: {
        primary: colors.text,
      },
      background: {
        default: colors.background,
      },
      footer: {
        main: colors.footer,
      },
      header: {
        main: colors.header,
      },
      progress: {
        main: colors.progress,
      },
      headerText: {
        main: colors.header_text,
      },
      footerText: {
        main: colors.footer_text,
      },
      borderText: {
        main: colors.border_text,
      },
    };
  }

  return (
    <ThemeContext.Provider
      value={{
        ...state,
      }}
    >
      <MUITheme theme={state.theme}>{children}</MUITheme>
    </ThemeContext.Provider>
  );
};

export default Theme;
