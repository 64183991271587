import React, { useEffect } from "react";
import {
  Container,
  Box,
  TextField,
  Checkbox,
  makeStyles,
} from "@material-ui/core";

import { QuestionSection, Question } from "../Question";
import ScreenTitle from "../ScreenTitle";
import ProgressButtonGroup from "../ProgressButtonGroup";
import { ContentInfo, ContentTitle } from "../HomeTitles";

import {
  ACCOUNT_TYPE_SOLO_401K,
  ACCOUNT_TYPE_BUSINESS_ACCOUNT,
} from "../../../../constants";

import { disclaimer } from "./index.json";
import { isEqual } from "lodash";

const useStyles = makeStyles((theme) => ({
  disclaimer: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
  },
}));

const CheckingAccount = ({
  previousStep,
  nextStep,
  accountType,
  createRothCheckingForPostTaxFunds,
  createTraditionalCheckingForTaxDeferredFunds,
  checkingAccountNicknameRoth,
  checkingAccountNicknameTraditional,
  checkingAccountTaxTypes,
  hasInitValues,
  setFieldValue,
  handleSave,
}) => {
  const classes = useStyles();

  useEffect(() => {
    function populateCheckingAccountTaxTypes() {
      const arr = [];
      if (createRothCheckingForPostTaxFunds) arr.push("post-tax");
      if (createTraditionalCheckingForTaxDeferredFunds) arr.push("tax-defer");
      if (!isEqual(checkingAccountTaxTypes, arr)) {
        setFieldValue("checkingAccountTaxTypes", arr);
      }
    }
    if (hasInitValues) {
      populateCheckingAccountTaxTypes();
    }
  }, [hasInitValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNicknameChange = (e) => {
    e.persist();
    const name = e.target.name;
    let value = e.target.value;
    value = value.replace(/[^a-zA-Z\d\s:]/g, "");
    setFieldValue(name, value);
  };

  const handleChangeCheckbox = (e) => {
    e.persist();
    setFieldValue(e.target.name, e.target.checked);
    if (e.target.name === "createRothCheckingForPostTaxFunds") {
      !e.target.checked && setFieldValue("checkingAccountNicknameRoth", "");
    } else {
      !e.target.checked &&
        setFieldValue("checkingAccountNicknameTraditional", "");
    }
    handleCheckingAccountTaxTypes(e.target.name, e.target.checked);
  };

  const handleCheckingAccountTaxTypes = (name, checked) => {
    let arr = checkingAccountTaxTypes || [];
    const itemName =
      name === "createRothCheckingForPostTaxFunds" ? "post-tax" : "tax-defer";
    if (checked) {
      arr.push(itemName);
    } else {
      arr = arr.filter((item) => item !== itemName);
    }
    setFieldValue("checkingAccountTaxTypes", arr);
  };

  const resolveDisabled = () => {
    // Check if atleast one investment sector is selected
    // Check whether atleast one checking account is selected
    let disabled = false;
    if (
      !createTraditionalCheckingForTaxDeferredFunds &&
      !createRothCheckingForPostTaxFunds
    )
      disabled = true;
    return disabled;
  };

  const handleNextStep = () => {
    if (handleSave) {
      handleSave();
    }
    return nextStep();
  };

  const type =
    accountType === ACCOUNT_TYPE_BUSINESS_ACCOUNT
      ? "business account"
      : accountType === ACCOUNT_TYPE_SOLO_401K
      ? "401K"
      : "IRA";

  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle title="Account and Investment Options" />
        <QuestionSection>
          <Question
            text={`What type of checking do you want to open for your ${type}?`}
            required
          />
          <Box display="flex">
            <Box pt={16}>
              <Checkbox
                checked={createTraditionalCheckingForTaxDeferredFunds}
                onChange={handleChangeCheckbox}
                name="createTraditionalCheckingForTaxDeferredFunds"
                color="primary"
              />
            </Box>
            <Box>
              <ContentTitle text="Traditional checking account (for tax deferred funds)" />
            </Box>
          </Box>
          <Box display="flex">
            <Box pt={16}>
              <Checkbox
                checked={createRothCheckingForPostTaxFunds}
                onChange={handleChangeCheckbox}
                name="createRothCheckingForPostTaxFunds"
                color="primary"
              />
            </Box>
            <Box>
              <ContentTitle text="Roth checking account (for post-tax funds)" />
            </Box>
          </Box>
        </QuestionSection>
        <Box p={10} mt={20} className={classes.disclaimer}>
          {Object.keys(disclaimer).map((key, i) => {
            const paddingTop = i === 0 ? 0 : 20;
            return (
              <Box pt={paddingTop} key={i}>
                <ContentInfo text={disclaimer[key]} />
              </Box>
            );
          })}
        </Box>
        {createTraditionalCheckingForTaxDeferredFunds && (
          <QuestionSection>
            <Question text="Account Nickname (Traditional)" />
            <TextField
              id="checkingAccountNicknameTraditional"
              name="checkingAccountNicknameTraditional"
              fullWidth
              onChange={handleNicknameChange}
              placeholder="Optional"
              value={checkingAccountNicknameTraditional}
            />
          </QuestionSection>
        )}
        {createRothCheckingForPostTaxFunds && (
          <QuestionSection>
            <Question text="Account Nickname (Post-tax)" />
            <TextField
              id="checkingAccountNicknameRoth"
              name="checkingAccountNicknameRoth"
              fullWidth
              onChange={handleNicknameChange}
              placeholder="Optional"
              value={checkingAccountNicknameRoth}
            />
          </QuestionSection>
        )}
        <ProgressButtonGroup
          left={{
            clickHandler: previousStep,
            pageCalling: "Back Click: Checking Account Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: Checking Account Page",
            disabled: resolveDisabled(),
          }}
          hasRequiredInputs
        />
      </Container>
    </Box>
  );
};

export default CheckingAccount;
