import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  text: {
    letterSpacing: 0.15,
    marginBottom: 8,
  },
  container: {
    paddingTop: 32,
  },
  asterix: {
    color: theme.palette.primary.main,
  },
}));

export const QuestionSection = ({ children }) => {
  const classes = useStyles();
  return <Box className={classes.container}>{children}</Box>;
};

export const Question = ({ text, required }) => {
  const classes = useStyles();
  return (
    <Box>
      <Typography variant="body1" className={classes.text}>
        {text} <small className={classes.asterix}>{required && "*"}</small>
      </Typography>
    </Box>
  );
};
