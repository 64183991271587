import React, { useCallback } from "react";
import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core";
import AddressAutoComplete from "components/AddressAutocomplete";
import { isEmpty } from "lodash";

import ScreenTitle from "../ScreenTitle";
import { QuestionSection, Question } from "../Question";
import ProgressButtonGroup from "../ProgressButtonGroup";
import PostCode from "../PostCode";
import { mapStatesToOptions } from "util/helpers";

import { CONTACT_INFO } from "../../../../constants";

const ResAddress = ({
  previousStep,
  nextStep,
  resAddressEqualsMailing,
  hasResidedAtAddressForRequiredDuration,
  resAddressObj,
  resAddressLine1,
  resAptNumber,
  resCity,
  resPostcode,
  resState,
  previousAddressObj,
  previousAddressLine1,
  previousAptNumber,
  previousCity,
  previousState,
  previousPostcode,
  mailingAddressLine1,
  mailingAptNumber,
  mailingCity,
  mailingPostcode,
  mailingState,
  mailingAddressObj,
  setFieldValue,
  status,
  handleFormChange,
  completedSections,
  goToStep,
  currentStep,
  handleSave,
  isAdditionalParticipant,
  errors,
}) => {
  const handleChangeRadio = (e) => {
    e.persist();
    setFieldValue(
      "resAddressEqualsMailing",
      e.target.value === "true" ? true : false
    );
  };

  const handleChangeRadioLivedAtDuration = (e) => {
    e.persist();
    setFieldValue(
      "hasResidedAtAddressForRequiredDuration",
      e.target.value === "true" ? true : false
    );
  };

  const handleAddressSelection = useCallback(
    (addressComponents) => {
      setFieldValue("resAddressLine1", addressComponents.address);
      setFieldValue("resCity", addressComponents.city);
      setFieldValue("resPostcode", addressComponents.zipCode);
      setFieldValue("resState", addressComponents.state);
      setFieldValue("resAddressObj", addressComponents.value);
    },
    [setFieldValue]
  );

  const handlePreviousAddressSelection = useCallback(
    (addressComponents) => {
      setFieldValue("previousAddressLine1", addressComponents.address);
      setFieldValue("previousCity", addressComponents.city);
      setFieldValue("previousPostcode", addressComponents.zipCode);
      setFieldValue("previousState", addressComponents.state);
      setFieldValue("previousAddressObj", addressComponents.value);
    },
    [setFieldValue]
  );

  const handleChange = (e) => {
    e.persist();
    handleFormChange(e);
  };

  const handleAptChange = (e) => {
    e.persist();
    const value = e.target.value;
    const filtered = value.replace(/[^a-zA-Z0-9  -]/g, "");
    setFieldValue(e.target.name, filtered);
  };

  const handleNextStep = () => {
    // Copy residential address to mailing address if its the same, if not initialize, the mailing object
    if (resAddressEqualsMailing) {
      setFieldValue("mailingAddressLine1", resAddressLine1);
      setFieldValue("mailingAptNumber", resAptNumber);
      setFieldValue("mailingCity", resCity);
      setFieldValue("mailingPostcode", resPostcode);
      setFieldValue("mailingState", resState);
      setFieldValue("mailingAddressObj", resAddressObj);
      !completedSections.includes(CONTACT_INFO) &&
        setFieldValue("completedSections", [
          ...completedSections,
          CONTACT_INFO,
        ]);
      if (handleSave) handleSave();
      return goToStep(currentStep + 2);
    } else {
      if (isAdditionalParticipant) {
        // if mailing and res are equal set to ""
        mailingAddressLine1 === resAddressLine1 &&
          setFieldValue("mailingAddressLine1", "");
        mailingAptNumber === resAptNumber &&
          setFieldValue("mailingAptNumber", "");
        mailingCity === resCity && setFieldValue("mailingCity", "");
        mailingPostcode === resPostcode && setFieldValue("mailingPostcode", "");
        mailingState === resState && setFieldValue("mailingState", "");
        mailingAddressLine1 === resAddressLine1 &&
          setFieldValue("mailingAddressObj", "");
      } else {
        setFieldValue("mailingAddressLine1", "");
        setFieldValue("mailingAptNumber", "");
        setFieldValue("mailingCity", "");
        setFieldValue("mailingPostcode", "");
        setFieldValue("mailingState", "");
        setFieldValue("mailingAddressObj", "");
      }

      if (handleSave) handleSave();
      return nextStep();
    }
  };

  const resolveDisabled = () => {
    return (
      isEmpty(resAddressLine1) ||
      isEmpty(resCity) ||
      isEmpty(resState) ||
      isEmpty(resPostcode) ||
      (hasResidedAtAddressForRequiredDuration === false &&
        (!previousAddressLine1 ||
          !previousCity ||
          !previousState ||
          !previousPostcode)) ||
      !!errors["resAddressLine1"]
    );
  };

  function handlePrevStep() {
    if (status === "DECLINED") {
      return previousStep();
    }
    return goToStep(currentStep - 2);
  }

  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle title="Contact Information" />
        <QuestionSection>
          <Question text="Your home address" />
          <AddressAutoComplete
            onSelectAddress={handleAddressSelection}
            initialValue={resAddressObj}
          />
        </QuestionSection>
        <Box pt={30}>
          <Typography variant="body2">
            OR manually enter your address below:
          </Typography>
        </Box>
        <QuestionSection>
          <Question text="Street Address" required />
          <TextField
            id="resAddressLine1"
            name="resAddressLine1"
            value={resAddressLine1}
            fullWidth
            onChange={handleChange}
            placeholder="Enter address"
            error={!!errors["resAddressLine1"]}
            helperText={errors["resAddressLine1"] || ""}
          />
        </QuestionSection>
        <Box display="flex" justifyContent="start" alignItems="center">
          <Box flexGrow={1}>
            <QuestionSection>
              <Question text="Apartment/Suite" />
              <TextField
                id="resAptNumber"
                name="resAptNumber"
                value={resAptNumber}
                fullWidth
                onChange={handleAptChange}
                placeholder="Enter Apt/Suite number"
              />
            </QuestionSection>
          </Box>
          <Box flexGrow={1} pl={30}>
            <QuestionSection>
              <Question text="City" required />
              <TextField
                id="resCity"
                name="resCity"
                value={resCity}
                fullWidth
                onChange={handleChange}
                placeholder="Enter city"
              />
            </QuestionSection>
          </Box>
        </Box>
        <Box display="flex" justifyContent="start" alignItems="center">
          <Box flexGrow={1}>
            <QuestionSection>
              <Question text="State" required />
              <Select
                fullWidth
                labelId="resState"
                name="resState"
                id="resState"
                value={resState || ""}
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem value="" disabled dense>
                  Select a state
                </MenuItem>
                {mapStatesToOptions().map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.name}
                  </MenuItem>
                ))}
              </Select>
            </QuestionSection>
          </Box>
          <Box flexGrow={1} pl={30}>
            <PostCode
              name="resPostcode"
              value={resPostcode}
              onChange={setFieldValue}
              required
            />
          </Box>
        </Box>
        {/* previous address */}
        <QuestionSection>
          <Question
            text="Have you been at this address for longer than 18 months?"
            required
          />
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="hasResidedAtAddressForRequiredDuration"
              name="hasResidedAtAddressForRequiredDuration"
              value={hasResidedAtAddressForRequiredDuration}
              onChange={handleChangeRadioLivedAtDuration}
              row
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </QuestionSection>
        {hasResidedAtAddressForRequiredDuration === false && (
          <>
            <QuestionSection>
              <Question text="Your previous home address" />
              <AddressAutoComplete
                onSelectAddress={handlePreviousAddressSelection}
                initialValue={previousAddressObj}
              />
            </QuestionSection>
            <Box pt={30}>
              <Typography variant="body2">
                OR manually enter your address below:
              </Typography>
            </Box>
            <QuestionSection>
              <Question text="Previous address Line 1" required />
              <TextField
                id="previousAddressLine1"
                name="previousAddressLine1"
                value={previousAddressLine1}
                fullWidth
                onChange={handleChange}
                placeholder="Enter address"
              />
            </QuestionSection>
            <Box display="flex" justifyContent="start" alignItems="center">
              <Box flexGrow={1}>
                <QuestionSection>
                  <Question text="Apartment/Suite" />
                  <TextField
                    id="previousAptNumber"
                    name="previousAptNumber"
                    value={previousAptNumber}
                    fullWidth
                    onChange={handleAptChange}
                    placeholder="Enter Apt/Suite number"
                  />
                </QuestionSection>
              </Box>
              <Box flexGrow={1} pl={30}>
                <QuestionSection>
                  <Question text="City" required />
                  <TextField
                    id="previousCity"
                    name="previousCity"
                    value={previousCity}
                    fullWidth
                    onChange={handleChange}
                    placeholder="Enter city"
                  />
                </QuestionSection>
              </Box>
            </Box>
            <Box display="flex" justifyContent="start" alignItems="center">
              <Box flexGrow={1}>
                <QuestionSection>
                  <Question text="State" required />
                  <Select
                    fullWidth
                    labelId="previousState"
                    name="previousState"
                    id="previousState"
                    value={previousState || ""}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="" disabled dense>
                      Select a state
                    </MenuItem>
                    {mapStatesToOptions().map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.name}
                      </MenuItem>
                    ))}
                  </Select>
                </QuestionSection>
              </Box>
              <Box flexGrow={1} pl={30}>
                <PostCode
                  name="previousPostcode"
                  value={previousPostcode}
                  onChange={setFieldValue}
                  required
                />
              </Box>
            </Box>
          </>
        )}
        <QuestionSection>
          <Question
            text="Is your home address the same as your mailing address?"
            required
          />
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="resAddressEqualsMailing"
              name="resAddressEqualsMailing"
              value={resAddressEqualsMailing}
              onChange={handleChangeRadio}
              row
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </QuestionSection>

        <ProgressButtonGroup
          left={{
            clickHandler: handlePrevStep,
            pageCalling: "Back Click: Resident Address Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: Resident Address Page",
            disabled: resolveDisabled(),
          }}
          hasRequiredInputs
        />
      </Container>
    </Box>
  );
};

export default ResAddress;
