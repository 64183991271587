import React, { useContext, createContext } from "react";

import { AuthContext } from "components/Auth";

export const Guard = createContext({});

// for development to work on this screen with requiring authentication ////
const TESTING_WITHOUT_AUTH = false;

export function GuardProvider({ children }) {
  const { isLoggedIn } = useContext(AuthContext) || {};

  const shouldProtect =
    (process.env.NODE_ENV === "development" && TESTING_WITHOUT_AUTH) ||
    !isLoggedIn;

  return (
    <>
      <Guard.Provider
        value={{
          shouldProtect,
        }}
      >
        {children}
      </Guard.Provider>
    </>
  );
}

export default function useGuard() {
  return useContext(Guard);
}
