import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Typography,
  Select,
  Container,
  MenuItem,
} from "@material-ui/core";
import { isEmpty } from "lodash";
import { QuestionSection, Question } from "../Question";
import PostCode from "../PostCode";
import DisclaimerText from "components/DisclaimerText";
import ProgressButtonGroup from "../ProgressButtonGroup";
import ScreenTitle from "../ScreenTitle";
import DateField from "../DateField";
import QuestionSSN from "../QuestionSSN";
import content from "./index.json";
import {
  mapStatesToOptions,
  mapCountriesToOptions,
  useIsMountedRef,
  createAbortController,
} from "util/helpers";
import { getSSN, saveSSN } from "util/manageSSN";
import { singleInputValidator } from "util/validation";
import { BENEFICIARY } from "../../../../constants";

const resolveDisabled = ({
  spouseEmail,
  spouseEmailError,
  spouseBirthdayError,
  spouseLegalName,
  spouseAddressLine1,
  spouseCity,
  spouseState,
  spouseCountry,
  spouseBirthDate,
  spouseSsnRef,
  spousePostcode,
  errors: allErrors,
}) => {
  let disabled = false;

  if (
    !spouseEmail ||
    Boolean(spouseEmailError) ||
    Boolean(spouseBirthdayError) ||
    !spouseLegalName ||
    !spouseAddressLine1 ||
    !spouseCity ||
    !spouseState ||
    !spousePostcode ||
    !spouseCountry ||
    !spouseBirthDate ||
    !spouseSsnRef
  ) {
    disabled = true;
  }

  if (!isEmpty(allErrors)) {
    disabled = true;
  }
  return disabled;
};

const SpouseConsent = ({
  resAddressLine1,
  resAptNumber,
  resCity,
  resPostcode,
  resState,
  designatedBeneficiaries,
  spouseEmail,
  spouseLegalName,
  isSpouseAddressSameAsParticipant,
  spouseAddressLine1,
  spouseApt,
  spouseCity,
  spouseState,
  spousePostcode,
  spouseCountry,
  spouseBirthDate,
  spouseSsnRef,
  countryCitizenship,
  handleFormChange,
  setFieldValue,
  allErrors,
  doesRequireSpousalConsent,
  previousStep,
  nextStep,
  handleSave,
  completedSections,
}) => {
  const isMounted = useIsMountedRef();
  const [localAddress, setLocalAddress] = useState({
    address: "",
    aptNumber: "",
    city: "",
    state: "",
    postCode: "",
    country: "",
  });

  const [isEmailTouched, setEmailTouched] = useState("");
  const [shouldShowSSN, setShowSSN] = useState(true);
  const [ssnValue, setSsnValue] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [spouseEmailError, setSpouseEmailError] = useState("");
  const [spouseBirthdayError, setSpouseBirthdayError] = useState("");
  const [prepopBeneficiaryInputs, setPrepopBeneficiaryInputs] = useState({});

  const handleRadioChange = (e) => {
    e.persist();
    setFieldValue(
      "isSpouseAddressSameAsParticipant",
      e.target.value === "true" ? true : false
    );
    if (e.target.name === "isSpouseAddressSameAsParticipant") {
      if (e.target.value === false || e.target.value === "false") {
        wipeSpouseAddress();
      } else {
        initSpouseAddress(e.target.value);
      }
    }
  };

  const handleChange = (e, name) => {
    e.persist();
    handleFormChange(e);
    // Adding the below logic to retain user input
    let newValue = {};
    newValue[name] = e.target.value;
    setLocalAddress((prevState) => ({
      ...prevState,
      ...newValue,
    }));
  };

  const handleEmailBlur = () => {
    setEmailTouched(true);
  };

  const initSpouseAddress = useCallback(
    (isSame) => {
      const addressObject = isSame
        ? {
            address: resAddressLine1,
            aptNumber: resAptNumber,
            city: resCity,
            state: resState,
            postCode: resPostcode,
          }
        : {
            ...localAddress,
          };

      setFieldValue("spouseAddressLine1", addressObject.address);
      setFieldValue("spouseApt", addressObject.aptNumber);
      setFieldValue("spouseCity", addressObject.city);
      setFieldValue("spouseState", addressObject.state);
      setFieldValue("spousePostcode", addressObject.postCode);
      setFieldValue("spouseCountry", countryCitizenship);
    },
    [
      resAddressLine1,
      resAptNumber,
      resCity,
      resState,
      resPostcode,
      countryCitizenship,
      localAddress,
      setFieldValue,
    ]
  );

  function wipeSpouseAddress() {
    setFieldValue("spouseAddressLine1", "");
    setFieldValue("spouseApt", "");
    setFieldValue("spouseCity", "");
    setFieldValue("spouseState", "");
    setFieldValue("spousePostcode", "");
    setFieldValue("spouseCountry", "");
  }

  const handleAptChange = (e, name) => {
    e.persist();
    const value = e.target.value;
    const filtered = value.replace(/[^a-zA-Z0-9  -]/g, "");
    setFieldValue(e.target.name, filtered);

    // Adding the below logic to retain user input
    let newValue = {};
    newValue[name] = e.target.value;
    setLocalAddress((prevState) => ({
      ...prevState,
      ...newValue,
    }));
  };

  const handleDateChange = (date) => {
    const payload = {
      persist: () => {},
      target: {
        name: "spouseBirthDate",
        value: date,
      },
    };
    handleChange(payload);
  };

  const handleSSNChange = async (e) => {
    e.persist();
    // Check for length of the value (9 numbers in SSN and 2 '-' masks), and also check for 'X' added as a part of hiding.
    if (e.target.value.length <= 11) {
      // This regex replaces every non digit value so that only number values are accepted and '-' is removed before saving it to field.
      const inputSSN = e.target.value.replace(/\D/g, "").trim();
      setSsnValue(inputSSN);
      if (inputSSN.length === 9) {
        setLoading(true);
        saveSSN({ ssn: inputSSN }, null)
          .then((str) => setFieldValue("spouseSsnRef", str))
          .catch((error) => console.error(error))
          .finally(() => setLoading(false));
      }

      if (inputSSN.length < 9) {
        setFieldValue("spouseSsnRef", "");
      }
    }
  };

  useEffect(() => {
    const { abortController, signal } = createAbortController();
    async function fetchSSN() {
      if (!doesRequireSpousalConsent) return;
      if (spouseSsnRef && !ssnValue) {
        setLoading(true);
        const str = await getSSN(spouseSsnRef, "spouseSsnRef", signal, null);
        str && setSsnValue(str);
      }
      isMounted.current && setLoading(false);
    }
    fetchSSN();
    return function cleanup() {
      abortController && abortController.abort();
    };
  }, [spouseSsnRef, doesRequireSpousalConsent, isMounted, ssnValue]);

  useEffect(() => {
    if (!doesRequireSpousalConsent) {
      // move past, should run on mount
      return nextStep();
    }
  }, [doesRequireSpousalConsent, nextStep]);

  useEffect(() => {
    if (!doesRequireSpousalConsent) return;
    if (isSpouseAddressSameAsParticipant) {
      initSpouseAddress(isSpouseAddressSameAsParticipant);
    }
    // this should run mount only
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    function resolveInputsFromBeneficiaries() {
      const spouse = designatedBeneficiaries.find((item) => {
        return item.relationshipToAccountHolder === "spouse";
      });
      if (!spouse) return;
      const prepopulated = {};
      if (spouse.dob) {
        !spouseBirthDate && setFieldValue("spouseBirthDate", spouse.dob);
        prepopulated["spouseBirthDate"] = true;
      }
      if (spouse.email) {
        !spouseEmail && setFieldValue("spouseEmail", spouse.email);
        prepopulated["spouseEmail"] = true;
      }
      if (spouse.ssnRef) {
        !spouseSsnRef && setFieldValue("spouseSsnRef", spouse.ssnRef);
        prepopulated["spouseSsnRef"] = true;
      }
      if (spouse.legalName) {
        !spouseLegalName && setFieldValue("spouseLegalName", spouse.legalName);
        prepopulated["spouseLegalName"] = true;
      }
      if (spouse.mailingAddressLine1) {
        !spouseAddressLine1 &&
          setFieldValue("spouseAddressLine1", spouse.mailingAddressLine1);
        !spouseApt && setFieldValue("spouseApt", spouse.mailingAddressApt);
        !spouseCity && setFieldValue("spouseCity", spouse.mailingAddressCity);
        !spouseState &&
          setFieldValue("spouseState", spouse.mailingAddressState);
        !spousePostcode &&
          setFieldValue("spousePostcode", spouse.mailingAddressPostcode);
        !spouseCountry && setFieldValue("spouseCountry", spouse.mailingCountry);
        prepopulated["spouseAddressLine1"] = true;
      }
      setPrepopBeneficiaryInputs(prepopulated);
      // populate with data and then hide inputs by setting useBeneficiaryInputs to true
    }
    resolveInputsFromBeneficiaries();
    // eslint-disable-next-line
  }, [designatedBeneficiaries, setFieldValue]);

  useEffect(() => {
    (async () => {
      if (!doesRequireSpousalConsent) return;
      const error = await singleInputValidator("email", spouseEmail);
      setSpouseEmailError(error ? error[0] : "");
    })();
  }, [spouseEmail, doesRequireSpousalConsent]);

  useEffect(() => {
    (async () => {
      if (!doesRequireSpousalConsent) return;
      const error = await singleInputValidator("birthDate", spouseBirthDate);
      setSpouseBirthdayError(error ? { spouseBirthDate: error } : "");
    })();
  }, [spouseBirthDate, doesRequireSpousalConsent]);

  function handleNextStep() {
    !completedSections.includes(BENEFICIARY) &&
      setFieldValue("completedSections", [...completedSections, BENEFICIARY]);
    if (handleSave) {
      handleSave();
    }
    return nextStep();
  }

  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle title="Spousal Consent" />
        <Box>
          <DisclaimerText>
            <Typography variant="body2">{content.spouse}</Typography>
          </DisclaimerText>

          {!prepopBeneficiaryInputs["spouseEmail"] && (
            <>
              <QuestionSection>
                <Question text="Spouse's Email" required />
                <TextField
                  name="spouseEmail"
                  value={spouseEmail}
                  onChange={handleChange}
                  onBlur={handleEmailBlur}
                  placeholder="Enter email address"
                  fullWidth
                  error={
                    isEmailTouched && Boolean(spouseEmailError) ? true : false
                  }
                  helperText={
                    isEmailTouched && Boolean(spouseEmailError)
                      ? "Enter a valid email address"
                      : ""
                  }
                />
              </QuestionSection>
            </>
          )}

          {!prepopBeneficiaryInputs["spouseLegalName"] && (
            <>
              <QuestionSection>
                <Question text="Spouse's full name" required />
                <TextField
                  name="spouseLegalName"
                  value={spouseLegalName}
                  onChange={handleChange}
                  placeholder="Enter legal name"
                  fullWidth
                />
              </QuestionSection>
            </>
          )}
          {!prepopBeneficiaryInputs["spouseAddressLine1"] && (
            <>
              <QuestionSection>
                <Question
                  text="Is your spouse's address the same as yours?"
                  required
                />
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="isSpouseAddressSameAsParticipant"
                    name="isSpouseAddressSameAsParticipant"
                    value={isSpouseAddressSameAsParticipant}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </QuestionSection>
            </>
          )}
          {!isSpouseAddressSameAsParticipant &&
            !prepopBeneficiaryInputs["spouseAddressLine1"] && (
              <>
                <QuestionSection>
                  <Question text="Address Line 1" required />
                  <TextField
                    id="spouseAddressLine1"
                    name="spouseAddressLine1"
                    value={spouseAddressLine1}
                    fullWidth
                    onChange={(e) => handleChange(e, "address")}
                    placeholder="Enter address"
                  />
                </QuestionSection>
                <Box display="flex" justifyContent="start" alignItems="center">
                  <Box flexGrow={1}>
                    <QuestionSection>
                      <Question text="Apartment/Suite" />
                      <TextField
                        id="spouseApt"
                        name="spouseApt"
                        value={spouseApt}
                        fullWidth
                        onChange={(e) => handleAptChange(e, "spouseApt")}
                        placeholder="Enter Apt/Suite number"
                      />
                    </QuestionSection>
                  </Box>
                  <Box flexGrow={1} pl={30}>
                    <QuestionSection>
                      <Question text="City" required />
                      <TextField
                        id="spouseCity"
                        name="spouseCity"
                        value={spouseCity}
                        fullWidth
                        onChange={(e) => handleChange(e, "city")}
                        placeholder="Enter city"
                      />
                    </QuestionSection>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="start" alignItems="center">
                  <Box flexGrow={1}>
                    <QuestionSection>
                      <Question text="State" required />
                      <Select
                        fullWidth
                        labelId="spouseState"
                        name="spouseState"
                        id="spouseState"
                        value={spouseState || ""}
                        onChange={(e) => handleChange(e, "state")}
                        displayEmpty
                      >
                        <MenuItem value="" disabled dense>
                          Select a state
                        </MenuItem>
                        {mapStatesToOptions().map((opt) => (
                          <MenuItem key={opt.value} value={opt.value}>
                            {opt.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </QuestionSection>
                  </Box>
                  <Box flexGrow={1} pl={30}>
                    <PostCode
                      name="spousePostcode"
                      value={spousePostcode}
                      onChange={setFieldValue}
                      required
                    />
                  </Box>
                </Box>
                <Box flexGrow={1}>
                  <QuestionSection>
                    <Question text="Country" required />
                    <Select
                      fullWidth
                      labelId="spouseCountry"
                      name="spouseCountry"
                      id="spouseCountry"
                      value={spouseCountry || ""}
                      onChange={handleChange}
                      label="Country"
                      displayEmpty
                    >
                      <MenuItem value="" disabled dense>
                        Select a country
                      </MenuItem>
                      {mapCountriesToOptions().map((opt) => (
                        <MenuItem key={opt.value} value={opt.value}>
                          {opt.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </QuestionSection>
                </Box>
              </>
            )}

          {!prepopBeneficiaryInputs["spouseBirthDate"] && (
            <>
              <QuestionSection>
                <Question text="Date of birth" required />
                <DateField
                  value={spouseBirthDate || ""}
                  fieldValue="spouseBirthDate"
                  onChange={handleDateChange}
                  errors={isEmpty(spouseBirthDate) ? {} : spouseBirthdayError}
                  touched={{ spouseBirthDate: true }}
                  helperText={
                    Boolean(spouseBirthdayError)
                      ? "Enter a valid birth date"
                      : ""
                  }
                  disabled={false}
                />
              </QuestionSection>
            </>
          )}

          {!prepopBeneficiaryInputs["spouseSsnRef"] && (
            <>
              <QuestionSSN
                value={
                  ssnValue === "" && !!spouseSsnRef
                    ? "000000000"
                    : shouldShowSSN
                    ? ssnValue
                    : ssnValue.replace(/-/g, "").replace(/.(?=.{4,}$)/g, "X")
                }
                onChange={handleSSNChange}
                name={
                  ssnValue === "" && !!spouseSsnRef
                    ? "hidden"
                    : shouldShowSSN
                    ? "spouseSsnRef"
                    : "concealed"
                }
                id="spouseSsnRef"
                readOnly={isLoading || !shouldShowSSN}
                shouldShowSSN={shouldShowSSN}
                onFocus={() => {}}
                onBlur={() => {}}
                questionText="Spouse Social Security Number"
                buttonOnClick={() => setShowSSN((prevState) => !prevState)}
                showEditIcon={false}
                required
                isLoading={isLoading}
              />
            </>
          )}
        </Box>
        <ProgressButtonGroup
          left={{
            clickHandler: previousStep,
            pageCalling: "Back Click: Spouse Consent Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: Spouse Consent Page",
            disabled: resolveDisabled({
              spouseEmail,
              spouseEmailError,
              spouseBirthdayError,
              spouseLegalName,
              spouseAddressLine1,
              spouseCity,
              spouseState,
              spousePostcode,
              spouseCountry,
              spouseBirthDate,
              spouseSsnRef,
              allErrors,
            }),
          }}
          hasRequiredInputs
        />
      </Container>
    </Box>
  );
};

export default SpouseConsent;
