import React from "react";
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  Link,
} from "@material-ui/core";
import { SOLERA_THEME_NAME } from "../../../../constants";

const SOLERA_TERMS = "https://www.solerabank.com/documents/PrivacyNotice.pdf";

export default function TermsCheckbox({
  classes,
  handleCheckboxChange,
  handleCheckboxChange2,
  agreeTerms,
  agreeTerms2,
  setModalOpen,
  themeName,
  companyName = "",
  termsLink = "",
}) {
  return (
    <>
      {themeName !== SOLERA_THEME_NAME && (
        <FormControl fullWidth>
          <FormControlLabel
            className={classes.agreeTermsForm}
            control={
              <Checkbox
                checked={agreeTerms2}
                onChange={(e) => handleCheckboxChange2(e)}
                name="agreeTerms2"
                color="primary"
              />
            }
            label={
              <Box display="flex" py={10}>
                <Typography variant="body2">
                  {"I have read and acknowledged the terms of the "}
                  <Link
                    color="primary"
                    variant="body2"
                    target="_blank"
                    rel="noreferrer"
                    href={termsLink}
                  >
                    <strong>{companyName} Services Agreement</strong>
                  </Link>
                </Typography>
              </Box>
            }
          />
        </FormControl>
      )}
      <FormControl fullWidth>
        <FormControlLabel
          className={classes.agreeTermsForm}
          control={
            <Checkbox
              checked={agreeTerms}
              onChange={(e) => handleCheckboxChange(e)}
              name="agreeTerms"
              color="primary"
            />
          }
          label={
            <Box display="flex" py={10}>
              <Typography variant="body2">
                {"I have read and acknowledged the terms of the "}
                <Link
                  color="primary"
                  variant="body2"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalOpen(true);
                  }}
                >
                  <strong>Patriot Act</strong>
                </Link>
                {" & to Solera National Bank's "}
                <Link
                  color="primary"
                  variant="body2"
                  target="_blank"
                  rel="noreferrer"
                  href={SOLERA_TERMS}
                >
                  <strong>Privacy Policy</strong>
                </Link>
              </Typography>
            </Box>
          }
        />
      </FormControl>
    </>
  );
}
