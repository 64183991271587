import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { Mixpanel } from "util/mixpanel-instance";

const useStyles = makeStyles({
  hidden: {
    visibility: "hidden",
  },
});

const ProgressButtonGroup = ({ left, right, hasRequiredInputs }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      pt={60}
      pb={40}
      mb={80}
    >
      <Box>
        {left && (
          <Button
            onClick={() => {
              Mixpanel.track(left.pageCalling);
              if (left.clickHandler) {
                left.clickHandler();
              } else {
                return null;
              }
            }}
            disabled={left.disabled || false}
            size="medium"
            variant="text"
            color="primary"
            className={left.isHidden ? classes.hidden : ""}
            startIcon={<ChevronLeftIcon />}
          >
            {left.text ? left.text : "Back"}
          </Button>
        )}
      </Box>
      <Box>
        {right && (
          <Box position="relative">
            <Button
              onClick={() => {
                Mixpanel.track(right.pageCalling);
                if (right.clickHandler) {
                  right.clickHandler();
                } else {
                  return null;
                }
              }}
              disabled={right.disabled || false}
              size="large"
              variant="contained"
              className={right.isHidden ? classes.hidden : ""}
              color="primary"
            >
              {right.text ? right.text : "Next"}
            </Button>
            {hasRequiredInputs && (
              <Box position="absolute" mt={8}>
                <Typography color="textSecondary" variant="caption">
                  * indicates a required field
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

ProgressButtonGroup.propTypes = {
  left: PropTypes.shape({
    text: PropTypes.string,
    clickHandler: PropTypes.func,
    disabled: PropTypes.bool,
    isHidden: PropTypes.bool,
    pageCalling: PropTypes.string,
  }),
  right: PropTypes.shape({
    text: PropTypes.string,
    clickHandler: PropTypes.func,
    disabled: PropTypes.bool,
    isHidden: PropTypes.bool,
    pageCalling: PropTypes.string,
  }),
  hasRequiredInputs: PropTypes.bool,
};

export default ProgressButtonGroup;
