import bcrypt from "bcryptjs";
import * as Sentry from "@sentry/react";
// import rollbar from "config/rollbar";
import firebase, { FIREBASE_URLS } from "config/firebase";
import { request } from "./request";

const sendPostRequest = async (url, payload, customToken) => {
  let token;
  if (customToken) {
    // additional participant custom jwt tokens
    token = customToken;
  } else {
    token = await firebase.auth().currentUser.getIdToken();
  }
  const res = await request(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  });
  if (!res.ok) throw Error("res not ok", await res.text());
  try {
    // maybe no data in res
    const data = await res.json();
    return data;
  } catch (error) {
    return;
  }
};

export const initiateIdVerification = async (payload, customToken) => {
  try {
    const url = customToken
      ? `${FIREBASE_URLS.jumioInitiateTransaction}/additionalParticipant`
      : FIREBASE_URLS.jumioInitiateTransaction;

    const data = await sendPostRequest(url, payload, customToken);
    Sentry.captureMessage("data returned in initiate jumio", {
      extra: { response: data },
      level: Sentry.Severity.Info,
    });
    // rollbar.info("data returned in initiate jumio", data);
    const { transactionReference, redirectUrl, clientToken } = data || {};
    if (!redirectUrl) throw Error("no redirect Url in response");

    // return the redirect url and the transaction object
    return {
      jumioTransaction: {
        ...payload,
        transactionReference,
        clientToken,
      },
      redirectUrl,
    };
  } catch (error) {
    // rollbar.error(error, "initiate id verification");
    console.error("fail to initiate transaction", error);
    Sentry.captureException(error);
    throw error;
  }
};
