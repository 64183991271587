import React from "react";
import { Container, Box, Typography, makeStyles } from "@material-ui/core";

import ScreenTitle from "../ScreenTitle";
import ProgressButtonGroup from "../ProgressButtonGroup";
import IdIntro2Image1 from "assets/id-intro2-image1.png";
import IdIntro2Image12x from "assets/id-intro2-image1@2x.png";
import IdIntro2Image2 from "assets/id-intro2-image2.png";
import IdIntro2Image22x from "assets/id-intro2-image2@2x.png";
import IdIntro2Image3 from "assets/id-intro2-image3.png";
import IdIntro2Image32x from "assets/id-intro2-image3@2x.png";
import IdIntro2Image4 from "assets/id-intro2-image4.png";
import IdIntro2Image42x from "assets/id-intro2-image4@2x.png";

const useStyles = makeStyles({
  container: {
    justifyContent: "space-around",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    paddingTop: 30,
    paddingBottom: 30,
  },
  graphicContainer: {
    height: 155,
    width: 155,
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  graphic: {
    objectFit: "contain",
    margin: -15,
    width: 200,
    height: 200,
  },
});

const JumioIntro1 = ({ currentStep, previousStep, nextStep }) => {
  const classes = useStyles();
  const handleNextStep = () => {
    return nextStep();
  };
  const handlePreviousStep = () => {
    return previousStep();
  };
  const resolveDisabled = () => {
    return false;
  };
  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle step={currentStep} title="Identity Verification" />
        <Box mt={20}>
          <Typography variant="h6">Tips to ensure a smooth process:</Typography>
        </Box>
        <Box className={classes.container}>
          <Box className={classes.graphicContainer}>
            <img
              className={classes.graphic}
              src={IdIntro2Image1}
              srcSet={IdIntro2Image12x}
              alt="Id Verification Tip 1"
            />
          </Box>
          <Box className={classes.graphicContainer}>
            <img
              className={classes.graphic}
              src={IdIntro2Image2}
              srcSet={IdIntro2Image22x}
              alt="Id Verification Tip 2"
            />
          </Box>
        </Box>
        <Box className={classes.container}>
          <Box className={classes.graphicContainer}>
            <img
              className={classes.graphic}
              src={IdIntro2Image3}
              srcSet={IdIntro2Image32x}
              alt="Id Verification Tip 3"
            />
          </Box>
          <Box className={classes.graphicContainer}>
            <img
              className={classes.graphic}
              src={IdIntro2Image4}
              srcSet={IdIntro2Image42x}
              alt="Id Verification Tip 4"
            />
          </Box>
        </Box>
        <ProgressButtonGroup
          left={{
            clickHandler: handlePreviousStep,
            pageCalling: "Back Click: Stripe Tips Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: Stripe Tips Page",
            disabled: resolveDisabled(),
          }}
        />
      </Container>
    </Box>
  );
};

export default JumioIntro1;
