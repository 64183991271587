import React, { useCallback } from "react";
import {
  Box,
  Container,
  TextField,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core";
import AddressAutoComplete from "components/AddressAutocomplete";
import { isEmpty } from "lodash";

import ScreenTitle from "../ScreenTitle";
import { QuestionSection, Question } from "../Question";
import ProgressButtonGroup from "../ProgressButtonGroup";
import PostCode from "../PostCode";
import { mapStatesToOptions } from "util/helpers";

import { CONTACT_INFO } from "../../../../constants";

const MailAddress = ({
  previousStep,
  nextStep,
  currentStep,
  mailingAddressLine1,
  mailingCity,
  mailingPostcode,
  mailingState,
  mailingAddressObj,
  mailingAptNumber,
  completedSections,
  handleFormChange,
  setFieldValue,
  handleSave,
}) => {
  const handleAddressSelection = useCallback(
    (addressComponents) => {
      setFieldValue("mailingAddressLine1", addressComponents.address);
      setFieldValue("mailingCity", addressComponents.city);
      setFieldValue("mailingPostcode", addressComponents.zipCode);
      setFieldValue("mailingState", addressComponents.state);
      setFieldValue("mailingAddressObj", addressComponents.value);
    },
    [setFieldValue]
  );
  const handleChange = (e) => {
    e.persist();
    handleFormChange(e);
  };

  const handleAptChange = (e) => {
    e.persist();
    const value = e.target.value;
    const filtered = value.replace(/[^a-zA-Z0-9  -]/g, "");
    setFieldValue(e.target.name, filtered);
  };

  const resolveDisabled = () => {
    return (
      isEmpty(mailingAddressLine1) ||
      isEmpty(mailingCity) ||
      isEmpty(mailingState) ||
      isEmpty(mailingPostcode)
    );
  };

  const handleNextStep = () => {
    !completedSections.includes(CONTACT_INFO) &&
      setFieldValue("completedSections", [...completedSections, CONTACT_INFO]);
    if (handleSave) handleSave();
    return nextStep();
  };

  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle title="Contact Information" />
        <QuestionSection>
          <Question text="Your mailing address" />
          <AddressAutoComplete
            onSelectAddress={handleAddressSelection}
            initialValue={mailingAddressObj}
          />
        </QuestionSection>
        <Box pt={30}>
          <Typography variant="body2">
            OR manually enter your address below:
          </Typography>
        </Box>
        <QuestionSection>
          <Question text="Address Line 1" required />
          <TextField
            id="mailingAddressLine1"
            name="mailingAddressLine1"
            value={mailingAddressLine1}
            fullWidth
            onChange={handleChange}
            placeholder="Enter address"
          />
        </QuestionSection>
        <Box display="flex" justifyContent="start" alignItems="center">
          <Box flexGrow={1}>
            <QuestionSection>
              <Question text="Apartment/Suite" />
              <TextField
                id="mailingAptNumber"
                name="mailingAptNumber"
                value={mailingAptNumber}
                fullWidth
                onChange={handleAptChange}
                placeholder="Enter Apt/Suite number"
              />
            </QuestionSection>
          </Box>
          <Box flexGrow={1} pl={30}>
            <QuestionSection>
              <Question text="City" required />
              <TextField
                id="mailingCity"
                name="mailingCity"
                value={mailingCity}
                fullWidth
                onChange={handleChange}
                placeholder="Enter city"
              />
            </QuestionSection>
          </Box>
        </Box>
        <Box display="flex" justifyContent="start" alignItems="center">
          <Box flexGrow={1}>
            <QuestionSection>
              <Question text="State" required />
              <Select
                fullWidth
                labelId="mailingState"
                name="mailingState"
                id="mailingState"
                value={mailingState || ""}
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem value="" disabled dense>
                  Select a state
                </MenuItem>
                {mapStatesToOptions().map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.name}
                  </MenuItem>
                ))}
              </Select>
            </QuestionSection>
          </Box>
          <Box flexGrow={1} pl={30}>
            <PostCode
              name="mailingPostcode"
              value={mailingPostcode}
              onChange={setFieldValue}
              required
            />
          </Box>
        </Box>

        <ProgressButtonGroup
          left={{
            clickHandler: previousStep,
            pageCalling: "Back Click: Mail Address Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: Mail Address Page",
            disabled: resolveDisabled(),
          }}
          hasRequiredInputs
        />
      </Container>
    </Box>
  );
};

export default MailAddress;
