// fetch with retries
export async function request(url, options, retries = 3, backoff = 300) {
  try {
    const res = await fetch(url, options);
    if (!res) {
      throw Error("no response returned, res is undefined");
    }
    if (!res.ok) {
      console.log("res not ok", res);
      // sometimes we don't want to retry here
      if (res.status === 403) retries = 0;
      ////////////////////////////////////////
      throw Error(await res.text());
    }
    return res;
  } catch (err) {
    if (retries < 1) {
      throw err;
    }
    await new Promise((resolve) =>
      setTimeout(() => {
        resolve(request(url, options, retries - 1, backoff * 2));
      }, backoff)
    );
  }
}
