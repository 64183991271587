import {
  // RED,
  // DARK_RED,
  // BLUE,
  // LIGHT_BLUE,
  // DARK_BLUE,
  CREAM,
  WARM_BLACK,
  WHITE,
  TRANSPARENT,
} from "./colors";

const palette = {
  primary: {
    main: WHITE,
    dark: WHITE,
  },
  secondary: {
    main: WHITE,
    light: WHITE,
    dark: WHITE,
  },
  info: {
    main: CREAM,
  },
  text: {
    primary: WARM_BLACK,
  },
  background: {
    default: WHITE,
  },
  footer: {
    main: TRANSPARENT,
  },
  header: {
    main: WHITE,
  },
  progress: {
    main: WHITE,
  },
  borderText: {
    main: TRANSPARENT,
  },
};

export default palette;
