import React, { useRef, useEffect } from "react";
import { Box } from "@material-ui/core";
import StepWizard from "react-step-wizard";
import { Formik } from "formik";

import Layout from "components/Layout";
import ProgressBar from "containers/IRAForm/components/ProgressBar";

import { additionalParticipantSchema } from "util/validation";
import { deepDiff } from "util/helpers";
import { ADDITIONAL_PARTICIPANT_STATE } from "util/variableStructure";
import { updateAdditionalParticipant } from "util/handleAdditionalParticipant";

import Home from "containers/IRAForm/components/Home";
import Name from "containers/IRAForm/components/Name";
import Citizenship from "containers/IRAForm/components/Citizenship";
import NonCitizenDenied from "containers/IRAForm/components/NonCitizenDenied";
import ResAddress from "containers/IRAForm/components/ResAddress";
import MailAddress from "containers/IRAForm/components/MailAddress";
import PersonalDetails from "containers/IRAForm/components/PersonalDetails";
import Employment from "containers/IRAForm/components/Employment";
import JumioIntro1 from "containers/IRAForm/components/JumioIntro1";
import JumioIntro2 from "containers/IRAForm/components/JumioIntro2";
import JumioIdEmbed from "containers/IRAForm/components/JumioIdEmbed";
import JumioCompleted from "containers/IRAForm/components/JumioCompleted";
import CitizenshipUpload from "containers/IRAForm/components/CitizenshipUpload";
import SecurityQuestions from "containers/IRAForm/components/SecurityQuestions";
import ReviewAndSubmit from "containers/IRAForm/components/ReviewAndSubmit";
import Confirmation from "../Confirmation";
import { isEmpty, isBoolean } from "lodash";

const SCREENS = [
  Home,
  Name,
  Citizenship,
  NonCitizenDenied,
  ResAddress,
  MailAddress,
  PersonalDetails,
  Employment,
  JumioIntro1,
  JumioIntro2,
  JumioIdEmbed,
  JumioCompleted,
  CitizenshipUpload,
  SecurityQuestions,
  ReviewAndSubmit,
  Confirmation,
];

const INITIAL_STEP = 1;

async function saveHandler(before, after, token) {
  // 1. sanitize before
  // 2. check equality
  // 3. POST to endpoint
  try {
    const diff = deepDiff(after, before);
    if (isEmpty(diff)) {
      return;
    }
    supplementDefaultFields(after, diff);
    updateAdditionalParticipant(token, diff);
  } catch (e) {
    console.error(e);
  }
}

function supplementDefaultFields(after, diff) {
  // these fields are defaulted as bool and won't pick up in a diff, but they still need to be saved to the database
  Object.keys(after).forEach((field) => {
    if (
      isBoolean(ADDITIONAL_PARTICIPANT_STATE[field]) &&
      ADDITIONAL_PARTICIPANT_STATE[field] === after[field]
    ) {
      // form still has defaults, so need to add to diff to save
      diff[field] = after[field];
    }
  });
}

// FOR DEV //
const devStep = 1;
const initStep =
  process.env.NODE_ENV === "development" ? devStep : INITIAL_STEP;
/////////////

const Form = ({ data, idToken }) => {
  const dataRef = useRef(null);

  useEffect(() => {
    if (dataRef.current === null) {
      dataRef.current = data;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // run on mount set ref

  return (
    <Formik
      initialValues={data}
      enableReinitialize={true}
      validationSchema={additionalParticipantSchema}
      onSubmit={(values) => {
        console.log("submitting form");
        saveHandler(dataRef.current, values, idToken);
        dataRef.current = values;
      }}
    >
      {(props) => {
        return (
          <>
            <Layout>
              <Box flex={1}>
                <form>
                  <StepWizard
                    initialStep={initStep}
                    isLazyMount={true}
                    nav={
                      <ProgressBar
                        currentStep={props.currentStep}
                        isAdditionalParticipant
                        values={{
                          progressStage: 1,
                          isApplicationStarted: false,
                          submittedAt: null,
                        }}
                      />
                    }
                    onStepChange={() => {}}
                    instance={() => {}}
                  >
                    {SCREENS.map((Screen, i) => {
                      // console.log("errors", props.errors);
                      return (
                        <Screen
                          key={i}
                          {...props.values}
                          errors={props.errors}
                          touched={props.touched}
                          handleFormChange={props.handleChange}
                          setFieldTouched={props.setFieldTouched}
                          setFieldValue={props.setFieldValue}
                          handleSave={props.handleSubmit}
                          isAdditionalParticipant
                          addPartAuthToken={idToken}
                        />
                      );
                    })}
                  </StepWizard>
                </form>
              </Box>
            </Layout>
          </>
        );
      }}
    </Formik>
  );
};

export default Form;
