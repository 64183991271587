import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  welcomeInfo: {
    paddingTop: 20,
  },
  contentTitleText: {
    fontWeight: 600,
  },
});

export const WelcomeTitle = ({ text }) => {
  return (
    <Box>
      <Typography variant="h4">{text}</Typography>
    </Box>
  );
};

export const WelcomeInfo = ({ text }) => {
  const classes = useStyles();
  return (
    <Box className={classes.welcomeInfo}>
      <Typography variant="body1">{text}</Typography>
    </Box>
  );
};

export const ContentTitle = ({ text }) => {
  const classes = useStyles();
  return (
    <Box className={classes.welcomeInfo}>
      <Typography variant="h6" className={classes.contentTitleText}>
        {text}
      </Typography>
    </Box>
  );
};
export const ContentInfo = ({ text }) => {
  return (
    <Box>
      <Typography variant="body2">{text}</Typography>
    </Box>
  );
};
