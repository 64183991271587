import React, { useContext, useEffect } from "react";
import { ThemeContext } from "components/Theme";
import {
  Container,
  Box,
  Typography,
  Link,
  makeStyles,
} from "@material-ui/core";

import ProgressButtonGroup from "../ProgressButtonGroup";
import { ContentInfo } from "../HomeTitles";

const useStyles = makeStyles((theme) => ({
  text: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
  },
}));

function NonCitizenDenied({ previousStep, status, setFieldValue }) {
  const {
    body1 = "Unfortunately, we cannot create an account for non-citizens who do not have a local/domestic address.",
    heading1 = "PLEASE NOTE",
  } = useContext(ThemeContext)?.copy?.form?.nonCitizen || {};

  const { email = "", phone = "" } =
    useContext(ThemeContext)?.copy?.contactInformation || {};

  const classes = useStyles();
  useEffect(() => {
    status !== "DECLINED" && setFieldValue("status", "DECLINED");
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Container maxWidth="sm">
        <Box p={10} mt={20} className={classes.text}>
          <Box pt={0}>
            <ContentInfo text={heading1} />
          </Box>
          <Box pt={20}>
            <ContentInfo text={body1} />
          </Box>
          {email && (
            <Box pt={20}>
              <Typography variant="body2">
                For additional assistance, please contact us at{" "}
                <Link
                  to={`mailto:${email}`}
                  style={{ textDecoration: "underline" }}
                >
                  {email}
                </Link>
                {phone ? `or ${phone}.` : `.`}
              </Typography>
            </Box>
          )}
        </Box>
        <ProgressButtonGroup
          left={{
            clickHandler: previousStep,
            pageCalling: "Back Click: Non-Citizen Denied Page",
          }}
        />
      </Container>
    </Box>
  );
}

export default NonCitizenDenied;
