import React from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  Slide,
  Toolbar,
  Button,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import copy from "../../copy.json";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} timeout={400} />;
});

export default function TermsDialog({ classes, modalOpen, handleClose }) {
  return (
    <Dialog
      fullScreen
      open={modalOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogContent className={classes.dialog}>
        <Toolbar>
          <Box
            display="flex"
            flex="1"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Button
              variant="text"
              color="default"
              onClick={handleClose}
              endIcon={<CloseIcon>close</CloseIcon>}
            >
              Close
            </Button>
          </Box>
        </Toolbar>
        <Box py={30} px={15}>
          <Box>
            <Typography variant="h4">USA Patriot Act Notice</Typography>
            <Box mt={10}>
              <Typography variant="body1">{copy.patriotAct1}</Typography>
            </Box>
            <Box mt={10}>
              <Typography variant="body1">{copy.patriotAct2}</Typography>
            </Box>
          </Box>
          <Box mt={30}>
            <Typography variant="h6">What this means for you:</Typography>
            <Box mt={10}>
              <Typography variant="body1">{copy.patriotAct3}</Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
