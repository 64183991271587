import React, { useEffect } from "react";
import {
  Box,
  Container,
  TextField,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
} from "@material-ui/core";

import ScreenTitle from "../ScreenTitle";
import { QuestionSection, Question } from "../Question";
import ProgressButtonGroup from "../ProgressButtonGroup";

import { mapIndustriesToOptions } from "util/helpers";
import { PERSON_INFO } from "../../../../constants";

const Employment = ({
  previousStep,
  nextStep,
  currentStep,
  employmentStatus,
  currentOrRecentJobTitle,
  formerEmployer,
  employer,
  industry,
  completedSections,
  handleFormChange,
  setFieldValue,
  errors,
  touched,
  setFieldTouched,
  handleSave,
}) => {
  useEffect(() => {
    if (
      (employmentStatus === "retired" || employmentStatus === "unemployed") &&
      !!employer
    ) {
      setFieldValue("employer", "");
    }

    if (
      (employmentStatus === "self-employed" ||
        employmentStatus === "employed") &&
      !!formerEmployer
    ) {
      setFieldValue("formerEmployer", "");
    }
    // eslint-disable-next-line
  }, [employmentStatus]);
  const handleChange = (e) => {
    e.persist();
    handleFormChange(e);
  };

  const handleBlur = (e) => {
    setFieldTouched(e.target.name, true, false);
  };

  const resolveDisabled = () => {
    if (employmentStatus === "retired" || employmentStatus === "unemployed") {
      return (
        !formerEmployer ||
        Boolean(errors.formerEmployer) ||
        !currentOrRecentJobTitle ||
        Boolean(errors.currentOrRecentJobTitle) ||
        !industry
      );
    } else {
      return (
        !employer ||
        Boolean(errors.employer) ||
        !currentOrRecentJobTitle ||
        Boolean(errors.currentOrRecentJobTitle) ||
        !industry
      );
    }
  };

  const handleNextStep = () => {
    !completedSections.includes(PERSON_INFO) &&
      setFieldValue("completedSections", [...completedSections, PERSON_INFO]);
    if (handleSave) handleSave();
    return nextStep();
  };

  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle title="Employment Information" />
        <QuestionSection>
          <Question text="Employment status" required />
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="employmentStatus"
              name="employmentStatus"
              value={employmentStatus}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="employed"
                control={<Radio />}
                label="Employed"
              />
              <FormControlLabel
                value="self-employed"
                control={<Radio />}
                label="Self-employed"
              />
              <FormControlLabel
                value="unemployed"
                control={<Radio />}
                label="Unemployed"
              />
              <FormControlLabel
                value="retired"
                control={<Radio />}
                label="Retired"
              />
            </RadioGroup>
          </FormControl>
        </QuestionSection>
        <QuestionSection>
          <Question
            text={`${
              employmentStatus === "retired" ||
              employmentStatus === "unemployed"
                ? "Most recent"
                : "Current"
            } job title`}
            required
          />
          <TextField
            id="currentOrRecentJobTitle"
            name="currentOrRecentJobTitle"
            value={currentOrRecentJobTitle}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            required
            placeholder="Enter job title"
            helperText={
              touched.currentOrRecentJobTitle
                ? errors.currentOrRecentJobTitle
                : ""
            }
            error={
              touched.currentOrRecentJobTitle &&
              Boolean(errors.currentOrRecentJobTitle)
            }
          />
        </QuestionSection>
        <QuestionSection>
          <Question
            text={`Name of ${
              employmentStatus === "retired" ||
              employmentStatus === "unemployed"
                ? "most recent"
                : ""
            } ${employmentStatus === "self-employed" ? "company" : "employer"}`}
            required
          />
          <TextField
            id={
              employmentStatus === "retired" ||
              employmentStatus === "unemployed"
                ? "formerEmployer"
                : "employer"
            }
            name={
              employmentStatus === "retired" ||
              employmentStatus === "unemployed"
                ? "formerEmployer"
                : "employer"
            }
            value={
              employmentStatus === "retired" ||
              employmentStatus === "unemployed"
                ? formerEmployer
                : employer
            }
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            required
            placeholder={`Enter ${
              employmentStatus === "retired" ||
              employmentStatus === "unemployed"
                ? "former "
                : ""
            }employer name`}
            helperText={
              employmentStatus === "retired" ||
              employmentStatus === "unemployed"
                ? touched.formerEmployer
                  ? errors.formerEmployer
                  : ""
                : touched.employer
                ? errors.employer
                : ""
            }
            error={
              employmentStatus === "retired" ||
              employmentStatus === "unemployed"
                ? touched.formerEmployer && Boolean(errors.formerEmployer)
                : touched.employer && Boolean(errors.employer)
            }
          />
        </QuestionSection>
        <QuestionSection>
          <Question text="Select your Industry" required />
          <Select
            fullWidth
            labelId="industrySelect"
            name="industry"
            id="industry"
            value={industry || ""}
            onChange={handleChange}
          >
            {mapIndustriesToOptions().map((opt) => (
              <MenuItem key={opt.value} value={opt.value}>
                {opt.name}
              </MenuItem>
            ))}
          </Select>
        </QuestionSection>
        <ProgressButtonGroup
          left={{
            clickHandler: previousStep,
            pageCalling: "Back Click: Employment Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: Employment Page",
            disabled: resolveDisabled(),
          }}
          hasRequiredInputs
        />
      </Container>
    </Box>
  );
};

export default Employment;
