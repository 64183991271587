import React from "react";
import { Box, Typography, Link } from "@material-ui/core";

// just a hard refresh keeps this easier
export default function LogInstead({ screenType }) {
  return (
    <Typography variant="body2">
      {screenType === "signup"
        ? "Already have an account?"
        : "Don't have an account?"}{" "}
      <Box component="span">
        <Link
          href={screenType === "signup" ? "/register?login=true" : "/register"}
          style={{ textDecoration: "underline" }}
        >
          Click here
        </Link>{" "}
        to {screenType === "signup" ? "log in" : "sign up"} instead.
      </Box>
    </Typography>
  );
}
