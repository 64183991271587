import React, { useRef } from "react";

import { TextField } from "@material-ui/core";

const DateField = ({
  value,
  fieldValue,
  onChange,
  onBlur,
  errors,
  touched,
  disabled = false,
}) => {
  const localDate = useRef();
  const handleChange = (e) => {
    e.persist();
    if (e.target.value.length === 0) {
      onChange("");
    }
    const regExp = new RegExp(/[0-9/]/g);
    if (e.target.value.length <= 10 && regExp.test(e.target.value)) {
      let date = e.target.value;
      let formattedDate = "";

      if (
        localDate.current &&
        localDate.current.length === 3 &&
        date.length === 2
      ) {
        date = date.substring(0, 1);
      }

      if (
        localDate.current &&
        localDate.current.length === 6 &&
        date.length === 5
      ) {
        date = date.substring(0, 4);
      }

      date = date.replace(/\D/g, "");

      if (date.length === 1) {
        formattedDate = date;
      }

      if (date.length > 1) {
        formattedDate = date.substring(0, 2) + "/" + date.substring(2, 3);
      }

      if (date.length > 3) {
        formattedDate += date.substring(3, 4) + "/" + date.substring(4);
      }

      localDate.current = formattedDate;
      onChange(formattedDate);
    }
  };

  return (
    <TextField
      value={value}
      placeholder="MM/DD/YYYY"
      id={fieldValue}
      name={fieldValue}
      onChange={handleChange}
      onBlur={onBlur}
      fullWidth
      helperText={touched[fieldValue] ? errors[fieldValue] : ""}
      error={touched[fieldValue] && Boolean(errors[fieldValue])}
      disabled={disabled}
    />
  );
};

export default DateField;
