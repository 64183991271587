// specifically handles requests for additional participant flow (separate from the main app flow)
import { FIREBASE_URLS } from "config/firebase";
import { request } from "./request";
// import rollbar from "config/rollbar";
import * as Sentry from "@sentry/react";

export async function getAdditionalParticipant({
  primaryId = "",
  additionalId = "",
  phoneNumber = "",
}) {
  try {
    if (!primaryId || !additionalId || !phoneNumber)
      throw Error("no data provided");
    const url = `${FIREBASE_URLS.handleAdditionalParticipants}?pri=${primaryId}&add=${additionalId}&tel=${phoneNumber}`;
    const res = await request(url);
    const { data, token } = await res.json();
    return { data, token };
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function updateAdditionalParticipant(apiToken, data) {
  try {
    if (!apiToken) throw Error("no token");
    const url = `${FIREBASE_URLS.handleAdditionalParticipants}`;

    const opts = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
      body: JSON.stringify(data),
    };
    await request(url, opts);
  } catch (error) {
    console.error(error, "update");
    // rollbar.error(error, "updateAdditionalParticipant");
    Sentry.captureException(error);
    throw error;
  }
}
