import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  Select,
  Input,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import { isEmpty } from "lodash";

import { singleInputValidator } from "util/validation";
import { QuestionSection, Question } from "../Question";
import QuestionSSN from "../QuestionSSN";
import TextMaskPhoneNumber from "../TextMaskPhoneNumber";
import PostCode from "../PostCode";
import DateField from "../DateField";
import { getSSN, saveSSN } from "util/manageSSN";
import {
  mapCountriesToOptions,
  useIsMountedRef,
  createAbortController,
} from "util/helpers";
import { MARITAL_STATUS_MARRIED } from "../../../../constants";

const useStyles = makeStyles((theme) => ({
  inputRow: {
    flexWrap: "wrap",
    display: "flex",
    justifyContent: "space-between",
  },
  inputCol1: {
    flex: "0 0 100%",
    marginBottom: 0,
    [theme.breakpoints.up("sm")]: {
      flex: "0 0 60%",
    },
  },
  inputCol2: {
    flex: "0 0 100%",
    marginBottom: 0,
    [theme.breakpoints.up("sm")]: {
      flex: "0 0 30%",
    },
  },
}));

function TrustBeneficiaryComponent({
  beneficiary,
  index,
  handleChange,
  maritalStatus,
  // handleDateChange,
  handleRefChange,
  emailError,
  handleErrors,
  addPartAuthToken,
}) {
  const {
    entityType,
    legalName,
    taxIdRef,
    ssnRef,
    sharePercentage,
    gender,
    dob,
    relationshipToAccountHolder,
    preferredContactMethod,
    email,
    phoneNumber,
    mailingAddressLine1,
    mailingAddressApt,
    mailingAddressCity,
    mailingAddressPostcode,
    mailingAddressState,
    mailingAddressCountry,
  } = beneficiary;
  const localClasses = useStyles();
  const isMounted = useIsMountedRef();
  const [shouldShowSSN, setShowSSN] = useState(true);
  const [ssnValue, setSsnValue] = useState("");
  const [taxIdValue, setTaxIdValue] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [isEmailTouched, setEmailTouched] = useState(false);
  const [dobError, setDobError] = useState(null);

  useEffect(() => {
    if (maritalStatus !== MARITAL_STATUS_MARRIED) {
      const payload = {
        persist: () => {},
        target: {
          name: "relationshipToAccountHolder",
          value: "non-spouse",
        },
      };
      handleChange(payload);
    }
    // eslint-disable-next-line
  }, [maritalStatus]);

  useEffect(() => {
    const { abortController, signal } = createAbortController();
    async function fetchSSN() {
      const localRef = entityType === "person" ? ssnRef : taxIdRef;
      const refName = entityType === "person" ? "ben-ssnRef" : "ben-taxIdRef";
      if (localRef && (entityType === "person" ? !ssnValue : !taxIdValue)) {
        isLoading && setLoading(true);
        const str = await getSSN(localRef, refName, signal, addPartAuthToken);
        if (str) {
          entityType === "person" ? setSsnValue(str) : setTaxIdValue(str);
        }
      }
      isMounted.current && setLoading(false);
    }
    fetchSSN();
    return function cleanup() {
      abortController && abortController.abort();
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssnRef, taxIdRef]);

  const handleSSNChange = async (e) => {
    e.persist();
    let newValue = "";
    const completeLength = entityType === "person" ? 11 : 10;
    const strippedLength = entityType === "person" ? 9 : 9;
    if (e.target.value.length <= completeLength) {
      const inputTaxId = e.target.value;
      entityType === "person"
        ? setSsnValue(inputTaxId)
        : setTaxIdValue(inputTaxId);

      if (inputTaxId.length === completeLength) {
        // strip and save
        setLoading(true);
        const str = await saveSSN(
          {
            ssn: inputTaxId.replace(/\D/g, "").trim(),
          },
          addPartAuthToken
        );
        setLoading(false);
        newValue = str;
      }

      if (inputTaxId.length < strippedLength) {
        newValue = "";
      }
    }

    handleRefChange(newValue, entityType === "person" ? "ssnRef" : "taxIdRef");
  };

  const handleSsnBlur = () => {};

  const handleSsnFocus = () => {};

  const handleEmailBlur = () => {
    setEmailTouched(true);
  };

  const handlePostCode = (name, value) => {
    // TODO: need to spoof this a bit so parent doesn't break - we can resolve this in the Postcode component later
    const payload = {
      persist: () => {},
      target: {
        name,
        value,
      },
    };
    handleChange(payload);
  };

  const handleDateChange = (date) => {
    const payload = {
      persist: () => {},
      target: {
        name: "dob",
        value: date,
      },
    };
    handleChange(payload);
    handleDateValidation(date);
  };

  const handleDateValidation = async (value) => {
    const key = `dob${index}`;
    const error = await singleInputValidator("birthDate", value);
    setDobError({ dob: error });
    if (isEmpty(error)) {
      handleErrors({ [key]: null });
    } else {
      handleErrors({ [key]: error[0] });
    }
  };

  return (
    <div>
      <Typography variant="h6">Beneficiary {index + 1}</Typography>
      <QuestionSection>
        <Question text="Entity type" required />
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="entityType"
            name="entityType"
            value={entityType}
            onChange={handleChange}
            row
          >
            <FormControlLabel
              value="person"
              control={<Radio disabled={isLoading} />}
              label="Person"
            />
            <FormControlLabel
              value="nonPerson"
              control={<Radio disabled={isLoading} />}
              label="Non-Person (Business, Trust, etc)"
            />
          </RadioGroup>
        </FormControl>
      </QuestionSection>
      <QuestionSection>
        <Question text="Full Name" required />
        <TextField
          placeholder="Enter full legal name"
          fullWidth
          value={legalName}
          name="legalName"
          onChange={handleChange}
          disabled={isLoading}
        />
      </QuestionSection>
      <Box className={localClasses.inputRow}>
        {entityType === "person" ? (
          <Box className={localClasses.inputCol1}>
            <QuestionSSN
              value={
                ssnValue === "" && !!ssnRef
                  ? "000000000"
                  : shouldShowSSN
                  ? ssnValue
                  : ssnValue.replace(/-/g, "").replace(/.(?=.{4,}$)/g, "X")
              }
              onChange={handleSSNChange}
              name={
                ssnValue === "" && !!ssnRef
                  ? "hidden"
                  : shouldShowSSN
                  ? "ssnRef"
                  : "concealed"
              }
              id="ssnRef"
              readOnly={isLoading || !shouldShowSSN}
              shouldShowSSN={shouldShowSSN}
              onFocus={handleSsnFocus}
              onBlur={handleSsnBlur}
              questionText="Social Security Number"
              buttonOnClick={() => setShowSSN((prevState) => !prevState)}
              showEditIcon={false}
              required
              isLoading={isLoading}
            />
          </Box>
        ) : (
          <Box className={localClasses.inputCol1}>
            <QuestionSSN
              value={
                taxIdValue === "" && !!taxIdRef
                  ? "000000000"
                  : shouldShowSSN
                  ? taxIdValue
                  : taxIdValue.replace(/-/g, "").replace(/.(?=.{4,}$)/g, "X")
              }
              onChange={handleSSNChange}
              name={
                taxIdValue === "" && !!taxIdRef
                  ? "hidden"
                  : shouldShowSSN
                  ? "taxIdRef"
                  : "concealed"
              }
              id="taxIdRef"
              readOnly={isLoading || !shouldShowSSN}
              shouldShowSSN={shouldShowSSN}
              onFocus={handleSsnFocus}
              onBlur={handleSsnBlur}
              questionText="Tax Id"
              buttonOnClick={() => setShowSSN((prevState) => !prevState)}
              showEditIcon={false}
              required
              isLoading={isLoading}
            />
          </Box>
        )}
        <Box className={localClasses.inputCol2}>
          <QuestionSection>
            <Question text="Share %" required />
            <TextField
              type="number"
              fullWidth
              placeholder="Enter %"
              value={sharePercentage}
              name="sharePercentage"
              onChange={handleChange}
              inputProps={{ min: 1, max: 100 }}
              InputProps={{
                endAdornment: "%",
              }}
              disabled={isLoading}
            />
          </QuestionSection>
        </Box>
      </Box>
      {entityType === "person" && (
        <QuestionSection>
          <Question text="Beneficiary gender" required />
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="gender"
              value={gender}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="male"
                control={<Radio disabled={isLoading} />}
                label="Male"
              />
              <FormControlLabel
                value="female"
                control={<Radio disabled={isLoading} />}
                label="Female"
              />
            </RadioGroup>
          </FormControl>
        </QuestionSection>
      )}
      {entityType === "person" && (
        <QuestionSection>
          <Question text="Date of birth" required />

          <DateField
            value={dob || ""}
            fieldValue="dob"
            onChange={handleDateChange}
            errors={dobError || {}}
            touched={{ dob: true }}
            disabled={isLoading}
          />
        </QuestionSection>
      )}
      {entityType === "person" && maritalStatus === MARITAL_STATUS_MARRIED && (
        <QuestionSection>
          <Question text="Relationship to account holder" required />
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="relationshipToAccountHolder"
              name="relationshipToAccountHolder"
              value={relationshipToAccountHolder}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="spouse"
                control={<Radio disabled={isLoading} />}
                label="Spouse"
              />
              <FormControlLabel
                value="non-spouse"
                control={<Radio disabled={isLoading} />}
                label="Non-spouse"
              />
            </RadioGroup>
          </FormControl>
        </QuestionSection>
      )}
      <QuestionSection>
        <Question text="Preferred Contact Method" required />
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="preferredContactMethod"
            name="preferredContactMethod"
            value={preferredContactMethod}
            onChange={handleChange}
          >
            <FormControlLabel
              value="email"
              control={<Radio disabled={isLoading} />}
              label="Email"
            />
            <FormControlLabel
              value="phone"
              control={<Radio disabled={isLoading} />}
              label="Phone"
            />
            <FormControlLabel
              value="mailingAddress"
              control={<Radio disabled={isLoading} />}
              label="Mailing Address"
            />
          </RadioGroup>
        </FormControl>
      </QuestionSection>
      {preferredContactMethod === "email" && (
        <QuestionSection>
          <Question text="Email of beneficiary" required />
          <TextField
            name="email"
            value={email}
            onChange={handleChange}
            onBlur={handleEmailBlur}
            placeholder="Enter a valid email address"
            fullWidth
            error={isEmailTouched && Boolean(emailError)}
            helperText={
              isEmailTouched &&
              Boolean(emailError) &&
              "Enter a valid email address"
            }
            disabled={isLoading}
          />
        </QuestionSection>
      )}
      {preferredContactMethod === "phone" && (
        <QuestionSection>
          <Question text="Mobile number of beneficiary" required />
          <Input
            id="phoneNumber"
            name="phoneNumber"
            value={phoneNumber}
            required
            fullWidth
            onChange={handleChange}
            placeholder="Enter mobile number"
            inputComponent={TextMaskPhoneNumber}
            disabled={isLoading}
          />
        </QuestionSection>
      )}
      {preferredContactMethod === "mailingAddress" && (
        <>
          <QuestionSection>
            <Question text="Address Line 1" required />
            <TextField
              id="mailingAddressLine1"
              name="mailingAddressLine1"
              value={mailingAddressLine1}
              fullWidth
              onChange={handleChange}
              placeholder="Enter address"
              disabled={isLoading}
            />
          </QuestionSection>
          <Box display="flex" justifyContent="start" alignItems="center">
            <Box flexGrow={1}>
              <QuestionSection>
                <Question text="Apartment/Suite" />
                <TextField
                  id="mailingAddressApt"
                  name="mailingAddressApt"
                  value={mailingAddressApt}
                  fullWidth
                  onChange={handleChange}
                  placeholder="Enter Apt/Suite number"
                  disabled={isLoading}
                />
              </QuestionSection>
            </Box>
            <Box flexGrow={1} pl={30}>
              <QuestionSection>
                <Question text="City" required />
                <TextField
                  id="mailingAddressCity"
                  name="mailingAddressCity"
                  value={mailingAddressCity}
                  fullWidth
                  onChange={handleChange}
                  placeholder="Enter city"
                  disabled={isLoading}
                />
              </QuestionSection>
            </Box>
          </Box>
          <Box display="flex" justifyContent="start" alignItems="center">
            <Box flexGrow={1}>
              <QuestionSection>
                <Question text="State" required />
                <TextField
                  id="mailingAddressState"
                  name="mailingAddressState"
                  value={mailingAddressState}
                  fullWidth
                  onChange={handleChange}
                  placeholder="Enter state"
                  disabled={isLoading}
                />
              </QuestionSection>
            </Box>
            <Box flexGrow={1} pl={30}>
              <PostCode
                name="mailingAddressPostcode"
                value={mailingAddressPostcode}
                onChange={handlePostCode}
                required
                foreign
                disabled={isLoading}
              />
            </Box>
          </Box>
          <QuestionSection>
            <Question text="Country" required />
            <Select
              displayEmpty
              id="mailingAddressCountry"
              name="mailingAddressCountry"
              value={mailingAddressCountry}
              onChange={handleChange}
              inputProps={{ "aria-label": "Without label" }}
              fullWidth
              disabled={isLoading}
            >
              {mapCountriesToOptions().map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.name}
                </MenuItem>
              ))}
            </Select>
          </QuestionSection>
        </>
      )}
    </div>
  );
}

export default TrustBeneficiaryComponent;
