import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, makeStyles } from "@material-ui/core";

// import SectionTitle from "../SectionTitle";
// import { getSection } from "../../../../util/helpers";

const useStyles = makeStyles({
  text: {
    letterSpacing: 0.15,
    fontSize: 20,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "24px",
  },
});

const ScreenTitle = ({ title, step, endAdornment }) => {
  const classes = useStyles();
  // const sectionName = step && getSection(step);
  return (
    <Box>
      {/*step && <SectionTitle title={sectionName} />*/}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" className={classes.text} data-testid="heading">
          {title}
        </Typography>
        {endAdornment && (
          <Typography variant="h6" className={classes.text}>
            {endAdornment}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

ScreenTitle.propTypes = {
  title: PropTypes.string.isRequired,
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  endAdornment: PropTypes.string,
};

export default ScreenTitle;
