import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { Box, CircularProgress } from "@material-ui/core";
import { AuthContext } from "components/Auth";
import { ApplicationDataContext } from "components/ApplicationData";
import Header from "components/Header";
import Layout from "components/Layout";
import { ThemeContext } from "components/Theme";

import { SOLERA_THEME_NAME } from "../../constants";

const LoadingComponent = () => {
  const { isLoadingAuthListener, isLoggedIn, isOldTab } =
    useContext(AuthContext) || {};
  const { accountType, isDataInit, initialUser } =
    useContext(ApplicationDataContext) || {};
  const { themeName = "", isLoading: isLoadingTheme } =
    useContext(ThemeContext) || {};

  let homeUrl = `/welcome-to-${themeName}`;
  homeUrl += themeName === SOLERA_THEME_NAME ? "-banking" : "";
  const isLoading = isLoadingAuthListener || isLoadingTheme;
  return (
    <>
      {isLoading ? (
        <>
          <Header />
          <Loading />
        </>
      ) : isOldTab === true ? (
        <Redirect to="/tab-inactive" />
      ) : isLoggedIn ? (
        // before the accounts are created but after login
        // if a returning user then initialUser is always null
        initialUser ? (
          <>
            <Redirect to="/account-setup" />
          </>
        ) : isDataInit === false ? (
          <>
            <Header />
            <Loading />
          </>
        ) : accountType ? (
          <Redirect to="/account-creation" />
        ) : (
          <Redirect to="/account-setup" />
        )
      ) : (
        <Redirect to={homeUrl} />
      )}
    </>
  );
};

export function Loading() {
  return (
    <>
      <Layout>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flex={1}
        >
          <CircularProgress size={50} />
        </Box>
      </Layout>
    </>
  );
}

export default LoadingComponent;
