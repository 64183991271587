import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  TextField,
  Input,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  makeStyles,
} from "@material-ui/core";
import { firebase as firebaseAdmin } from "@firebase/app";
import * as Sentry from "@sentry/react";

// import rollbar from "config/rollbar";
import ScreenTitle from "../ScreenTitle";
import { QuestionSection, Question } from "../Question";
import ProgressButtonGroup from "../ProgressButtonGroup";
import TextMaskPhoneNumber from "../TextMaskPhoneNumber";
import { ContentInfo } from "../HomeTitles";
import firebase from "config/firebase";
import disclaimer from "./index.json";

import { ADD_PARTICIPANT } from "../../../../constants";

import { isEmpty } from "lodash";
import { singleInputValidator } from "util/validation";

const useStyles = makeStyles((theme) => ({
  disclaimer: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
  },
}));

const participantsCollection = firebase.firestore().collection("participants");

const AdditionalParticipant = ({
  previousStep,
  nextStep,
  currentStep,
  applicationId,
  addAdditionalParticipant,
  additionalParticipantFirstName,
  additionalParticipantMiddleName,
  additionalParticipantLastName,
  email: primaryEmail,
  additionalParticipantEmail,
  additionalParticipantPhone,
  additionalParticipantId,
  participantRefId,
  completedSections,
  handleFormChange,
  handleSave,
  errors,
  touched,
  setFieldTouched,
  setFieldValue,
  applicationProvider = "",
  applicationReferrer = "",
}) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [disabledEmail, setDisabledEmail] = useState(true);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(null);
  const [disabledPhoneNumber, setDisabledPhoneNumber] = useState(true);

  useEffect(() => {
    function setState() {
      if (additionalParticipantEmail && email === "") {
        setEmail(additionalParticipantEmail);
        setDisabledEmail(false);
      }
      if (additionalParticipantPhone && phoneNumber === "") {
        setPhoneNumber(additionalParticipantPhone);
        setDisabledPhoneNumber(false);
      }
    }
    setState();
    // eslint-disable-next-line
  }, [additionalParticipantEmail, additionalParticipantPhone]);

  const classes = useStyles();
  const handleChange = (e) => {
    e.persist();
    handleFormChange(e);
  };

  const handleEmailChange = (e) => {
    e.persist();
    const val = e.target.value;
    setEmail(val);
  };

  const handleEmailValidation = async (value) => {
    if (!value) {
      return;
    }
    if (value.length < 4) {
      if (disabledEmail === false) {
        setDisabledEmail(true);
      }
      return;
    }
    let error = await singleInputValidator("email", value);

    if (primaryEmail === value) {
      error = [
        "Participant email cannot be the same as the primary applicant's email address",
      ];
    }

    setEmailError(error);
    if (isEmpty(error)) {
      setDisabledEmail(false);
      setFieldValue("additionalParticipantEmail", value);
    } else {
      setDisabledEmail(true);
    }
  };

  const handlePhoneNumberChange = (e) => {
    e.persist();
    const val = e.target.value;
    setPhoneNumber(val);
    handlePhoneNumberValidation(val);
  };

  const handlePhoneNumberValidation = async (value) => {
    if (!value) {
      return;
    }
    if (value.length < 14) {
      if (disabledPhoneNumber === false) {
        setDisabledPhoneNumber(true);
      }
      return;
    }
    const error = await singleInputValidator("phoneNumber", value);

    setPhoneNumberError(error);
    if (isEmpty(error)) {
      setDisabledPhoneNumber(false);
      setFieldValue("additionalParticipantPhone", value.replace(/\D/g, ""));
    } else {
      setDisabledPhoneNumber(true);
    }
  };

  const handleBlur = (e) => {
    setFieldTouched(e.target.name, true, false);
  };

  const handleEmailBlur = (e) => {
    handleEmailValidation(e.target.value);
  };

  const handleChangeRadio = (e) => {
    e.persist();
    setFieldValue(e.target.name, e.target.value === "true" ? true : false);
  };

  const removeAdditionalParticipant = () => {
    setFieldValue("additionalParticipantFirstName", "");
    setFieldValue("additionalParticipantMiddleName", "");
    setFieldValue("additionalParticipantLastName", "");
    setFieldValue("additionalParticipantEmail", "");
    setFieldValue("additionalParticipantPhone", "");
    setFieldValue("additionalParticipantId", "");
  };

  const resolveAdditionalParticipantCreation = async () => {
    if (addAdditionalParticipant === false) {
      if (additionalParticipantId) {
        await participantsCollection.doc(additionalParticipantId).delete();
        removeAdditionalParticipant();
      }
      return;
    }
    if (additionalParticipantId) return await updateAdditionalParticipant();
    const payload = {
      firstName: additionalParticipantFirstName || "",
      middleName: additionalParticipantMiddleName || "",
      lastName: additionalParticipantLastName || "",
      email: email || additionalParticipantEmail || "",
      phoneNumber: additionalParticipantPhone || "",
      isPrimary: false,
      primaryAccountParticipantId: participantRefId,
      applicationId,
      createdAt: firebaseAdmin.firestore.Timestamp.now(),
      updatedAt: firebaseAdmin.firestore.Timestamp.now(),
      applicationProvider,
      applicationReferrer: applicationReferrer || "",
    };

    try {
      const { id } = await participantsCollection.add(payload);
      setFieldValue("additionalParticipantId", id);
    } catch (error) {
      // rollbar.log("error with payload", JSON.stringify(payload));
      // rollbar.error(error, "create an additional participant");
      // console.error(error);
      console.error("error with payload", JSON.stringify(payload), error);
      Sentry.captureException(error);
    }
  };

  const updateAdditionalParticipant = () => {
    try {
      participantsCollection.doc(additionalParticipantId).update({
        firstName: additionalParticipantFirstName || "",
        middleName: additionalParticipantMiddleName || "",
        lastName: additionalParticipantLastName || "",
        email: email || additionalParticipantEmail || "",
        phoneNumber: additionalParticipantPhone || "",
        updatedAt: firebaseAdmin.firestore.Timestamp.now(),
      });
    } catch (error) {
      console.error(error);
      // rollbar.error(error, "update additional participant");
      Sentry.captureException(error);
    }
  };

  const resolveDisabled = () => {
    return (
      addAdditionalParticipant &&
      (!additionalParticipantFirstName ||
        !additionalParticipantLastName ||
        !email ||
        Boolean(errors.additionalParticipantFirstName) ||
        Boolean(errors.additionalParticipantLastName) ||
        !!emailError ||
        disabledEmail ||
        !phoneNumber ||
        disabledPhoneNumber)
    );
  };

  const handleNextStep = () => {
    resolveAdditionalParticipantCreation();
    !completedSections.includes(ADD_PARTICIPANT) &&
      setFieldValue("completedSections", [
        ...completedSections,
        ADD_PARTICIPANT,
      ]);
    if (handleSave) {
      handleSave();
    }
    return nextStep();
  };

  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle title="Additional Participant" />
        <QuestionSection>
          <Question
            text="Would you like to add another plan participant?"
            required
          />
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="addAdditionalParticipant"
              name="addAdditionalParticipant"
              value={addAdditionalParticipant}
              onChange={handleChangeRadio}
              row
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </QuestionSection>
        {addAdditionalParticipant && (
          <>
            <QuestionSection>
              <Question text="Participant first name" required />
              <TextField
                id="additionalParticipantFirstName"
                name="additionalParticipantFirstName"
                value={additionalParticipantFirstName}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                required
                helperText={
                  touched.additionalParticipantFirstName
                    ? errors.additionalParticipantFirstName
                    : ""
                }
                error={
                  touched.additionalParticipantFirstName &&
                  Boolean(errors.additionalParticipantFirstName)
                }
                placeholder="Enter first name"
              />
            </QuestionSection>
            <QuestionSection>
              <Question text="Participant middle name" />
              <TextField
                id="additionalParticipantMiddleName"
                name="additionalParticipantMiddleName"
                value={additionalParticipantMiddleName}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                required
                helperText={
                  touched.additionalParticipantMiddleName
                    ? errors.additionalParticipantMiddleName
                    : ""
                }
                error={
                  touched.additionalParticipantMiddleName &&
                  Boolean(errors.additionalParticipantMiddleName)
                }
                placeholder="Optional"
              />
            </QuestionSection>
            <QuestionSection>
              <Question text="Participant last name" required />
              <TextField
                id="additionalParticipantLastName"
                name="additionalParticipantLastName"
                value={additionalParticipantLastName}
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                required
                helperText={
                  touched.additionalParticipantLastName
                    ? errors.additionalParticipantLastName
                    : ""
                }
                error={
                  touched.additionalParticipantLastName &&
                  Boolean(errors.additionalParticipantLastName)
                }
                placeholder="Enter last name"
              />
            </QuestionSection>
            <QuestionSection>
              <Question text="Participant Email" required />
              <TextField
                id="additionalParticipantEmail"
                name="additionalParticipantEmail"
                value={email}
                fullWidth
                type="email"
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                error={Boolean(emailError)}
                required
                helperText={Boolean(emailError) ? emailError : ""}
                placeholder="Enter email"
              />
            </QuestionSection>
            <QuestionSection>
              <Question text="Participant mobile number" required />
              <Input
                id="additionalParticipantPhone"
                name="additionalParticipantPhone"
                value={phoneNumber}
                onBlur={handleBlur}
                required
                fullWidth
                onChange={handlePhoneNumberChange}
                error={Boolean(phoneNumberError)}
                placeholder="Enter mobile"
                inputComponent={TextMaskPhoneNumber}
              />
              <FormHelperText error={Boolean(phoneNumberError)}>
                {Boolean(phoneNumberError) ? phoneNumberError : ""}
              </FormHelperText>
            </QuestionSection>
            <Box p={10} mt={20} className={classes.disclaimer}>
              {Object.keys(disclaimer).map((key, i) => {
                const paddingTop = i === 0 ? 0 : 20;
                return (
                  <Box pt={paddingTop} key={i}>
                    <ContentInfo text={disclaimer[key]} />
                  </Box>
                );
              })}
            </Box>
          </>
        )}
        <ProgressButtonGroup
          left={{
            clickHandler: previousStep,
            pageCalling: "Back Click: Additional Participant Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: Additional Participant Page",
            disabled: resolveDisabled(),
          }}
          hasRequiredInputs
        />
      </Container>
    </Box>
  );
};

export default AdditionalParticipant;
