import React, { Component } from "react";
// import rollbar from "config/rollbar";
import * as Sentry from "@sentry/react";

// import styles from "./styles.module.scss";

// catch UI bugs and fail gracefully
class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    // rollbar.error(error, errorInfo);
    // TODO: replace all ErrorBoundary uses with Sentry.ErrorBoundary component.
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      // TODO: add custom UI
      return (
        // <div className={styles.Container}>
        <div>
          <h4>Sorry, Something went wrong.</h4>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
