import React from "react";
import TextField from "@material-ui/core/TextField";

import { QuestionSection, Question } from "../Question";

const PostCode = ({
  name,
  value,
  onChange,
  required = false,
  foreign = false,
  disabled = false,
}) => {
  const handleChange = (e) => {
    const newValue = foreign
      ? e.target.value.replace(/[^a-zA-Z\d\s:]/g, "")
      : e.target.value.replace(/\D/g, "");
    if (newValue.length <= 5 || foreign) {
      onChange(name, newValue);
    }
  };
  return (
    <QuestionSection>
      <Question text="Post Code" required={required} />
      <TextField
        id={name}
        name={name}
        value={value}
        fullWidth
        onChange={handleChange}
        placeholder="Enter post code"
        autoComplete="postal-code"
        disabled={disabled}
      />
    </QuestionSection>
  );
};

export default PostCode;
