import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Paper,
  Container,
  makeStyles,
  Typography,
  Button,
} from "@material-ui/core";

import { ApplicationDataContext } from "components/ApplicationData";
import ProgressButtonGroup from "../ProgressButtonGroup";
import IdLoading from "components/IdLoading";
import { initiateIdVerification } from "util/jumioHelper";
import { bool } from "yup";

const useStyles = makeStyles({
  container: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    maxHeight: "90%",
    height: "74vh",
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    border: "none",
    visibility: (props) => (props.isLoading ? "hidden" : "visible"),
  },
});

const JumioIdEmbed = ({
  usaCitizen,
  usaPermanentResident,
  shouldNameBeneficiary,
  countryCitizenship,
  nextStep,
  previousStep,
  currentStep,
  goToStep,
  isAdditionalParticipant,
  additionalParticipantId,
  setFieldValue,
  addPartAuthToken,
  handleSave,
}) => {
  const { participantRefId: uid } = useContext(ApplicationDataContext);
  const [isLoading, setIsLoading] = useState(true);
  const [hasCompleted, setHasCompleted] = useState(false);
  const [showRetry, setShowRetry] = useState(false);
  const [resultText, setResultText] = useState("Opening Stripe...");

  const JUMIO_TRANSACTION_KEY = "jumioTransaction";

  function handleCompletion() {
    // enable the next button
    setHasCompleted(true);
    let jumioTransaction;
    try {
      const local = localStorage.getItem(JUMIO_TRANSACTION_KEY);
      jumioTransaction = JSON.parse(local);
    } catch (e) {
      jumioTransaction = { transaction: "completed" };
    }
    setFieldValue(JUMIO_TRANSACTION_KEY, jumioTransaction);

    try {
      localStorage.removeItem(JUMIO_TRANSACTION_KEY);
    } catch (e) {}

    // scroll nav buttons into view when complete
    window.scrollTo({
      top: document.body.offsetHeight,
      behavior: "smooth",
    });
  }

  function setJumioTransaction(data) {
    try {
      localStorage.setItem(JUMIO_TRANSACTION_KEY, JSON.stringify(data));
    } catch (e) {}
  }

  async function loadAndOpenStripe(fresh) {
    setIsLoading(true);
    setShowRetry(false);
    setResultText("Opening Stripe...");
    let id = uid;
    if (isAdditionalParticipant) {
      id = additionalParticipantId;
    }
    if (!id) {
      return;
    }

    async function getJumio() {
      const existingItem = localStorage.getItem(JUMIO_TRANSACTION_KEY);
      if (existingItem && !fresh) return JSON.parse(existingItem);

      const result = await initiateIdVerification(
        {
          participantId: id,
          countryCodeTwoChar: countryCitizenship || null,
        },
        addPartAuthToken
      );
      setJumioTransaction(result.jumioTransaction);
      return result.jumioTransaction;
    }

    const result = await getJumio();

    console.log("Stripe PK is ", process.env.REACT_APP_STRIPE_PK);
    const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PK);
    setIsLoading(false);
    stripe
      .verifyIdentity(result.clientToken)
      .then(function (result) {
        // If `verifyIdentity` fails, you should display the localized
        // error message to your user using `error.message`.
        if (result.error) {
          console.error("then error", result.error);
          setResultText(result.error.message ?? "Identity check failed.");
          setShowRetry(true);
        } else {
          setResultText("Identity verified!");
          handleCompletion();
        }
      })
      .catch(function (error) {
        console.error("catch error", error);
        setResultText("Identity check failed.");
        setShowRetry(true);
      });
  }

  useEffect(() => {
    loadAndOpenStripe();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handlePreviousStep() {
    if (isAdditionalParticipant) {
      return goToStep(currentStep - 2);
    }
    return shouldNameBeneficiary ? previousStep() : goToStep(currentStep - 2);
  }

  function handleNextStep() {
    // we always skip the JumioCompleted screen, which is just used for back navigation or refresh
    // we also skip citizenship upload for US citizens
    if (handleSave) {
      handleSave();
    }
    return !usaCitizen && usaPermanentResident
      ? goToStep(currentStep + 2)
      : goToStep(currentStep + 3);
  }

  function resolveDisabled() {
    // Need to change this, setting constant for timebeing
    return !hasCompleted;
  }

  const classes = useStyles({ isLoading });

  return (
    <Box flex={1}>
      <Container maxWidth="md">
        <Box>
          <Paper elevation={3} square={false} className={classes.container}>
            {isLoading ? (
              <IdLoading />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                <Typography variant="h2" color="textPrimary" component="h2">
                  {resultText}
                </Typography>
                {showRetry && (
                  <Button onClick={() => loadAndOpenStripe(true)}>Retry</Button>
                )}
              </Box>
            )}
          </Paper>
        </Box>
        <ProgressButtonGroup
          left={{
            clickHandler: handlePreviousStep,
            pageCalling: "Back Click: Stripe ID Embedded Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: Stripe ID Embedded Page",
            disabled: resolveDisabled(),
          }}
        />
      </Container>
    </Box>
  );
};

export default JumioIdEmbed;
