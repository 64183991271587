import firebase, { FIREBASE_URLS } from "config/firebase";
import { request } from "./request";

class PandadocClient {
  PD_DOCUMENT_STATUS_DRAFT = "document.draft";
  PD_DOCUMENT_STATUS_SENT = "document.sent";

  sendGetRequest = async (url, obj = {}) => {
    const params = new URLSearchParams(obj);
    const token = await firebase.auth().currentUser.getIdToken();
    const res = await request(`${url}?${params.toString()}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) throw Error("res not ok");
    const data = await res.json();
    return data;
  };

  sendPostRequest = async (url, payload = {}) => {
    const token = await firebase.auth().currentUser.getIdToken();
    const res = await request(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    if (!res.ok) throw Error("res not ok", await res.text());
    try {
      // maybe no data in res
      const data = await res.json();
      return data;
    } catch (error) {
      return;
    }
  };

  downloadDoc = async (documentId) => {
    try {
      const token = await firebase.auth().currentUser.getIdToken();
      const res = await request(
        `${FIREBASE_URLS.pandadoc}/${documentId}/download`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/pdf",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!res.ok) throw Error("res not ok", await res.text());
      // convert to blob
      const blob = await res.blob();
      return blob;
    } catch (error) {
      console.log(error);
    }
  };

  downloadToBrowser = (blob, fileName) => {
    // set a temp link for download
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute("download", fileName);
    a.click();
    // clean up
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  };

  createDocument = async (payload) => {
    try {
      const data = await this.sendPostRequest(FIREBASE_URLS.pandadoc, payload);
      const { id: documentId } = data || {};
      if (!documentId) throw Error("no document id in response");
      return documentId;
    } catch (error) {
      console.error("fail in create document", error);
      throw error;
    }
  };

  getDocumentStatus = async (id) => {
    try {
      const data = await this.sendGetRequest(
        `${FIREBASE_URLS.pandadoc}/${id}/status`
      );
      return data;
    } catch (error) {
      console.error("fail in get document status", error);
      throw error;
    }
  };

  waitForDocumentStatus = (documentId, status, attempts = 0) => {
    return new Promise((resolve, reject) => {
      if (attempts < 5) {
        this.getDocumentStatus(documentId).then(
          ({ status: liveDocumentStatus }) => {
            if (liveDocumentStatus === status) {
              resolve(documentId);
            } else {
              setTimeout(() => {
                this.waitForDocumentStatus(
                  documentId,
                  status,
                  attempts + 1
                ).then(resolve);
              }, 1000);
            }
          }
        );
      } else {
        reject();
      }
    });
  };

  setDocumentStatus = async (documentId) => {
    const payload = { status: "send" };
    try {
      await this.sendPostRequest(
        `${FIREBASE_URLS.pandadoc}/${documentId}/status`,
        payload
      );
    } catch (error) {
      console.error("fail in set document status", error);
      throw error;
    }
  };

  generatePublicViewLink = async (email, documentId) => {
    try {
      const payload = { email };
      const data = await this.sendPostRequest(
        `${FIREBASE_URLS.pandadoc}/${documentId}/generateEmbedSession`,
        payload
      );
      const { id } = data || {};
      if (!id) throw Error("no id for public view link");
      return id;
    } catch (error) {
      console.error("fail in generate public view link", error);
      throw error;
    }
  };

  getDocumentDetails = async (documentId) => {
    try {
      const data = await this.sendGetRequest(
        `${FIREBASE_URLS.pandadoc}/${documentId}`
      );
      const { fields } = data || {};
      if (!fields) throw Error("no fields");
      return fields;
    } catch (error) {
      console.error("fail in get document details", error);
      throw error;
    }
  };

  generateNewEmbedLink = async ({
    recipientEmail,
    payload,
    successCallback,
    errorCallback,
  }) => {
    try {
      const documentId = await this.createDocument(payload); // create a document from a template
      await this.waitForDocumentStatus(
        documentId,
        this.PD_DOCUMENT_STATUS_DRAFT
      ); // check that it is complete and in draft form
      await this.setDocumentStatus(documentId); // set the status to sent
      await this.waitForDocumentStatus(
        documentId,
        this.PD_DOCUMENT_STATUS_SENT
      ); // check status is sent
      const sessionId = await this.generatePublicViewLink(
        recipientEmail,
        documentId
      ); // get public link view form iframe
      const link = `https://app.pandadoc.com/s/${sessionId}`;
      successCallback(link, documentId);
    } catch (error) {
      console.error(error);
      errorCallback();
    }
  };
}

export default PandadocClient;
