import React, { useState, useEffect } from "react";
import {
  Container,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  TextField,
} from "@material-ui/core";
import Layout from "components/Layout";
import ScreenTitle from "../ScreenTitle";
import DateField from "../DateField";
import { QuestionSection, Question } from "../Question";
import ProgressButtonGroup from "../ProgressButtonGroup";
import { singleInputValidator } from "util/validation";
import { isEmpty } from "lodash";

const PersonalDetails = ({
  previousStep,
  nextStep,
  currentStep,
  resAddressEqualsMailing,
  gender,
  maritalStatus,
  birthDate,
  maidenName,
  handleFormChange,
  setFieldValue,
  errors,
  touched,
  setFieldTouched,
  goToStep,
  handleSave,
}) => {
  const [birthday, setBirthday] = useState("");
  const [birthdayError, setBirthdayError] = useState({});
  const [disabledDate, setDisabledDate] = useState(true);

  useEffect(() => {
    function setState() {
      if (birthDate && birthday === "") {
        setBirthday(birthDate);
        setDisabledDate(false);
      }
    }
    setState();
    // eslint-disable-next-line
  }, [birthDate]);

  const handleChange = (e) => {
    e.persist();
    handleFormChange(e);
  };

  const handleBlur = (e) => {
    setFieldTouched(e.target.name, true, false);
  };

  const handleDateChange = (value) => {
    setBirthday(value);
    handleBirthdayValidation(value);
  };

  const handleBirthdayValidation = async (value) => {
    const error = await singleInputValidator("birthDate", value);
    setBirthdayError({ birthday: error });
    if (isEmpty(error)) {
      setDisabledDate(false);
    } else {
      setDisabledDate(true);
    }
  };

  const goNext = () => {
    setFieldValue("birthDate", birthday);
    if (handleSave) handleSave();
    nextStep();
  };

  function resolveDisabled() {
    const maidenNameReq = maritalStatus !== "single" && gender === "female";
    return (
      disabledDate ||
      !maritalStatus ||
      !gender ||
      (maidenNameReq && !maidenName)
    );
  }

  return (
    <Layout>
      <Container maxWidth="sm">
        <ScreenTitle title="Personal Information" />
        <QuestionSection>
          <Question text="Gender" required />
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="gender"
              value={gender}
              onChange={handleChange}
              row
            >
              <FormControlLabel value="male" control={<Radio />} label="Male" />
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
            </RadioGroup>
          </FormControl>
        </QuestionSection>
        <QuestionSection>
          <Question text="Birthdate" required />
          <DateField
            value={birthday}
            fieldValue="birthday"
            onChange={handleDateChange}
            // onBlur={handleBirthdayValidation}
            errors={birthdayError || {}}
            touched={{ birthday: true }}
          />
        </QuestionSection>
        <QuestionSection>
          <Question text="Marital status" required />
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="maritalStatus"
              name="maritalStatus"
              value={maritalStatus}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="single"
                control={<Radio />}
                label="Single"
              />
              <FormControlLabel
                value="married"
                control={<Radio />}
                label="Married"
              />
              <FormControlLabel
                value="divorced"
                control={<Radio />}
                label="Divorced"
              />
              <FormControlLabel
                value="widowed"
                control={<Radio />}
                label="Widowed"
              />
            </RadioGroup>
          </FormControl>
        </QuestionSection>
        {maritalStatus !== "single" && gender === "female" && (
          <QuestionSection>
            <Question text="Your Maiden Name" required />
            <TextField
              id="maidenName"
              name="maidenName"
              value={maidenName}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              helperText={touched.maidenName ? errors.maidenName : ""}
              error={touched.maidenName && Boolean(errors.maidenName)}
            />
          </QuestionSection>
        )}
        <ProgressButtonGroup
          left={{
            clickHandler: resAddressEqualsMailing
              ? () => goToStep(currentStep - 2)
              : previousStep,
            pageCalling: "Back Click: Personal Details Page",
          }}
          right={{
            clickHandler: goNext,
            pageCalling: "Success: Personal Details Page",
            disabled: resolveDisabled(),
          }}
          hasRequiredInputs
        />
      </Container>
    </Layout>
  );
};

export default PersonalDetails;
