import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  makeStyles,
  FormControl,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

import ScreenTitle from "../ScreenTitle";
import ProgressButtonGroup from "../ProgressButtonGroup";
import TrustSS4 from "assets/trust_ss4.png";

import copy from "./copy.json";

const useStyles = makeStyles({
  container: {
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    paddingTop: 30,
    paddingBottom: 5,
  },
  graphicContainer: {
    background: "white",
    height: 200,
    width: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  graphic: {
    width: "100%",
  },
  listContainer: {
    "& li": {
      marginTop: 10,
    },
  },
  label: {
    marginTop: 20,
  },
});

const TrustEmbedIntro = ({
  currentStep,
  previousStep,
  nextStep,
  companyName,
}) => {
  const [hasVerified, setHasVerified] = useState(false);
  const classes = useStyles();

  const handleNextStep = () => {
    return nextStep();
  };

  const resolveDisabled = () => {
    return !hasVerified;
  };

  const handleCheckboxChange = (e) => {
    e.persist();
    setHasVerified(e.target.checked);
  };

  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle step={currentStep} title={`Document Signing & Submit`} />
        <Box className={classes.container}>
          <Box className={classes.graphicContainer}>
            <img
              className={classes.graphic}
              src={TrustSS4}
              alt="Trust SS4 document layered"
            />
          </Box>
        </Box>
        <Box mt={15}>
          <Typography variant="h6">{copy.heading1}</Typography>
        </Box>
        <Box component="ol" mt={20} className={classes.listContainer}>
          <Typography variant="body1">
            <li>
              <b>Trust agreement</b> - {copy.body1}
            </li>
            <li>
              <b>SS4</b> - {copy.body2}
            </li>
          </Typography>
        </Box>

        <Box mt={30}>
          <FormControl fullWidth>
            <FormControlLabel
              classes={{ label: classes.label }}
              control={
                <Checkbox
                  checked={hasVerified}
                  onChange={(e) => handleCheckboxChange(e)}
                  name="agreeTerms"
                  color="primary"
                />
              }
              label={
                <Typography variant="body1">
                  I acknowledge and give my consent for {companyName} to obtain
                  an EIN on my behalf.
                </Typography>
              }
              labelPlacement="end"
            />
          </FormControl>
        </Box>

        <ProgressButtonGroup
          left={{
            clickHandler: previousStep,
            pageCalling: "Back Click: Trust Embedded Intro Page",
            disabled: true,
            isHidden: true,
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: Trust Embedded Intro Page",
            disabled: resolveDisabled(),
          }}
        />
      </Container>
    </Box>
  );
};

export default TrustEmbedIntro;
