// GRATIS ONLY FLOW
import React, { useEffect, useContext } from "react";
import { Box, Container, TextField, Typography } from "@material-ui/core";

import ScreenTitle from "../ScreenTitle";
import { QuestionSection, Question } from "../Question";
import ProgressButtonGroup from "../ProgressButtonGroup";
import { today } from "util/helpers";
import { ThemeContext } from "components/Theme";
import Help from "components/Help";

const TrustNameAndState = ({
  previousStep,
  nextStep,
  goToStep,
  currentStep,
  handleSave,
  firstName,
  lastName,
  iraType,
  trustName,
  resAddressLine1,
  resAptNumber,
  resCity,
  resState,
  resPostcode,
  handleFormChange,
  errors,
  touched,
  setFieldTouched,
  setFieldValue,
}) => {
  const { helpInfo } = useContext(ThemeContext)?.copy?.form?.trustName || {};

  useEffect(() => {
    // initialize with a trust name and the trust address inputs which will always be based on the residential address
    function init() {
      if (!trustName) {
        const initName = resolveTrustName();
        setFieldValue("trustName", initName);
      }
      setFieldValue("trustStateOfRegistration", resState || "");
      setFieldValue("trustAddressLine1", resAddressLine1 || "");
      setFieldValue("trustApt", resAptNumber || "");
      setFieldValue("trustCity", resCity || "");
      setFieldValue("trustState", resState || "");
      setFieldValue("trustPostcode", resPostcode || "");
      setFieldValue("trustCreationDate", today());
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resolveTrustName = () => {
    const firstInitial =
      (firstName && firstName.split("")[0].toUpperCase()) || "";
    const lastInitial = (lastName && lastName.split("")[0].toUpperCase()) || "";
    const rando = Math.floor(1000 + Math.random() * 9000);
    const str = `${firstInitial}${lastInitial}${rando} Investment Trust`;
    return str;
  };

  const handleNameChange = (e) => {
    e.persist();
    const value = e.target.value;
    const filtered = value.replace(/[^a-zA-Z0-9  -]/g, "");
    setFieldValue("trustName", filtered);
  };

  const handleBlur = (e) => {
    setFieldTouched(e.target.name, true, false);
  };

  const resolveDisabled = () => {
    return !trustName;
  };

  const handlePreviousStep = () => {
    if (iraType.toLowerCase().includes("inherited")) {
      return previousStep();
    }
    return goToStep(currentStep - 2);
  };

  const handleNextStep = () => {
    if (handleSave) {
      handleSave();
    }
    nextStep();
  };

  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle step={currentStep} title="Trust Information" />
        <Box py={30}>
          <Typography>
            The following information will be used to complete a Trust document
            and IRS tax ID request in the final step.
          </Typography>
        </Box>

        <QuestionSection>
          <Question text="Name your trust" />
          <TextField
            id="trustName"
            name="trustName"
            value={trustName}
            fullWidth
            onBlur={handleBlur}
            onChange={handleNameChange}
            placeholder="Enter the name of your trust."
            required
            helperText={"We've gone ahead and suggested one for you"}
          />
        </QuestionSection>
        {helpInfo && <Help copy={helpInfo.copy} link={helpInfo.link} />}
        <ProgressButtonGroup
          left={{
            clickHandler: handlePreviousStep,
            pageCalling: "Back Click: Trust Name and State Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: Trust Name and State Page",
            disabled: resolveDisabled(),
          }}
        />
      </Container>
    </Box>
  );
};

export default TrustNameAndState;
