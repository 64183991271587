import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Container,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { format } from "date-fns";

import ScreenTitle from "../ScreenTitle";
import ProgressButtonGroup from "../ProgressButtonGroup";
import PandadocClient from "util/pandadocClient";
import DocumentSigningEmbed from "components/DocumentSigningEmbed";
import DocumentSigningLoading from "components/DocumentSigningLoading";

import { getSSN } from "util/manageSSN";
import { handleFileUpload, scroll, today, maskSsn } from "util/helpers";
import { TRUST_CREATION } from "../../../../constants";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    paddingTop: "90%", // almost 1:1 aspect ratio for doc
    height: "50vh",
  },
  confirmation: {
    marginTop: 60,
    marginBottom: -20,
    [theme.breakpoints.up("md")]: {
      textAlign: "right",
      marginBottom: -30,
    },
    "& p": {
      fontWeight: 900,
    },
  },
}));

const TrustSS4Embed = ({
  email = "",
  firstName = "",
  lastName = "",
  phoneNumber = "",
  trustAddressLine1 = "",
  trustCity = "",
  trustState = "",
  trustPostcode = "",
  trustName = "",
  legalName = "",
  ssnRef,
  ss4ExternalStoreId = null,
  applicationId,
  applicationRefId,
  setFieldValue,
  completedSections,
  previousStep,
  currentStep,
  goToStep,
  handleSave,
}) => {
  const [pandadocClient, setPandaDocClient] = useState(null);
  const [SS4Link, setSS4Link] = useState(null);
  const [SS4DocId, setSS4DocId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasErrored, setHasErrored] = useState(false);
  const [isDocSignedNow, setDocSignedNow] = useState(false);
  const [isPreparingForDownload, setIsPreparingForDownload] = useState(false);

  useEffect(() => {
    async function generateSS4Embed() {
      try {
        const pandadocClient = new PandadocClient();
        setPandaDocClient(pandadocClient);
        const payload = await constructPayload();
        setIsLoading(true);
        setHasErrored(false);
        const successCallback = (link, documentId) => {
          setSS4Link(link);
          setSS4DocId(documentId);
        };
        await pandadocClient.generateNewEmbedLink({
          recipientEmail: email || "johndoe@gmail.com",
          payload,
          successCallback,
          errorCallback,
        });
        setIsLoading(false);
      } catch (error) {
        setHasErrored(true);
        setIsLoading(false);
      }
    }

    if (!ss4ExternalStoreId) {
      generateSS4Embed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PD_TEMPLATE_ROLE = "Signer";

  async function fetchSSN() {
    if (ssnRef) {
      const str = await getSSN(ssnRef, "ssnRef");
      return str;
    }
    return "";
  }

  async function constructPayload() {
    let ssn = await fetchSSN();
    let fields = {
      legalName: {
        value: legalName,
      },
      legalNameWithIRA: {
        value: `${legalName} IRA`,
      },
      legalNameAccountHolder: {
        value: `${legalName}, IRA ACCOUNT HOLDER`,
      },
      legalNameTrustee: {
        value: `${legalName}, TRUSTEE`,
      },
      trustName: {
        value: trustName,
      },
      mailingAddress: {
        value: trustAddressLine1 || "",
      },
      cityStateZip: {
        value: `${trustCity || ""}, ${trustState || ""} ${trustPostcode || ""}`,
      },
      trusteeSSN: {
        value: maskSsn(ssn),
      },
      date: {
        value: format(new Date(), "MM/dd/yyyy"),
      },
      phoneNumber: {
        value: phoneNumber,
      },
    };

    const recipients = [
      {
        email: email || "johndoe@gmail.com",
        first_name: firstName || "John",
        last_name: lastName || "Doe",
        role: PD_TEMPLATE_ROLE,
      },
    ];

    return {
      recipients,
      fields,
      documentType: "SS4",
      documentName: `${email}-SS4`,
    };
  }

  function errorCallback() {
    setHasErrored(true);
  }

  async function SS4CompletionHandler() {
    setIsLoading(true);
    setIsPreparingForDownload(true);
    try {
      const blob = await pandadocClient.downloadDoc(SS4DocId);
      const ref = await handleFileUpload(
        blob,
        `/applications/${applicationRefId || applicationId}/trust`,
        "SS4.pdf"
      );

      setFieldValue("ss4StorageRef", ref);
      setFieldValue("ss4ExternalStoreId", SS4DocId);
      setFieldValue("trustCreationDate", today());
      if (handleSave) {
        handleSave();
      }
      scroll(document.body.scrollHeight, 0, "smooth");
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
    setIsPreparingForDownload(false);
    setDocSignedNow(true);
  }

  const classes = useStyles();

  function handleNextStep() {
    handleSubmit();
    goToStep(1);
  }

  function handleSubmit() {
    setFieldValue("completedSections", [...completedSections, TRUST_CREATION]);
    handleSave();
  }

  return (
    <Box flex={1}>
      <Container maxWidth="md">
        <ScreenTitle
          step={currentStep}
          title="The following document will allow Gratis to obtain a tax ID on your behalf"
          endAdornment="2/2"
        />
        <Box mt={30}>
          <Paper elevation={3} square={false} className={classes.container}>
            {hasErrored ? (
              <DocumentSigningLoading error={true} />
            ) : ss4ExternalStoreId && !isDocSignedNow ? (
              <>
                <DocumentSigningLoading completed={true} />
              </>
            ) : isLoading ? (
              <DocumentSigningLoading
                isCompleting={isDocSignedNow ? true : isPreparingForDownload}
              />
            ) : (
              <>
                <DocumentSigningEmbed
                  url={SS4Link}
                  onDocumentCompleted={SS4CompletionHandler}
                  isPreparingForDownload={
                    isDocSignedNow ? true : isPreparingForDownload
                  }
                />
              </>
            )}
          </Paper>
        </Box>
        {!!ss4ExternalStoreId && (
          <Box className={classes.confirmation}>
            <Typography variant="body2">
              You're all done, click FINISH to complete your application.
            </Typography>
          </Box>
        )}
        <ProgressButtonGroup
          left={{
            clickHandler: previousStep,
            pageCalling: "Back Click: Trust SS4 Embedded Page",
            disabled: true,
            isHidden: true,
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: Trust SS4 Embedded Page",
            disabled: !ss4ExternalStoreId,
            text: "Finish",
          }}
        />
      </Container>
    </Box>
  );
};

export default TrustSS4Embed;
