import React, { useContext, useState } from "react";
import { ThemeContext } from "components/Theme";
import { Box, Container, Checkbox, Typography } from "@material-ui/core";
import { firebase as firebaseAdmin } from "@firebase/app";

import ProgressButtonGroup from "../ProgressButtonGroup";
import BorderedText from "components/BorderedText";
import {
  LOCAL_STORAGE_REFERRER,
  LOCAL_STORAGE_REFERRER_DATA,
  LOCAL_STORAGE_REFERRER_AUTHORITY,
  GRATIS_THEME_NAME,
  REVIEW,
  REVIEW_SUBMIT,
} from "../../../../constants";

const ReviewAndSubmit = ({
  previousStep,
  goToStep,
  nextStep,
  setFieldValue,
  isAdditionalParticipant,
  completedSections,
  handleSave,
  providerName,
}) => {
  const {
    body1 = `By submitting this application, you affirm the following:`,
    body2 = `I authorize the bank to make inquiries to state, federal and consumer reporting agencies to obtain information to determine if the bank should open my account.`,
    body3 = `I understand this is an application for an account and that the bank may decline to open the account based on information provided or obtained from various agencies.`,
    body4 = `I certify that all the information I provided for this application is true and correct`,
    heading1 = "Confirm your information",
    heading2 = "",
    heading3 = "",
  } = useContext(ThemeContext)?.copy?.form?.submit || {};
  const [isConfirmed, setConfirmed] = useState(false);

  const handleSubmit = async () => {
    if (providerName === GRATIS_THEME_NAME) {
      // we've got more screens now with this flow
      removeLocalStorage();
      setFieldValue("completedSections", [...completedSections, REVIEW]);
      handleSave();
      return nextStep();
    }
    if (isAdditionalParticipant) {
      setFieldValue(
        "additionalParticipantSubmittedAt",
        firebaseAdmin.firestore.Timestamp.now()
      );
      // pause just a little to ensure past validation
      await new Promise((resolve) => setTimeout(resolve, 150));
      handleSave();
      return nextStep();
    }
    setFieldValue("completedSections", [...completedSections, REVIEW_SUBMIT]);
    setFieldValue("submittedAt", firebaseAdmin.firestore.Timestamp.now());
    setFieldValue("status", "REQUIRES REVIEW");
    removeLocalStorage();
    handleSave();
    goToStep(1);
  };

  function removeLocalStorage() {
    try {
      localStorage.removeItem(LOCAL_STORAGE_REFERRER);
      localStorage.removeItem(LOCAL_STORAGE_REFERRER_DATA);
      localStorage.removeItem(LOCAL_STORAGE_REFERRER_AUTHORITY);
    } catch (e) {}
  }

  const resolveDisabled = () => {
    return !isConfirmed;
  };

  const buttonTitle = providerName === GRATIS_THEME_NAME ? "Next" : "Submit";

  return (
    <Box>
      <Container maxWidth="sm">
        <>
          <Typography variant="h4">{heading1}</Typography>
          <Box pt={30}>
            <Typography variant="body1">{heading2}</Typography>
          </Box>
          <Box pt={20} pb={20}>
            <Typography variant="body1">{heading3}</Typography>
          </Box>
          <BorderedText>
            <Box>
              <Typography variant="body1">
                <b>{body1}</b>
              </Typography>
            </Box>
            <Box py={20}>
              <Typography variant="body1">{body2}</Typography>
            </Box>
            <Box pb={20}>
              <Typography variant="body1">{body3}</Typography>
            </Box>
            <Box display="flex" pt={20}>
              <Box>
                <Checkbox
                  checked={isConfirmed}
                  onChange={() => setConfirmed((prevState) => !prevState)}
                  color="primary"
                />
              </Box>
              <Box pt={10}>
                <Typography variant="body2">{body4}</Typography>
              </Box>
            </Box>
          </BorderedText>
          <Box pt={40}>
            <Typography variant="body2">{`By selecting ${buttonTitle.toUpperCase()}, you will not be able to return to previous screens to edit your information.`}</Typography>
          </Box>
          <ProgressButtonGroup
            left={{
              clickHandler: previousStep,
              pageCalling: "Back Click: Review and Submmit Page",
            }}
            right={{
              text: buttonTitle,
              clickHandler: handleSubmit,
              pageCalling: "Success: Review and Submit Page",
              disabled: resolveDisabled(),
            }}
          />
        </>
      </Container>
    </Box>
  );
};

export default ReviewAndSubmit;
