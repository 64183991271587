import React, { useState, useEffect } from "react";
import { Box, Container, FormHelperText, makeStyles } from "@material-ui/core";
import { isEmpty } from "lodash";

import ScreenTitle from "../ScreenTitle";
import { QuestionSection, Question } from "../Question";
import ProgressButtonGroup from "../ProgressButtonGroup";
import FileInput from "components/FileInput";
import { Loading } from "containers/Loading";

import { handleFileUpload, getFileExtension, getFile } from "util/helpers";
import {
  ACCOUNT_TYPE_SOLO_401K,
  TRUST,
  MAX_UPLOAD_SIZE,
  MAX_UPLOAD_SIZE_TEXT,
  MAX_UPLOAD_SIZE_MB,
} from "../../../../constants";

const useStyles = makeStyles({
  previewContainer: {
    position: "relative",
    background: "white",
    borderRadius: 10,
    display: "flex",
    border: "1px solid",
    maxWidth: 300,
  },
  imageContainer: {
    overflow: "hidden",
    width: "100%",
    minHeight: 400,
  },
  iframeContainer: {
    width: "100%",
    minHeight: 400,
    position: "relative",
  },
  preview: {
    width: "100%",
    opacity: "100%",
    objectFit: "contain",
  },
  iframe: {
    position: "absolute",
    borderRadius: 10,
    border: "none",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
  },
  iconContainer: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    backgroundColor: "white",
    borderRadius: "50%",
    opacity: "70%",
    boxShadow: "4px 4px 4px black",
  },
  loadingContainer: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
  },
});

const TrustAdoptionUpload = ({
  previousStep,
  nextStep,
  currentStep,
  completedSections,
  hasInitValues,
  applicationRefId,
  applicationId,
  trustStorageRef,
  setFieldValue,
  handleSave,
  accountType,
}) => {
  const classes = useStyles();
  const [planAdoptionImage, setPlanAdoptionImage] = useState({});
  const [planAdoptionError, setPlanAdoptionError] = useState(null);
  const [downloadError, setDownloadError] = useState(null);
  const [isLoading, setLoading] = useState(true);

  // Copy of the type of upload depends on the account type
  // if accountType is solo401k it should say "Plan Adoption Agreement"
  var accountTypeUploadCopy =
    accountType === ACCOUNT_TYPE_SOLO_401K
      ? "Plan Adoption Agreement"
      : "Trust Agreement";

  useEffect(() => {
    async function getFileFromStorage() {
      try {
        if (trustStorageRef) {
          const url = await getFile(trustStorageRef);
          setPlanAdoptionImage((prevState) => ({
            ...prevState,
            src: url,
            type: "application/pdf",
          }));
        }
      } catch (error) {
        console.error(error);
        setDownloadError("Sorry we encountered an issue downloading this file");
      } finally {
        setLoading(false);
      }
    }
    if (hasInitValues) getFileFromStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasInitValues]);

  const handleFileSelection = async (field, uploadEvent) => {
    if (
      uploadEvent &&
      uploadEvent.currentTarget &&
      uploadEvent.currentTarget.files &&
      uploadEvent.currentTarget.files[0]
    ) {
      try {
        const file = uploadEvent.currentTarget.files[0];
        const size = file.size;
        if (size > MAX_UPLOAD_SIZE)
          throw Error(
            `The maximum allowed size is ${MAX_UPLOAD_SIZE_MB}Mb. This file is ${parseInt(
              size / (1024 * 1024)
            )}Mb.`
          );
        setLoading(true);
        setPlanAdoptionError(null);
        const ref = await handleFileUpload(
          file,
          `/applications/${applicationRefId || applicationId}/trust`,
          `plan_adoption.${getFileExtension(file)}`
        );

        const url = await getFile(ref);
        setPlanAdoptionImage((prevState) => ({
          ...prevState,
          src: url,
          type: "application/pdf",
        }));
        setFieldValue("trustStorageRef", ref);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setPlanAdoptionError(error.message);
      }
    }
  };

  const resolveDisabled = () => {
    return isEmpty(planAdoptionImage) || !!planAdoptionError;
  };

  const handlePreviousStep = () => {
    return previousStep();
  };

  const handleNextStep = () => {
    !completedSections.includes(TRUST) &&
      setFieldValue("completedSections", [...completedSections, TRUST]);
    if (handleSave) {
      handleSave();
    }
    return nextStep();
  };

  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle step={currentStep} title="Trust Information" />
        <FormHelperText error={!!downloadError}>{downloadError}</FormHelperText>
        <QuestionSection>
          <Question
            text={`Please upload your ${accountTypeUploadCopy} here`}
            required
          />
          <Box>
            <Box
              className={`${classes.previewContainer}
                ${
                  planAdoptionImage.type === "application/pdf"
                    ? classes.iframeContainer
                    : classes.imageContainer
                }
              `}
            >
              {planAdoptionImage.type === "application/pdf" ? (
                <iframe
                  title={`${accountTypeUploadCopy} preview`}
                  src={`${planAdoptionImage.src}`}
                  className={classes.iframe}
                  allowFullScreen={true}
                ></iframe>
              ) : (
                <img
                  className={classes.preview}
                  src={planAdoptionImage.src}
                  alt={planAdoptionImage.name}
                />
              )}
              {!isLoading ? (
                <Box className={classes.iconContainer}>
                  <FileInput
                    name="planAdoption"
                    type="planAdoption"
                    changeHandler={handleFileSelection.bind(
                      null,
                      "planAdoption"
                    )}
                  />
                </Box>
              ) : (
                <Box className={classes.loadingContainer}>
                  <Loading />
                </Box>
              )}
            </Box>
          </Box>
          <FormHelperText>
            PDFs only please. {MAX_UPLOAD_SIZE_TEXT}
          </FormHelperText>
          <FormHelperText error={!!planAdoptionError}>
            {planAdoptionError}
          </FormHelperText>
        </QuestionSection>

        <ProgressButtonGroup
          left={{
            clickHandler: handlePreviousStep,
            pageCalling: "Back Click: Trust Adoption Upload Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: Trust Adoption Upload Page",
            disabled: resolveDisabled(),
          }}
          hasRequiredInputs
        />
      </Container>
    </Box>
  );
};

export default TrustAdoptionUpload;
