import mixpanel from "mixpanel-browser";

let mixpanelToken = "";
if (process.env.REACT_APP_ENV === "staging") {
  mixpanelToken = "9b4858fa2a2d59e14e1de780263ef04c";
} else if (process.env.REACT_APP_ENV === "production") {
  mixpanelToken = "543cb4d2fb34b355c8be6d9187f14814";
}

if (mixpanelToken) {
  mixpanel.init(mixpanelToken);
}

const actions = {
  identify: (id) => {
    if (mixpanelToken) {
      mixpanel.identify(id);
    }
  },
  alias: (id) => {
    if (mixpanelToken) {
      mixpanel.alias(id);
    }
  },
  track: (name, props) => {
    if (mixpanelToken) {
      mixpanel.track(name, props);
    }
  },
  register: (name, props) => {
    if (mixpanelToken) {
      mixpanel.register(name, props);
    }
  },
  register_once: (name, props) => {
    if (mixpanelToken) {
      mixpanel.register_once(name, props);
    }
  },
};

export const Mixpanel = actions;
