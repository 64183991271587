import React from "react";
import {
  Box,
  Button,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";

export default function EmailSent({
  email,
  classes,
  handleEmailSend,
  localStorageEmailSent,
}) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Box textAlign="center" mt={32}>
        <Typography variant="h2" component="h2">
          Email sent to {email || localStorageEmailSent || ""}
        </Typography>
      </Box>
      <Box textAlign="center" mt={32}>
        <Typography variant="body1">
          Please check your inbox for an email from{" "}
          <b>noreply@solerabank.com</b> to confirm your account.
        </Typography>
        <ExpansionPanel className={classes.expansionPanel}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.expansionPanelSummary}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
            id="additional-actions1-header"
          >
            <Typography>Not seeing this in your inbox?</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            <Typography variant="body2" color="textSecondary">
              Some tips for finishing up sign up:
              <ul>
                <li>
                  Double check the email above matches the email you intended to
                  use for sign in.
                </li>
                <li>Check your spam inbox</li>
                <li>Try another email address</li>
              </ul>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Box>
      <Box textAlign="center" mt={32}>
        <Button variant="text" onClick={handleEmailSend}>
          Try again
        </Button>
      </Box>
    </Box>
  );
}
