import React, { useEffect } from "react";
import {
  Container,
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  TextField,
  makeStyles,
} from "@material-ui/core";

import { QuestionSection, Question } from "../Question";
import ScreenTitle from "../ScreenTitle";
import ProgressButtonGroup from "../ProgressButtonGroup";
import { ContentInfo } from "../HomeTitles";
import PostCode from "../PostCode";

import { mapCountriesToOptions, handleFieldLogic } from "util/helpers";

import copy from "./copy.json";

const useStyles = makeStyles((theme) => ({
  disclaimer: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
  },
}));

const Citizenship = ({
  previousStep,
  nextStep,
  currentStep,
  goToStep,
  status,
  hasUSAddress,
  countryCitizenship,
  usaCitizen,
  usaPermanentResident,
  foreignAddressObj,
  foreignAddressLine1,
  foreignAddressApt,
  foreignAddressCity,
  foreignAddressState,
  foreignAddressPostcode,
  foreignAddressCountry,
  handleFormChange,
  setFieldValue,
  handleSave,
}) => {
  useEffect(() => {
    function init() {
      if (
        (usaCitizen === true || usaCitizen === "true") &&
        (countryCitizenship === null || countryCitizenship === "")
      ) {
        setFieldValue("countryCitizenship", "US");
      }
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const classes = useStyles();
  const handleChangeRadio = (e) => {
    e.persist();
    setFieldValue(e.target.name, e.target.value === "true" ? true : false);
    handleFieldLogic(e.target.name, e.target.value, handleCallback);
  };

  const handleChange = (e) => {
    e.persist();
    handleFormChange(e);
    handleFieldLogic(e.target.name, e.target.value, handleCallback);
  };

  const handleCallback = (data) => {
    Object.keys(data).forEach((key) => {
      setFieldValue(key, data[key]);
    });
  };

  const handleAptChange = (e) => {
    e.persist();
    const value = e.target.value;
    const filtered = value.replace(/[^a-zA-Z0-9  -]/g, "");
    setFieldValue(e.target.name, filtered);
  };

  const resolveDisabled = () => {
    // if not US citizen then country should not be US
    if (usaCitizen === true && countryCitizenship === "") return false;
    if (usaCitizen === false && countryCitizenship === "US") return true;
    if (usaCitizen === true || usaCitizen === "true") {
      return countryCitizenship !== "US";
    } else {
      if (
        (usaCitizen === false || usaCitizen === "false") &&
        usaPermanentResident === false
      ) {
        return (
          !foreignAddressLine1 ||
          !foreignAddressCity ||
          !foreignAddressState ||
          !foreignAddressPostcode ||
          !foreignAddressCountry
        );
      }
      return countryCitizenship === "" || countryCitizenship === null;
    }
  };

  const handleStepChange = () => {
    if (!usaCitizen && !usaPermanentResident && !hasUSAddress) {
      status !== "DECLINED" && setFieldValue("status", "DECLINED");
      if (handleSave) {
        handleSave();
      }
      return nextStep();
    }
    status === "DECLINED" && setFieldValue("status", "DRAFT");
    if (handleSave) handleSave();
    goToStep(currentStep + 2);
  };

  return (
    <Box>
      <Container maxWidth="sm">
        <ScreenTitle title="Contact Information" />
        <QuestionSection>
          <Question text="Are you a US citizen?" required />
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="usaCitizen"
              name="usaCitizen"
              value={usaCitizen}
              onChange={handleChangeRadio}
              row
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </QuestionSection>
        {!usaCitizen && (
          <>
            <QuestionSection>
              <Question text="Country of citizenship" required />
              <Select
                fullWidth
                labelId="countryCitizenship"
                name="countryCitizenship"
                id="countryCitizenship"
                value={countryCitizenship || ""}
                onChange={handleChange}
                label="Country of citizenship"
                displayEmpty
              >
                <MenuItem value="" disabled dense>
                  Select a country
                </MenuItem>
                {mapCountriesToOptions().map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.name}
                  </MenuItem>
                ))}
              </Select>
            </QuestionSection>
            <QuestionSection>
              <Question
                text="Are you a permanent resident of the US?"
                required
              />
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="usaPermanentResident"
                  name="usaPermanentResident"
                  value={usaPermanentResident}
                  onChange={handleChangeRadio}
                  row
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </QuestionSection>
            {usaPermanentResident ? (
              <Box p={10} mt={20} className={classes.disclaimer}>
                <ContentInfo text={copy.permResident} />
              </Box>
            ) : (
              <>
                <Box p={10} mt={20} className={classes.disclaimer}>
                  <ContentInfo text={copy.nonPermResident} />
                </Box>
                <QuestionSection>
                  <Question text="Foreign Address Line 1" required />
                  <TextField
                    id="foreignAddressLine1"
                    name="foreignAddressLine1"
                    defaultValue={foreignAddressLine1}
                    fullWidth
                    onChange={handleChange}
                    placeholder="Enter address"
                  />
                </QuestionSection>
                <QuestionSection>
                  <Question text="Address Line 2" />
                  <TextField
                    id="foreignAddressApt"
                    name="foreignAddressApt"
                    defaultValue={foreignAddressApt}
                    fullWidth
                    onChange={handleAptChange}
                    placeholder="Eg: Apt 1, Unit 2, etc..."
                  />
                </QuestionSection>
                <Box display="flex" justifyContent="start" alignItems="center">
                  <Box flexGrow={1}>
                    <QuestionSection>
                      <Question text="City" required />
                      <TextField
                        id="foreignAddressCity"
                        name="foreignAddressCity"
                        defaultValue={foreignAddressCity}
                        fullWidth
                        onChange={handleChange}
                        placeholder="Enter city"
                      />
                    </QuestionSection>
                  </Box>
                  <Box flexGrow={1} pl={30}>
                    <QuestionSection>
                      <Question text="State" required />
                      <TextField
                        id="foreignAddressState"
                        name="foreignAddressState"
                        defaultValue={foreignAddressState}
                        fullWidth
                        onChange={handleChange}
                        placeholder="Enter state"
                      />
                    </QuestionSection>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="start" alignItems="center">
                  <Box flexGrow={1}>
                    <PostCode
                      name="foreignAddressPostcode"
                      value={foreignAddressPostcode}
                      onChange={setFieldValue}
                      foreign
                      required
                    />
                  </Box>
                  <Box flexGrow={1} pl={30}>
                    <QuestionSection>
                      <Question text="Country" required />
                      <Select
                        fullWidth
                        labelId="foreignAddressCountry"
                        name="foreignAddressCountry"
                        id="foreignAddressCountry"
                        value={foreignAddressCountry || ""}
                        onChange={handleChange}
                        label="Country"
                        displayEmpty
                      >
                        <MenuItem value="" disabled dense>
                          Select a country
                        </MenuItem>
                        {mapCountriesToOptions().map((opt) => (
                          <MenuItem key={opt.value} value={opt.value}>
                            {opt.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </QuestionSection>
                  </Box>
                </Box>
                <QuestionSection>
                  <Question text="Do you have a US address?" required />
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="hasUSAddress"
                      name="hasUSAddress"
                      value={hasUSAddress}
                      onChange={handleChangeRadio}
                      row
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </QuestionSection>
              </>
            )}
          </>
        )}
        <ProgressButtonGroup
          left={{
            clickHandler: previousStep,
            pageCalling: "Back Click: Citizenship Page",
          }}
          right={{
            clickHandler: handleStepChange,
            pageCalling: "Success: Citizenship Page",
            disabled: resolveDisabled(),
          }}
          hasRequiredInputs
        />
      </Container>
    </Box>
  );
};

export default Citizenship;
