import React, { useState, useEffect } from "react";
import {
  Container,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Box,
  MenuItem,
  Checkbox,
} from "@material-ui/core";

import { QuestionSection, Question } from "../Question";
import ScreenTitle from "../ScreenTitle";
import ProgressButtonGroup from "../ProgressButtonGroup";
import SelectMultiple from "components/SelectMultiple";

import { countries, investments } from "util/helpers";

import { ACCT_INVEST } from "../../../../constants";

const IRAType2 = ({
  previousStep,
  nextStep,
  currentStep,
  goToStep,
  iraType,
  investmentSector1,
  investmentSector2,
  willInvestInternationally,
  countriesForInternationalTrans,
  completedSections,
  setFieldValue,
  handleSave,
}) => {
  const [invests, setInvests] = useState(() => {
    return [investmentSector1, investmentSector2].filter((item) => !!item);
  });

  useEffect(() => {
    setInvests([investmentSector1, investmentSector2].filter((item) => !!item));
  }, [investmentSector1, investmentSector2]);

  const handleInvestChange = (e) => {
    if (e.target.value.length <= 2) {
      setInvests(e.target.value.slice());
      setFieldValue("investmentSector1", "");
      setFieldValue("investmentSector2", "");
      //eslint-disable-next-line array-callback-return
      e.target.value.map((item, i) => {
        setFieldValue(`investmentSector${i + 1}`, e.target.value[i]);
      });
    }
  };

  const handleCountriesChange = (e) => {
    if (e.target.value && e.target.value.length <= 3) {
      setFieldValue("countriesForInternationalTrans", e.target.value.slice());
    }
  };

  const handleChangeRadio = (e) => {
    e.persist();
    setFieldValue(e.target.name, e.target.value === "true" ? true : false);
  };

  const handleNextStep = () => {
    if (iraType === "Inherited-Traditional" || iraType === "Inherited-Roth") {
      if (handleSave) {
        handleSave();
      }
      return nextStep();
    }
    !completedSections.includes(ACCT_INVEST) &&
      setFieldValue("completedSections", [...completedSections, ACCT_INVEST]);
    if (handleSave) {
      handleSave();
    }
    return goToStep(currentStep + 2);
  };

  const resolveDisabled = () => {
    // Check if atleast one investment sector is selected
    let disabled = false;
    if (!investmentSector1) disabled = true;
    if (willInvestInternationally) {
      if (
        Array.isArray(countriesForInternationalTrans) &&
        countriesForInternationalTrans.length === 0
      )
        disabled = true;
    }
    return disabled;
  };

  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle step={currentStep} title="Investment Options" />
        <QuestionSection>
          <Question text="What do you plan to invest in?" required />
          <SelectMultiple
            value={invests}
            name="investmentSector"
            handleChange={handleInvestChange}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return "Select up to 2";
              }
              return selected.join(", ");
            }}
            options={investments.map((item) => (
              <MenuItem key={item} value={item}>
                <Checkbox
                  checked={invests.includes(item)}
                  onChange={() => {}}
                  name={item}
                  color="primary"
                />
                {` ${item}`}
              </MenuItem>
            ))}
          />
        </QuestionSection>
        <QuestionSection>
          <Question
            text="Will there be any international transactions?"
            required
          />
          <FormControl component="fieldset">
            <RadioGroup
              name="willInvestInternationally"
              value={willInvestInternationally}
              onChange={handleChangeRadio}
              row
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </QuestionSection>
        {willInvestInternationally && (
          <QuestionSection>
            <Question text="Please select a country" required />
            <SelectMultiple
              value={countriesForInternationalTrans}
              name="countriesForInternationalTrans"
              handleChange={handleCountriesChange}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return "Select up to 3";
                }
                const fullNames = selected.map((countryCode) => {
                  const fullName = countries.find(
                    (country) => country.code === countryCode
                  );
                  return (fullName && fullName.name) || countryCode;
                });
                return fullNames.join(", ");
              }}
              options={countries.map(({ name, code }) => (
                <MenuItem key={name} value={code}>
                  <Checkbox
                    checked={countriesForInternationalTrans.includes(code)}
                    onChange={() => {}}
                    name={name}
                    color="primary"
                  />
                  {` ${name}`}
                </MenuItem>
              ))}
            />
          </QuestionSection>
        )}
        <ProgressButtonGroup
          left={{
            clickHandler: previousStep,
            pageCalling: "Back Click: IRA Type 2 Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: IRA Type 2 Page",
            disabled: resolveDisabled(),
          }}
          hasRequiredInputs
        />
      </Container>
    </Box>
  );
};

export default IRAType2;
