import React, { useState, useContext, useEffect, useRef } from "react";
import StepWizard from "react-step-wizard";
import { Formik } from "formik";
import { Prompt } from "react-router-dom";

import Header from "components/Header";
import Layout from "components/Layout";
import { ApplicationDataContext } from "components/ApplicationData";
import { AuthContext } from "components/Auth";
import { ThemeContext } from "components/Theme";
import AlertDialog from "components/AlertDialog";

import withFormData from "./components/withFormData";
import validationSchema from "util/validation";
import { updateApplicationProgress } from "util/helpers";
import { getAuthority, setAuthority, getReferrer } from "util/providerSource";
import { screens } from "util/formScreens";

// import styles from "./styles.module.scss";
import mainCopy from "./copy.json";

import { isEmpty } from "lodash";

import ProgressBar from "./components/ProgressBar";

import {
  LOCAL_STORAGE_REFERRER_AUTHORITY,
  LOCAL_STORAGE_REFERRER,
  LOCAL_STORAGE_REFERRER_DATA,
  REFERRER_LIST_PARAM_KEY,
} from "../../constants";

//// FOR DEV - change this work on different screens ////////
const devStep = 1;
const initialStep = process.env.NODE_ENV === "development" ? devStep : 1;
////////////////////////////////////////////////////////////

const IRAForm = ({ initialValues, saveForm }) => {
  const dataRef = useRef(null);
  const {
    accountType,
    checkingAccountType,
    allReferrerInitData,
    referrerList,
  } = useContext(ApplicationDataContext) || {};

  const { signOut } = useContext(AuthContext);

  const Screens = screens({ accountType, checkingAccountType });

  const [step, setStep] = useState(initialStep);
  const [stepWizardInstance, setInstance] = useState(null);
  const [referrerInitData, setReferrerInitData] = useState(null);
  const [isConfirmHomeOpen, setConfirmHomeOpen] = useState(false);

  const { themeName: providerName, copy = {} } = useContext(ThemeContext) || {};
  const { companyName = "" } = copy?.contactInformation;

  useEffect(() => {
    if (dataRef.current === null) {
      dataRef.current = initialValues;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // run on mount set ref

  useEffect(() => {
    function getReferrerObj(referrer) {
      return referrerList.find((item) => {
        return item[REFERRER_LIST_PARAM_KEY] === referrer;
      });
    }
    function getReferrerInitData() {
      let authority;
      try {
        authority = getAuthority();
        if (authority) {
          setReferrerInitData(allReferrerInitData[authority]);
        } else {
          let referrer;
          referrer = getReferrer();
          if (referrer) {
            const referrerObj = getReferrerObj(referrer);
            if (referrerObj) {
              // set authority in local storage
              setAuthority(referrerObj.authority);
              setReferrerInitData(
                allReferrerInitData[referrerObj.authority] || null
              );
            }
          }
        }
      } catch (_) {}
    }
    getReferrerInitData();
  }, [allReferrerInitData, referrerList]);

  const handleStepChange = ({ activeStep }) => {
    setStep(activeStep);
    window.scrollTo(0, 0);
  };

  const setStepInstance = (instance) => {
    setInstance(instance);
  };

  const saveData = (values, step) => {
    const updatedValues = updateApplicationProgress(values, step);
    // console.log("updated values", updatedValues);
    // setValues(updatedValues); // set progress values explicitly so that when we navigate back to Home the progress is visible.
    saveForm(updatedValues);
  };

  const saveAndExit = (values, step, setValues) => {
    // console.log("set values", setValues);
    const updatedValues = updateApplicationProgress(values, step);
    // console.log("updated values", updatedValues);
    setValues(updatedValues); // set progress values explicitly so that when we navigate back to Home the progress is visible.
    saveForm(updatedValues);
    stepWizardInstance.firstStep();
  };

  const logout = () => {
    signOut();
    localStorage.removeItem(LOCAL_STORAGE_REFERRER);
    localStorage.removeItem(LOCAL_STORAGE_REFERRER_DATA);
    localStorage.removeItem(LOCAL_STORAGE_REFERRER_AUTHORITY);
    // a good old fashioned hard reload here
    window.location.reload();
  };

  useEffect(() => {
    // from verify email - by this point they'll be into the app
    window.localStorage.removeItem("emailSent");
  }, []);

  function stopFormReturn(e) {
    e.preventDefault();
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dataRef.current = values;
          saveData(values, step);
        }}
      >
        {(props) => {
          // For Debug purpose

          if (!isEmpty(props.errors)) {
            console.log("errors in formik", props.errors);
          }
          // console.log("values", props.values);
          return (
            <>
              {/* Pass the setValues since we have update application progress on exit */}
              <Header
                clickHandler={
                  step === 1 ? logout : () => setConfirmHomeOpen(true)
                }
                step={step}
              />
              <AlertDialog
                open={isConfirmHomeOpen}
                handleConfirm={() => {
                  saveAndExit(props.values, step, props.setValues);
                  setConfirmHomeOpen(false);
                }}
                handleClose={() => setConfirmHomeOpen(false)}
                title="Are you sure you want to leave this screen?"
                content={`To ensure your changes are saved on this screen, first  click "NEXT" to save your answers on this screen.`}
              />
              <Layout>
                <form
                  // className={styles.IRAForm}
                  autoComplete="off"
                  // submission happens in stages and is handled in formik but if the cursor is in a textbox and
                  // the return key is pressed, then the form submits and the page reloads
                  // stopFormReturn stops this
                  onSubmit={stopFormReturn}
                >
                  <StepWizard
                    initialStep={step}
                    isLazyMount={true}
                    nav={<ProgressBar {...props} />}
                    onStepChange={handleStepChange}
                    instance={setStepInstance}
                  >
                    {Screens.map((Screen, i) => {
                      return (
                        <Screen
                          key={i}
                          {...props.values}
                          errors={props.errors}
                          touched={props.touched}
                          handleFormChange={props.handleChange}
                          setFieldTouched={props.setFieldTouched}
                          setFieldValue={props.setFieldValue}
                          handleSave={props.handleSubmit}
                          providerName={providerName}
                          companyName={companyName}
                          referrerInitData={referrerInitData}
                        />
                      );
                    })}
                  </StepWizard>
                </form>
              </Layout>
            </>
          );
        }}
      </Formik>
      <Prompt when={true} message={mainCopy.prompt} />
    </>
  );
};

export default withFormData(IRAForm);
