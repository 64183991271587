import React from "react";
import {
  Box,
  Container,
  Typography,
  LinearProgress,
  withStyles,
  makeStyles,
} from "@material-ui/core";

import { breakpoints } from "config/defaultTheme/breakpoints";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    borderRadius: 5,
    width: 80,
    [`@media (min-width:${breakpoints.md}px)`]: {
      width: 200,
    },
  },
  colorPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#fff",
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  text: {
    letterSpacing: 0.15,
    textTransform: "uppercase",
    color: "#fff",
  },
  progress: {
    width: 80,
    [`@media (min-width:${breakpoints.md}px)`]: {
      width: 200,
    },
    backgroundColor: "#fff",
    color: "#fff",
  },
  contentContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
  },
  container: {
    display: "flex",
    background: theme.palette.progress.main,
    height: 32,
    alignItems: "center",
  },
}));

const ProgressBar = ({
  isAdditionalParticipant = false,
  currentStep,
  totalSteps,
  values: { progressStage, isApplicationStarted, submittedAt, ss4StorageRef },
}) => {
  // This logic is to prevent progess from decreasing after the user clicks back after the saving the form.
  const progressStep =
    progressStage > currentStep ? progressStage : currentStep;
  let percentComplete = Math.floor((progressStep / totalSteps) * 100);
  if (percentComplete === 100) percentComplete = 99;

  const classes = useStyles();
  if (
    currentStep === 1 &&
    (!isApplicationStarted || submittedAt || !!ss4StorageRef)
  ) {
    return null;
  }
  const shouldRenderDetails = isAdditionalParticipant
    ? true
    : currentStep !== 4
    ? true
    : false;
  return (
    <Box className={classes.container} mb={32}>
      <Container maxWidth="sm">
        {shouldRenderDetails && (
          <Box className={classes.contentContainer}>
            <Box>
              <Typography variant="body2" className={classes.text}>
                step {currentStep} of {totalSteps}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Box pr={5}>
                <Typography variant="body2" className={classes.text}>
                  Completed: {percentComplete}%
                </Typography>
              </Box>
              <BorderLinearProgress
                variant="determinate"
                value={percentComplete}
              />
            </Box>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default ProgressBar;
