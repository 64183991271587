// users only see this screen if coming back to application from refresh or clicking back from security section

import React from "react";
import { Container, Box, Typography, makeStyles } from "@material-ui/core";

import ScreenTitle from "../ScreenTitle";
import ProgressButtonGroup from "../ProgressButtonGroup";
import IdIntro1 from "assets/id-intro1.png";
import IdIntro12x from "assets/id-intro1@2x.png";
import IdIntro13x from "assets/id-intro1@3x.png";
import {
  ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL,
  ACCOUNT_TYPE_CUSTODIAL_IRA,
  CHECKING_ACCOUNT_TYPE_LLC,
} from "../../../../constants";

const useStyles = makeStyles({
  container: {
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    paddingTop: 30,
    paddingBottom: 5,
  },
  graphicContainer: {
    background: "white",
    height: 200,
    width: 200,
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  graphic: {
    objectFit: "contain",
    margin: -15,
    width: 200,
    height: 200,
  },
  header: {
    fontSize: "16px",
  },
});

const JumioIntro1 = ({
  currentStep,
  previousStep,
  goToStep,
  llcWhollyOwnedByIra,
  accountType,
  checkingAccountType,
  isAdditionalParticipant,
  usaCitizen,
  usaPermanentResident,
}) => {
  const classes = useStyles();

  const handleNextStep = () => {
    return !usaCitizen && usaPermanentResident
      ? goToStep(currentStep + 1)
      : goToStep(currentStep + 2);
  };

  const handlePreviousStep = () => {
    if (isAdditionalParticipant) {
      return goToStep(currentStep - 4);
    }
    if (
      ![
        ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL,
        ACCOUNT_TYPE_CUSTODIAL_IRA,
      ].includes(accountType) &&
      checkingAccountType === CHECKING_ACCOUNT_TYPE_LLC &&
      llcWhollyOwnedByIra
    ) {
      return goToStep(currentStep - 4);
    }
    return goToStep(currentStep - 4);
  };

  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle
          step={currentStep}
          title="Identity Verification Completed"
        />
        <Box className={classes.container}>
          <Box className={classes.graphicContainer}>
            <img
              className={classes.graphic}
              src={IdIntro1}
              srcSet={(IdIntro12x, IdIntro13x)}
              alt="Id Verification Completed"
            />
          </Box>
        </Box>
        <Box mt={15} mb={50}>
          <Typography variant="h6" className={classes.header}>
            <b>
              You have completed the verification step. Your data is being
              processed.
            </b>
          </Typography>
        </Box>
        <ProgressButtonGroup
          left={{
            clickHandler: handlePreviousStep,
            pageCalling: "Back Click: Stripe Completion Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: Stripe Completion Page",
            disabled: false,
          }}
        />
      </Container>
    </Box>
  );
};

export default JumioIntro1;
