import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Container,
  TextField,
  MenuItem,
  Select,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { isEmpty } from "lodash";

import AddressAutoComplete from "components/AddressAutocomplete";
import ScreenTitle from "../ScreenTitle";
import { QuestionSection, Question } from "../Question";
import ProgressButtonGroup from "../ProgressButtonGroup";
import QuestionSSN from "../QuestionSSN";
import DateField from "../DateField";
import PostCode from "../PostCode";

import { getSSN, saveSSN } from "util/manageSSN";
import {
  mapStatesToOptions,
  useIsMountedRef,
  createAbortController,
} from "util/helpers";
import { singleInputValidator } from "util/validation";
import {
  ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL,
  ACCOUNT_TYPE_SOLO_401K,
} from "../../../../constants";

const TrustInfo = ({
  previousStep,
  nextStep,
  currentStep,
  goToStep,
  accountType,
  iraType,
  trustStateOfRegistration,
  trustName,
  trustCreationDate,
  trustAddressObj,
  trustAddressLine1,
  trustApt,
  trustCity,
  trustPostcode,
  trustState,
  resAddressLine1,
  resAptNumber,
  resCity,
  resPostcode,
  resState,
  mailingAddressLine1,
  mailingAptNumber,
  mailingCity,
  mailingPostcode,
  mailingState,
  trustTaxIdRef,
  trustAddressEquals,
  handleFormChange,
  errors,
  setFieldTouched,
  setFieldValue,
  handleSave,
  addPartAuthToken,
  referrerInitData,
}) => {
  const isMounted = useIsMountedRef();
  const [trustTaxIdValue, setTrustTaxIdValue] = useState("");
  const [shouldShowTrustTaxId, setShowTrustTaxId] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [localDate, setLocalDate] = useState("");
  const [localDateError, setLocalDateError] = useState({});
  const [disabledDate, setDisabledDate] = useState(true);

  const { trustTaxId: trustTaxIdInit } = referrerInitData || {};
  useEffect(() => {
    const { abortController, signal } = createAbortController();
    async function fetchSSN() {
      if (trustTaxIdRef && !trustTaxIdValue) {
        isLoading && setLoading(true);
        const str = await getSSN(
          trustTaxIdRef,
          "trustTaxIdRef",
          signal,
          addPartAuthToken
        );
        str && setTrustTaxIdValue(str);
      }
      isMounted.current && setLoading(false);
    }
    fetchSSN();
    return function cleanup() {
      abortController && abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trustTaxIdRef]);

  const handleAddressSelection = useCallback(
    (addressComponents) => {
      setFieldValue("trustAddressLine1", addressComponents.address);
      setFieldValue("trustCity", addressComponents.city);
      setFieldValue("trustPostcode", addressComponents.zipCode);
      setFieldValue("trustState", addressComponents.state);
      setFieldValue("trustAddressObj", addressComponents.value);
    },
    [setFieldValue]
  );

  const handleChange = (e) => {
    e.persist();
    handleFormChange(e);
  };

  const handleNameChange = (e) => {
    e.persist();
    const value = e.target.value;
    const filtered = value.replace(/[^a-zA-Z0-9  -]/g, "");
    setFieldValue("trustName", filtered);
  };

  const handleAptChange = (e) => {
    e.persist();
    const value = e.target.value;
    const filtered = value.replace(/[^a-zA-Z0-9  -]/g, "");
    setFieldValue(e.target.name, filtered);
  };

  const handleChangeRadio = (e) => {
    setFieldValue("trustAddressEquals", e.target.value);
  };

  const handleBlur = (e) => {
    setFieldTouched(e.target.name, true, false);
  };

  const handleTrustTaxIdChange = (e) => {
    e.persist();
    // Check for length of the value (9 numbers in SSN and 2 '-' masks), and also check for 'X' added as a part of hiding.
    if (e.target.value.length <= 11) {
      // This regex replaces every non digit value so that only number values are accepted and '-' is removed before saving it to field.
      const inputTaxId = e.target.value.replace(/\D/g, "").trim();
      setTrustTaxIdValue(inputTaxId);
      if (inputTaxId.length === 9) {
        setLoading(true);
        saveSSN({ ssn: inputTaxId }, addPartAuthToken)
          .then((str) => setFieldValue("trustTaxIdRef", str))
          .catch((error) => console.error(error))
          .finally(() => setLoading(false));
      }

      if (inputTaxId.length < 9) {
        setFieldValue("trustTaxIdRef", "");
      }
    }
  };

  useEffect(() => {
    if (trustCreationDate && localDate === "") {
      setLocalDate(trustCreationDate);
      setDisabledDate(false);
    }
    // eslint-disable-next-line
  }, [trustCreationDate]);

  const handleDateChange = (value) => {
    setLocalDate(value);
    handleDateValidation(value);
  };

  const handleDateValidation = async (value) => {
    const error = await singleInputValidator("birthDate", value);
    setLocalDateError({ trustCreationDate: error });
    if (isEmpty(error)) {
      setDisabledDate(false);
    } else {
      setDisabledDate(true);
    }
  };

  const handlePreviousStep = () => {
    if (
      accountType === ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL &&
      !iraType.toLowerCase().includes("inherited")
    ) {
      return goToStep(currentStep - 2);
    }
    return previousStep();
  };

  const resolveDisabled = () => {
    let disabled = false;
    if (
      !trustName ||
      Boolean(errors.trustName) ||
      !localDate ||
      disabledDate ||
      Boolean(errors.trustCreationDate) ||
      !trustTaxIdRef ||
      (trustAddressEquals === "other" &&
        (isEmpty(trustAddressLine1) ||
          isEmpty(trustCity) ||
          isEmpty(trustState) ||
          isEmpty(trustPostcode)))
    ) {
      disabled = true;
    }
    if (accountType !== ACCOUNT_TYPE_SOLO_401K) {
      if (
        !trustStateOfRegistration ||
        Boolean(errors.trustStateOfRegistration)
      ) {
        disabled = true;
      }
    }

    return disabled;
  };

  const handleNextStep = () => {
    //eslint-disable-next-line default-case
    switch (trustAddressEquals) {
      case "residential":
        setFieldValue("trustAddressLine1", resAddressLine1);
        setFieldValue("trustApt", resAptNumber);
        setFieldValue("trustCity", resCity);
        setFieldValue("trustPostcode", resPostcode);
        setFieldValue("trustState", resState);
        break;
      case "mailing":
        setFieldValue("trustAddressLine1", mailingAddressLine1);
        setFieldValue("trustApt", mailingAptNumber);
        setFieldValue("trustCity", mailingCity);
        setFieldValue("trustPostcode", mailingPostcode);
        setFieldValue("trustState", mailingState);
        break;
    }
    setFieldValue("trustCreationDate", localDate);
    if (handleSave) {
      handleSave();
    }
    nextStep();
  };

  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle step={currentStep} title="Trust Information" />
        <QuestionSection>
          <Question text="Name of your trust" required />
          <TextField
            id="trustName"
            name="trustName"
            value={trustName}
            fullWidth
            onChange={handleNameChange}
            onBlur={handleBlur}
            placeholder="Enter name of trust"
            required
          />
        </QuestionSection>
        <QuestionSSN
          value={
            trustTaxIdValue === "" && trustTaxIdRef
              ? "000000000"
              : shouldShowTrustTaxId
              ? trustTaxIdValue
              : trustTaxIdValue.replace(/-/g, "").replace(/.(?=.{4,}$)/g, "X")
          }
          onChange={handleTrustTaxIdChange}
          name={
            trustTaxIdValue === "" && trustTaxIdRef
              ? "hidden"
              : shouldShowTrustTaxId
              ? "trustTaxId"
              : "concealed"
          }
          id="trustTaxId"
          shouldShowSSN={shouldShowTrustTaxId}
          questionText={trustTaxIdInit?.question || "Trust tax ID/EIN"}
          buttonOnClick={() =>
            setShowTrustTaxId((prevButtonState) => !prevButtonState)
          }
          required
          readOnly={isLoading || !shouldShowTrustTaxId}
          showEditIcon={false}
          isLoading={isLoading}
        />
        <QuestionSection>
          <Question text="Trust creation date" required />
          <DateField
            value={localDate}
            fieldValue="trustCreationDate"
            onChange={handleDateChange}
            // onBlur={handleBlur}
            errors={localDateError || {}}
            touched={{ trustCreationDate: true }}
          />
        </QuestionSection>
        {accountType !== ACCOUNT_TYPE_SOLO_401K && (
          <QuestionSection>
            <Question text="State trust was registered" required />
            <Select
              fullWidth
              labelId="trustStateOfRegistration"
              name="trustStateOfRegistration"
              id="trustStateOfRegistration"
              value={trustStateOfRegistration || ""}
              onChange={handleChange}
            >
              {mapStatesToOptions().map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.name}
                </MenuItem>
              ))}
            </Select>
          </QuestionSection>
        )}
        <QuestionSection>
          <Question text="Trust address" required />
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="trustAddressEquals"
              name="trustAddressEquals"
              value={trustAddressEquals}
              onChange={handleChangeRadio}
              row
            >
              <FormControlLabel
                value="residential"
                control={<Radio />}
                label="Same as residential"
              />
              <FormControlLabel
                value="mailing"
                control={<Radio />}
                label="Same as mailing"
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
        </QuestionSection>
        {trustAddressEquals === "other" && (
          <>
            <QuestionSection>
              <Question text="Trust address" />
              <AddressAutoComplete
                onSelectAddress={handleAddressSelection}
                initialValue={trustAddressObj}
              />
            </QuestionSection>
            <Box pt={30}>
              <Typography variant="body2">
                OR manually enter your address below:
              </Typography>
            </Box>
            <QuestionSection>
              <Question text="Address Line 1" required />
              <TextField
                id="trustAddressLine1"
                name="trustAddressLine1"
                value={trustAddressLine1}
                fullWidth
                onChange={handleChange}
                placeholder="Enter address"
              />
            </QuestionSection>
            <Box display="flex" justifyContent="start" alignItems="center">
              <Box flexGrow={1}>
                <QuestionSection>
                  <Question text="Apartment/Suite" />
                  <TextField
                    id="trustApt"
                    name="trustApt"
                    value={trustApt}
                    fullWidth
                    onChange={handleAptChange}
                    placeholder="Enter Apt/Suite number"
                  />
                </QuestionSection>
              </Box>
              <Box flexGrow={1} pl={30}>
                <QuestionSection>
                  <Question text="City" required />
                  <TextField
                    id="trustCity"
                    name="trustCity"
                    value={trustCity}
                    fullWidth
                    onChange={handleChange}
                    placeholder="Enter city"
                  />
                </QuestionSection>
              </Box>
            </Box>
            <Box display="flex" justifyContent="start" alignItems="center">
              <Box flexGrow={1}>
                <QuestionSection>
                  <Question text="State" required />
                  <Select
                    fullWidth
                    labelId="trustState"
                    name="trustState"
                    id="trustState"
                    value={trustState || ""}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="" disabled dense>
                      Select a state
                    </MenuItem>
                    {mapStatesToOptions().map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.name}
                      </MenuItem>
                    ))}
                  </Select>
                </QuestionSection>
              </Box>
              <Box flexGrow={1} pl={30}>
                <PostCode
                  name="trustPostcode"
                  value={trustPostcode}
                  onChange={setFieldValue}
                  required
                />
              </Box>
            </Box>
          </>
        )}
        <ProgressButtonGroup
          left={{
            clickHandler: handlePreviousStep,
            pageCalling: "Back Click: Trust Info Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: Trust Info Page",
            disabled: resolveDisabled(),
          }}
          hasRequiredInputs
        />
      </Container>
    </Box>
  );
};

export default TrustInfo;
