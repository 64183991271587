import React from "react";
import { Box, Typography, Link } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const Help = ({ link = "", copy = "" }) => {
  return (
    <Box mt={40} display="flex" flexDirection="row" alignItems="center">
      <InfoOutlinedIcon
        fontSize="default"
        style={{ marginRight: 6, color: "#4DA1A6" }}
      />
      <Typography>
        <Link
          variant="body2"
          underline="always"
          color="primary"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {copy}
        </Link>
      </Typography>
    </Box>
  );
};

export default Help;
