import firebase, { FIREBASE_URLS } from "config/firebase";
import * as Sentry from "@sentry/react";
import { request } from "./request";
// import rollbar from "config/rollbar";
import config from "../config/config";
import crypto from "crypto-js";

// const publicKey = process.env.REACT_APP_ENCRYPT_PUBLIC_KEY;

export async function getSSN(id, key, signal = null, customJwt) {
  // id is ssn database ref
  try {
    let token;
    if (customJwt) {
      token = customJwt;
    } else {
      token = await getToken();
    }
    const url = customJwt
      ? `${FIREBASE_URLS.manageSSN}/additionalParticipant/${id}`
      : `${FIREBASE_URLS.manageSSN}/${id}`;
    const res = await request(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ key }),
      signal,
    });
    if (!res.ok) throw Error("res not ok");
    const data = await res.json();
    // now decrypt with public key
    const cipher = data.str;
    const bytesPub = crypto.AES.decrypt(cipher, config.encKey);
    const ssn = bytesPub.toString(crypto.enc.Utf8);
    return ssn;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    // if (error.name !== "AbortError") {
    //   rollbar.error(error, "get ssn");
    // }
  }
}

export async function saveSSN(payload, customJwt) {
  // payload consists of userId and SSN
  try {
    let token;
    if (customJwt) {
      token = customJwt;
    } else {
      token = await getToken();
    }
    const url = customJwt
      ? `${FIREBASE_URLS.manageSSN}/additionalParticipant`
      : `${FIREBASE_URLS.manageSSN}`;
    // encrypt payload
    const cipher = crypto.AES.encrypt(
      payload.ssn.toString(),
      config.encKey
    ).toString();
    payload.ssn = cipher;
    const res = await request(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    if (!res.ok) throw Error("res not ok", await res.text());
    if (res.ok) {
      const result = await res.json();
      return result.str;
    }
  } catch (error) {
    console.error(error, "save ssn");
    Sentry.captureException(error);
    // rollbar.error(error, "save ssn");
  }
}

function getToken() {
  return firebase.auth().currentUser.getIdToken();
}
