import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import imagePaths from "util/resolveImagePath";
import { ThemeContext } from "components/Theme";

import {
  Button,
  Box,
  Typography,
  makeStyles,
  Link,
  IconButton,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

import { breakpoints } from "config/defaultTheme/breakpoints";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "calc(100vh - var(--footerHeight) - var(--headerHeight))",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    [`@media(min-width:${breakpoints.sm}px)`]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
  },
  slide: {
    paddingTop: 50,
    paddingBottom: 30,
  },
  contentContainer: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    padding: "0px 16px 30px 16px",
    [`@media(min-width:${breakpoints.sm}px)`]: {
      padding: "0px 32px 30px 32px",
    },
    maxWidth: 800,
    margin: "auto",
  },
  title: {
    padding: "32px 30px 12px 30px",
    margin: 0,
  },
  graphicContainer: {
    background: "white",
    borderRadius: 100,
    maxWidth: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [`@media(min-width:${breakpoints.sm}px)`]: {
      width: 325,
      maxWidth: 325,
    },
  },
  graphic: {
    objectFit: "contain",
    width: "100%",
  },
  dotContainer: {
    [`@media(max-width:${breakpoints.smDown}px)`]: {
      marginTop: "-15px",
    },
  },
  dot: {
    cursor: "pointer",
    height: 10,
    width: 10,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "50%",
    display: "flex",
    transition: "background-color 0.6s ease",
  },
  active: {
    backgroundColor: theme.palette.text.primary,
  },
  arrowContainer: {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%,-40%)",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [`@media(min-width:${breakpoints.sm}px)`]: {
      width: "calc(100% - 150px)",
      maxWidth: 820,
      top: "50%",
      transform: "translate(-50%,-50%)",
    },
  },
  arrow: {
    color: "var(--dark-grey-blue)",
    cursor: "pointer",
  },
  linkCopy: {
    display: "block",
    [`@media(min-width:${breakpoints.sm}px)`]: {
      display: "inline-block",
    },
    "& a": {
      textDecoration: "underline",
    },
  },
}));

const IntroSlides = ({ additionalParticipantClickHandler, copy = {} }) => {
  const { themeName = "" } = useContext(ThemeContext) || {};
  const [slideIndex, setSlideIndex] = useState(0);
  const graphics = [
    {
      image: imagePaths(themeName).intro1,
      image2x: imagePaths(themeName).intro1_2x,
      alt: "Welcome",
    },
    {
      image: imagePaths(themeName).intro2,
      image2x: imagePaths(themeName).intro2_2x,
      alt: "Simple & secure",
    },
    {
      image: imagePaths(themeName).intro3,
      image2x: imagePaths(themeName).intro3_2x,
      alt: "10 minutes or less",
    },
  ];

  const classes = useStyles();

  function renderContent(i, copy) {
    return (
      <div key={i}>
        <Box className={classes.slide}>
          <Box className={classes.contentContainer}>
            <Box>
              <Box className={classes.graphicContainer}>
                <img
                  className={classes.graphic}
                  src={graphics[i - 1].image}
                  srcSet={`${graphics[i - 1].image2x} , ${
                    graphics[i - 1].image3x
                  } `}
                  alt={graphics[i - 1].alt}
                />
              </Box>
            </Box>
            <Typography variant="h4" className={classes.title}>
              {copy && copy[`heading${i}`]}
            </Typography>

            <Typography
              key={`body-${i}`}
              variant="h6"
              className={classes.bodyCopy}
            >
              {copy && copy[`body${i}`]}
            </Typography>

            {i === 3 && (
              <Box pt={30}>
                {additionalParticipantClickHandler ? (
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={additionalParticipantClickHandler}
                  >
                    Begin
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    component={RouterLink}
                    to="/register"
                  >
                    Sign Up
                  </Button>
                )}
              </Box>
            )}

            {!additionalParticipantClickHandler && (
              <Box pt={35}>
                <Typography variant="body2">
                  Already started an application?{" "}
                  <Box component="span" className={classes.linkCopy}>
                    <Link href="/register?login=true">Click here</Link> to sign
                    in.
                  </Box>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </div>
    );
  }

  const handleChangeIndex = (index) => {
    setSlideIndex(index);
  };

  const handleArrowLeft = () => {
    if (slideIndex === 0) return;
    setSlideIndex((index) => index - 1);
  };

  const handleArrowRight = () => {
    if (slideIndex === 2) return;
    setSlideIndex((index) => index + 1);
  };

  return (
    <Box>
      <Box position="relative" className={classes.container}>
        <SwipeableViews
          enableMouseEvents
          index={slideIndex}
          onChangeIndex={handleChangeIndex}
        >
          {Array.from([1, 2, 3].map((i) => renderContent(i, copy)))}
        </SwipeableViews>
        <Box className={classes.arrowContainer}>
          {slideIndex > 0 ? (
            <IconButton
              aria-label="left"
              onClick={handleArrowLeft}
              disableRipple
            >
              <ChevronLeft fontSize="large" className={classes.arrow} />
            </IconButton>
          ) : (
            <Box></Box>
          )}
          {slideIndex <= 1 ? (
            <IconButton
              aria-label="right"
              onClick={handleArrowRight}
              disableRipple
            >
              <ChevronRight fontSize="large" className={classes.arrow} />
            </IconButton>
          ) : (
            <Box></Box>
          )}
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.dotContainer}
        >
          {Array.from(
            [1, 2, 3].map((item, index) => {
              return (
                <Box
                  key={index}
                  component="span"
                  mx={10}
                  className={`${classes.dot} ${
                    index === slideIndex ? classes.active : ""
                  }`}
                  onClick={() => setSlideIndex(index)}
                />
              );
            })
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default IntroSlides;
