import React from "react";
import PropTypes from "prop-types";

import {
  Box,
  Typography,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import { CheckCircle as CheckCircleIcon } from "@material-ui/icons";

import { ReactComponent as PandadocLogo } from "assets/pandadoc.svg";

const useStyles = makeStyles({
  loadingContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    top: "20%",
    zIndex: 9999,
  },
  successIcon: {
    color: "var(--light-moss-green)",
    fontSize: 50,
  },
});
const DocumentSigningLoading = ({ error, completed, isCompleting }) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.loadingContainer}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {error ? (
        <Typography variant="h4">Sorry something went wrong!</Typography>
      ) : completed ? (
        <Typography variant="h6">
          You have already completed your trust.
        </Typography>
      ) : (
        <>
          <Typography variant="body1">Hang tight.</Typography>
          <Box mt={20}>
            <Typography variant="body1">
              {isCompleting
                ? "Your document is being finalized. You will be able to download it soon."
                : "Your document is loading...."}
            </Typography>
          </Box>
        </>
      )}
      <Box mt={40}>
        <PandadocLogo />
      </Box>
      <Box
        mt={40}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flex={1}
      >
        {completed ? (
          <CheckCircleIcon className={classes.successIcon} />
        ) : (
          <CircularProgress size={50} />
        )}
      </Box>
    </Box>
  );
};

DocumentSigningLoading.propTypes = {
  error: PropTypes.bool,
  completed: PropTypes.bool,
};

export default DocumentSigningLoading;
