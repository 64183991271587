import { BASE_BUCKET } from "config/firebase";
import themeName from "config/resolveThemeName";

export default function imagePaths(providerPath) {
  providerPath = providerPath || themeName();
  return {
    completed: `${BASE_BUCKET}/${providerPath}/completed.png`,
    completed_2x: `${BASE_BUCKET}/${providerPath}/completed@2x.png`,
    favicon: `${BASE_BUCKET}/${providerPath}/favicon.png`,
    intro1: `${BASE_BUCKET}/${providerPath}/intro1.png`,
    intro1_2x: `${BASE_BUCKET}/${providerPath}/intro1@2x.png`,
    intro2: `${BASE_BUCKET}/${providerPath}/intro2.png`,
    intro2_2x: `${BASE_BUCKET}/${providerPath}/intro2@2x.png`,
    intro3: `${BASE_BUCKET}/${providerPath}/intro3.png`,
    intro3_2x: `${BASE_BUCKET}/${providerPath}/intro3@2x.png`,
    logoTransparent: `${BASE_BUCKET}/${providerPath}/logo-transparent.svg`,
    logoHeader: `${BASE_BUCKET}/${providerPath}/logo-header.svg`,
    logoBlack: `${BASE_BUCKET}/${providerPath}/logo-black.svg`,
    logoEmailColorBg: `${BASE_BUCKET}/${providerPath}/logo-email-color-bg.png`,
    logoEmailWhiteBg: `${BASE_BUCKET}/${providerPath}/logo-email-white-bg.png`,
    welcome: `${BASE_BUCKET}/${providerPath}/welcome.png`,
    welcome_2x: `${BASE_BUCKET}/${providerPath}/welcome@2x.png`,
    welcome2: `${BASE_BUCKET}/${providerPath}/welcome2.png`,
    welcome2_2x: `${BASE_BUCKET}/${providerPath}/welcome2@2x.png`,
  };
}
