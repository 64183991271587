/* eslint-disable react/display-name */
import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { ApplicationDataContext } from "components/ApplicationData";
import { cloneDeep } from "lodash";

import { FORM_INITIAL_STATE } from "util/variableStructure";

function withFormData(WrappedComponent) {
  const Wrapper = () => {
    class innerComponent extends Component {
      state = {
        maxCompletedSection: 0,
        isLoadingPostForm: false,
        hasErroredPostForm: false,
        isPostFormComplete: false,
        initialValues: FORM_INITIAL_STATE,
      };

      componentDidMount() {
        this.updateInitialFromSaved();
      }

      updateInitialFromSaved = () => {
        const allInputs = Object.keys(this.state.initialValues);
        const userData = cloneDeep(this.context);
        const obj = {};
        allInputs.forEach((input) => {
          obj[input] = userData.hasOwnProperty(input)
            ? userData[input]
            : this.state.initialValues[input];
        });
        this.setState((prevState) => ({
          ...prevState,
          initialValues: {
            ...prevState.initialValues,
            ...obj,
            hasInitValues: true,
          },
        }));
      };

      saveForm = async (values) => {
        const { saveProgress } = this.context;
        await saveProgress(values);
      };

      render() {
        return <WrappedComponent {...this.state} saveForm={this.saveForm} />;
      }
    }
    innerComponent.contextType = ApplicationDataContext;
    return innerComponent;
  };
  return withCookies(Wrapper());
}

export default withFormData;
