export const CONTACT_INFO = "Contact Information";
export const PERSON_INFO = "Personal Information";
export const ADD_PARTICIPANT = "Additional Participant";
export const ACCT_INVEST = "Account & Investment Options";
export const LLC = "LLC Information";
export const TRUST = "Trust Information";
export const BENEFICIARY = "Beneficiary Information";
export const ID_SECURITY = "Identity & Security";
export const REVIEW_SUBMIT = "Review & Submit";
export const REVIEW = "Review";
export const TRUST_CREATION = "Trust Creation";
export const TRI_PARTY_AGREEMENT = "Tri Party Agreement";

export const LOCAL_STORAGE_REFERRER = "solera-referrer";
export const LOCAL_STORAGE_REFERRER_DATA = "solera-referrer-data";
export const LOCAL_STORAGE_REFERRER_AUTHORITY = "solera-referrer-authority";
export const QUERY_PARAM_REFERRER = "referrer";
export const REFERRER_LIST_PARAM_KEY = "referrerUrlSafe";

export const LOCAL_STORAGE_THEME_NAME = "themeName";
export const LOCAL_STORAGE_THEME = "theme";

export const SESSION_STORAGE_MAGIC_LINK_NAME = "magicLinkSent";
export const LOCAL_STORAGE_MAGIC_LINK_NAME = "emailForSignIn";

export const MARITAL_STATUS_MARRIED = "married";

export const ACCOUNT_TYPE_SOLO_401K = "Solo 401K";
export const ACCOUNT_TYPE_CHECKBOOK_BANK_ONLY =
  "Checkbook IRA - Bank Account Only";
export const ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL =
  "Checkbook IRA - Custodial IRA & Bank Account";
export const ACCOUNT_TYPE_BUSINESS_ACCOUNT =
  "Business Account - Bank Account Only";
export const ACCOUNT_TYPE_CUSTODIAL_IRA = "Custodial IRA - Bank Account Only";

export const ACCOUNT_TYPES_REQUIRE_BENEFICIARIES = [
  ACCOUNT_TYPE_CUSTODIAL_IRA,
  ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL,
];

export const CHECKING_ACCOUNT_TYPE_TRUST = "Trust";
export const CHECKING_ACCOUNT_TYPE_LLC = "LLC";

export const MAX_UPLOAD_SIZE_MB = 10;
export const MAX_UPLOAD_SIZE = MAX_UPLOAD_SIZE_MB * 1024 * 1024; // 10MB
export const MAX_UPLOAD_SIZE_TEXT = `Maximum file size of ${MAX_UPLOAD_SIZE_MB}Mb.`;
export const GRATIS_THEME_NAME = "gratis";
export const SOLERA_THEME_NAME = "solera";
