import React, { useState, useEffect } from "react";
import { Box, Container, FormHelperText, makeStyles } from "@material-ui/core";
import { isEmpty } from "lodash";

import ScreenTitle from "../ScreenTitle";
import { QuestionSection, Question } from "../Question";
import ProgressButtonGroup from "../ProgressButtonGroup";
import FileInput from "components/FileInput";
import { Loading } from "containers/Loading";

import { handleFileUpload, getFileExtension, getFile } from "util/helpers";
import {
  LLC,
  MAX_UPLOAD_SIZE,
  MAX_UPLOAD_SIZE_TEXT,
  MAX_UPLOAD_SIZE_MB,
} from "../../../../constants";

const useStyles = makeStyles({
  previewContainer: {
    position: "relative",
    background: "white",
    borderRadius: 10,
    display: "flex",
    border: "1px solid",
    maxWidth: 300,
  },
  imageContainer: {
    overflow: "hidden",
    width: "100%",
    minHeight: 200,
  },
  iframeContainer: {
    width: "100%",
    minHeight: 200,
    position: "relative",
  },
  preview: {
    width: "100%",
    opacity: "100%",
    objectFit: "contain",
  },
  iframe: {
    position: "absolute",
    borderRadius: 10,
    border: "none",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
  },
  iconContainer: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    backgroundColor: "white",
    borderRadius: "50%",
    opacity: "70%",
    boxShadow: "4px 4px 4px black",
  },
  loadingContainer: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
  },
});

const LLCUpload = ({
  previousStep,
  nextStep,
  currentStep,
  goToStep,
  completedSections,
  applicationRefId,
  applicationId,
  llcOperatingAgreementRef,
  llcArticlesOfOrganizationRef,
  setFieldValue,
  hasInitValues,
  llcWhollyOwnedByIra,
  handleSave,
}) => {
  const classes = useStyles();
  const [articlesOfOrgImage, setArticlesOfOrgImage] = useState({});
  const [articlesOfOrgError, setArticlesOfOrgError] = useState(null);
  const [operatingAgreementImage, setOperatingAgreementImage] = useState({});
  const [operatingAgreementError, setOperatingAgreementError] = useState(null);
  const [downloadError, setDownloadError] = useState(null);
  const [isLoadingArticlesOfOrganization, setLoadingArticlesOfOrganization] =
    useState(true);
  const [isLoadingOperatingAgreement, setLoadingOperatingAgreement] =
    useState(true);

  useEffect(() => {
    async function getFilesFromStorage() {
      const downloads = [];
      if (llcArticlesOfOrganizationRef)
        downloads.push({ llcArticlesOfOrganizationRef });
      if (llcOperatingAgreementRef)
        downloads.push({ llcOperatingAgreementRef });
      if (downloads.length === 0) {
        setLoadingOperatingAgreement(false);
        return setLoadingArticlesOfOrganization(false);
      }
      try {
        const urls = await Promise.all(
          downloads.map((item) => {
            const values = Object.keys(item).map((key) => item[key]);
            return getFile(values[0]);
          })
        );
        downloads.forEach((download, i) => {
          if (Object.keys(download)[0] === "llcArticlesOfOrganizationRef") {
            setArticlesOfOrgImage((prevState) => ({
              ...prevState,
              src: urls[i],
              type: "application/pdf",
            }));
          } else {
            setOperatingAgreementImage((prevState) => ({
              ...prevState,
              src: urls[i],
              type: "application/pdf",
            }));
          }
        });
      } catch (error) {
        console.error(error);
        setDownloadError(
          "Sorry we encountered an issue downloading one of your files"
        );
      } finally {
        setLoadingOperatingAgreement(false);
        setLoadingArticlesOfOrganization(false);
      }
    }
    if (hasInitValues === true) getFilesFromStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasInitValues]);

  const handleFileSelection = async (field, uploadEvent) => {
    const successCallback =
      field === "articlesOfOrganization"
        ? setArticlesOfOrgImage
        : setOperatingAgreementImage;

    const errorCallback =
      field === "articlesOfOrganization"
        ? setArticlesOfOrgError
        : setOperatingAgreementError;
    if (
      uploadEvent &&
      uploadEvent.currentTarget &&
      uploadEvent.currentTarget.files &&
      uploadEvent.currentTarget.files[0]
    ) {
      try {
        const file = uploadEvent.currentTarget.files[0];
        const size = file.size;
        if (size > MAX_UPLOAD_SIZE)
          throw Error(
            `The maximum allowed size is ${MAX_UPLOAD_SIZE_MB}Mb. This file is ${parseInt(
              size / (1024 * 1024)
            )}Mb.`
          );

        const dbName =
          field === "articlesOfOrganization"
            ? "llcArticlesOfOrganizationRef"
            : "llcOperatingAgreementRef";
        const storageName =
          field === "articlesOfOrganization"
            ? "llc_articles_of_organization"
            : "llc_operating_agreement";

        field === "articlesOfOrganization"
          ? setLoadingArticlesOfOrganization(true)
          : setLoadingOperatingAgreement(true);
        errorCallback(null);

        const ref = await handleFileUpload(
          file,
          `/applications/${applicationRefId || applicationId}/llc`,
          `${storageName}.${getFileExtension(file)}`
        );

        const url = await getFile(ref);

        successCallback((prevState) => ({
          ...prevState,
          src: url,
          type: "application/pdf",
        }));
        setFieldValue(dbName, ref);
        setLoadingOperatingAgreement(false);
        setLoadingArticlesOfOrganization(false);
      } catch (error) {
        console.error(error);
        errorCallback(error.message);
      }
    }
  };

  const resolveDisabled = () => {
    return (
      isEmpty(llcOperatingAgreementRef) ||
      isEmpty(llcArticlesOfOrganizationRef) ||
      !!operatingAgreementError ||
      !!articlesOfOrgError
    );
  };

  const handlePreviousStep = () => {
    if (llcWhollyOwnedByIra) {
      goToStep(currentStep - 2);
    } else {
      return previousStep();
    }
  };

  const handleNextStep = () => {
    !completedSections.includes(LLC) &&
      setFieldValue("completedSections", [...completedSections, LLC]);
    if (handleSave) {
      handleSave();
    }
    return nextStep();
  };

  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle step={currentStep} title="LLC Documents" />
        <FormHelperText error={!!downloadError}>{downloadError}</FormHelperText>
        <QuestionSection>
          <Question
            text="Please upload your Articles of Organization here"
            required
          />
          <Box>
            <Box
              className={`${classes.previewContainer}
                ${
                  articlesOfOrgImage.type === "application/pdf"
                    ? classes.iframeContainer
                    : classes.imageContainer
                }
              `}
            >
              {articlesOfOrgImage.type === "application/pdf" ? (
                <iframe
                  title="Articles of Organization preview"
                  src={`${articlesOfOrgImage.src}`}
                  className={classes.iframe}
                  allowFullScreen={true}
                ></iframe>
              ) : (
                <img
                  className={classes.preview}
                  src={articlesOfOrgImage.src}
                  alt={articlesOfOrgImage.name}
                />
              )}
              {!isLoadingArticlesOfOrganization ? (
                <Box className={classes.iconContainer}>
                  <FileInput
                    name="articlesOfOrganization"
                    type="articlesOfOrganization"
                    changeHandler={handleFileSelection.bind(
                      null,
                      "articlesOfOrganization"
                    )}
                  />
                </Box>
              ) : (
                <Box className={classes.loadingContainer}>
                  <Loading />
                </Box>
              )}
            </Box>
          </Box>
          <FormHelperText>
            PDFs only please. {MAX_UPLOAD_SIZE_TEXT}
          </FormHelperText>
          <FormHelperText error={!!articlesOfOrgError}>
            {articlesOfOrgError}
          </FormHelperText>
        </QuestionSection>
        {/* second upload */}
        <QuestionSection>
          <Question
            text="Please upload your Operating Agreement here"
            required
          />
          <Box>
            <Box
              className={`${classes.previewContainer}
                ${
                  operatingAgreementImage.type === "application/pdf"
                    ? classes.iframeContainer
                    : classes.imageContainer
                }
              `}
            >
              {operatingAgreementImage.type === "application/pdf" ? (
                <iframe
                  title="Operating Agreement preview"
                  src={`${operatingAgreementImage.src}`}
                  className={classes.iframe}
                  allowFullScreen={true}
                ></iframe>
              ) : (
                <img
                  className={classes.preview}
                  src={operatingAgreementImage.src}
                  alt={operatingAgreementImage.name}
                />
              )}
              {!isLoadingOperatingAgreement ? (
                <Box className={classes.iconContainer}>
                  <FileInput
                    name="operatingAgreement"
                    type="operatingAgreement"
                    changeHandler={handleFileSelection.bind(
                      null,
                      "operatingAgreement"
                    )}
                  />
                </Box>
              ) : (
                <Box className={classes.loadingContainer}>
                  <Loading />
                </Box>
              )}
            </Box>
          </Box>
          <FormHelperText>
            PDFs only please. Maximum file size is 10 Mb
          </FormHelperText>
          <FormHelperText error={!!operatingAgreementError}>
            {operatingAgreementError}
          </FormHelperText>
        </QuestionSection>

        <ProgressButtonGroup
          left={{
            clickHandler: handlePreviousStep,
            pageCalling: "Back Click: LLC Upload Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: LLC Upload Page",
            disabled: resolveDisabled(),
          }}
          hasRequiredInputs
        />
      </Container>
    </Box>
  );
};

export default LLCUpload;
