import React, { useContext } from "react";
import { ThemeContext } from "components/Theme";
import {
  makeStyles,
  Typography,
  Container,
  Box,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import { firebase as firebaseAdmin } from "@firebase/app";
import imagePaths from "util/resolveImagePath";

import {
  WelcomeTitle,
  WelcomeInfo,
} from "containers/IRAForm/components/HomeTitles";
import {
  QuestionSection,
  Question,
} from "containers/IRAForm/components/Question";

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    paddingBottom: 30,
  },
  graphicContainer: {
    background: "white",
    borderRadius: 100,
    maxWidth: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      width: 325,
      maxWidth: 325,
    },
  },
  graphic: {
    objectFit: "contain",
    width: "100%",
  },
}));

const welcomeText = "Thank you for completing your application!";
const welcomeInfo = "Your application is currently being reviewed.";
const contactInfoText =
  "For questions or concerns regarding your application, please include your name on file and application Ref ID in the subject line";

const Confirmation = ({
  firstName,
  lastName,
  email,
  additionalParticipantSubmittedAt: submittedAt,
  applicationRefId,
  applicationId,
}) => {
  const classes = useStyles();

  const { email: themeEmail = "", companyName = "" } =
    useContext(ThemeContext)?.copy?.contactInformation || {};

  const { themeName = "" } = useContext(ThemeContext) || {};

  let dateText = "";
  try {
    // timestamp gets serialized when converted to json, may need to create one from object then convert
    if (submittedAt._seconds) {
      const ts = new firebaseAdmin.firestore.Timestamp(
        submittedAt._seconds,
        submittedAt._nanoseconds
      );
      dateText = moment(ts.toDate()).format("MM/DD/YYYY");
    } else {
      dateText = moment(submittedAt.toDate()).format("MM/DD/YYYY");
    }
  } catch (e) {
    dateText = moment(new Date()).format("MM/DD/YYYY");
  }

  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <Box className={classes.container}>
          <Box className={classes.graphicContainer}>
            <img
              className={classes.graphic}
              src={imagePaths(themeName).completed}
              srcSet={imagePaths(themeName).completed_2x}
              alt={`${companyName} Completed`}
            />
          </Box>
        </Box>
        <WelcomeTitle text={welcomeText} />
        <WelcomeInfo text={welcomeInfo} />
        <QuestionSection>
          <Question text="Name on file" />
          <TextField
            id="nameOnFile"
            name="nameOnFile"
            value={`${firstName ? firstName : ""} ${lastName ? lastName : ""}`}
            fullWidth
            readOnly
          />
        </QuestionSection>
        <QuestionSection>
          <Question text="Email on file" />
          <TextField
            id="emailOnFile"
            name="emailOnFile"
            value={email}
            fullWidth
            readOnly
          />
        </QuestionSection>
        <QuestionSection>
          <Question text="Date Submitted" />
          <TextField
            id="submittedAt"
            name="submittedAt"
            value={dateText}
            fullWidth
            readOnly
          />
        </QuestionSection>
        <QuestionSection>
          <Question text="Application Reference ID" />
          <TextField
            id="applicationRefId"
            name="applicationRefId"
            value={applicationRefId || applicationId || ""}
            fullWidth
            readOnly
          />
        </QuestionSection>
        <WelcomeInfo text={contactInfoText} />
        <Box
          pt={20}
          pb={40}
          mb={80}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="body1">
            Contact us at <a href={`mailTo:${themeEmail}`}>{themeEmail}</a>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Confirmation;
