import React from "react";
// import styles from "./styles.module.scss";

// TODO: noHeader will be undefined if not supplied, consider setting default value?
const Layout = ({ children, noHeader }) => {
  // const classes = `${styles.Layout} ${
  //   noHeader ? styles.LayoutNoHeader : styles.LayoutHeader
  // }`;
  const classes = ``;
  return (
    <div className={classes} data-component="Layout">
      {children}
    </div>
  );
};

export default Layout;
