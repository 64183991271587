import React, { useState, useEffect } from "react";
import { Box, Container, FormHelperText, makeStyles } from "@material-ui/core";

import ScreenTitle from "../ScreenTitle";
import { QuestionSection, Question } from "../Question";
import ProgressButtonGroup from "../ProgressButtonGroup";
import FileInput from "components/FileInput";
import { Loading } from "containers/Loading";

import { handleFileUpload, getFileExtension, getFile } from "util/helpers";
import {
  TRI_PARTY_AGREEMENT,
  MAX_UPLOAD_SIZE,
  MAX_UPLOAD_SIZE_TEXT,
  MAX_UPLOAD_SIZE_MB,
} from "../../../../constants";

const useStyles = makeStyles({
  previewContainer: {
    position: "relative",
    background: "white",
    borderRadius: 10,
    display: "flex",
    border: "1px solid",
    maxWidth: 300,
  },
  imageContainer: {
    overflow: "hidden",
    width: "100%",
    minHeight: 400,
  },
  iframeContainer: {
    width: "100%",
    minHeight: 400,
    position: "relative",
  },
  preview: {
    width: "100%",
    opacity: "100%",
    objectFit: "contain",
  },
  iframe: {
    position: "absolute",
    borderRadius: 10,
    border: "none",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
  },
  iconContainer: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    backgroundColor: "white",
    borderRadius: "50%",
    opacity: "70%",
    boxShadow: "4px 4px 4px black",
  },
  loadingContainer: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
  },
});

const TriPartyAgreementUpload = ({
  previousStep,
  nextStep,
  currentStep,
  completedSections,
  hasInitValues,
  applicationRefId,
  applicationId,
  triPartyAgreementRef,
  setFieldValue,
  handleSave,
  accountType,
}) => {
  const classes = useStyles();
  const [triPartyImage, setTriPartyImage] = useState({});
  const [triPartyError, setTriPartyError] = useState(null);
  const [downloadError, setDownloadError] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const documentNameCopy = "Tri Party Agreement";

  useEffect(() => {
    async function getFileFromStorage() {
      try {
        if (triPartyAgreementRef) {
          const url = await getFile(triPartyAgreementRef);
          setTriPartyImage((prevState) => ({
            ...prevState,
            src: url,
            type: "application/pdf",
          }));
        }
      } catch (error) {
        console.error(error);
        setDownloadError("Sorry we encountered an issue downloading this file");
      } finally {
        setLoading(false);
      }
    }
    if (hasInitValues) getFileFromStorage();
  }, [hasInitValues, triPartyAgreementRef]);

  const handleFileSelection = async (field, uploadEvent) => {
    if (
      uploadEvent &&
      uploadEvent.currentTarget &&
      uploadEvent.currentTarget.files &&
      uploadEvent.currentTarget.files[0]
    ) {
      try {
        const file = uploadEvent.currentTarget.files[0];
        const size = file.size;
        if (size > MAX_UPLOAD_SIZE)
          throw Error(
            `The maximum allowed size is ${MAX_UPLOAD_SIZE_MB}Mb. This file is ${parseInt(
              size / (1024 * 1024)
            )}Mb.`
          );
        setLoading(true);
        setTriPartyError(null);
        const ref = await handleFileUpload(
          file,
          `/applications/${applicationRefId || applicationId}/custodial`,
          `tri_party_agreement.${getFileExtension(file)}`
        );

        const url = await getFile(ref);
        setTriPartyImage((prevState) => ({
          ...prevState,
          src: url,
          type: "application/pdf",
        }));
        setFieldValue("triPartyAgreementRef", ref);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setTriPartyError(error.message);
      }
    }
  };

  const resolveDisabled = () => {
    // this screen is optional
    return false;
  };

  const handlePreviousStep = () => {
    return previousStep();
  };

  const handleNextStep = () => {
    setFieldValue("completedSections", [
      ...completedSections,
      TRI_PARTY_AGREEMENT,
    ]);
    if (handleSave) {
      handleSave();
    }
    return nextStep();
  };

  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle step={currentStep} title="Tri Party Agreement" />
        <FormHelperText error={!!downloadError}>{downloadError}</FormHelperText>
        <QuestionSection>
          <Question
            text={`Do you have a Tri Party Agreement available at this time? Please upload here.`}
          />
          <Box>
            <Box
              className={`${classes.previewContainer}
                ${
                  triPartyImage.type === "application/pdf"
                    ? classes.iframeContainer
                    : classes.imageContainer
                }
              `}
            >
              {triPartyImage.type === "application/pdf" ? (
                <iframe
                  title={`${documentNameCopy} preview`}
                  src={`${triPartyImage.src}`}
                  className={classes.iframe}
                  allowFullScreen={true}
                ></iframe>
              ) : (
                <img
                  className={classes.preview}
                  src={triPartyImage.src}
                  alt={triPartyImage.name}
                />
              )}
              {!isLoading ? (
                <Box className={classes.iconContainer}>
                  <FileInput
                    name="triPartyAgreement"
                    type="triPartyAgreement"
                    changeHandler={handleFileSelection.bind(
                      null,
                      "triPartyAgreement"
                    )}
                  />
                </Box>
              ) : (
                <Box className={classes.loadingContainer}>
                  <Loading />
                </Box>
              )}
            </Box>
          </Box>
          <FormHelperText>
            PDFs only please. {MAX_UPLOAD_SIZE_TEXT}
          </FormHelperText>
          <FormHelperText error={!!triPartyError}>
            {triPartyError}
          </FormHelperText>
        </QuestionSection>

        <ProgressButtonGroup
          left={{
            clickHandler: handlePreviousStep,
            pageCalling: "Back Click: Tri Party Agreement Upload Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: Tri Party Agreement Upload Page",
            disabled: resolveDisabled(),
          }}
          hasRequiredInputs
        />
      </Container>
    </Box>
  );
};

export default TriPartyAgreementUpload;
