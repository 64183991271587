import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import AuthProvider from "./components/Auth";
import ApplicationDataProvider from "./components/ApplicationData";
import ThemeProvider from "./components/Theme";
import config from "./config/config";

import CssBaseline from "@material-ui/core/CssBaseline";

import { saveDeepLinks } from "util/handleDeepLinks";

Sentry.init({
  ...config.sentry,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

saveDeepLinks();
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <CssBaseline />
      <AuthProvider>
        <ApplicationDataProvider>
          <App />
        </ApplicationDataProvider>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
