import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Button, AppBar, makeStyles, Box } from "@material-ui/core";
import { ThemeContext } from "components/Theme";
import imagePaths from "util/resolveImagePath";

const useStyles = makeStyles((theme) => ({
  head: {
    top: "auto",
    height: "var(--headerHeight)",
    backgroundColor: theme.palette.header.main,
  },
  graphicContainer: {
    paddingLeft: 16,
    height: "100%",
  },
  logo: {
    height: "60px",
    "& svg": {
      fill: "#fff",
    },
  },
  headerButton: {
    marginRight: 10,
    color: theme.palette.headerText?.main || "#FFFFFF",
    fontSize: 14,
  },
}));

const Header = ({ clickHandler, step }) => {
  const { themeName = "" } = useContext(ThemeContext) || {};
  const classes = useStyles();
  return (
    <AppBar
      position="static"
      data-component="header"
      className={classes.head}
      elevation={0}
    >
      <Box
        className={classes.graphicContainer}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        {!!themeName && (
          <img
            className={classes.logo}
            alt="logo"
            src={imagePaths(themeName).logoHeader}
          />
        )}
        {clickHandler && (
          <Button
            onClick={clickHandler}
            className={classes.headerButton}
            disableElevation
          >
            {step === 1 ? "Log out" : "Home"}
          </Button>
        )}
      </Box>
    </AppBar>
  );
};

Header.defaultProps = {
  clickHandler: null,
};

Header.propTypes = {
  clickHandler: PropTypes.func,
};

export default Header;
