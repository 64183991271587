import React, { PureComponent, createContext } from "react";
import firebase, { emailLinkSettings } from "config/firebase";
import { withCookies } from "react-cookie";
import { isFunction, cloneDeep } from "lodash";
import * as Sentry from "@sentry/react";

import {
  LOCAL_STORAGE_REFERRER,
  LOCAL_STORAGE_MAGIC_LINK_NAME,
  SESSION_STORAGE_MAGIC_LINK_NAME,
} from "../../constants";
import { authErrors, AUTH_ERROR_USER_NOT_FOUND } from "util/errors";
import { AUTH_INITIAL_STATE } from "../../util/variableStructure";
import { Mixpanel } from "util/mixpanel-instance";
// import rollbar from "config/rollbar";

export const AuthContext = createContext();

const BASIC_ERROR_MESSAGE = "Sorry, went something wrong. Please try again.";

class AuthProvider extends PureComponent {
  state = AUTH_INITIAL_STATE;

  async componentDidMount() {
    if (process.env.NODE_ENV === "development") {
      // this.signOut();
    }
    this.setAuthListener();
    this.checkAuthLinkAndSignin();
  }

  componentWillUnmount() {
    isFunction(this.stopAuthListener) && this.stopAuthListener();
  }

  setAuthListener = () => {
    const auth = firebase.auth();
    console.log("setting auth listener in auth context");
    this.stopAuthListener = auth.onAuthStateChanged(
      async (authUser) => {
        if (authUser) {
          // console.log("we have a user logged in", authUser);
          this.addUserToErrorReporting(authUser);
          // if user exists get user object from firestore
          const linkSentFromCurrentTab = sessionStorage.getItem(
            SESSION_STORAGE_MAGIC_LINK_NAME
          );
          if (linkSentFromCurrentTab) {
            return this.setState({
              isOldTab: true,
              isLoadingAuthListener: false,
            });
          }
          return this.setState({
            isLoadingAuthListener: false,
            isLoggedIn: true,
            uid: authUser.uid,
            email: authUser.email,
          });
        } else {
          // is not authenticated
          console.log(
            "user is no longer authenticated, need to cancel subscriptions"
          );
          this.setState({ isLoadingAuthListener: false });
        }
      },
      (error) => {
        // rollbar.error(error, "setting auth listener");
        console.error(error, "setting auth listener");
        Sentry.captureException(error);
      }
    );
  };

  endSession = () => {
    this.signOut();
  };

  checkAuthLinkAndSignin = () => {
    // rollbar.log("checking auth link and sign in");
    Sentry.captureMessage("checking auth link and sign in");
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // console.log("inside func check auth");
      // rollbar.log("inside func check auth link");
      Sentry.captureMessage("inside func check auth link");

      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem(LOCAL_STORAGE_MAGIC_LINK_NAME);
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:

        // rollbar.log("no email detected from local storage, prompting");
        Sentry.captureMessage(
          "no email detected from local storage, prompting"
        );

        email = window.prompt("Please provide your email for confirmation");
      }
      if (!email) {
        // rollbar.log(
        //   "no email found and none provided from prompt, return function, so not logging in"
        // );
        Sentry.captureMessage(
          "no email found and none provided from prompt, return function, so not logging in"
        );

        return;
      }
      // The client SDK will parse the code from the link for you.
      // rollbar.log(
      //   "got the email from prompt or local storage, so we are signInWithEmailLink now"
      // );
      Sentry.captureMessage(
        "got the email from prompt or local storage, so we are signInWithEmailLink now"
      );

      email = typeof email === "string" ? email.trim() : email;
      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then((result) => {
          Sentry.captureMessage("new email link sign-in", {
            extra: { result },
          });
          // Clear email from storage.
          // console.log("res", result);
          window.localStorage.removeItem(LOCAL_STORAGE_MAGIC_LINK_NAME);
        })
        .catch((error) => {
          Sentry.captureException(error);

          // just log the errors for info to be safe - they most likey aren't bugs but normal auth checking
          if (error.code === "auth/invalid-action-code") {
            // probably don't need to handle this, this isn't necessarily an error
          } else if (error.code === "auth/expired-action-code") {
            // Thrown if OTP in email link expires.
            // rollbar.info("otp expired", error);
          } else if (error.code === "auth/invalid-email") {
            // Thrown if the email address is not valid.
            // rollbar.info("invalid format email address", error);
          } else if (error.code === "auth/user-disabled") {
            // Thrown if the user corresponding to the given email has been disabled.
            // rollbar.info("the user is disabled", error);
          } else if (error.code) {
            // an unknown error code
            // rollbar.info(error, "sign in with email link");
          }
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          this.setState({ errorMagicLinkAuth: error.message });
        });
    }
  };

  resolveEmailLinkSettings = () => {
    const referrer = localStorage.getItem(LOCAL_STORAGE_REFERRER);
    const settings = cloneDeep(emailLinkSettings);
    if (referrer) {
      console.log("appending deep link");
      // append to login deep link
      settings.url = settings.url + `?referrer=${referrer}`;
    }
    return settings;
  };

  sendAuthLink = (email, callback) => {
    if (!email) return;
    this.setState({ isLoadingSendEmailLink: true, hasEmailLinkErrored: false });
    sessionStorage.setItem(SESSION_STORAGE_MAGIC_LINK_NAME, "true");
    const settings = this.resolveEmailLinkSettings();
    Mixpanel.register_once({
      "User Email": email,
    });
    Mixpanel.track("User Login");

    Sentry.captureMessage("sendSignInLinkToEmail begin", {
      level: Sentry.Severity.Info,
      extra: { email, settings },
    });

    const sendSignInEmail = firebase
      .functions()
      .httpsCallable("sendSignInEmail");

    sendSignInEmail({ url: settings.url, email })
      .then((result) => {
        this.setState({
          hasEmailLinkSent: true,
          isLoadingSendEmailLink: false,
        });

        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem(LOCAL_STORAGE_MAGIC_LINK_NAME, email);

        Sentry.captureMessage("sendSignInLinkToEmail complete", {
          level: Sentry.Severity.Info,
          extra: { email, settings },
        });

        callback(null);
      })
      .catch((error) => {
        Sentry.captureMessage("sendSignInLinkToEmail FAILED", {
          level: Sentry.Severity.Error,
          extra: { email, settings },
        });
        Sentry.captureException(error);

        this.setState({
          hasEmailLinkErrored: true,
          isLoadingSendEmailLink: false,
        });
        callback(error);
      });
  };

  signupUserNamePassword = (email, password, cb) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        cb(null);
        Mixpanel.identify(userCredential.user.uid);
      })
      .catch((error) => {
        this.logError(error, "signupUserNamePassword");
        const message = authErrors[error.code];
        cb(message || BASIC_ERROR_MESSAGE);
      });
  };

  loginUserNamePassword = (email, password, cb) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        cb(null);
        Mixpanel.identify(userCredential.user.uid);
      })
      .catch((error) => {
        this.logError(error, "loginUserNamePassword");
        const message = authErrors[error.code];
        cb(message || BASIC_ERROR_MESSAGE);
      });
  };

  logError = (error, funcName) => {
    if (error.code === AUTH_ERROR_USER_NOT_FOUND) return;
    console.error(error);

    // rollbar.error(error, funcName);
    Sentry.captureException(error, { extra: { funcName } });
  };

  signOut = () => {
    Mixpanel.track("User Logout");
    // console.log("signing out");
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.setState({ ...AUTH_INITIAL_STATE, isLoadingAuth: false });
      })
      .catch((error) => {
        console.error(error);
        // rollbar.error(error, "signout");
        Sentry.captureException(error);
      });
  };

  handlePasswordReset = (emailAddress, cb) => {
    firebase
      .auth()
      .sendPasswordResetEmail(emailAddress)
      .then(function () {
        // Email sent.
        cb(null);
      })
      .catch((error) => {
        // An error happened.
        cb(error);
      });
  };

  addUserToErrorReporting = (user) => {
    try {
      Sentry.setUser(user);

      // rollbar.configure({
      //   payload: {
      //     person: {
      //       id: user.uid,
      //       email: user.email,
      //     },
      //   },
      // });
    } catch (error) {
      //rollbar.error(error, "failed to add user object to error reporting");
      console.error(error, "failed to add user object to error reporting");
      Sentry.captureException(error);
    }
  };

  render() {
    const { children } = this.props;
    return (
      <AuthContext.Provider
        value={{
          ...this.state,
          sendAuthLink: this.sendAuthLink,
          signupUserNamePassword: this.signupUserNamePassword,
          loginUserNamePassword: this.loginUserNamePassword,
          signOut: this.signOut,
          handlePasswordReset: this.handlePasswordReset,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
}

export default withCookies(AuthProvider);
