import React from "react";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  disclaimer: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
  },
}));

const DisclaimerText = ({ children }) => {
  const classes = useStyles();
  return (
    <Box p={10} mt={20} className={classes.disclaimer}>
      {children}
    </Box>
  );
};

export default DisclaimerText;
