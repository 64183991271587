import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Box,
  TextField,
} from "@material-ui/core";

import { QuestionSection, Question } from "../Question";
import ScreenTitle from "../ScreenTitle";
import ProgressButtonGroup from "../ProgressButtonGroup";
import BorderedText from "components/BorderedText";
import { ThemeContext } from "components/Theme";
import Help from "components/Help";

const IRAType = ({
  previousStep,
  nextStep,
  currentStep,
  iraType,
  handleFormChange,
  checkingAccountNicknameRoth,
  checkingAccountNicknameTraditional,
  setFieldValue,
  handleSave,
}) => {
  const [textValue, setTextValue] = useState("");
  const { helpInfo } = useContext(ThemeContext)?.copy?.form?.iraType || {};

  useEffect(() => {
    if (textValue.length === 0) {
      setTextValue(
        iraType === "Traditional" || iraType === "Inherited-Traditional"
          ? checkingAccountNicknameTraditional
          : checkingAccountNicknameRoth
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkingAccountNicknameTraditional, checkingAccountNicknameRoth]);

  function setAccountNickname(type, text) {
    if (type === "Traditional" || type === "Inherited-Traditional") {
      setFieldValue("checkingAccountNicknameTraditional", text);
      setFieldValue("checkingAccountNicknameRoth", "");
      setFieldValue("createTraditionalCheckingForTaxDeferredFunds", true);
      setFieldValue("createRothCheckingForPostTaxFunds", false);
    } else {
      setFieldValue("checkingAccountNicknameTraditional", "");
      setFieldValue("checkingAccountNicknameRoth", text);
      setFieldValue("createTraditionalCheckingForTaxDeferredFunds", false);
      setFieldValue("createRothCheckingForPostTaxFunds", true);
    }
  }

  const handleChange = (e) => {
    e.persist();
    handleFormChange(e);
    setAccountNickname(e.target.value, textValue);
  };

  const handleTextChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^a-zA-Z\d\s:]/g, "");
    setTextValue(value);
    setAccountNickname(iraType, value);
  };

  const handleNextStep = () => {
    if (handleSave) {
      handleSave();
    }
    return nextStep();
  };

  const resolveDisabled = () => {
    let disabled = false;
    return disabled;
  };

  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle step={currentStep} title="Investment Options" />
        <QuestionSection>
          <Question text="Which type of IRA would you like to open?" required />
          <Box py={12}>
            <BorderedText>
              Your new IRA needs to match the tax type of the prior plan you
              wish to rollover from.
            </BorderedText>
          </Box>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="iraType"
              name="iraType"
              value={iraType}
              onChange={handleChange}
            >
              <FormControlLabel
                value="Traditional"
                control={<Radio />}
                label="Traditional"
              />
              <FormControlLabel value="Roth" control={<Radio />} label="Roth" />
              <FormControlLabel
                value="Inherited-Traditional"
                control={<Radio />}
                label="Inherited Traditional"
              />
              <FormControlLabel
                value="Inherited-Roth"
                control={<Radio />}
                label="Inherited Roth"
              />
            </RadioGroup>
          </FormControl>
        </QuestionSection>
        <QuestionSection>
          <Question text="Account Nickname" />
          <TextField
            id="accountNickname"
            value={
              checkingAccountNicknameTraditional
                ? checkingAccountNicknameTraditional
                : checkingAccountNicknameRoth
            }
            fullWidth
            onChange={handleTextChange}
            placeholder="Optional"
          />
        </QuestionSection>
        {helpInfo && <Help copy={helpInfo.copy} link={helpInfo.link} />}
        <ProgressButtonGroup
          left={{
            clickHandler: previousStep,
            pageCalling: "Back Click: IRA Type Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: IRA Type Page",
            disabled: resolveDisabled(),
          }}
          hasRequiredInputs
        />
      </Container>
    </Box>
  );
};

export default IRAType;
