import React, { createContext, useEffect, useContext, useState } from "react";
import firebase from "config/firebase";
import { firebase as firebaseAdmin } from "@firebase/app";
import * as Sentry from "@sentry/react";

import { AuthContext } from "components/Auth";

import retrieveReferrers, { retreiveReferrersData } from "util/providerSource";
import {
  SESSION_STORAGE_MAGIC_LINK_NAME,
  ACCOUNT_TYPES_REQUIRE_BENEFICIARIES,
} from "../../constants";

import { APPLICATION_STATE, PARTICIPANT_STATE } from "util/variableStructure";
import { sanitizeFieldValues } from "util/sanitizeFieldValues";
import resolveThemeName from "config/resolveThemeName";

export const ApplicationDataContext = createContext();
const applicationsCollection = firebase.firestore().collection("applications");
const participantsCollection = firebase.firestore().collection("participants");

const ApplicationData = ({ children }) => {
  const authData = useContext(AuthContext) || {};
  const { isLoadingAuthListener, isLoggedIn, uid, email } = authData;

  const [referrerList, setReferrerList] = useState([]);
  const [allReferrerInitData, setAllReferrerData] = useState([]);
  const [initState, setInitState] = useState({
    isLoadingCreateNewUser: false,
    errorCreateNewUser: "",
    isDataInit: false,
  });

  const [inputData, setInputData] = useState(null);
  const [initialUser, setInitialUser] = useState(null);

  useEffect(() => {
    setReferrers();
    setReferrerData();
    // on mount
  }, []);

  useEffect(() => {
    async function init() {
      if (!isLoadingAuthListener) {
        console.log("auth listener is loaded in data context");
        if (isLoggedIn) {
          initData(uid);
        }
      } else {
        console.log("setting auth listener in data context");
      }
    }
    init();
  }, [isLoadingAuthListener, isLoggedIn, uid]); //eslint-disable-line react-hooks/exhaustive-deps

  const setReferrers = async () => {
    const referrers = await retrieveReferrers();
    setReferrerList(referrers);
  };

  const setReferrerData = async () => {
    const data = await retreiveReferrersData();
    setAllReferrerData(data);
  };

  const initData = async (uid) => {
    console.log("init the data stores");
    const linkSentFromCurrentTab = sessionStorage.getItem(
      SESSION_STORAGE_MAGIC_LINK_NAME
    );

    try {
      const participant = await participantsCollection.doc(uid).get();
      Sentry.captureMessage("Participant document lookup", {
        extra: { "participant.uid": uid, exists: participant.exists },
        level: Sentry.Severity.Info,
      });

      if (!participant.exists) {
        // participant does not exist and will need to be created
        // also create an application if they are the primary participant
        // get session storage - we don't want multiple tabs open that can create a new document

        console.log(
          "Participant does not exist and will be created",
          linkSentFromCurrentTab
        );

        Sentry.captureMessage(
          "Participant does not exist and will be created",
          {
            extra: {
              linkSentFromCurrentTab,
              "participant.uid": uid,
            },
            level: Sentry.Severity.Info,
          }
        );

        if (
          linkSentFromCurrentTab === undefined ||
          linkSentFromCurrentTab === null
        ) {
          const initUser = {
            uid,
            email,
          };

          // just populate initUser -
          return setInitialUser(initUser);
        }
      }

      const participantData = participant.data();
      if (!participantData.applicationId) {
        Sentry.captureMessage("No application associated with participant", {
          extra: { "participant.uid": uid },
          level: Sentry.Severity.Error,
        });
        throw Error("No application associated with participant");
      }

      // get the associated application
      const application = await applicationsCollection
        .doc(participantData.applicationId)
        .get();

      if (!application.exists) {
        const applId = participantData.applicationId;
        Sentry.captureMessage("Application with expected ID does not exist", {
          extra: { "participant.uid": uid, "application.id": applId },
          level: Sentry.Severity.Error,
        });

        throw Error("have participant but application does not exist");
      }
      // console.log("app", application.data(), "part", participantData);
      const applicationData = application.data();

      loadData(participantData, applicationData);
      setInitState((prev) => ({
        ...prev,
        isDataInit: true,
      }));
    } catch (error) {
      Sentry.captureException(error, {});
      // this.setState({
      //   errorFetchUser: error.message,
      //   isLoadingAuthListener: false,
      // });
      if (window.location.href.includes("additional-participant")) {
        // do nothing here, we've logged out in add part flow but this function still is running
      } else {
        // an issue that needs logging
        console.error(error, "on auth state changed");
        // rollbar.error(error, "on auth state changed");
      }
    }
  };

  const createNewApplication = async (uid) => {
    const { id } = await applicationsCollection.add({
      createdAt: firebaseAdmin.firestore.Timestamp.now(),
      updatedAt: firebaseAdmin.firestore.Timestamp.now(),
      primaryParticipantId: uid,
      status: "DRAFT",
      providerSource: "solera", // this will get updated with account type
      createdByUserType: "customer",
      appHost: resolveThemeName(),
    });

    return {
      applicationRefId: id,
      primaryParticipantId: uid,
      status: "DRAFT",
      providerSource: "SOLERA",
    };
  };

  const createNewParticipant = async () => {
    const participant = {
      participantRefId: initialUser.uid,
      email: initialUser.email,
      createdAt: firebaseAdmin.firestore.Timestamp.now(),
      updatedAt: firebaseAdmin.firestore.Timestamp.now(),
      appHost: resolveThemeName(),
    };
    try {
      setInitState((prev) => ({
        ...prev,
        isLoadingCreateNewUser: true,
        errorCreateNewUser: "",
      }));
      let application = {};
      application = await createNewApplication(initialUser.uid);
      participant.isPrimary = true;
      participant.applicationId = application.applicationRefId;
      await participantsCollection.doc(initialUser.uid).set(participant);

      loadData(participant, application);
      setInitState((prev) => ({
        ...prev,
        isLoadingCreateNewUser: false,
        isDataInit: true,
      }));
      setInitialUser(null);
    } catch (error) {
      setInitState((prev) => ({
        ...prev,
        isLoadingCreateNewUser: false,
        errorCreateNewUser: error.message,
      }));
      console.log("error", error);
      Sentry.captureException(error);
    }
  };

  const loadData = async (participant, application) => {
    console.log("running load data", application, participant);
    setInputData((prev) => ({
      ...prev,
      ...participant,
      ...application,
    }));
  };

  const saveProgress = async (inputs) => {
    // takes all updates and splits out participant and application fields. Saves to respective objects in firestore.
    try {
      let applicationInputs = {};
      let participantInputs = {};
      sanitizeFieldValues(inputs);

      // Saving Application data
      Object.keys(APPLICATION_STATE).forEach((key, i) => {
        applicationInputs[key] =
          inputs[key] === undefined ? APPLICATION_STATE[key] : inputs[key];
      });

      if (!applicationInputs.primaryParticipantId) {
        // rollbar.info(
        //   "save trying to overwrite primaryParticipantId on application"
        // );
        Sentry.captureMessage(
          "save trying to overwrite primaryParticipantId on application",
          { extra: { inputs } }
        );
      }
      if (applicationInputs.primaryParticipantId) {
        await applicationsCollection.doc(inputData.applicationId).update({
          ...applicationInputs,
          applicationRefId: inputData.applicationId,
          updatedAt: firebaseAdmin.firestore.Timestamp.now(),
        });
      }

      // Saving Participant data
      Object.keys(PARTICIPANT_STATE).forEach((key, i) => {
        participantInputs[key] =
          inputs[key] === undefined ? PARTICIPANT_STATE[key] : inputs[key];
      });

      if (!participantInputs.applicationId) {
        Sentry.captureMessage(
          "save trying to overwrite applicationId on participant",
          { extra: { inputs } }
        );
      }

      if (participantInputs.applicationId) {
        await participantsCollection.doc(inputData.participantRefId).update({
          ...participantInputs,
          updatedAt: firebaseAdmin.firestore.Timestamp.now(),
        });
      }
    } catch (error) {
      console.error(error);
      // rollbar.error(error, "saveProgress");
      Sentry.captureException(error);
    }
  };

  const updateAccountType = async ({
    accountType,
    checkingAccountType,
    referralSource,
    referralSourceUserInput = "",
    applicationReferrer = "",
  }) => {
    // resolve providerSource - this provides authority for admins on the dashboard
    const referrerObj = referrerList.find(
      (item) => item.referralSource === referralSource
    );

    const providerSource = (referrerObj && referrerObj.authority) || "solera";

    // add logic for requirements based on selection
    let requiresBeneficiaries = false;
    if (ACCOUNT_TYPES_REQUIRE_BENEFICIARIES.includes(accountType)) {
      requiresBeneficiaries = true;
    }

    console.log("should create the docs now", initState.isDataInit);

    if (initState.isDataInit === false) {
      await createNewParticipant();
    }

    console.log("finished creating new records");
    setInputData((prev) => ({
      ...prev,
      accountType,
      checkingAccountType,
      referralSource,
      referralSourceUserInput,
      providerSource,
      applicationReferrer,
      applicationProvider: providerSource,
      shouldNameBeneficiary: requiresBeneficiaries,
    }));
  };

  return (
    <ApplicationDataContext.Provider
      value={{
        referrerList,
        allReferrerInitData,
        ...initState,
        ...inputData,
        saveProgress,
        updateAccountType,
        initialUser,
      }}
    >
      {children}
    </ApplicationDataContext.Provider>
  );
};

export default ApplicationData;
