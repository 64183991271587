import React from "react";
import PropTypes from "prop-types";

import { Box, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  loadingContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    top: "20%",
    zIndex: 9999,
  },
});
const IdLoading = ({ error, completed }) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.loadingContainer}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {error ? (
        <Typography variant="h4">Sorry something went wrong!</Typography>
      ) : completed ? (
        <Typography variant="h6">
          You have already completed identity verification.
        </Typography>
      ) : (
        <>
          <Typography variant="body1">Identity Verification system</Typography>
          <Box mt={20}>
            <Typography variant="body1">Loading...</Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

IdLoading.propTypes = {
  error: PropTypes.bool,
  completed: PropTypes.bool,
};

export default IdLoading;
