export function sanitizeFieldValues(values) {
  // careful - this mutates the values object
  Object.keys(values).forEach((key) => {
    if (typeof values[key] === "string") {
      if (values[key].length > 0) {
        // trim
        values[key] = values[key].trim();
        // remove special mask chars if present
        if (key.toLowerCase().includes("phone")) {
          const cleaned = values[key].replace(/\D/g, "");
          values[key] = cleaned;
        }
      }
    }
    if (typeof values[key] === "object") {
      if (key === "designatedBeneficiaries") {
        values[key].forEach((obj) => {
          obj.phoneNumber = obj.phoneNumber.replace(/\D/g, "");
        });
      }
    }
  });
}
