import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, Container, Typography, makeStyles } from "@material-ui/core";

import { AuthContext } from "components/Auth";
import { ThemeContext } from "components/Theme";

import Header from "components/Header";
import Layout from "components/Layout";
import { Loading } from "containers/Loading";

import EmailSent from "./components/EmailSent";
import EmailPassword from "./components/EmailPassword";
import Success from "./components/Success";

import { emailValidator, passwordValidator } from "util/validation";
import { breakpoints } from "config/defaultTheme/breakpoints";

import TermsDialog from "./components/TermsDialog";
import MagicLinkInput from "./components/MagicLinkInput";

const styles = {
  container: {
    [`@media(min-width:${breakpoints.sm}px)`]: {
      "& h2": {
        fontSize: 25,
      },
    },
  },
  contentContainer: {
    paddingBottom: 40,
    [`@media(max-width:${breakpoints.sm}px)`]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  successIcon: {
    color: "var(--light-moss-green)",
    fontSize: 150,
  },
  hideButton: {
    cursor: "pointer",
  },
  forgotPassword: {
    cursor: "pointer",
  },
  iconOuter: {
    background: "white",
    height: 205,
    width: 205,
    borderRadius: 100,
  },
  dialog: {
    background: "white",
  },
  graphicContainer: {
    background: "white",
    height: 205,
    width: 205,
    borderRadius: 100,
  },
  graphic: {
    marginBottom: 60,
    width: 264,
    height: 227,
    objectFit: "contain",
  },
  agreeTermsForm: {
    display: "flex",
    alignItems: "flex-start",
  },
  expansionPanel: {
    border: "none",
    boxShadow: "none",
    "&::before": {
      height: 0,
    },
  },
  expansionPanelSummary: {
    justifyContent: "center",
    "& .MuiExpansionPanelSummary-content": {
      flexGrow: 0,
    },
  },
  expansionPanelDetails: {
    textAlign: "left",
    width: 350,
    marginLeft: "50%",
    transform: "translateX(-40%)",
    "& ul": {
      paddingLeft: "20px",
    },
    "& li": {
      marginTop: 8,
    },
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
};

const Register = () => {
  const {
    isLoggedIn,
    sendAuthLink,
    isLoadingAuthListener,
    isLoadingSendEmailLink,
    hasEmailLinkErrored,
    errorMagicLinkAuth,
    isOldTab,
    // email & password method
    signupUserNamePassword,
    loginUserNamePassword,
  } = useContext(AuthContext);

  const { themeName = "solera" } = useContext(ThemeContext) || {};

  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [agreeTerms2, setAgreeTerms2] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [emailPasswordLoading, setEmailPasswordLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [localStorageEmailSent, setLocalStorageEmailSent] = useState(null);
  const [errorEmailPassword, setErrorEmailPassword] = useState(null);
  const [screenType, setScreenType] = useState("signup");

  useEffect(() => {
    const emailSentLocalStorage = window.localStorage.getItem("emailSent");
    emailSentLocalStorage && setLocalStorageEmailSent(emailSentLocalStorage);
    const isLogin = new URLSearchParams(history.location.search).get("login");

    if (isLogin) {
      setScreenType("login");
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isLoggedIn) {
      window.setTimeout(() => {
        setEmailPasswordLoading(false);
        history.push("/account-setup");
      }, 1500);
    }
  }, [isLoggedIn]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleEmailChange = (e) => {
    e.persist();
    setEmail(e.target.value);
    if (emailTouched) {
      validateEmail();
    }
  };

  const handlePasswordChange = (e) => {
    e.persist();
    setPassword(e.target.value);

    validatePassword(e.target.value);
  };

  useEffect(() => {
    if (isOldTab) {
      history.push("/");
    }
  }, [isOldTab]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleCheckboxChange = (e) => {
    e.persist();
    setAgreeTerms(e.target.checked);
  };

  const handleCheckboxChange2 = (e) => {
    e.persist();
    setAgreeTerms2(e.target.checked);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const validateEmail = async () => {
    if (!emailTouched) {
      setEmailTouched(true);
    }
    const errors = await emailValidator({ email });
    if (errors) {
      return setEmailError(
        (Array.isArray(errors) && errors[0]) || "Invalid input"
      );
    }
    return setEmailError(errors);
  };

  const validatePassword = async (value) => {
    if (!passwordTouched) {
      setPasswordTouched(true);
    }

    const errors = await passwordValidator({ password: value });
    if (errors) {
      return setPasswordError(
        (Array.isArray(errors) && errors[0]) || "Invalid input"
      );
    }
    return setPasswordError(errors);
  };

  const handleEmailSent = async () => {
    sendAuthLink(email, (error) => {
      if (error) return;
      window.localStorage.setItem("emailSent", email);
      setLocalStorageEmailSent(email);
      setEmailSent(true);
    });
  };

  const handleSignupEmailPassword = () => {
    if (!email || !password) return;
    setEmailPasswordLoading(true);
    setErrorEmailPassword(null);
    signupUserNamePassword(email.trim(), password.trim(), (errorMessage) => {
      // success is handled in effect
      if (errorMessage) {
        setErrorEmailPassword(errorMessage);
        setEmailPasswordLoading(false);
      }
    });
  };

  const handleLoginEmailPassword = () => {
    if (!email || !password) return;
    setEmailPasswordLoading(true);
    setErrorEmailPassword(null);
    loginUserNamePassword(email.trim(), password.trim(), (errorMessage) => {
      // success is handled in effect
      if (errorMessage) {
        setErrorEmailPassword(errorMessage);
        setEmailPasswordLoading(false);
      }
    });
  };

  const resetToSendAgain = () => {
    setEmailSent(false);
    setLocalStorageEmailSent(null);
  };

  const classes = makeStyles(styles)();

  const hasEmailSent = emailSent || !!localStorageEmailSent;
  const shouldDisplaySpinner = isLoadingAuthListener;
  return (
    <Box className={classes.container}>
      <Header />
      <Layout>
        <Container className={classes.contentContainer} maxWidth="sm">
          {themeName === "solera" ? (
            !!errorMagicLinkAuth ? (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <Typography color="error">{errorMagicLinkAuth}</Typography>
              </Box>
            ) : shouldDisplaySpinner ? (
              <Loading />
            ) : isLoggedIn ? (
              <Success classes={classes} screenType={screenType} />
            ) : hasEmailSent ? (
              <EmailSent
                email={email}
                localStorageEmailSent={localStorageEmailSent}
                classes={classes}
                handleEmailSend={resetToSendAgain}
              />
            ) : (
              <MagicLinkInput
                screenType={screenType}
                classes={classes}
                agreeTerms={agreeTerms}
                setModalOpen={setModalOpen}
                email={email}
                emailError={emailError}
                hasEmailLinkErrored={hasEmailLinkErrored}
                validateEmail={validateEmail}
                handleEmailChange={handleEmailChange}
                isLoadingSendEmailLink={isLoadingSendEmailLink}
                handleCheckboxChange={handleCheckboxChange}
                handleEmailSent={handleEmailSent}
              />
            )
          ) : (
            <>
              {shouldDisplaySpinner ? null : (
                <EmailPassword
                  handleEmailChange={handleEmailChange}
                  handlePasswordChange={handlePasswordChange}
                  email={email}
                  password={password}
                  emailError={emailError}
                  passwordError={passwordError}
                  classes={classes}
                  screenType={screenType}
                  agreeTerms={agreeTerms}
                  agreeTerms2={agreeTerms2}
                  setModalOpen={setModalOpen}
                  handleCheckboxChange={handleCheckboxChange}
                  handleCheckboxChange2={handleCheckboxChange2}
                  validateEmail={validateEmail}
                  signup={handleSignupEmailPassword}
                  login={handleLoginEmailPassword}
                  loading={emailPasswordLoading}
                  errorMessage={errorEmailPassword}
                />
              )}
            </>
          )}
        </Container>
      </Layout>
      <TermsDialog
        classes={classes}
        modalOpen={modalOpen}
        handleClose={handleClose}
      />
    </Box>
  );
};

export default Register;
