import React, { useContext } from "react";
import { ThemeContext } from "components/Theme";
import {
  Container,
  Box,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";
import imagePaths from "util/resolveImagePath";

import { WelcomeTitle, WelcomeInfo } from "../HomeTitles";
import { QuestionSection, Question } from "../Question";
import { Mixpanel } from "util/mixpanel-instance";

const HomeScreen2 = ({
  firstName,
  lastName,
  email,
  submittedAt,
  applicationRefId,
  applicationId,
}) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      justifyContent: "flex-start",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      paddingBottom: 30,
      paddingTop: 30,
    },
    graphicContainer: {
      background: "white",
      borderRadius: 100,
      maxWidth: 200,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.up("sm")]: {
        width: 325,
        maxWidth: 325,
      },
    },
    graphic: {
      objectFit: "contain",
      width: "100%",
    },
  }));

  Mixpanel.track("End of Application Page");

  const {
    body1 = "Your application is currently being reviewed.\nYou can expect to be contacted within 1-3 business days.",
    body2 = "For questions or concerns regarding your application, please include your name on file and application Ref ID in the subject line",
    heading1 = "Thank you for completing your application!",
  } = useContext(ThemeContext)?.copy?.form?.completed || {};

  const { email: themeEmail = "", companyName = "" } =
    useContext(ThemeContext)?.copy?.contactInformation || {};

  const { themeName = "" } = useContext(ThemeContext) || {};

  const classes = useStyles();
  const welcomeText = heading1;
  const welcomeInfo = body1;
  const contactInfoText = body2;
  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <Box className={classes.container}>
          <Box className={classes.graphicContainer}>
            <img
              className={classes.graphic}
              src={imagePaths(themeName).completed}
              srcSet={imagePaths(themeName).completed_2x}
              alt={`${companyName} Completed`}
            />
          </Box>
        </Box>
        <WelcomeTitle text={welcomeText} />
        <WelcomeInfo text={welcomeInfo} />
        <QuestionSection>
          <Question text="Name on file" />
          <TextField
            id="nameOnFile"
            name="nameOnFile"
            value={`${firstName ? firstName : ""} ${lastName ? lastName : ""}`}
            fullWidth
            readOnly
          />
        </QuestionSection>
        <QuestionSection>
          <Question text="Email on file" />
          <TextField
            id="emailOnFile"
            name="emailOnFile"
            value={email}
            fullWidth
            readOnly
          />
        </QuestionSection>
        <QuestionSection>
          <Question text="Date Submitted" />
          <TextField
            id="submittedAt"
            name="submittedAt"
            value={
              submittedAt
                ? moment(submittedAt.toDate()).format("MM/DD/YYYY")
                : moment(new Date()).format("MM/DD/YYYY")
            }
            fullWidth
            readOnly
          />
        </QuestionSection>
        <QuestionSection>
          <Question text="Application Reference ID" />
          <TextField
            id="applicationRefId"
            name="applicationRefId"
            value={applicationRefId || applicationId || ""}
            fullWidth
            readOnly
          />
        </QuestionSection>
        <WelcomeInfo text={contactInfoText} />
        <Box
          pt={20}
          pb={40}
          mb={80}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {themeEmail && (
            <Typography variant="body1">
              Contact us at <a href={`mailTo:${themeEmail}`}>{themeEmail}</a>
            </Typography>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default HomeScreen2;
