import React, { useContext, useState, useEffect } from "react";
import { ThemeContext } from "components/Theme";
import { Box, Container, TextField, Input, Checkbox } from "@material-ui/core";

import ScreenTitle from "../ScreenTitle";
import { QuestionSection, Question } from "../Question";
import ProgressButtonGroup from "../ProgressButtonGroup";
import TextMaskPhoneNumber from "../TextMaskPhoneNumber";
import { ContentInfo } from "../HomeTitles";
import { Mixpanel } from "util/mixpanel-instance";

const Name = ({
  previousStep,
  nextStep,
  currentStep,
  firstName,
  middleName,
  lastName,
  phoneNumber,
  agreesToTextNotifications,
  errors,
  touched,
  setFieldTouched,
  setFieldValue,
  handleSave,
  referrerInitData,
}) => {
  const { body1 = "I agree to occasional text messages regarding my account" } =
    useContext(ThemeContext)?.copy?.form?.contact || {};
  const [phone, setPhone] = useState("");

  useEffect(() => {
    function initPhoneNumber() {
      if (phoneNumber && !phone) {
        setPhone(phoneNumber);
      }
    }
    initPhoneNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);

  const handleBlur = (e) => {
    setFieldTouched(e.target.name, true, false);
  };

  const handleChangeCheckbox = (e) => {
    setFieldValue(e.target.name, e.target.checked);
  };

  const handlePhoneNumber = (e) => {
    e.persist();
    const value = e.target.value;
    setPhone(value);
    if (value.replace(/\D/g, "").length === 10) {
      setFieldValue("phoneNumber", value);
    }
  };

  const handleNameChange = (e) => {
    e.persist();
    const value = e.target.value;
    const filtered = value.replace(/[^a-zA-Z  -]/g, "");
    setFieldValue(e.target.name, filtered);
  };

  const resolveDisabled = () => {
    return (
      !firstName ||
      !lastName ||
      !phone ||
      phone.replace(/\D/g, "").length !== 10 ||
      Boolean(errors.firstName) ||
      Boolean(errors.lastName) ||
      Boolean(errors.phoneNumber)
    );
  };

  const handleNextStep = () => {
    Mixpanel.register_once({
      Name:
        firstName.trim() +
        " " +
        (middleName ? middleName.trim() : "") +
        " " +
        lastName.trim(),
    });
    setFieldValue(
      "legalName",
      firstName.trim() +
        " " +
        (middleName ? middleName.trim() : "") +
        " " +
        lastName.trim()
    );
    if (handleSave) {
      handleSave();
    }
    nextStep();
  };

  console.log("errors", errors);
  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle title="Contact Information" />
        <QuestionSection>
          <Question text="Your first name" required />
          <TextField
            id="firstName"
            name="firstName"
            value={firstName}
            onChange={handleNameChange}
            onBlur={handleBlur}
            fullWidth
            required
            helperText={touched.firstName ? errors.firstName : ""}
            error={touched.firstName && Boolean(errors.firstName)}
            placeholder="Enter legal first name"
          />
        </QuestionSection>
        <QuestionSection>
          <Question text="Your middle name" />
          <TextField
            id="middleName"
            name="middleName"
            value={middleName}
            fullWidth
            onChange={handleNameChange}
            onBlur={handleBlur}
            required
            helperText={touched.middleName ? errors.middleName : ""}
            error={touched.middleName && Boolean(errors.middleName)}
            placeholder="Or middle initial"
          />
        </QuestionSection>
        <QuestionSection>
          <Question text="Your last name" required />
          <TextField
            id="lastName"
            name="lastName"
            value={lastName}
            fullWidth
            onChange={handleNameChange}
            onBlur={handleBlur}
            required
            helperText={touched.lastName ? errors.lastName : ""}
            error={touched.lastName && Boolean(errors.lastName)}
            placeholder="Enter legal last name"
          />
        </QuestionSection>
        <QuestionSection>
          <Question text="Your mobile number" required />
          <Input
            id="phoneNumber"
            name="phoneNumber"
            value={phone}
            onBlur={handleBlur}
            required
            fullWidth
            onChange={handlePhoneNumber}
            error={touched.phoneNumber && Boolean(errors.phoneNumber)}
            placeholder="(xxx)xxx-xxxx"
            inputComponent={TextMaskPhoneNumber}
          />
        </QuestionSection>
        <Box display="flex" pt={20}>
          <Box>
            <Checkbox
              checked={agreesToTextNotifications}
              onChange={handleChangeCheckbox}
              color="primary"
              name="agreesToTextNotifications"
            />
          </Box>
          <Box pt={10}>
            <ContentInfo text={body1} />
          </Box>
        </Box>
        <ProgressButtonGroup
          left={{
            clickHandler: previousStep,
            pageCalling: "Back Click: Contact Info Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: Contact Info Page",
            disabled: resolveDisabled(),
          }}
          hasRequiredInputs
        />
      </Container>
    </Box>
  );
};

export default Name;
