import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "components/Theme";
import {
  Container,
  Box,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";

import config from "../../../../config/config";
import ScreenTitle from "../ScreenTitle";
import ProgressButtonGroup from "../ProgressButtonGroup";
import IdIntro1 from "assets/id-intro1.png";
import IdIntro12x from "assets/id-intro1@2x.png";
import IdIntro13x from "assets/id-intro1@3x.png";
import {
  ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL,
  ACCOUNT_TYPE_CUSTODIAL_IRA,
  CHECKING_ACCOUNT_TYPE_LLC,
} from "../../../../constants";
// import { config } from 'dotenv';

const useStyles = makeStyles({
  container: {
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    paddingTop: 30,
    paddingBottom: 5,
  },
  graphicContainer: {
    background: "white",
    height: 200,
    width: 200,
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  graphic: {
    objectFit: "contain",
    margin: -15,
    width: 200,
    height: 200,
  },
  header: {
    fontSize: "16px",
  },
});

const JumioIntro1 = ({
  currentStep,
  previousStep,
  nextStep,
  goToStep,
  llcWhollyOwnedByIra,
  accountType,
  checkingAccountType,
  isAdditionalParticipant,
  jumioTransaction,
  setFieldValue,
  handleSave,
  doesRequireSpousalConsent,
}) => {
  const {
    body1 = `That's why we've partnered with Stripe, an online identity
  verification company that uses advanced technology to protect
  against fraud and identity theft.`,
    heading1 = "We take your financial security seriously",
  } = useContext(ThemeContext)?.copy?.form?.identity || {};
  const classes = useStyles();

  const [canBypassJumio, setCanBypassJumio] = useState(false);

  useEffect(() => {
    function autoNavigate() {
      // don't repeat this if the customer has a jumioTransaction object
      if (jumioTransaction) {
        goToStep(currentStep + 3);
      }
    }
    autoNavigate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (config.isDev) {
      setCanBypassJumio(true);
    }
  }, []);

  const bypassJumio = () => {
    if (!canBypassJumio) return;
    const jumioTransaction = { transaction: "completed in QA/DEV" };
    setFieldValue("jumioTransaction", jumioTransaction);
    handleSave();
    goToStep(currentStep + 3);
  };

  const handleNextStep = () => {
    return nextStep();
  };
  const handlePreviousStep = () => {
    if (isAdditionalParticipant) {
      return previousStep();
    }
    if (
      accountType !== ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL &&
      checkingAccountType === CHECKING_ACCOUNT_TYPE_LLC &&
      llcWhollyOwnedByIra
    ) {
      return goToStep(currentStep - 1);
    }
    // back needs to skip spousal consent
    if (
      [
        ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL,
        ACCOUNT_TYPE_CUSTODIAL_IRA,
      ].includes(accountType) &&
      doesRequireSpousalConsent === false
    ) {
      return goToStep(currentStep - 2);
    }
    return previousStep();
  };
  const resolveDisabled = () => {
    return false;
  };
  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle step={currentStep} title="Identity Verification" />
        <Box className={classes.container}>
          <Box className={classes.graphicContainer}>
            <img
              className={classes.graphic}
              src={IdIntro1}
              srcSet={(IdIntro12x, IdIntro13x)}
              alt="Id Verification Started"
            />
          </Box>
        </Box>
        <Box mt={15}>
          <Typography variant="h6" className={classes.header}>
            <b>{heading1}</b>
          </Typography>
        </Box>
        <Box mt={20}>
          <Typography variant="body1">{body1}</Typography>
        </Box>
        <Box mt={10}>
          <Typography variant="body1">
            In the next few screens, Stripe will walk you through either using
            your computer's webcam or mobile device to capture two things:
          </Typography>
        </Box>
        <Box component="ol">
          <Typography variant="body1">
            <li>An image of your Id or passport</li>
            <li>A live selfie</li>
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1">
            These steps will ensure that the only person who can open an account
            in your name is you.
          </Typography>
        </Box>
        {canBypassJumio && (
          <Box my={30}>
            <Button onClick={bypassJumio}>Skip Identity Verification</Button>
          </Box>
        )}
        <ProgressButtonGroup
          left={{
            clickHandler: handlePreviousStep,
            pageCalling: "Back Click: Stripe Intro 1 Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: Stripe Intro 1 Page",
            disabled: resolveDisabled(),
          }}
        />
      </Container>
    </Box>
  );
};

export default JumioIntro1;
