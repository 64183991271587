import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Container,
  TextField,
  MenuItem,
  Select,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { isEmpty } from "lodash";

import AddressAutoComplete from "components/AddressAutocomplete";
import ScreenTitle from "../ScreenTitle";
import { QuestionSection, Question } from "../Question";
import ProgressButtonGroup from "../ProgressButtonGroup";
import QuestionSSN from "../QuestionSSN";
import PostCode from "../PostCode";

import { getSSN, saveSSN } from "util/manageSSN";
import {
  mapStatesToOptions,
  useIsMountedRef,
  createAbortController,
} from "util/helpers";
import {
  ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL,
  ACCOUNT_TYPE_BUSINESS_ACCOUNT,
} from "../../../../constants";

const LLCInfo = ({
  previousStep,
  nextStep,
  currentStep,
  goToStep,
  accountType,
  firstName,
  lastName,
  iraType,
  llcStateOfRegistration,
  llcName,
  llcAddressObj,
  llcAddressLine1,
  llcUnitOrSuite,
  llcCity,
  llcPostcode,
  llcState,
  llcTaxIdRef,
  llcAddressEquals,
  llcWhollyOwnedByIra,
  resAddressLine1,
  resAptNumber,
  resCity,
  resPostcode,
  resState,
  mailingAddressLine1,
  mailingAptNumber,
  mailingCity,
  mailingPostcode,
  mailingState,
  handleFormChange,
  errors,
  setFieldTouched,
  setFieldValue,
  addPartAuthToken,
  handleSave,
}) => {
  const isMounted = useIsMountedRef();
  const [llcTaxIdValue, setLlcTaxIdValue] = useState("");
  const [shouldShowLlcTaxId, setShowLlcTaxId] = useState(true);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const { abortController, signal } = createAbortController();
    async function fetchSSN() {
      if (llcTaxIdRef && !llcTaxIdValue) {
        isLoading && setLoading(true);
        const str = await getSSN(
          llcTaxIdRef,
          "llcTaxIdRef",
          signal,
          addPartAuthToken
        );
        str && setLlcTaxIdValue(str);
      }
      isMounted.current && setLoading(false);
    }
    fetchSSN();

    return function cleanup() {
      abortController && abortController.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [llcTaxIdRef]);

  const handleAddressSelection = useCallback(
    (addressComponents) => {
      setFieldValue("llcAddressLine1", addressComponents.address);
      setFieldValue("llcCity", addressComponents.city);
      setFieldValue("llcPostcode", addressComponents.zipCode);
      setFieldValue("llcState", addressComponents.state);
      setFieldValue("llcAddressObj", addressComponents.value);
    },
    [setFieldValue]
  );

  const handleChange = (e) => {
    e.persist();
    handleFormChange(e);
  };

  const handleNameChange = (e) => {
    e.persist();
    const value = e.target.value;
    const filtered = value.replace(/[^a-zA-Z0-9  -]/g, "");
    setFieldValue("llcName", filtered);
  };

  const handleAptChange = (e) => {
    e.persist();
    const value = e.target.value;
    const filtered = value.replace(/[^a-zA-Z0-9  -]/g, "");
    setFieldValue(e.target.name, filtered);
  };

  const handleBlur = (e) => {
    setFieldTouched(e.target.name, true, false);
  };

  const handleLlcTaxIdChange = (e) => {
    e.persist();
    // Check for length of the value (9 numbers in SSN and 2 '-' masks), and also check for 'X' added as a part of hiding.
    if (e.target.value.length <= 11) {
      // This regex replaces every non digit value so that only number values are accepted and '-' is removed before saving it to field.
      const inputTaxId = e.target.value.replace(/\D/g, "").trim();
      setLlcTaxIdValue(inputTaxId);
      if (inputTaxId.length === 9) {
        setLoading(true);
        saveSSN({ ssn: inputTaxId }, addPartAuthToken)
          .then((str) => setFieldValue("llcTaxIdRef", str))
          .catch((error) => console.error(error))
          .finally(() => setLoading(false));
      }

      if (inputTaxId.length < 9) {
        setFieldValue("llcTaxIdRef", "");
      }
    }
  };

  const handleChangeRadio = (e) => {
    if (e.target.name === "llcWhollyOwnedByIra") {
      setFieldValue(e.target.name, e.target.value === "true" ? true : false);
      setFieldValue("iraPercentOwnershipInLlc", 100);
    } else {
      setFieldValue(e.target.name, e.target.value);
      setFieldValue("iraPercentOwnershipInLlc", "");
    }
  };

  const handlePreviousStep = () => {
    if (
      accountType === ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL &&
      !iraType.toLowerCase().includes("inherited")
    ) {
      return goToStep(currentStep - 2);
    }
    return previousStep();
  };

  const handleNextStep = () => {
    //eslint-disable-next-line default-case
    switch (llcAddressEquals) {
      case "residential":
        setFieldValue("llcAddressLine1", resAddressLine1);
        setFieldValue("llcUnitOrSuite", resAptNumber);
        setFieldValue("llcCity", resCity);
        setFieldValue("llcPostcode", resPostcode);
        setFieldValue("llcState", resState);
        break;
      case "mailing":
        setFieldValue("llcAddressLine1", mailingAddressLine1);
        setFieldValue("llcUnitOrSuite", mailingAptNumber);
        setFieldValue("llcCity", mailingCity);
        setFieldValue("llcPostcode", mailingPostcode);
        setFieldValue("llcState", mailingState);
        break;
    }
    if (handleSave) {
      handleSave();
    }
    if (llcWhollyOwnedByIra) {
      goToStep(currentStep + 2);
    } else {
      nextStep();
    }
  };

  const resolveDisabled = () => {
    let disabled = false;
    if (
      llcWhollyOwnedByIra === null &&
      accountType !== ACCOUNT_TYPE_BUSINESS_ACCOUNT
    )
      disabled = true;
    if (
      !llcName ||
      Boolean(errors.llcName) ||
      !llcStateOfRegistration ||
      Boolean(errors.llcStateOfRegistration) ||
      !llcTaxIdRef ||
      (llcAddressEquals === "other" &&
        (isEmpty(llcAddressLine1) ||
          isEmpty(llcCity) ||
          isEmpty(llcState) ||
          isEmpty(llcPostcode)))
    ) {
      disabled = true;
    }

    return disabled;
  };

  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle step={currentStep} title="LLC Information" />
        <QuestionSection>
          <Question text="Name of your LLC" required />
          <TextField
            id="llcName"
            name="llcName"
            value={llcName}
            fullWidth
            onChange={handleNameChange}
            onBlur={handleBlur}
            placeholder="Enter name of LLC"
            required
          />
        </QuestionSection>
        <QuestionSSN
          value={
            llcTaxIdValue === "" && !!llcTaxIdRef
              ? "000000000"
              : shouldShowLlcTaxId
              ? llcTaxIdValue
              : llcTaxIdValue.replace(/-/g, "").replace(/.(?=.{4,}$)/g, "X")
          }
          onChange={handleLlcTaxIdChange}
          name={
            llcTaxIdValue === "" && !!llcTaxIdRef
              ? "hidden"
              : shouldShowLlcTaxId
              ? "llcTaxId"
              : "concealed"
          }
          id="llcTaxId"
          shouldShowSSN={shouldShowLlcTaxId}
          questionText="LLC tax ID/EIN"
          buttonOnClick={() =>
            setShowLlcTaxId((prevButtonState) => !prevButtonState)
          }
          required
          isLoading={isLoading}
          readOnly={isLoading || !shouldShowLlcTaxId}
          showEditIcon={false}
        />

        <QuestionSection>
          <Question text="State LLC was registered" required />
          <Select
            fullWidth
            labelId="llcStateOfRegistration"
            name="llcStateOfRegistration"
            id="llcStateOfRegistration"
            value={llcStateOfRegistration || ""}
            onChange={handleChange}
            displayEmpty
          >
            <MenuItem value="" disabled dense>
              Select a state
            </MenuItem>
            {mapStatesToOptions().map((opt) => (
              <MenuItem key={opt.value} value={opt.value}>
                {opt.name}
              </MenuItem>
            ))}
          </Select>
        </QuestionSection>
        <QuestionSection>
          <Question text="LLC address" required />
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="llcAddressEquals"
              name="llcAddressEquals"
              value={llcAddressEquals}
              onChange={handleChangeRadio}
              row
            >
              <FormControlLabel
                value="residential"
                control={<Radio />}
                label="Same as residential"
              />
              <FormControlLabel
                value="mailing"
                control={<Radio />}
                label="Same as mailing"
              />
              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
        </QuestionSection>
        {llcAddressEquals === "other" && (
          <>
            <QuestionSection>
              <Question text="LLC address" />
              <AddressAutoComplete
                onSelectAddress={handleAddressSelection}
                initialValue={llcAddressObj}
              />
            </QuestionSection>
            <Box pt={30}>
              <Typography variant="body2">
                OR manually enter your address below:
              </Typography>
            </Box>
            <QuestionSection>
              <Question text="Address Line 1" required />
              <TextField
                id="llcAddressLine1"
                name="llcAddressLine1"
                value={llcAddressLine1}
                fullWidth
                onChange={handleChange}
                placeholder="Enter address"
              />
            </QuestionSection>
            <Box display="flex" justifyContent="start" alignItems="center">
              <Box flexGrow={1}>
                <QuestionSection>
                  <Question text="Apartment/Suite" />
                  <TextField
                    id="llcUnitOrSuite"
                    name="llcUnitOrSuite"
                    value={llcUnitOrSuite}
                    fullWidth
                    onChange={handleAptChange}
                    placeholder="Enter Apt/Suite number"
                  />
                </QuestionSection>
              </Box>
              <Box flexGrow={1} pl={30}>
                <QuestionSection>
                  <Question text="City" required />
                  <TextField
                    id="llcCity"
                    name="llcCity"
                    value={llcCity}
                    fullWidth
                    onChange={handleChange}
                    placeholder="Enter city"
                  />
                </QuestionSection>
              </Box>
            </Box>
            <Box display="flex" justifyContent="start" alignItems="center">
              <Box flexGrow={1}>
                <QuestionSection>
                  <Question text="State" required />
                  <Select
                    fullWidth
                    labelId="llcState"
                    name="llcState"
                    id="llcState"
                    value={llcState || ""}
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="" disabled dense>
                      Select a state
                    </MenuItem>
                    {mapStatesToOptions().map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.name}
                      </MenuItem>
                    ))}
                  </Select>
                </QuestionSection>
              </Box>
              <Box flexGrow={1} pl={30}>
                <PostCode
                  name="llcPostcode"
                  value={llcPostcode}
                  onChange={setFieldValue}
                  required
                />
              </Box>
            </Box>
          </>
        )}
        {accountType !== ACCOUNT_TYPE_BUSINESS_ACCOUNT && (
          <QuestionSection>
            <Question text="Is this LLC wholly-owned by your IRA?" required />
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="llcWhollyOwnedByIra"
                name="llcWhollyOwnedByIra"
                value={llcWhollyOwnedByIra}
                onChange={handleChangeRadio}
                row
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </QuestionSection>
        )}
        <ProgressButtonGroup
          left={{
            clickHandler: handlePreviousStep,
            pageCalling: "Back Click: LLC Info Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: LLC Info Page",
            disabled: resolveDisabled(),
          }}
          hasRequiredInputs
        />
      </Container>
    </Box>
  );
};

export default LLCInfo;
