import config from "./config";
import themes from "./themes.json";
import { LOCAL_STORAGE_THEME_NAME } from "../constants";

///////// DEV /////////
const DEV_THEME = "solera";
// const DEV_THEME = "gratis";
///////////////////////

export default function resolveThemeName() {
  const local = localStorage.getItem(LOCAL_STORAGE_THEME_NAME);
  if (local) {
    // doesn't expire
    return local;
  }
  if (config.isDev) {
    localStorage.setItem(LOCAL_STORAGE_THEME_NAME, DEV_THEME);
    return DEV_THEME;
  }
  const key = config.isDev ? "hostname_staging" : "hostname";
  // process.env.REACT_APP_ENV === "staging" ? "hostname_staging" : "hostname";
  const hostname = document.location.hostname;
  console.log("host", hostname);

  const obj = themes.find((theme) => theme[key] === hostname);
  if (!obj) return null;

  const name = obj.name;
  console.log("theme", name);
  localStorage.setItem(LOCAL_STORAGE_THEME_NAME, name);
  return name;
}
