export const AUTH_ERROR_USER_NOT_FOUND = "auth/user-not-found";
export const AUTH_ERROR_EXISTING_ACCOUNT = "auth/email-already-in-use";

export const authErrors = {
  // code: our message to users
  [AUTH_ERROR_USER_NOT_FOUND]:
    "Sorry, we couldn't find a user with those details. Please check your inputs and try again.",
  [AUTH_ERROR_EXISTING_ACCOUNT]:
    "Sorry, it looks like there is already an account using this email.",
};
