import React, { Component, createRef } from "react";
import proptypes from "prop-types";
import { Typography, Box, withStyles, IconButton } from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";

const useStyles = () => ({
  input: {
    display: "none",
  },
});

class FileInput extends Component {
  constructor(props) {
    super(props);
    this.fileInput = createRef();
  }
  render() {
    const {
      name,
      error,
      required,
      changeHandler,
      classes,
      type = "file",
    } = this.props;
    const acceptedFile =
      type === "greenCard"
        ? ".jpg, .png"
        : type === "planAdoption"
        ? ".pdf"
        : type === "articlesOfOrganization" || type === "operatingAgreement"
        ? ".pdf"
        : type === "triPartyAgreement"
        ? ".pdf"
        : ".jpg, .png, .pdf, .gif";
    return (
      <>
        <Box>
          <input
            className={classes.input}
            ref={this.fileInput}
            name={name}
            type="file"
            id={name}
            required={required}
            aria-required={required}
            aria-invalid={!!error}
            aria-describedby={`${name}Error`}
            onChange={changeHandler}
            accept={acceptedFile}
          />
          <IconButton
            aria-label="left"
            color="primary"
            onClick={() => {
              this.fileInput.current.click();
            }}
            disableRipple
          >
            <PublishIcon />
          </IconButton>
        </Box>
        {!!error && (
          <Box>
            <Typography variant="body2" id={`${name}Error`} color="error">
              {error}
            </Typography>
          </Box>
        )}
      </>
    );
  }
}

FileInput.defaultProps = {
  required: false,
};

FileInput.propTypes = {
  name: proptypes.string.isRequired,
  error: proptypes.string,
  required: proptypes.bool,
  changeHandler: proptypes.func.isRequired,
  type: proptypes.oneOf([
    "file",
    "greenCard",
    "planAdoption",
    "articlesOfOrganization",
    "operatingAgreement",
    "triPartyAgreement",
  ]),
};

export default withStyles(useStyles)(FileInput);
