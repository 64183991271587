import React from "react";
import proptypes from "prop-types";

import MaskedInput from "react-text-mask";
import masks from "util/masks";

// Custom Input for SSN which can be passed to Material UI TextField/Input
function TextMaskSSN(props) {
  const { inputRef, ...other } = props;
  const mask =
    other.name === "hidden"
      ? masks.hidden
      : other.name === "concealed"
      ? masks.concealed
      : other.name.toLowerCase().includes("taxid")
      ? masks.taxId
      : masks.ssn;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      guide={false}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskSSN.propTypes = {
  inputRef: proptypes.func.isRequired,
};

export default TextMaskSSN;
