import * as Yup from "yup";
import { isString, isNumber } from "lodash";
import moment from "moment";

import { investments } from "./helpers";
import {
  ACCOUNT_TYPE_SOLO_401K,
  ACCOUNT_TYPE_CHECKBOOK_BANK_ONLY,
  ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL,
  ACCOUNT_TYPE_BUSINESS_ACCOUNT,
  ACCOUNT_TYPE_CUSTODIAL_IRA,
  CHECKING_ACCOUNT_TYPE_LLC,
  CHECKING_ACCOUNT_TYPE_TRUST,
} from "../constants";

const FILE_SIZE = 5 * 1000 * 1000; // 5MB
const SUPPORTED_FILE_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "file/pdf",
  "application/pdf",
];
const SUPPORTED_IMAGE_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
const INVALID_EMPLOYER_NAMES = [
  "selfemployed",
  "self-employed",
  "self employed",
];

function twoCharString(value) {
  if (!value) return true; // allow ""
  return isString(value) && value.length === 2; // 2 digit code
}

function validatePhoneNumber(value) {
  const transformedNumber = value && `+1${value.replace(/\D/g, "")}`;
  if (!transformedNumber) return null;
  return (
    transformedNumber.length === 12 &&
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/.test(
      transformedNumber
    )
  );
}

export const schema = {
  accountType: Yup.mixed().oneOf([
    null,
    ACCOUNT_TYPE_SOLO_401K,
    ACCOUNT_TYPE_CHECKBOOK_BANK_ONLY,
    ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL,
    ACCOUNT_TYPE_BUSINESS_ACCOUNT,
    ACCOUNT_TYPE_CUSTODIAL_IRA,
  ]),
  addAdditionalParticipant: Yup.bool(),
  additionalParticipantEmail: Yup.string().min(3).email("Invalid email"),
  additionalParticipantFirstName: Yup.string(),
  additionalParticipantLastName: Yup.string(),
  additionalParticipantMiddleName: Yup.string(),
  additionalParticipantPhone: Yup.mixed().test(
    "valid number",
    "Unsupported phone format",
    (value) => {
      if (!value) return true;
      return validatePhoneNumber(value);
    }
  ),
  // checkingAccountNicknameInheritedRoth
  // checkingAccountNicknameInheritedTraditional
  checkingAccountNicknameRoth: Yup.string(),
  checkingAccountNicknameTraditional: Yup.string(),
  checkingAccountTaxTypes: Yup.array().of(
    Yup.mixed().oneOf(["post-tax", "tax-defer"])
  ),
  checkingAccountType: Yup.mixed().when("accountType", {
    is: ACCOUNT_TYPE_CUSTODIAL_IRA,
    then: null,
    otherwise: Yup.mixed().oneOf([
      CHECKING_ACCOUNT_TYPE_LLC,
      CHECKING_ACCOUNT_TYPE_TRUST,
    ]),
  }),
  countriesForInternationalTrans: Yup.array().of(
    Yup.mixed().test("is valid", "not a valid code", (value) => {
      return twoCharString(value);
    })
  ),
  createRothCheckingForPostTaxFunds: Yup.bool(),
  createTraditionalCheckingForTaxDeferredFunds: Yup.bool(),
  designatedBeneficiaries: Yup.array(),
  // doesRequireSpousalConsent
  // inheritedIraHolderDateOfDeath
  // inheritedIraHolderDob
  inheritedIraHolderFirstName: Yup.string(),
  inheritedIraHolderLastName: Yup.string(),
  inheritedIraHolderMiddleName: Yup.string(),
  inheritedIraHolderSsnRef: Yup.string(),
  investmentSector1: Yup.mixed().oneOf(["", ...investments]),
  investmentSector2: Yup.mixed().oneOf(["", ...investments]),
  iraType: Yup.mixed().oneOf([
    "Traditional",
    "Roth",
    "Inherited-Traditional",
    "Inherited-Roth",
  ]),
  llcAddressEquals: Yup.mixed().oneOf(["residential", "mailing", "other"]),
  llcArticlesOfOrganizationRef: Yup.string(),
  llcOperatingAgreementRef: Yup.string(),
  llcTaxIdRef: Yup.string(),
  llcCity: Yup.string().ensure(),
  llcName: Yup.string().ensure(),
  llcPostcode: Yup.string().ensure(),
  llcState: Yup.mixed().test("is valid", "not a valid code", (value) => {
    return twoCharString(value);
  }),
  llcTaxId: Yup.string().ensure(),
  llcUnitOrSuite: Yup.string(),
  llcWhollyOwnedByIra: Yup.mixed().oneOf([null, true, false]),
  iraPercentOwnershipInLlc: Yup.mixed().test(
    "is valid",
    "not a percent",
    (value) => {
      if (!value) return true;
      return isNumber(value) && value >= 1;
    }
  ),
  llcOwnerNameWith25PercentShare1: Yup.string(),
  llcOwnerNameWith25PercentShare2: Yup.string(),
  llcOwnerNameWith25PercentShare3: Yup.string(),
  llcOwnerSharePercentage1: Yup.mixed().test(
    "is valid",
    "not a percent",
    (value) => {
      if (!value) return true;
      return isNumber(value) && value >= 1;
    }
  ),
  llcOwnerSharePercentage2: Yup.mixed().test(
    "is valid",
    "not a percent",
    (value) => {
      if (!value) return true;
      return isNumber(value) && value >= 1;
    }
  ),
  llcOwnerSharePercentage3: Yup.mixed().test(
    "is valid",
    "not a percent",
    (value) => {
      if (!value) return true;
      return isNumber(value) && value >= 1;
    }
  ),
  shouldNameBeneficiary: Yup.bool(),
  shouldNameSuccessor: Yup.bool(),
  spouseApt: Yup.string(),
  spouseCity: Yup.string(),
  spouseAddressLine1: Yup.string(),
  spouseLegalName: Yup.string(),
  spousePostcode: Yup.string(),
  spouseState: Yup.string(),
  spouseSsnRef: Yup.string(),
  successorLegalName: Yup.string(),
  trustAddressEquals: Yup.mixed().oneOf(["residential", "mailing", "other"]),
  trustAddressLine1: Yup.string(),
  trustApt: Yup.string(),
  trustCity: Yup.string(),
  trustCreationDate: Yup.string(),
  trustName: Yup.string(),
  trustPostcode: Yup.string(),
  trustState: Yup.mixed().test("is valid", "not a valid code", (value) => {
    return twoCharString(value);
  }),
  trustStateOfRegistration: Yup.string(),
  trustStorageRef: Yup.string(),
  trustTaxIdRef: Yup.string(),
  triPartyAgreementRef: Yup.string(),
  willInvestInternationally: Yup.bool(),
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  middleName: Yup.string(),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().min(3).email("Invalid email").required("Required"),
  agreesToTextNotifications: Yup.mixed().oneOf([null, true, false]),
  gender: Yup.mixed().oneOf([null, "male", "female"]),
  countryCitizenship: Yup.mixed().test(
    "is valid",
    "not a valid code",
    (value) => {
      return twoCharString(value);
    }
  ),
  usaCitizen: Yup.bool(),
  usaPermanentResident: Yup.bool(),
  maritalStatus: Yup.mixed().oneOf([
    "",
    null,
    "single",
    "married",
    "divorced",
    "widowed",
  ]),
  employmentStatus: Yup.mixed().oneOf([
    "",
    null,
    "retired",
    "unemployed",
    "employed",
    "self-employed",
  ]),
  employer: Yup.mixed().test(
    "employer name",
    "Please provide more information",
    (value) => {
      if (!value) return true;
      return !INVALID_EMPLOYER_NAMES.includes(value.toLowerCase());
    }
  ),
  formerEmployer: Yup.mixed().test(
    "employer name",
    "Please provide more information",
    (value) => {
      if (!value) return true;
      return !INVALID_EMPLOYER_NAMES.includes(value.toLowerCase());
    }
  ),
  currentOrRecentJobTitle: Yup.mixed().test(
    "employer name",
    "Please provide more information",
    (value) => {
      if (!value) return true;
      return !INVALID_EMPLOYER_NAMES.includes(value.toLowerCase());
    }
  ),
  industry: Yup.mixed().test("is valid", "not a valid code", (value) => {
    if (!value) return true;
    return isNumber(value);
  }),
  hasForeignAddress: Yup.mixed().oneOf([null, true, false]),
  hasUSAddress: Yup.bool(),
  foreignAddressApt: Yup.string().ensure(),
  foreignAddressCity: Yup.string().ensure(),
  foreignAddressCountry: Yup.string().ensure(),
  foreignAddressLine1: Yup.string().ensure(),
  foreignAddressPostcode: Yup.string().ensure(),
  foreignAddressState: Yup.string().ensure(),
  mailingAddressLine1: Yup.string().ensure(),
  mailingAptNumber: Yup.string().ensure(),
  mailingCity: Yup.string().ensure(),
  mailingPostcode: Yup.string().ensure(),
  mailingState: Yup.mixed().test("is valid", "not a valid code", (value) => {
    return twoCharString(value);
  }),
  hasResidedAtAddressForRequiredDuration: Yup.bool(),
  previousAddressLine1: Yup.string().ensure(),
  previousAptNumber: Yup.string().ensure(),
  previousCity: Yup.string().ensure(),
  previousPostcode: Yup.string().ensure(),
  previousState: Yup.mixed().test("is valid", "not a valid code", (value) => {
    return twoCharString(value);
  }),
  resAddressEqualsMailing: Yup.bool(),
  resAptNumber: Yup.string().ensure(),
  resCity: Yup.string().ensure(),
  resPostcode: Yup.string().ensure(),
  resState: Yup.mixed().test("is valid", "not a valid code", (value) => {
    return twoCharString(value);
  }),
  resAddressLine1: Yup.string().test(
    "po box",
    "Cannot be a PO Box",
    (value) => {
      if (!value) return true;
      const val = value.replace(/\./g, "").replace(/ /g, "").toLowerCase();
      return !val.includes("pobox");
    }
  ),
  maidenName: Yup.mixed().test("is valid", "not a valid value", (value) => {
    if (!value) return true;
    return isString(value);
  }),
  birthDate: Yup.mixed()
    // .required("Required")
    .test("is date valid", "please enter a valid date", (value) => {
      if (!value) return true;
      return moment(value, "MM/DD/YYYY").isValid() && value.length === 10;
    })
    .test("is date future", "date is in the future", (value) => {
      if (!value) return true;
      return moment().diff(moment(value, "MM/DD/YYYY"), "days") >= 0;
    })
    .test(
      "is too old",
      "please check the date you entered - MM/DD/YYYY.",
      (value) => {
        if (!value) return true;
        return moment().diff(moment(value, "MM/DD/YYYY"), "years") < 150;
      }
    ),
  inheritedIraHolderDob: Yup.mixed()
    // .required("Required")
    .test("is date valid", "please enter a valid date", (value) => {
      if (!value) return true;
      return moment(value, "MM/DD/YYYY").isValid() && value.length === 10;
    })
    .test("is date future", "date is in the future", (value) => {
      if (!value) return true;
      return moment().diff(moment(value, "MM/DD/YYYY"), "days") >= 0;
    })
    .test("is too old", "please check the date you entered.", (value) => {
      if (!value) return true;
      return moment().diff(moment(value, "MM/DD/YYYY"), "years") < 150;
    }),
  inheritedIraHolderDateOfDeath: Yup.mixed()
    .test("is date valid", "please enter a valid date", (value) => {
      if (!value) return true;
      return moment(value, "MM/DD/YYYY").isValid() && value.length === 10;
    })
    .test("is date future", "date is in the future", (value) => {
      if (!value) return true;
      return moment().diff(moment(value, "MM/DD/YYYY"), "days") >= 0;
    })
    .test(
      "is past cutoff",
      "sorry this isn't valid for us if over 120 years ago",
      (value) => {
        if (!value) return true;
        return moment().diff(moment(value, "MM/DD/YYYY"), "years") < 120;
      }
    ),
  phoneNumber: Yup.string()
    .required("Required")
    .test("valid number", "Unsupported phone format", (value) => {
      return validatePhoneNumber(value);
    }),
};

export const addPartSchema = {
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().min(3).email("Invalid email"),
  gender: Yup.mixed().oneOf(["", null, "female", "male"]),
  maritalStatus: Yup.mixed().oneOf([
    "",
    null,
    "single",
    "married",
    "divorced",
    "widowed",
  ]),
  birthDate: Yup.mixed()
    .test("is date valid", "please enter a valid date", (value) => {
      if (!value) return true;
      return moment(value, "MM/DD/YYYY").isValid() && value.length === 10;
    })
    .test("is date future", "date is in the future", (value) => {
      if (!value) return true;
      return moment().diff(moment(value, "MM/DD/YYYY"), "days") >= 0;
    })
    .test(
      "is too old",
      "please check the date you entered - MM/DD/YYYY.",
      (value) => {
        if (!value) return true;
        return moment().diff(moment(value, "MM/DD/YYYY"), "years") < 150;
      }
    ),

  phoneNumber: Yup.string()
    .required("Required")
    .test("valid number", "Unsupported phone format", (value) => {
      const transformedNumber = value && `+1${value.replace(/\D/g, "")}`;
      if (!transformedNumber) return null;
      return (
        transformedNumber.length === 12 &&
        /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/.test(
          transformedNumber
        )
      );
    }),
  employmentStatus: Yup.mixed().oneOf([
    "",
    null,
    "retired",
    "unemployed",
    "employed",
    "self-employed",
  ]),
  employer: Yup.string(),
  industry: Yup.string(),
};

const emailSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8)
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).*/,
      "Password must contain at least one numeric digit, one special character, and one uppercase and one lowercase letter"
    )
    .required("Required"),
});

const fileUploadSchema = Yup.object().shape({
  file: Yup.mixed()
    .test(
      "fileSize",
      "File too large",
      (value) => value && value.size <= FILE_SIZE
    )
    .test("fileFormat", "Unsupported Format", (value) => {
      return value && SUPPORTED_FILE_FORMATS.includes(value.type);
    }),
});

const imageUploadSchema = Yup.object().shape({
  file: Yup.mixed()
    .test(
      "fileSize",
      "File too large",
      (value) => value && value.size <= FILE_SIZE
    )
    .test("fileFormat", "Unsupported Format", (value) => {
      return value && SUPPORTED_IMAGE_FORMATS.includes(value.type);
    }),
});

// input is {key: value}
export async function inputValidator(schema, input) {
  return await schema
    .validate(input)
    .then(() => {
      return null;
    })
    .catch((err) => {
      return err.errors;
    });
}

export async function singleInputValidator(field, value) {
  const localSchema = Yup.object().shape({
    [field]: schema[field],
  });
  return localSchema
    .validate({ [field]: value })
    .then(() => {
      return null;
    })
    .catch((err) => {
      return err.errors;
    });
}

export async function emailValidator(input) {
  return await inputValidator(emailSchema, input);
}

export async function passwordValidator(input) {
  return await inputValidator(passwordSchema, input);
}

export async function fileUploadValidator(input) {
  return await inputValidator(fileUploadSchema, input);
}

export async function imageUploadValidator(input) {
  return await inputValidator(imageUploadSchema, input);
}

export default Yup.object(schema);

export const additionalParticipantSchema = Yup.object(addPartSchema);
