import React, { useContext } from "react";

import Header from "components/Header";
import { Box } from "@material-ui/core";

import IntroSlides from "components/IntroSlides";
import { ThemeContext } from "components/Theme";
import Loading from "components/Loading";

import { Mixpanel } from "../../util/mixpanel-instance";

const AppIntro = () => {
  Mixpanel.track("Landing Page");

  const { copy = {}, isLoading } = useContext(ThemeContext);
  return (
    <Box>
      <Header />
      {isLoading ? (
        <Loading color={"#000"} />
      ) : (
        <IntroSlides copy={copy.home} />
      )}
    </Box>
  );
};

export default AppIntro;
