import imagePaths from "./resolveImagePath";
import values from "lodash/values";

const imageArr = [];

export function precacheImages(providerPath) {
  // merge static image array with dynamic theme images defined in resolveImagePaths
  const mergedImages = [...imageArr, ...values(imagePaths(providerPath))];
  mergedImages.forEach((src) => {
    const img = new Image();
    img.src = src;
  });
}
