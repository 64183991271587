import React, { useContext } from "react";
import {
  Box,
  Container,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@material-ui/core";

import { ThemeContext } from "components/Theme";
import ScreenTitle from "../ScreenTitle";
import { QuestionSection, Question } from "../Question";
import ProgressButtonGroup from "../ProgressButtonGroup";
import { TRUST } from "../../../../constants";
import BorderedText from "components/BorderedText";

const TrustSuccessor = ({
  previousStep,
  nextStep,
  shouldNameSuccessor,
  successorLegalName,
  handleFormChange,
  errors,
  touched,
  setFieldTouched,
  setFieldValue,
  handleSave,
  completedSections,
}) => {
  const copy = useContext(ThemeContext).copy?.form?.successor;

  const handleChange = (e) => {
    e.persist();
    handleFormChange(e);
  };

  const handleBlur = (e) => {
    setFieldTouched(e.target.name, true, false);
  };

  const handleChangeRadio = (e) => {
    e.persist();
    setFieldValue(e.target.name, e.target.value === "true" ? true : false);
  };

  const resolveDisabled = () => {
    return shouldNameSuccessor
      ? !successorLegalName || Boolean(errors.successorLegalName)
      : false;
  };

  const handleNextStep = () => {
    if (!completedSections.includes(TRUST)) {
      setFieldValue("completedSections", [...completedSections, TRUST]);
    }

    if (handleSave) {
      handleSave();
    }
    nextStep();
  };

  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle title={copy?.heading1 || "Trust Information"} />
        <Box py={20}>
          <Typography>
            {copy?.body1 ||
              "You may choose to designate a person to serve as co-trustee and help administer your trust investments. This might be a spouse, parent, or adult child, for example."}
          </Typography>
        </Box>
        <Box py={20}>
          <BorderedText>
            {copy?.body2 ||
              "This role is not the same as an IRA beneficiary who would inherit your IRA. We'll get to that next."}
          </BorderedText>
        </Box>
        <QuestionSection>
          <Question
            text={
              copy?.question1 ||
              "Would you like to name a co-trustee to your trust?"
            }
            required
          />
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="shouldNameSuccessor"
              name="shouldNameSuccessor"
              value={shouldNameSuccessor}
              onChange={handleChangeRadio}
              row
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </QuestionSection>
        {shouldNameSuccessor && (
          <QuestionSection>
            <Question text={copy?.question2 || "Co-trustee name"} required />
            <TextField
              id="successorLegalName"
              name="successorLegalName"
              value={successorLegalName}
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter full legal name"
              helperText={
                touched.successorLegalName ? errors.successorLegalName : ""
              }
              error={
                touched.successorLegalName && Boolean(errors.successorLegalName)
              }
            />
          </QuestionSection>
        )}

        <ProgressButtonGroup
          left={{
            clickHandler: previousStep,
            pageCalling: "Back Click: Trust Successor Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: Trust Successor Page",
            disabled: resolveDisabled(),
          }}
          hasRequiredInputs
        />
      </Container>
    </Box>
  );
};

export default TrustSuccessor;
