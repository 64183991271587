const isDev = process.env.REACT_APP_ENV !== "production";
const isStage = process.env.REACT_APP_ENV === "staging";
// TODO: fix the appId to be constructed from the package.json file.
const version = "1.0.1";
const appId = `blaze-self-serve@${version}`; // `${process.env.npm_package_name}@${process.env.npm_package_version}`;

const config = {
  appId,
  isDev,
  isStage,
  version,
  encKey: isDev ? "1fAtYu1gFmKLPYTAdnxt" : "1fAtYu1gFmKLPYTAdnxt",

  firebase: isDev
    ? {
        apiKey: "AIzaSyA5EoX1ZquL3_-A7P3rYi359ypRwkTAG_4",
        authDomain: "solera-app-staging-2a393.firebaseapp.com",
        databaseURL: "https://solera-app-staging-2a393.firebaseio.com",
        projectId: "solera-app-staging-2a393",
        storageBucket: "solera-app-staging-2a393.appspot.com",
        messagingSenderId: "889583160389",
        appId: "1:889583160389:web:4cf8679dcccb33d378e72c",
        measurementId: "G-D070BDKWSH",
      }
    : {
        apiKey: "AIzaSyDISbHqN3JJekn3GNVQ15SizjQWl5HjR7A",
        authDomain: "solera-app.firebaseapp.com",
        databaseURL: "https://solera-app.firebaseio.com",
        projectId: "solera-app",
        storageBucket: "solera-app.appspot.com",
        messagingSenderId: "764419907639",
        appId: "1:764419907639:web:469e2867866c8c5c4c9fb2",
        measurementId: "G-8WVWWJ2F2R",
      },

  jumio: {
    url: isDev
      ? "https://us-central1-solera-app-staging-2a393.cloudfunctions.net/verifyIdentity"
      : "https://us-central1-solera-app.cloudfunctions.net/verifyIdentity",
  },

  sentry: {
    dsn: "https://c6dd2b494fef4742858835e0ce438d48@o1025860.ingest.sentry.io/6438001",
    environment: process.env.REACT_APP_ENV,
    release: appId,
  },
};

export default config;
