import React, { useState, useEffect } from "react";
import { Box, Paper, Container, makeStyles } from "@material-ui/core";
import moment from "moment";
import ScreenTitle from "../ScreenTitle";

import ProgressButtonGroup from "../ProgressButtonGroup";
import PandadocClient from "util/pandadocClient";
import DocumentSigningEmbed from "components/DocumentSigningEmbed";
import DocumentSigningLoading from "components/DocumentSigningLoading";

import { handleFileUpload, scroll } from "util/helpers";

const useStyles = makeStyles({
  container: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    paddingTop: "90%", // almost 1:1 aspect ratio for doc
    height: "50vh",
  },
});

const TrustDocEmbed = ({
  email = "",
  firstName = "",
  lastName = "",
  iraType = "",
  trustName = "",
  trustStateOfRegistration = "",
  legalName = "",
  trustExternalStoreId = null,
  successorLegalName = "",
  applicationRefId,
  applicationId,
  setFieldValue,
  nextStep,
  previousStep,
  currentStep,
  handleSave,
}) => {
  const [pandadocClient, setPandaDocClient] = useState(null);
  const [trustLink, setTrustLink] = useState(null);
  const [trustDocId, setTrustDocId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrored, setHasErrored] = useState(false);
  const [isDocSignedNow, setDocSignedNow] = useState(false);
  const [isPreparingForDownload, setIsPreparingForDownload] = useState(false);

  useEffect(() => {
    async function generateTrustEmbed() {
      const pandadocClient = new PandadocClient();
      setPandaDocClient(pandadocClient);
      const payload = constructPayload();
      setIsLoading(true);
      setHasErrored(false);
      const successCallback = (link, documentId) => {
        setTrustLink(link);
        setTrustDocId(documentId);
      };
      await pandadocClient.generateNewEmbedLink({
        recipientEmail: email || "johndoe@gmail.com",
        payload,
        successCallback,
        errorCallback,
      });
      setIsLoading(false);
    }

    function constructPayload() {
      const recipients = [
        {
          email: email || "johndoe@gmail.com",
          first_name: firstName || "John",
          last_name: lastName || "Doe",
          role: PD_TEMPLATE_ROLE,
        },
      ];
      const tokens = [
        {
          name: "Signer.TrustName",
          value: trustName || "Sample trust name",
        },
        {
          name: "Signer.SuccessorLegalName",
          value: successorLegalName || "n/a",
        },
        {
          name: "Signer.IraType",
          value: iraType,
        },
        {
          name: "Signer.LegalName",
          value: legalName || "John Doe",
        },
        {
          name: "Signer.TrustState",
          value: trustStateOfRegistration || "Delaware",
        },
        {
          name: "Signer.TrustCreationDate",
          value: moment().format("MMMM Do YYYY"),
        },
      ];

      return {
        fields: {},
        tokens,
        recipients,
        documentType: successorLegalName ? "IRATrust" : "IRATrust2",
        documentName: `${lastName || "Doe"}:${
          email || "johndoe@gmail.com"
        } - trust`,
      };
    }

    if (!trustExternalStoreId) {
      generateTrustEmbed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PD_TEMPLATE_ROLE = "Signer";

  function errorCallback() {
    setHasErrored(true);
  }

  async function trustCompletionHandler(val) {
    setIsLoading(true);
    setIsPreparingForDownload(true);
    try {
      const blob = await pandadocClient.downloadDoc(trustDocId);
      const ref = await handleFileUpload(
        blob,
        `/applications/${applicationRefId || applicationId}/trust`,
        "plan_adoption.pdf"
      );
      setFieldValue("trustStorageRef", ref);
      setFieldValue("trustExternalStoreId", trustDocId);
      if (handleSave) {
        handleSave();
      }
      scroll(document.body.scrollHeight, 0, "smooth");
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
    setIsPreparingForDownload(false);
    setDocSignedNow(true);
  }

  const classes = useStyles();

  function handleNextStep() {
    if (handleSave) {
      handleSave();
    }
    nextStep();
  }

  return (
    <Box flex={1}>
      <Container maxWidth="md">
        <ScreenTitle
          step={currentStep}
          title="Trust Signing"
          endAdornment="1/2"
        />
        <Box mt={30}>
          <Paper elevation={3} square={false} className={classes.container}>
            {hasErrored ? (
              <DocumentSigningLoading error={true} />
            ) : trustExternalStoreId && !isDocSignedNow ? (
              <DocumentSigningLoading completed={true} />
            ) : isLoading ? (
              <DocumentSigningLoading
                isCompleting={isDocSignedNow ? true : isPreparingForDownload}
              />
            ) : (
              <DocumentSigningEmbed
                url={trustLink}
                onDocumentCompleted={trustCompletionHandler}
                isPreparingForDownload={
                  isDocSignedNow ? true : isPreparingForDownload
                }
              />
            )}
          </Paper>
        </Box>
        <ProgressButtonGroup
          left={{
            clickHandler: previousStep,
            pageCalling: "Back Click: Trust Doc Embedded Page",
            disabled: true,
            isHidden: true,
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: Trust Doc Embedded Page",
            disabled: !trustExternalStoreId,
          }}
        />
      </Container>
    </Box>
  );
};

export default TrustDocEmbed;
