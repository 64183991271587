import React, { useState, useEffect, useMemo } from "react";
import { cloneDeep, isEmpty } from "lodash";
import {
  Box,
  Button,
  Container,
  Typography,
  Checkbox,
  makeStyles,
} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

import ScreenTitle from "../ScreenTitle";
import { ContentInfo } from "../HomeTitles";
import ProgressButtonGroup from "../ProgressButtonGroup";
import TrustBeneficiaryComponent from "../TrustBeneficiaryComponent";
import { Question } from "../Question";

import { cleanBeneficiaries } from "util/helpers";
import { singleInputValidator } from "util/validation";
import { BENEFICIARY, MARITAL_STATUS_MARRIED } from "../../../../constants";
import copy from "./copy.json";
import DisclaimerText from "components/DisclaimerText";

export const INIT_STATE_OBJ = {
  entityType: "person",
  type: "primary",
  legalName: "",
  taxIdRef: "",
  ssnRef: "",
  sharePercentage: "",
  gender: "male",
  dob: "",
  relationshipToAccountHolder: "spouse",
  preferredContactMethod: "email",
  email: "",
  phoneNumber: "",
  mailingAddressLine1: "",
  mailingAddressApt: "",
  mailingAddressCity: "",
  mailingAddressPostcode: "",
  mailingAddressState: "",
  mailingAddressCountry: "",
};

export const INIT_CONTINGENT_STATE_OBJ = {
  entityType: "person",
  type: "contingent",
  legalName: "",
  taxIdRef: "",
  ssnRef: "",
  sharePercentage: "",
  gender: "male",
  dob: "",
  relationshipToAccountHolder: "spouse",
  preferredContactMethod: "email",
  email: "",
  phoneNumber: "",
  mailingAddressLine1: "",
  mailingAddressApt: "",
  mailingAddressCity: "",
  mailingAddressPostcode: "",
  mailingAddressState: "",
  mailingAddressCountry: "",
};

const useStyles = makeStyles((theme) => ({
  hideButton: {
    position: "absolute",
    right: "5px",
    top: "5px",
  },
  hideButtonLabel: {
    fontSize: 12,
    fontWeight: "lighter",
  },
  beneficiaryBox: {
    backgroundColor: "#FFF",
    borderRadius: 5,
    boxShadow: "0px 0px 4px 2px rgba(0,0,0,0.1)",
  },
  textSuccess: {
    color: theme.palette.success.dark,
  },
  textError: {
    color: theme.palette.error.main,
  },
}));

export const resolveDisabled = ({
  beneficiaries,
  contingentBeneficiaries,
  totalContingentShare,
  emailError,
  contingentEmailError,
  totalPrimaryShare,
  inputErrors: localErrors,
  errors: allErrors,
}) => {
  let disabled = false;
  if (
    Array.isArray(contingentBeneficiaries) &&
    contingentBeneficiaries.length > 0
  ) {
    if (totalContingentShare !== 100) {
      disabled = true;
    }
    if (
      contingentBeneficiaries.some(
        (item, i) =>
          !item.legalName ||
          !item.sharePercentage ||
          (item.entityType === "person" ? !item.ssnRef : !item.taxIdRef) ||
          (item.entityType === "person" && !item.dob) ||
          (item.preferredContactMethod === "email" &&
            (!item.email || Boolean(contingentEmailError[i]))) ||
          (item.preferredContactMethod === "phone" &&
            (!item.phoneNumber ||
              item.phoneNumber.replace(/\D/g, "").length !== 10)) ||
          (item.preferredContactMethod === "mailingAddress" &&
            (!item.mailingAddressLine1 ||
              !item.mailingAddressCity ||
              !item.mailingAddressPostcode ||
              !item.mailingAddressState ||
              !item.mailingAddressCountry))
      )
    ) {
      disabled = true;
    }
  }
  if (totalPrimaryShare !== 100) {
    disabled = true;
  }
  if (
    beneficiaries.some((item, i) => {
      return (
        !item.legalName ||
        !item.sharePercentage ||
        (item.entityType === "person" ? !item.ssnRef : !item.taxIdRef) ||
        (item.entityType === "person" && !item.dob) ||
        (item.preferredContactMethod === "email" &&
          (!item.email || Boolean(emailError[i]))) ||
        (item.preferredContactMethod === "phone" &&
          (!item.phoneNumber ||
            item.phoneNumber.replace(/\D/g, "").length !== 10)) ||
        (item.preferredContactMethod === "mailingAddress" &&
          (!item.mailingAddressLine1 ||
            !item.mailingAddressCity ||
            !item.mailingAddressPostcode ||
            !item.mailingAddressState ||
            !item.mailingAddressCountry))
      );
    })
  ) {
    disabled = true;
  }
  if (!isEmpty(localErrors)) {
    disabled = true;
  }
  if (!isEmpty(allErrors)) {
    disabled = true;
  }
  return disabled;
};

function checkSpouseConsent(
  primaryBeneficiaries = [],
  isSpouseSelected,
  maritalStatus
) {
  let consentRequired = false;
  if (isSpouseSelected) {
    if (primaryBeneficiaries.length > 1) {
      if (maritalStatus === MARITAL_STATUS_MARRIED) {
        consentRequired = true;
      }
    }
  } else {
    if (maritalStatus === MARITAL_STATUS_MARRIED) {
      consentRequired = true;
    }
  }
  return consentRequired;
}

const TrustBeneficiaries = ({
  previousStep,
  nextStep,
  maritalStatus,
  designatedBeneficiaries,
  completedSections,
  setFieldValue,
  addPartAuthToken,
  handleSave,
  errors: allErrors,
}) => {
  const classes = useStyles();
  const [beneficiaries, setBeneficiary] = useState([INIT_STATE_OBJ]);
  const [contingentBeneficiaries, setContingentBeneficiary] = useState(null);
  const [isSpouseSelected, setIsSpouseSelected] = useState(true);
  const [checked, setChecked] = useState(false);
  const [emailError, setEmailError] = useState([]);
  const [contingentEmailError, setContingentEmailError] = useState([]);
  const [inputErrors, setInputErrors] = useState({});

  const primaryBeneficiaries = beneficiaries.filter((item) => {
    return item.type === "primary";
  });

  const totalPrimaryShare = primaryBeneficiaries.reduce((acc, item) => {
    const percent = parseInt(item.sharePercentage)
      ? parseInt(item.sharePercentage)
      : 0;
    return acc + percent;
  }, 0);

  const totalContingentShare =
    (contingentBeneficiaries &&
      contingentBeneficiaries.reduce((acc, item) => {
        const percent = parseInt(item.sharePercentage)
          ? parseInt(item.sharePercentage)
          : 0;
        return acc + percent;
      }, 0)) ||
    0;

  useEffect(() => {
    // run on mount to init local state. here local state is used for performance due to nested form state
    function initState() {
      const primary = designatedBeneficiaries.filter(
        (ben) => ben.type === "primary"
      );
      const contingent = designatedBeneficiaries.filter(
        (ben) => ben.type === "contingent"
      );
      if (!isEmpty(primary)) {
        setBeneficiary(primary);
        resolveSpouseSelected(primary);
      }
      if (!isEmpty(contingent)) {
        setContingentBeneficiary(contingent);
      }
    }
    if (!isEmpty(designatedBeneficiaries)) {
      initState();
    }
    return;
    // eslint-disable-next-line
  }, [designatedBeneficiaries]);

  const handleChange = async (index, e) => {
    try {
      e.persist();
    } catch (_) {}
    let value = e.target.value;

    let triggeredField;
    let triggeredValue;
    if (e.target.name === "sharePercentage") {
      value = parseInt(value);
      if (value > 100 || value === 0) {
        return;
      }
      if (!value) value = "";
    }

    if (e.target.name === "email") {
      const error = await singleInputValidator("email", value);
      const copyEmailError = emailError.slice();
      copyEmailError[index] = error ? error[0] : "";
      setEmailError(copyEmailError);
    }

    if (e.target.name === "mailingAddressApt") {
      value = value.replace(/[^a-zA-Z0-9  -]/g, "");
    }
    if (e.target.name === "preferredContactMethod") {
      if (value === "mailingAddress") {
        triggeredField = "mailingAddressCountry";
        triggeredValue = "US";
      }
    }

    const newArr = cloneDeep(beneficiaries);

    const newObj = newArr[index];
    newObj[e.target.name] = value;
    if (triggeredField && !newObj[triggeredField]) {
      newObj[triggeredField] = triggeredValue;
    }

    if (e.target.name === "entityType") {
      if (value === "nonPerson") {
        newObj["relationshipToAccountHolder"] = "";
        newObj["gender"] = null;
      }
    }

    const newBens = beneficiaries.map((item, i) => {
      return index === i ? newObj : item;
    });
    setBeneficiary(newBens);

    if (e.target.name === "relationshipToAccountHolder") {
      const primary = newBens.filter((ben) => ben.type === "primary");
      resolveSpouseSelected(primary);
    }

    if (e.target.name === "entityType") {
      if (value === "nonPerson") {
        const primary = newBens.filter((ben) => ben.type === "primary");
        resolveSpouseSelected(primary);
      }
    }
  };

  const handleSetErrors = (error) => {
    let errors = cloneDeep(inputErrors);
    const [pair] = Object.entries(error);
    const key = pair[0];
    const val = pair[1];

    if (val === null) {
      delete errors[key];
    } else {
      errors[key] = val;
    }
    setInputErrors(errors);
  };

  const handleContingentChange = async (index, e) => {
    e.persist();
    let value = e.target.value;

    let triggeredField;
    let triggeredValue;

    if (e.target.name === "sharePercentage") {
      value = parseInt(value);
      if (value > 100 || value === 0) {
        return;
      }
      if (!value) value = "";
    }

    if (e.target.name === "email") {
      const error = await singleInputValidator("email", value);
      const copyEmailError = contingentEmailError.slice();
      copyEmailError[index] = error ? error[0] : "";
      setContingentEmailError(copyEmailError);
    }

    if (e.target.name === "preferredContactMethod") {
      if (value === "mailingAddress") {
        triggeredField = "mailingAddressCountry";
        triggeredValue = "US";
      }
    }

    if (e.target.name === "mailingAddressApt") {
      value = value.replace(/[^a-zA-Z0-9  -]/g, "");
    }

    const newArr = cloneDeep(contingentBeneficiaries);

    const newObj = newArr[index];
    newObj[e.target.name] = value;
    if (triggeredField && !newObj[triggeredField]) {
      newObj[triggeredField] = triggeredValue;
    }
    setContingentBeneficiary(
      contingentBeneficiaries.map((item, i) => {
        return index === i ? newObj : item;
      })
    );
  };

  const handleRefChange = (index, value, type) => {
    const newArr = cloneDeep(beneficiaries);

    const newObj = newArr[index];
    newObj[type] = value;

    setBeneficiary(
      beneficiaries.map((item, i) => {
        return index === i ? newObj : item;
      })
    );
  };

  const handleContingentRefChange = (index, value, type) => {
    const newArr = cloneDeep(contingentBeneficiaries);

    const newObj = newArr[index];
    newObj[type] = value;
    setContingentBeneficiary(
      contingentBeneficiaries.map((item, i) => {
        return index === i ? newObj : item;
      })
    );
  };

  function resolveSpouseSelected(ben = []) {
    const isSelected = ben.some((item) => {
      return item.relationshipToAccountHolder === "spouse";
    });
    setIsSpouseSelected(isSelected);
    return wipeSpousalFields(isSelected);
  }

  function wipeSpousalFields(isSelected) {
    if (isSelected) {
      setFieldValue("spouseEmail", "");
      setFieldValue("spouseLegalName", "");
      setFieldValue("spouseAddressLine1", "");
      setFieldValue("spouseApt", "");
      setFieldValue("spouseCity", "");
      setFieldValue("spouseState", "");
      setFieldValue("spousePostcode", "");
      setFieldValue("spouseCountry", "");
      setFieldValue("spouseBirthDate");
    }
  }

  const handleNextStep = () => {
    // save beneficiaries here
    let allBeneficiaries = [...primaryBeneficiaries];
    if (contingentBeneficiaries)
      allBeneficiaries.push(...contingentBeneficiaries);

    setFieldValue(
      "designatedBeneficiaries",
      cleanBeneficiaries(allBeneficiaries)
    );
    // handle spousal consent field for portal use and next step
    const requiresSpouseConsent = checkSpouseConsent(
      primaryBeneficiaries,
      isSpouseSelected,
      maritalStatus
    );
    setFieldValue("doesRequireSpousalConsent", requiresSpouseConsent);

    if (!completedSections.includes(BENEFICIARY) && !requiresSpouseConsent) {
      setFieldValue("completedSections", [...completedSections, BENEFICIARY]);
    }
    if (handleSave) {
      handleSave();
    }
    return nextStep();
  };

  function handleAdditionalBeneficiary() {
    setBeneficiary((ben) => [...ben, INIT_STATE_OBJ]);
    resolveSpouseSelected([...beneficiaries, INIT_STATE_OBJ]);
  }

  function handleRemoveBeneficiary(index) {
    const filteredBens = beneficiaries.filter((item, i) => index !== i);
    setBeneficiary(filteredBens);
    setEmailError(emailError.filter((item, i) => index !== i));
    resolveSpouseSelected(filteredBens);
  }

  function handleAdditionalContingentBeneficiary() {
    if (contingentBeneficiaries === null) {
      return setContingentBeneficiary([INIT_CONTINGENT_STATE_OBJ]);
    }
    setContingentBeneficiary((ben) => [...ben, INIT_CONTINGENT_STATE_OBJ]);
  }

  function handleRemoveContingentBeneficiary(index) {
    setContingentBeneficiary(
      contingentBeneficiaries.filter((item, i) => index !== i)
    );
    setContingentEmailError(
      contingentEmailError.filter((item, i) => index !== i)
    );
  }

  const memoizedPrimaryBeneficiaries = useMemo(() => {
    return beneficiaries.map(
      (beneficiary, i) =>
        beneficiary.type === "primary" && (
          <Box
            key={i}
            mt={30}
            mb={30}
            p={15}
            className={classes.beneficiaryBox}
          >
            <TrustBeneficiaryComponent
              beneficiary={beneficiary}
              maritalStatus={maritalStatus}
              index={i}
              handleChange={handleChange.bind(null, i)}
              handleRefChange={handleRefChange.bind(null, i)}
              emailError={emailError[i]}
              handleErrors={handleSetErrors}
              addPartAuthToken={addPartAuthToken}
            />
            <Box
              pt={30}
              pb={30}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                variant="contained"
                disabled={!beneficiaries || beneficiaries.length < 2}
                color="primary"
                size="large"
                onClick={() => handleRemoveBeneficiary(i)}
              >
                <RemoveIcon />
                {`  REMOVE BENEFICIARY`}
              </Button>
            </Box>
          </Box>
        )
    );
    // eslint-disable-next-line
  }, [beneficiaries, emailError]);

  const memoizedContingentBeneficiaries = useMemo(() => {
    return (
      Array.isArray(contingentBeneficiaries) &&
      contingentBeneficiaries.map(
        (beneficiary, i) =>
          beneficiary.type === "contingent" && (
            <Box
              key={i}
              mt={30}
              mb={30}
              p={15}
              className={classes.beneficiaryBox}
            >
              <TrustBeneficiaryComponent
                beneficiary={beneficiary}
                maritalStatus={maritalStatus}
                index={i}
                handleChange={handleContingentChange.bind(null, i)}
                handleRefChange={handleContingentRefChange.bind(null, i)}
                emailError={contingentEmailError[i]}
                handleErrors={handleSetErrors}
                addPartAuthToken={addPartAuthToken}
              />
              <Box
                pt={30}
                pb={30}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => handleRemoveContingentBeneficiary(i)}
                >
                  <RemoveIcon />
                  {`  REMOVE CONTINGENT`}
                </Button>
              </Box>
            </Box>
          )
      )
    );
    // eslint-disable-next-line
  }, [contingentBeneficiaries, emailError]);

  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle title="Designated Beneficiaries" />
        <Box pt={20}>
          <Typography variant="body2">
            {`Please designate a beneficiary(s) to inherit your IRA.`}
          </Typography>
          <DisclaimerText>
            <ContentInfo text={copy.info} />
          </DisclaimerText>
        </Box>

        <div>
          {beneficiaries.length !== 0 ? (
            memoizedPrimaryBeneficiaries
          ) : (
            <Box mt={30} mb={30} p={15} className={classes.beneficiaryBox}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="h6">
                  You have removed all beneficiaries from your IRA
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Checkbox
                  checked={checked}
                  onChange={() => {
                    setChecked((prevState) => {
                      setFieldValue("shouldNameBeneficiary", prevState);
                      return !prevState;
                    });
                  }}
                  color="primary"
                />
                <Typography variant="body2">
                  I confirm I would like to remove all beneficiaries at this
                  time.
                </Typography>
              </Box>
            </Box>
          )}
        </div>

        {primaryBeneficiaries.length !== 0 && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body1">Total share % allocated</Typography>
              <Typography
                variant="body1"
                className={
                  totalPrimaryShare === 100
                    ? classes.textSuccess
                    : classes.textError
                }
              >
                {totalPrimaryShare}/100%
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">
                Must have 100% to continue
              </Typography>
            </Box>
          </>
        )}

        <Box
          pt={30}
          pb={20}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {totalPrimaryShare >= 100 ? (
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleAdditionalContingentBeneficiary}
            >
              <AddIcon />
              {`  ADD CONTINGENT`}
            </Button>
          ) : totalPrimaryShare < 100 ? (
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleAdditionalBeneficiary}
            >
              <AddIcon />
              {`  ADD ANOTHER PRIMARY`}
            </Button>
          ) : null}
        </Box>
        <Box>
          <Box mt={40}>
            {Array.isArray(contingentBeneficiaries) &&
              contingentBeneficiaries.length > 0 && (
                <Question text="Contingent Beneficiaries" />
              )}
          </Box>
          {Array.isArray(contingentBeneficiaries) &&
            memoizedContingentBeneficiaries}
        </Box>
        <Box>
          {contingentBeneficiaries && contingentBeneficiaries.length > 0 && (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1">
                  Total contingent share % allocated
                </Typography>
                <Typography
                  variant="body1"
                  className={
                    totalContingentShare === 100
                      ? classes.textSuccess
                      : classes.textError
                  }
                >
                  {totalContingentShare}/100%
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption">
                  Must have 100% to continue
                </Typography>
              </Box>
            </>
          )}
        </Box>

        <ProgressButtonGroup
          left={{
            clickHandler: previousStep,
            pageCalling: "Back Click: Trust Beneficiaries Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: Trust Beneficiaries Page",
            disabled: resolveDisabled({
              beneficiaries,
              contingentBeneficiaries,
              totalContingentShare,
              emailError,
              contingentEmailError,
              totalPrimaryShare,
              inputErrors,
              allErrors,
            }),
          }}
          hasRequiredInputs
        />
      </Container>
    </Box>
  );
};

export default TrustBeneficiaries;
