import React, { useContext } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";

import LogInstead from "../LogInstead";
import TermsCheckbox from "../TermsCheckbox";

import { ThemeContext } from "components/Theme";
import staticCopy from "../../copy.json";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  buttonWrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function MagicLinkInput({
  screenType,
  classes,
  agreeTerms,
  setModalOpen,
  email,
  emailError,
  hasEmailLinkErrored,
  validateEmail,
  handleEmailChange,
  isLoadingSendEmailLink,
  handleCheckboxChange,
  handleEmailSent,
}) {
  const { copy = {}, themeName = "solera" } = useContext(ThemeContext) || {};
  const {
    body3:
      bodyNoAuth = "Enter your email, and we'll send you a link to confirm it's you. Click the provided link in the email to sign into your application.",
    body4:
      bodyLoginNoAuth = "Enter your email, and we'll send you a link to continue your application. Click the provided link in the email to sign into your application.",
  } = copy.register || {};

  const lClasses = useStyles();

  return (
    <Box mt={64}>
      <Box>
        <Typography variant="h2">
          {screenType === "signup"
            ? staticCopy.headerNoAuth
            : staticCopy.headerLoginNoAuth}
        </Typography>
      </Box>

      <Box mt={30} mb={30}>
        <Typography variant="body1">
          {screenType === "signup" ? bodyNoAuth : bodyLoginNoAuth}
        </Typography>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            id="email"
            name="email"
            required
            type="email"
            value={email || ""}
            error={!!emailError}
            onBlur={validateEmail}
            helperText={!!emailError ? emailError : ""}
            onChange={handleEmailChange}
            fullWidth
            placeholder="Enter your email address"
          />
        </Grid>
      </Grid>
      <Box mt={42}>
        <TermsCheckbox
          classes={classes}
          handleCheckboxChange={handleCheckboxChange}
          agreeTerms={agreeTerms}
          setModalOpen={setModalOpen}
          themeName={themeName}
        />
      </Box>
      <Box mt={78} display="flex" alignItems="center" justifyContent="center">
        <div className={lClasses.buttonWrapper}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            disabled={
              !agreeTerms || !email || !!emailError || isLoadingSendEmailLink
            }
            onClick={handleEmailSent}
          >
            {"Send Sign-in Link"}
          </Button>
          {isLoadingSendEmailLink && (
            <CircularProgress size={20} className={lClasses.buttonProgress} />
          )}
        </div>
      </Box>
      <Box pt={55} textAlign="center">
        <LogInstead classes={classes} screenType={screenType} />
      </Box>
      {hasEmailLinkErrored && (
        <Box mt={36} textAlign="center">
          <Typography color="error">
            Sorry, something went wrong, please try again.
          </Typography>
        </Box>
      )}
    </Box>
  );
}
