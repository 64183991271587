import React from "react";

import HomeScreen1 from "./HomeScreen1";
import HomeScreen2 from "./HomeScreen2";

// This component acts like a router;
// when application is complete, we route to HomeScreen 2
// else we route to HomeScreen1
const Home = (props) => {
  const hasUserCompletedApp = !!props.submittedAt || !!props.ss4ExternalStoreId;
  return (
    <>
      {hasUserCompletedApp ? (
        <HomeScreen2 {...props} />
      ) : (
        <HomeScreen1 {...props} />
      )}
    </>
  );
};

export default Home;
