import React, { useState, useEffect } from "react";

import { Container, Box, FormHelperText, makeStyles } from "@material-ui/core";

import Layout from "components/Layout";
import FileInput from "components/FileInput";
import ScreenTitle from "../ScreenTitle";

import { QuestionSection, Question } from "../Question";
import ProgressButtonGroup from "../ProgressButtonGroup";
import { Loading } from "../../../Loading";
import {
  MAX_UPLOAD_SIZE,
  MAX_UPLOAD_SIZE_TEXT,
  MAX_UPLOAD_SIZE_MB,
} from "../../../../constants";

import {
  handleFileUpload,
  getFileExtension,
  addFile,
  getFile,
} from "util/helpers";
import { isEmpty } from "lodash";

const useStyles = makeStyles({
  previewContainer: {
    position: "relative",
    background: "white",
    borderRadius: 10,
    border: "1px solid",
    display: "flex",
    maxWidth: 300,
  },
  imageContainer: {
    overflow: "hidden",
    width: "100%",
    minHeight: 400,
  },
  iframeContainer: {
    width: "100%",
    minHeight: 400,
    position: "relative",
  },
  preview: {
    width: "100%",
    opacity: "100%",
    objectFit: "contain",
  },
  iframe: {
    position: "absolute",
    borderRadius: 10,
    border: "none",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
  },
  iconContainer: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    backgroundColor: "white",
    borderRadius: "50%",
    opacity: "70%",
    boxShadow: "4px 4px 4px black",
  },
  loadingContainer: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
  },
});

const CitizenshipUpload = ({
  previousStep,
  nextStep,
  goToStep,
  currentStep,
  greenCardStorageRef,
  participantRefId,
  setFieldValue,
  handleSave,
  isAdditionalParticipant,
  additionalParticipantId,
  jumioTransaction,
}) => {
  const classes = useStyles();
  const [greenCardImage, setGreenCardImage] = useState({});
  const [greenCardError, setGreenCardError] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function getFileFromStorage() {
      if (greenCardStorageRef && !isAdditionalParticipant) {
        try {
          const url = await getFile(greenCardStorageRef);
          setGreenCardImage((prevState) => ({
            ...prevState,
            src: url,
          }));
        } catch (error) {
          console.error(error);
        }
      }
      setLoading(false);
    }
    getFileFromStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [greenCardStorageRef]);

  const handleCtaDisable = () => {
    return isEmpty(greenCardImage) || !!greenCardError;
  };

  const handleFileSelection = async (field, uploadEvent) => {
    uploadEvent.persist();
    if (
      uploadEvent &&
      uploadEvent.currentTarget &&
      uploadEvent.currentTarget.files &&
      uploadEvent.currentTarget.files[0]
    ) {
      const file = uploadEvent.currentTarget.files[0];
      const size = file.size;
      if (size > MAX_UPLOAD_SIZE)
        return setGreenCardError(
          `The maximum allowed size is ${MAX_UPLOAD_SIZE_MB}Mb. This file is ${parseInt(
            size / (1024 * 1024)
          )}Mb.`
        );
      const payload = {
        uploadEvent,
        field,
        setFieldHandler: () => {},
        successCallback: setGreenCardImage,
        errorCallback: setGreenCardError,
        fileType: "greenCard",
        file,
      };
      setLoading(true);
      setGreenCardError(null);

      const metadata = {
        customMetadata: {
          check: additionalParticipantId,
        },
      };
      const ref = await handleFileUpload(
        file,
        `/participants/${
          isAdditionalParticipant ? additionalParticipantId : participantRefId
        }/identity`,
        `green_card.${getFileExtension(file)}`,
        isAdditionalParticipant ? metadata : null
      );
      if (!ref) {
        setLoading(false);
        setGreenCardError("Sorry there was a problem uploading your document");
        return;
      }
      try {
        await addFile(payload);
      } catch (e) {
        console.error("error adding file", e);
      }
      setFieldValue("greenCardStorageRef", ref);
      setLoading(false);
    }
  };

  const handleNextStep = () => {
    if (handleSave) handleSave();
    nextStep();
  };

  const handlePrevStep = () => {
    if (jumioTransaction) {
      return previousStep();
    }
    return goToStep(currentStep - 2);
  };

  return (
    <Layout>
      <Container maxWidth="sm">
        <ScreenTitle step={currentStep} title="Citizenship Upload" />
        <QuestionSection>
          <Question text="Upload a photo of your Green Card" required />
          <Box>
            <Box
              className={`${classes.previewContainer}
                    ${
                      greenCardImage.type === "application/pdf"
                        ? classes.iframeContainer
                        : classes.imageContainer
                    }
                  `}
            >
              {greenCardImage.type === "application/pdf" ? (
                <iframe
                  title="Green card preview"
                  src={`${greenCardImage.src}`}
                  className={classes.iframe}
                  allowFullScreen={true}
                ></iframe>
              ) : (
                <img
                  className={classes.preview}
                  src={greenCardImage.src}
                  alt={greenCardImage.name}
                />
              )}
              {!isLoading ? (
                <Box className={classes.iconContainer}>
                  <FileInput
                    name="greenCard"
                    type="greenCard"
                    changeHandler={handleFileSelection.bind(null, "greenCard")}
                  />
                </Box>
              ) : (
                <Box className={classes.loadingContainer}>
                  <Loading />
                </Box>
              )}
            </Box>
          </Box>
          <FormHelperText>{MAX_UPLOAD_SIZE_TEXT}</FormHelperText>
          <FormHelperText error={!!greenCardError}>
            {greenCardError}
          </FormHelperText>
        </QuestionSection>
        <ProgressButtonGroup
          left={{
            clickHandler: handlePrevStep,
            pageCalling: "Back Click: Citizenship Upload Page ",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: Citizenship Upload Page",
            disabled: handleCtaDisable(),
          }}
          hasRequiredInputs
        />
      </Container>
    </Layout>
  );
};

export default CitizenshipUpload;
