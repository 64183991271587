import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import Layout from "components/Layout";

export default function Loading({ color }) {
  return (
    <>
      <Layout>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flex={1}
        >
          <CircularProgress size={50} style={{ color: color }} />
        </Box>
      </Layout>
    </>
  );
}
