import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography, Link as MUILink } from "@material-ui/core";

export default function ForgotPassword({ classes }) {
  return (
    <Typography variant="body2">
      {"Forgot your password?"}{" "}
      <Box component="span">
        <MUILink
          className={classes.forgotPassword}
          to="/forgot-password"
          component={Link}
          style={{ textDecoration: "underline" }}
        >
          Click here
        </MUILink>{" "}
        to reset your password.
      </Box>
    </Typography>
  );
}
