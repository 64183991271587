import React from "react";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    border: {
      border: ({ borderColor }) =>
        `1px solid ${
          borderColor ||
          theme.palette.borderText?.main ||
          theme.palette.primary.main
        }`,
      borderRadius: 5,
      padding: 10,
    },
  };
});

const BorderedText = ({ children, borderColor }) => {
  const classes = useStyles({ borderColor });
  return <Box className={classes.border}>{children}</Box>;
};

export default BorderedText;
