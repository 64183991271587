import React, { useState, useContext } from "react";
import { ThemeContext } from "components/Theme";
import imagePaths from "util/resolveImagePath";
import {
  Container,
  Box,
  Button,
  Checkbox,
  Typography,
  makeStyles,
} from "@material-ui/core";

import { WelcomeTitle, WelcomeInfo, ContentTitle } from "../HomeTitles";

import { sections, additionalParticipantSections } from "util/formSections";
import { Mixpanel } from "../../../../util/mixpanel-instance";

const HomeScreen1 = ({
  nextStep,
  goToStep,
  lastEditedStep,
  completedSections,
  isApplicationStarted,
  accountType,
  checkingAccountType,
  firstName,
  lastName,
  isAdditionalParticipant,
  providerName,
}) => {
  const useStyles = makeStyles((theme) => ({
    container: {
      justifyContent: "flex-start",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      paddingBottom: 30,
      paddingTop: 30,
    },
    graphicContainer: {
      background: "white",
      borderRadius: 100,
      maxWidth: 200,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.up("sm")]: {
        width: 325,
        maxWidth: 325,
      },
    },
    graphic: {
      objectFit: "contain",
      width: "100%",
    },
  }));

  const {
    body1 = "Thank you for taking the time to complete our self-directed retirement account application. Below are just a few sections you will need to complete - we're with you every step of the way!",
    body2 = "",
    heading1 = "Welcome",
  } = useContext(ThemeContext)?.copy?.form?.home || {};

  const { email = "", companyName = "" } =
    useContext(ThemeContext)?.copy?.contactInformation || {};

  const { themeName = "" } = useContext(ThemeContext) || {};

  const [hasImageLoaded, setHasImageLoaded] = useState(false);
  const classes = useStyles();
  const welcomeText = isApplicationStarted
    ? `${heading1} back, ${firstName} ${lastName}!`
    : `${heading1}!`;
  const welcomeInfoText = body1;

  const appSections = isAdditionalParticipant
    ? additionalParticipantSections
    : sections;

  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <Box className={classes.container}>
          <Box className={classes.graphicContainer}>
            {isApplicationStarted ? (
              <img
                className={classes.graphic}
                onLoad={() => setHasImageLoaded(true)}
                src={imagePaths(themeName).welcome2}
                srcSet={imagePaths(themeName).welcome2_2x}
                alt={`${companyName || "Welcome"} Started`}
              />
            ) : (
              <img
                className={classes.graphic}
                onLoad={() => setHasImageLoaded(true)}
                src={imagePaths(themeName).welcome}
                srcSet={imagePaths(themeName).welcome_2x}
                alt={`${companyName} Not Started`}
              />
            )}
          </Box>
        </Box>
        {hasImageLoaded ? (
          <>
            <WelcomeTitle text={welcomeText} />
            <WelcomeInfo text={welcomeInfoText} />
            {appSections({
              accountType,
              checkingAccountType,
              providerName,
            }).map((item, index) => (
              <Box key={index} display="flex">
                {isApplicationStarted && (
                  <Box pt={16}>
                    <Checkbox
                      checked={completedSections.includes(item)}
                      onChange={() => {}}
                      name={item.title}
                      color="primary"
                    />
                  </Box>
                )}
                <Box>
                  <ContentTitle text={`${index + 1}. ${item}`} />
                </Box>
              </Box>
            ))}
            {!!body2 && !isApplicationStarted && (
              <Box pt={30}>
                <Typography variant="body2">{body2}</Typography>
              </Box>
            )}
            <Box
              pt={isApplicationStarted ? 30 : 40}
              pb={isApplicationStarted ? 30 : 40}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={
                  isApplicationStarted
                    ? () => {
                        Mixpanel.track("Begin/Continue Application Page");
                        Mixpanel.register_once({
                          Name: firstName + " " + lastName,
                        });
                        Mixpanel.register_once({ Provider: providerName });
                        goToStep(lastEditedStep > 1 ? lastEditedStep : 2);
                      }
                    : () => {
                        Mixpanel.track("Begin/Continue Application Page");
                        nextStep();
                      }
                }
              >
                {isApplicationStarted
                  ? "CONTINUE MY APPLICATION"
                  : "BEGIN MY APPLICATION"}
              </Button>
            </Box>
            <Box mb={80}>
              {isApplicationStarted && (
                <Box
                  pb={20}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {email && (
                    <Typography variant="body1">
                      Contact us at <a href={`mailTo:${email}`}>{email}</a>
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          </>
        ) : null}
      </Container>
    </Box>
  );
};

export default HomeScreen1;
