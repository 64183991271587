import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

import config from "./config";

// const {
//   REACT_APP_FIREBASE_API_KEY,
//   REACT_APP_FIREBASE_AUTH_DOMAIN,
//   REACT_APP_FIREBASE_PROJECT_ID,
//   REACT_APP_FIREBASE_APP_ID,
//   REACT_APP_FIREBASE_STORAGE_BUCKET,
//   REACT_APP_FIREBASE_DATABASE_URL,
//   REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   REACT_APP_FIREBASE_MEASUREMENT_ID,
// } = process.env;
// const config = {
//   apiKey: REACT_APP_FIREBASE_API_KEY,
//   authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: REACT_APP_FIREBASE_PROJECT_ID,
//   appId: REACT_APP_FIREBASE_APP_ID,
//   storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
//   databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
//   messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
// };

export default firebase.initializeApp(config.firebase);

const runFuncsLocally = false;
const stagingBase =
  "https://us-central1-solera-app-staging-2a393.cloudfunctions.net/";
const prodBase = "https://us-central1-solera-app.cloudfunctions.net/";

const devBase = runFuncsLocally
  ? "http://localhost:5001/solera-app-staging-2a393/us-central1/"
  : stagingBase;

const BASE_URL = config.isDev
  ? config.isStage
    ? stagingBase
    : devBase
  : prodBase;

const PANDADOC_BASE_URL =
  "https://us-central1-gratis-api-223cc.cloudfunctions.net/";

export const FIREBASE_URLS = {
  manageSSN: BASE_URL + "manageSSN",
  jumioInitiateTransaction: config.jumio.url,
  handleAdditionalParticipants: BASE_URL + "handleAdditionalParticipants",
  resetPassword: BASE_URL + "resetPassword",
  pandadoc: config.isDev
    ? `${PANDADOC_BASE_URL}pandadocs/staging/documents`
    : `${PANDADOC_BASE_URL}pandadocs/documents`,
};

export const BASE_BUCKET = config.isDev
  ? "https://storage.googleapis.com/solera-app-staging-theme"
  : "https://storage.googleapis.com/solera-app-theme";

const MAGIC_LINK_REDIRECT_URL =
  process.env.REACT_APP_ENV === "production"
    ? "https://join.solerabank.com/register"
    : process.env.REACT_APP_ENV === "staging"
    ? window.location.hostname.includes("whitelabel")
      ? "https://solera-app-whitelabel.solerabank.io/register"
      : "https://solera-app.solerabank.io/register"
    : "http://localhost:3000/register";

export const emailLinkSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be whitelisted in the Firebase Console.
  url: MAGIC_LINK_REDIRECT_URL,
  // This must be true.
  handleCodeInApp: true,
};
