import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  TextField,
  Input,
  InputAdornment,
  Typography,
  makeStyles,
} from "@material-ui/core";

import ScreenTitle from "../ScreenTitle";
import { QuestionSection, Question } from "../Question";
import ProgressButtonGroup from "../ProgressButtonGroup";

import { LLC } from "../../../../constants";

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    flexWrap: "wrap",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 8,
    marginBottom: 35,
  },
  input1: {
    flex: "0 0 100%",
    marginBottom: 15,
    [theme.breakpoints.up("sm")]: {
      flex: "0 0 60%",
      marginBottom: 0,
    },
  },
  input2: {
    flex: "0 0 100%",
    marginBottom: 15,
    [theme.breakpoints.up("sm")]: {
      flex: "0 0 30%",
      marginBottom: 0,
    },
  },
}));

const LLCOwnership = ({
  previousStep,
  nextStep,
  currentStep,
  completedSections,
  iraPercentOwnershipInLlc,
  llcOwnerNameWith25PercentShare1,
  llcOwnerNameWith25PercentShare2,
  llcOwnerNameWith25PercentShare3,
  llcOwnerSharePercentage1,
  llcOwnerSharePercentage2,
  llcOwnerSharePercentage3,
  setFieldValue,
  handleSave,
}) => {
  const [shouldShowLlcOwners, setShowLlcOwners] = useState(false);

  useEffect(() => {
    handleDisplaySection();
    // eslint-disable-next-line
  }, []);

  const classes = useStyles();

  const handleChange = (e) => {
    let val = e.target.value;
    const total = totalShare("iraPercentOwnershipInLlc", val);
    if (total > 100) return;

    if (val === "") {
      return setFieldValue("iraPercentOwnershipInLlc", val);
    }
    val = parseFloat(val);
    if (val > 0 && val <= 100) {
      setFieldValue("iraPercentOwnershipInLlc", val);
    }
  };

  const totalShare = (name, value) => {
    let arr = [
      { name: "iraPercentOwnershipInLlc", value: iraPercentOwnershipInLlc },
      { name: "llcOwnerSharePercentage1", value: llcOwnerSharePercentage1 },
      { name: "llcOwnerSharePercentage2", value: llcOwnerSharePercentage2 },
      { name: "llcOwnerSharePercentage3", value: llcOwnerSharePercentage3 },
    ];

    arr = arr.filter((item) => item.name !== name);
    let total = 0;

    arr.forEach((item) => {
      total += parseFloat(item.value || 0);
    });
    total += parseFloat(value || 0);
    return total;
  };

  const handleAdditionalOwnerName = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const handleAdditionalOwnerPercent = (e) => {
    let { name, value } = e.target;
    const total = totalShare(name, value);
    if (total > 100) return;
    if (value === "") {
      return setFieldValue(name, value);
    }
    value = parseFloat(value);
    if (value > 0 && value <= 100) {
      setFieldValue(name, value);
    }
  };

  const resolveDisabled = () => {
    function checkSubtotals() {
      return [
        llcOwnerSharePercentage1,
        llcOwnerSharePercentage2,
        llcOwnerSharePercentage3,
      ].some((val) => {
        if (val) {
          return parseFloat(val) < 25;
        } else {
          return false;
        }
      });
    }
    return !iraPercentOwnershipInLlc || checkSubtotals();
  };

  const handlePreviousStep = () => {
    return previousStep();
  };

  const handleBlur = () => {
    handleDisplaySection();
  };

  const handleDisplaySection = () => {
    if (parseFloat(iraPercentOwnershipInLlc) < 75) {
      setShowLlcOwners(true);
    } else {
      setShowLlcOwners(false);
    }
  };

  const handleNextStep = () => {
    !completedSections.includes(LLC) &&
      setFieldValue("completedSections", [...completedSections, LLC]);
    if (handleSave) {
      handleSave();
    }
    return nextStep();
  };

  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle step={currentStep} title="LLC Information" />
        <QuestionSection>
          <Question
            text="What's your IRA's % ownership in this LLC?"
            required
          />
          <Input
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
            id="iraPercentOwnershipInLlc"
            name="iraPercentOwnershipInLlc"
            value={iraPercentOwnershipInLlc || ""}
            type="number"
            fullWidth
            onChange={handleChange}
            placeholder="Enter percentage(%)"
            onBlur={handleBlur}
          />
        </QuestionSection>
        {shouldShowLlcOwners && (
          <QuestionSection>
            <Question text="Please include additional LLC owners with 25% or more" />
            <Box mt={20}>
              <Typography variant="body2">Additional owner (1)</Typography>
              <Box className={classes.inputWrapper}>
                <TextField
                  className={classes.input1}
                  id="llcOwnerNameWith25PercentShare1"
                  name="llcOwnerNameWith25PercentShare1"
                  value={llcOwnerNameWith25PercentShare1}
                  type="text"
                  onChange={handleAdditionalOwnerName}
                  placeholder="Name"
                />
                <Input
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                  className={classes.input2}
                  id="llcOwnerSharePercentage1"
                  name="llcOwnerSharePercentage1"
                  value={llcOwnerSharePercentage1}
                  type="number"
                  onChange={handleAdditionalOwnerPercent}
                  placeholder="Share %"
                />
              </Box>
            </Box>
            <Box mt={10}>
              <Typography variant="body2">Additional owner (2)</Typography>
              <Box className={classes.inputWrapper}>
                <TextField
                  className={classes.input1}
                  id="llcOwnerNameWith25PercentShare2"
                  name="llcOwnerNameWith25PercentShare2"
                  value={llcOwnerNameWith25PercentShare2}
                  type="text"
                  onChange={handleAdditionalOwnerName}
                  placeholder="Name"
                />
                <Input
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                  className={classes.input2}
                  id="llcOwnerSharePercentage2"
                  name="llcOwnerSharePercentage2"
                  value={llcOwnerSharePercentage2}
                  type="number"
                  onChange={handleAdditionalOwnerPercent}
                  placeholder="Share %"
                />
              </Box>
            </Box>
            <Box mt={10}>
              <Typography variant="body2">Additional owner (3)</Typography>
              <Box className={classes.inputWrapper}>
                <TextField
                  className={classes.input1}
                  id="llcOwnerNameWith25PercentShare3"
                  name="llcOwnerNameWith25PercentShare3"
                  value={llcOwnerNameWith25PercentShare3}
                  type="text"
                  onChange={handleAdditionalOwnerName}
                  placeholder="Name"
                />
                <Input
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                  className={classes.input2}
                  id="llcOwnerSharePercentage3"
                  name="llcOwnerSharePercentage3"
                  value={llcOwnerSharePercentage3}
                  type="number"
                  onChange={handleAdditionalOwnerPercent}
                  placeholder="Share %"
                />
              </Box>
            </Box>
          </QuestionSection>
        )}
        <ProgressButtonGroup
          left={{
            clickHandler: handlePreviousStep,
            pageCalling: "Back Click: LLC Ownership Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: LLC Ownership Page",
            disabled: resolveDisabled(),
          }}
          hasRequiredInputs
        />
      </Container>
    </Box>
  );
};

export default LLCOwnership;
