export const AUTH_INITIAL_STATE = {
  uid: "",
  email: "",
  applicationId: "",
  hasEmailLinkSent: false,
  //
  phoneNumber: "",
  firstName: "",
  lastName: "",
  ssnRef: "",
  application: {
    iraTrustExternalStoreId: "",
    iraSs4ExternalStoreId: "",
    iraTrustStorageRef: "",
    iraSs4StorageRef: "",
    accountType: "",
    checkingAccountType: "",
    referralSource: "",
    referralSourceUserInput: "",
    providerSource: "",
    applicationReferrer: "",
    applicationProvider: "",
  },
  isLoggedIn: false,
  // loading
  isLoadingAuthListener: true,
  isLoadingCreateNewUser: false,
  isLoadingSignIn: false,
  isLoadingSendEmailLink: false,
  // errors
  hasEmailLinkErrored: false,
  errorMagicLinkAuth: "",
  errorFetchUser: "",
  errorCreateNewUser: "",
};

export const FORM_INITIAL_STATE = {
  // must be included in this object to pull existing data into form for dev
  // progress info
  progressStage: 0,
  lastEditedStep: 2,
  completedSections: [],
  isApplicationStarted: false,
  //personal info general
  firstName: "",
  middleName: "",
  lastName: "",
  agreesToTextNotifications: false,
  gender: "male",
  maritalStatus: "single",
  birthDate: "",
  phoneNumber: "",
  email: "",
  maidenName: "",
  // address
  resAddressEqualsMailing: true,
  hasResidedAtAddressForRequiredDuration: true,
  resAddressObj: null,
  resAddressLine1: "",
  resAptNumber: "",
  resCity: "",
  resState: "",
  resPostcode: "",
  // previous address
  previousAddressObj: null,
  previousAddressLine1: "",
  previousAptNumber: "",
  previousCity: "",
  previousState: "",
  previousPostcode: "",
  //mailing address
  mailingAddressObj: null,
  mailingAddressLine1: "",
  mailingAptNumber: "",
  mailingCity: "",
  mailingState: "",
  mailingPostcode: "",
  //employment
  employmentStatus: "employed",
  currentOrRecentJobTitle: "",
  employer: "",
  formerEmployer: "",
  industry: "",
  //trust
  ssnRef: "", // encrypted
  legalName: "",
  investmentSector1: "",
  investmentSector2: "",
  checkingAccountTaxTypes: [],
  willInvestInternationally: false,
  countriesForInternationalTrans: [],
  iraType: "Traditional",
  trustName: "",
  trustStateOfRegistration: "", // eg CA
  trustCreationDate: "",
  trustAddressLine1: "",
  trustApt: "",
  trustCity: "",
  trustPostcode: "",
  trustState: "",
  trustTaxIdRef: "",
  trustAddressEquals: "other",
  trustStorageRef: "",
  trustExternalStoreId: "",
  ss4ExternalStoreId: "",
  ss4StorageRef: "",
  llcOperatingAgreementRef: "",
  llcArticlesOfOrganizationRef: "",
  llcName: "",
  llcStateOfRegistration: "", // eg California
  llcAddressObj: null,
  llcAddressLine1: "",
  llcUnitOrSuite: "",
  llcCity: "",
  llcPostcode: "",
  llcState: "",
  llcTaxIdRef: "",
  llcAddressEquals: "other",
  llcWhollyOwnedByIra: null,
  iraPercentOwnershipInLlc: null,
  triPartyAgreementRef: "",
  jumioTransaction: null,
  llcOwnerNameWith25PercentShare1: "",
  llcOwnerNameWith25PercentShare2: "",
  llcOwnerNameWith25PercentShare3: "",
  llcOwnerSharePercentage1: "",
  llcOwnerSharePercentage2: "",
  llcOwnerSharePercentage3: "",
  createRothCheckingForPostTaxFunds: false,
  createTraditionalCheckingForTaxDeferredFunds: true,
  checkingAccountNicknameRoth: "",
  checkingAccountNicknameTraditional: "",
  checkingAccountType: "Trust",
  accountType: null,
  referralSource: "",
  providerSource: "",
  referralSourceUserInput: "",
  applicationReferrer: "",
  applicationProvider: "",
  inheritedIraHolderFirstName: "",
  inheritedIraHolderMiddleName: "",
  inheritedIraHolderLastName: "",
  inheritedIraHolderDob: "",
  inheritedIraHolderDateOfDeath: "",
  inheritedIraHolderSsnRef: "",
  // designated successor and beneficiary
  shouldNameSuccessor: false,
  shouldNameBeneficiary: false,
  successorLegalName: "",
  designatedBeneficiaries: [],
  /////
  spouseEmail: "",
  spouseLegalName: "",
  spouseAddressLine1: "",
  spouseApt: "",
  spouseCity: "",
  spouseState: "",
  spousePostcode: "",
  spouseCountry: "",
  spouseBirthDate: "",
  spouseSsnRef: "",
  ///////
  doesRequireSpousalConsent: null,
  isSpouseAddressSameAsParticipant: false,
  // Id and security questions
  IPAddress: "",
  // citizenship
  countryCitizenship: "",
  hasUSAddress: true,
  usaCitizen: true,
  usaPermanentResident: true,
  greenCardStorageRef: null,
  hasForeignAddress: null,
  foreignAddressLine1: "",
  foreignAddressApt: "",
  foreignAddressCity: "",
  foreignAddressState: "",
  foreignAddressPostcode: "",
  foreignAddressCountry: "",
  //
  marijuanaIncome: false,
  submittedAt: null,
  applicationRefId: null,
  applicationId: null,
  participantRefId: null,
  addAdditionalParticipant: false,
  additionalParticipantFirstName: "",
  additionalParticipantMiddleName: "",
  additionalParticipantLastName: "",
  additionalParticipantEmail: "",
  additionalParticipantPhone: "",
  primaryParticipantId: null,
  additionalParticipantId: null,
  isPrimary: null,
  status: "",
};

export const APPLICATION_STATE = {
  primaryParticipantId: null,
  additionalParticipantId: null,
  isApplicationStarted: false,
  addAdditionalParticipant: false,
  additionalParticipantFirstName: "",
  additionalParticipantMiddleName: "",
  additionalParticipantLastName: "",
  additionalParticipantEmail: "",
  additionalParticipantPhone: "",
  iraType: "Traditional",
  trustName: "",
  trustStateOfRegistration: "", // eg CA
  trustCreationDate: "",
  trustAddressObj: null,
  trustAddressLine1: "",
  trustApt: "",
  trustCity: "",
  trustPostcode: "",
  trustState: "",
  trustTaxIdRef: "",
  trustAddressEquals: "other",
  trustStorageRef: "",
  trustExternalStoreId: "",
  ss4ExternalStoreId: "",
  ss4StorageRef: "",
  llcOperatingAgreementRef: "",
  llcArticlesOfOrganizationRef: "",
  llcName: "",
  llcStateOfRegistration: "", // eg CA
  llcAddressObj: null,
  llcAddressLine1: "",
  llcUnitOrSuite: "",
  llcCity: "",
  llcPostcode: "",
  llcState: "",
  llcTaxIdRef: "",
  llcAddressEquals: "other",
  llcWhollyOwnedByIra: null,
  iraPercentOwnershipInLlc: null,
  llcOwnerNameWith25PercentShare1: "",
  llcOwnerNameWith25PercentShare2: "",
  llcOwnerNameWith25PercentShare3: "",
  llcOwnerSharePercentage1: "",
  llcOwnerSharePercentage2: "",
  llcOwnerSharePercentage3: "",
  createRothCheckingForPostTaxFunds: false,
  createTraditionalCheckingForTaxDeferredFunds: false,
  checkingAccountTaxTypes: [],
  checkingAccountNicknameRoth: "",
  checkingAccountNicknameTraditional: "",
  checkingAccountType: "Trust",
  triPartyAgreementRef: "",
  accountType: null,
  referralSource: "",
  referralSourceUserInput: "",
  providerSource: "",
  investmentSector1: "",
  investmentSector2: "",
  willInvestInternationally: false,
  countriesForInternationalTrans: [],
  inheritedIraHolderFirstName: "",
  inheritedIraHolderMiddleName: "",
  inheritedIraHolderLastName: "",
  inheritedIraHolderDob: "",
  inheritedIraHolderDateOfDeath: "",
  inheritedIraHolderSsnRef: "",
  ////////////////////
  shouldNameSuccessor: false,
  shouldNameBeneficiary: false,
  successorLegalName: "",
  designatedBeneficiaries: [],
  doesRequireSpousalConsent: null,
  spouseEmail: "",
  spouseLegalName: "",
  spouseAddressLine1: "",
  spouseApt: "",
  spouseCity: "",
  spouseState: "",
  spousePostcode: "",
  spouseCountry: "",
  spouseBirthDate: "",
  spouseSsnRef: "",
  isSpouseAddressSameAsParticipant: false,
  applicationRefId: null,
  submittedAt: null,
  status: "",
};

export const PARTICIPANT_STATE = {
  applicationId: null,
  participantRefId: null,
  applicationReferrer: "",
  applicationProvider: "",
  progressStage: null,
  lastEditedStep: null,
  completedSections: [],
  isPrimary: null,
  firstName: null,
  middleName: "",
  lastName: null,
  legalName: null,
  agreesToTextNotifications: false,
  gender: null,
  maritalStatus: null,
  birthDate: null,
  phoneNumber: null,
  email: null,
  maidenName: null,
  // Residential Address
  resAddressEqualsMailing: true,
  hasResidedAtAddressForRequiredDuration: true,
  resAddressObj: null,
  resAddressLine1: "",
  resAptNumber: "",
  resCity: "",
  resState: "",
  resPostcode: "",
  // previous address
  previousAddressObj: null,
  previousAddressLine1: "",
  previousAptNumber: "",
  previousCity: "",
  previousState: "",
  previousPostcode: "",
  // Mailing address
  mailingAddressObj: null,
  mailingAddressLine1: "",
  mailingAptNumber: "",
  mailingCity: "",
  mailingState: "",
  mailingPostcode: "",
  employmentStatus: null,
  currentOrRecentJobTitle: "",
  employer: "",
  formerEmployer: "",
  industry: "",
  ssnRef: "", //encrypted
  IPAddress: "",
  countryCitizenship: null,
  usaCitizen: false,
  usaPermanentResident: false,
  greenCardStorageRef: null,
  hasForeignAddress: null,
  hasUSAddress: true,
  foreignAddressLine1: "",
  foreignAddressApt: "",
  foreignAddressCity: "",
  foreignAddressState: "",
  foreignAddressPostcode: "",
  foreignAddressCountry: "",
  marijuanaIncome: null,
  jumioTransaction: null,
};

export const ADDITIONAL_PARTICIPANT_STATE = {
  additionalParticipantSubmittedAt: null,
  additionalParticipantId: "",
  progressStage: null,
  lastEditedStep: null,
  completedSections: [],
  firstName: "",
  middleName: "",
  lastName: "",
  legalName: "",
  agreesToTextNotifications: false,
  gender: null,
  maritalStatus: null,
  birthDate: null,
  phoneNumber: "",
  email: "",
  maidenName: null,
  // Residential Address
  resAddressEqualsMailing: true,
  hasResidedAtAddressForRequiredDuration: true,
  resAddressObj: null,
  resAddressLine1: "",
  resAptNumber: "",
  resCity: "",
  resState: "",
  resPostcode: "",
  // previous address
  previousAddressObj: null,
  previousAddressLine1: "",
  previousAptNumber: "",
  previousCity: "",
  previousState: "",
  previousPostcode: "",
  // Mailing address
  mailingAddressObj: null,
  mailingAddressLine1: "",
  mailingAptNumber: "",
  mailingCity: "",
  mailingState: "",
  mailingPostcode: "",
  employmentStatus: null,
  currentOrRecentJobTitle: "",
  employer: "",
  formerEmployer: "",
  industry: "",
  ssnRef: "", //encrypted
  IPAddress: "",
  countryCitizenship: "",
  usaCitizen: true,
  usaPermanentResident: true,
  hasForeignAddress: null,
  hasUSAddress: true,
  foreignAddressLine1: "",
  foreignAddressApt: "",
  foreignAddressCity: "",
  foreignAddressState: "",
  foreignAddressPostcode: "",
  foreignAddressCountry: "",
  marijuanaIncome: null,
  // docs
  greenCardStorageRef: null,
  jumioTransaction: null,
};
