import resolveThemeName from "config/resolveThemeName";
import { GRATIS_THEME_NAME } from "../constants";

// form components
import Home from "containers/IRAForm/components/Home";
import Name from "containers/IRAForm/components/Name";
import Citizenship from "containers/IRAForm/components/Citizenship";
import NonCitizenDenied from "containers/IRAForm/components/NonCitizenDenied";
import ResAddress from "containers/IRAForm/components/ResAddress";
import MailAddress from "containers/IRAForm/components/MailAddress";
import PersonalDetails from "containers/IRAForm/components/PersonalDetails";
import Employment from "containers/IRAForm/components/Employment";
import IRAType from "containers/IRAForm/components/IRAType";
import IRAType2 from "containers/IRAForm/components/IRAType2";
import InheritedIRA from "containers/IRAForm/components/InheritedIRA";
import CheckingAccount from "containers/IRAForm/components/CheckingAccount";
import CheckingAccount2 from "containers/IRAForm/components/CheckingAccount2";
import AdditionalParticipant from "containers/IRAForm/components/AdditionalParticipant";
import TrustInfo from "containers/IRAForm/components/TrustInfo";
import TrustAdoptionUpload from "containers/IRAForm/components/TrustAdoptionUpload";
import LLCInfo from "containers/IRAForm/components/LLCInfo";
import LLCOwnership from "containers/IRAForm/components/LLCOwnership";
import LLCUpload from "containers/IRAForm/components/LLCUpload";
import TrustBeneficiaryIntro from "containers/IRAForm/components/TrustBeneficiaryIntro";
import TrustBeneficiaries from "containers/IRAForm/components/TrustBeneficiaries";
import SpouseConsent from "containers/IRAForm/components/SpouseConsent";
import JumioIntro1 from "containers/IRAForm/components/JumioIntro1";
import JumioIntro2 from "containers/IRAForm/components/JumioIntro2";
import JumioIdEmbed from "containers/IRAForm/components/JumioIdEmbed";
import JumioCompleted from "containers/IRAForm/components/JumioCompleted";
import CitizenshipUpload from "containers/IRAForm/components/CitizenshipUpload";
import SecurityQuestions from "containers/IRAForm/components/SecurityQuestions";
import TriPartyAgreementUpload from "containers/IRAForm/components/TriPartyAgreementUpload";
import ReviewAndSubmit from "containers/IRAForm/components/ReviewAndSubmit";
// Gratis
import TrustNameState from "containers/IRAForm/components/TrustNameState";
import TrustSuccessor from "containers/IRAForm/components/TrustSuccessor";
import TrustEmbedIntro from "containers/IRAForm/components/TrustEmbedIntro";
import TrustDocEmbed from "containers/IRAForm/components/TrustDocEmbed";
import TrustSS4Embed from "containers/IRAForm/components/TrustSS4Embed";

import {
  ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL,
  ACCOUNT_TYPE_CUSTODIAL_IRA,
  CHECKING_ACCOUNT_TYPE_LLC,
} from "../constants";

export function screens({ accountType, checkingAccountType }) {
  const providerName = resolveThemeName();
  let screensList = [];

  screensList.push(Home);
  screensList.push(Name);
  screensList.push(Citizenship);
  screensList.push(NonCitizenDenied);
  screensList.push(ResAddress);
  screensList.push(MailAddress);
  screensList.push(PersonalDetails);
  screensList.push(Employment);
  // Checkbook custodial && Custodial Account do NOT have additional participant
  if (
    ![
      ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL,
      ACCOUNT_TYPE_CUSTODIAL_IRA,
    ].includes(accountType)
  ) {
    screensList.push(AdditionalParticipant);
  }

  if ([ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL].includes(accountType)) {
    screensList.push(IRAType);
    screensList.push(IRAType2);
    // note there is some overlap of questions and functionality between IRAType and CheckingAccount screens
    screensList.push(InheritedIRA);
  } else if (accountType !== ACCOUNT_TYPE_CUSTODIAL_IRA) {
    // this type does is not a Trust or IRA and does not include checkingAccountType
    screensList.push(CheckingAccount);
    screensList.push(CheckingAccount2);
  }

  if (checkingAccountType === CHECKING_ACCOUNT_TYPE_LLC) {
    screensList.push(LLCInfo);
    screensList.push(LLCOwnership);
    screensList.push(LLCUpload);
  } else if (providerName === GRATIS_THEME_NAME) {
    // diff trust inputs + successor
    screensList.push(TrustNameState);
    screensList.push(TrustSuccessor);
  } else if (accountType !== ACCOUNT_TYPE_CUSTODIAL_IRA) {
    screensList.push(TrustInfo);
    screensList.push(TrustAdoptionUpload);
  }
  // custodial and checkbook custodial have beneficiaries
  if (
    [
      ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL,
      ACCOUNT_TYPE_CUSTODIAL_IRA,
    ].includes(accountType)
  ) {
    screensList.push(TrustBeneficiaryIntro);
    screensList.push(TrustBeneficiaries);
    screensList.push(SpouseConsent);
  }
  screensList.push(JumioIntro1);
  screensList.push(JumioIntro2);
  screensList.push(JumioIdEmbed);
  screensList.push(JumioCompleted);
  screensList.push(CitizenshipUpload);
  screensList.push(SecurityQuestions);
  if (accountType === ACCOUNT_TYPE_CUSTODIAL_IRA) {
    // this has a different document upload which is optional
    screensList.push(TriPartyAgreementUpload);
  }
  screensList.push(ReviewAndSubmit);
  if (providerName === GRATIS_THEME_NAME) {
    // add trust documentation embedded document signing
    screensList.push(TrustEmbedIntro);
    screensList.push(TrustDocEmbed);
    screensList.push(TrustSS4Embed);
  }
  return screensList;
}
