import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  Container,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Box,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { matchSorter } from "match-sorter";

import Header from "components/Header";
import { useHistory } from "react-router-dom";
import { QuestionSection, Question } from "../IRAForm/components/Question";
import ProgressButtonGroup from "../IRAForm/components/ProgressButtonGroup";
import { ApplicationDataContext } from "components/ApplicationData";
import { ThemeContext } from "components/Theme";
import { Loading } from "containers/Loading";
import Layout from "components/Layout";

import {
  REFERRER_LIST_PARAM_KEY,
  ACCOUNT_TYPE_SOLO_401K,
  ACCOUNT_TYPE_CHECKBOOK_BANK_ONLY,
  ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL,
  ACCOUNT_TYPE_BUSINESS_ACCOUNT,
  ACCOUNT_TYPE_CUSTODIAL_IRA,
  CHECKING_ACCOUNT_TYPE_TRUST,
  CHECKING_ACCOUNT_TYPE_LLC,
} from "../../constants";

import {
  retreiveReferrersData,
  getReferrer,
  setAuthority,
} from "util/providerSource";

import { isEmpty, take } from "lodash";
import { Mixpanel } from "../../util/mixpanel-instance";

const useStyles = makeStyles({
  text: {
    letterSpacing: 0.15,
    fontSize: 20,
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "24px",
  },
});

const OPTION_NOT_FOUND = "I can't find my referrer";

const filterOptions = (options, { inputValue }) => {
  if (inputValue.length < 3) return [];
  const arr = matchSorter(options, inputValue);
  const maxOpts = 2;
  const top2 = take(arr, maxOpts);
  if (isEmpty(top2) && inputValue.length > 3) {
    return [OPTION_NOT_FOUND];
  }
  return top2;
};

// eslint-disable-next-line no-unused-vars
const SPECIAL_CASE_REFERRERS = {
  "DORADO IRA": ACCOUNT_TYPE_CUSTODIAL_IRA,
};

const AccountType = () => {
  const classes = useStyles();
  const history = useHistory();
  const { referrerList, updateAccountType } =
    useContext(ApplicationDataContext) || {};
  const { dataInit = {} } = useContext(ThemeContext) || {};
  const [referralOptions, setReferralOptions] = useState([]);

  /* localProps are pre-sets depending on the referrer*/
  const [localProps, setLocalProps] = useState({
    accountType: null,
    checkingAccountType: null,
    referralSource: "",
    referralSourceUserInput: "",
  });

  /* referrerInputOptions are */
  const [referrerInputOptions, setReferrerInputOptions] = useState({
    accountType: [],
    checkingAccountType: [],
  });

  /* themeData is a large db store with presets, with more data than providerData, but some overlaps */
  const [themeDataInit, setThemeDataInit] = useState({
    accountType: null,
    checkingAccountType: null,
    referralSource: "",
  });

  const [isLoadingThemeData, setIsLoadingThemeData] = useState(true);

  const [isReferrerFromDeepLink, setIsReferrerFromDeepLink] = useState(false);

  function initReferrerData(data) {
    // this comes from a different store than theme data and allows referrers to init data without having their own complete apps
    // for example, they may not offer all accountTypes or checkingAccountTypes
    let values = {};
    let options = {};
    if (data.accountType?.value) {
      values.accountType = data.accountType.value;
    }
    if (data.checkingAccountType?.value) {
      values.checkingAccountType = data.checkingAccountType.value;
    }
    if (Object.keys(values).length > 0) {
      setLocalProps((prevState) => ({
        ...prevState,
        ...values,
      }));
    }
    if (data.accountType?.options) {
      options.accountType = data.accountType.options;
    }
    if (data.checkingAccountType?.options) {
      options.checkingAccountType = data.checkingAccountType.options;
    }
    if (Object.keys(options).length > 0) {
      setReferrerInputOptions((prevState) => ({
        ...prevState,
        ...options,
      }));
    }
  }

  function setReferralSourceFromData(referrerObj) {
    setLocalProps((prevState) => ({
      ...prevState,
      referralSource: referrerObj.referralSource,
    }));
  }

  const updateAccount = useCallback(
    async ({
      accountType,
      checkingAccountType,
      referralSource,
      referralSourceUserInput = "",
    }) => {
      await updateAccountType({
        accountType,
        checkingAccountType,
        referralSource: referralSource.trim(),
        applicationReferrer: referralSource.trim(),
        referralSourceUserInput,
      });
    },
    [updateAccountType]
  );

  useEffect(() => {
    /**
     * Some data comes from another db source called providerData. If it exists, this helps init this screen
     */
    async function handleReferrerInitData(authority) {
      try {
        const allReferrerData = await retreiveReferrersData();
        if (!allReferrerData) return;
        const data = allReferrerData[authority];
        initReferrerData(data);
      } catch (e) {}
    }
    /***
     * This extracts referrer options used in state for this component
     */
    function getReferralOptions(list) {
      return list
        .filter((item) => item.name !== "Solera")
        .map((item) => item.referralSource);
    }

    /**
     * Extracts and return the specific referrer object from the list
     */
    function getReferrerObj(referrer) {
      return referrerList.find((item) => {
        return item[REFERRER_LIST_PARAM_KEY] === referrer;
      });
    }
    function handleReferrerFromLocalStorage() {
      // options are set from load, look for pre-set - these are query params that each provider has
      let referrer;
      try {
        referrer = getReferrer();
      } catch (e) {}
      if (referrer) {
        const referrerObj = getReferrerObj(referrer);
        if (referrerObj) {
          // set authority in local storage
          try {
            setAuthority(referrerObj.authority);
          } catch (_) {}
          setReferralSourceFromData(referrerObj);
          setIsReferrerFromDeepLink(true);
          handleReferrerInitData(referrerObj.authority);
        }
      }
    }

    // dependency referrerList comes from context - it is updated at mount
    // this effect then runs
    if (referrerList) {
      const opts = getReferralOptions(referrerList); // extract options from full list
      setReferralOptions(opts);
      handleReferrerFromLocalStorage();
    }
  }, [referrerList]);

  useEffect(() => {
    // some providers only do some types of accounts and this comes from a full theme. set this data now.
    async function loadInputDataFromTheme() {
      if (!dataInit) return;
      const payload = {};
      if (dataInit.accountType) payload.accountType = dataInit.accountType;
      if (dataInit.checkingAccountType)
        payload.checkingAccountType = dataInit.checkingAccountType;
      if (dataInit.referralSource)
        payload.referralSource = dataInit.referralSource;

      if (
        dataInit.accountType &&
        dataInit.checkingAccountType &&
        dataInit.referralSource
      ) {
        // we've got all we need from theme data, let's update move forward past this screen
        await updateAccount({
          accountType: dataInit.accountType,
          checkingAccountType: dataInit.checkingAccountType,
          referralSource: dataInit.referralSource,
        });
        // navigate on
        history && history.push("/account-creation");
      } else {
        // we might only get some of the info needed on this screen
        setLocalProps((prevState) => ({
          ...prevState,
          ...payload,
        }));
        setThemeDataInit((prevState) => ({
          ...prevState,
          ...payload,
        }));
        setIsLoadingThemeData(false);
      }
    }

    loadInputDataFromTheme();
  }, [dataInit, updateAccount, history]);

  const handleRadioChange = (e) => {
    e.persist();
    if (e.target.value === ACCOUNT_TYPE_SOLO_401K) {
      setLocalProps((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
        checkingAccountType: CHECKING_ACCOUNT_TYPE_TRUST,
      }));
    } else if (e.target.value === ACCOUNT_TYPE_CUSTODIAL_IRA) {
      setLocalProps((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
        checkingAccountType: null,
      }));
    } else {
      setLocalProps((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleChange = (e, newValue) => {
    e.persist();
    let val = newValue;
    if (val === OPTION_NOT_FOUND) val = "0000-NOT ASSIGNED";
    setLocalProps((prevState) => ({
      ...prevState,
      referralSource: val,
      referralSourceUserInput:
        val === "0000-NOT ASSIGNED" ? prevState.referralSourceUserInput : "",
    }));
  };

  const handleAutocompleteInputChange = (e) => {
    e.persist();
    const val = e.target.value;
    if (val === 0) return;
    setLocalProps((prevState) => ({
      ...prevState,
      referralSourceUserInput: val,
    }));
  };

  const handleNextStep = async () => {
    await updateAccount({ ...localProps });
    if (localProps.referralSource) {
      Mixpanel.register_once({
        Provider: localProps.referralSource,
      });
      Mixpanel.track("Account Creation Page", {
        "Account-type": localProps.accountType,
        "Checking-Account-type": localProps.checkingAccountType,
      });
    } else {
      Mixpanel.register_once({
        Provider: localProps.referralSourceUserInput,
      });
      Mixpanel.track("Account Creation Page", {
        "Account-type": localProps.accountType,
        "Checking-Account-type": localProps.checkingAccountType,
      });
    }
    history.push("/account-creation");
  };

  const accountTypesRequiresCheckingQuestion = [
    ACCOUNT_TYPE_BUSINESS_ACCOUNT,
    ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL,
    ACCOUNT_TYPE_CHECKBOOK_BANK_ONLY,
  ];

  const resolveDisabled = () => {
    if (localProps.accountType === ACCOUNT_TYPE_CUSTODIAL_IRA) {
      return !localProps.referralSource;
    }
    return (
      !localProps.referralSource ||
      !localProps.accountType ||
      !localProps.checkingAccountType
    );
  };

  const referralTitle = `Which company set up your ${
    localProps.checkingAccountType &&
    localProps.checkingAccountType === CHECKING_ACCOUNT_TYPE_LLC
      ? CHECKING_ACCOUNT_TYPE_LLC
      : CHECKING_ACCOUNT_TYPE_TRUST
  }?`;

  const heading =
    themeDataInit.checkingAccountType && themeDataInit.accountType
      ? "Let's get started"
      : "Select account type";

  return (
    <div>
      <Header />
      <Box flex={1}>
        {isLoadingThemeData ? (
          <Loading />
        ) : (
          <Container maxWidth="sm">
            <Layout>
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                flex={1}
              >
                <Box pt={30}>
                  <Typography variant="h6" className={classes.text}>
                    {heading}
                  </Typography>
                </Box>
                {!!themeDataInit.accountType ? (
                  <div>You are setting up a {themeDataInit.accountType}</div>
                ) : (
                  <QuestionSection>
                    <Question text="Which account would you like to open? (Select one)" />
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="accountType"
                        name="accountType"
                        value={localProps.accountType}
                        onChange={handleRadioChange}
                      >
                        {/* These options come from referrer db data */}
                        {!isEmpty(referrerInputOptions.accountType) ? (
                          referrerInputOptions.accountType.map(
                            ({ label, value }) => {
                              return (
                                <FormControlLabel
                                  value={value}
                                  control={<Radio />}
                                  label={label}
                                  key={value}
                                />
                              );
                            }
                          )
                        ) : (
                          <>
                            {/* These options are set default */}
                            <FormControlLabel
                              value={ACCOUNT_TYPE_SOLO_401K}
                              control={<Radio />}
                              label="Solo 401K"
                            />
                            <FormControlLabel
                              value={ACCOUNT_TYPE_CHECKBOOK_BANK_ONLY}
                              control={<Radio />}
                              label="Checkbook IRA - Bank Account Only"
                            />
                            <FormControlLabel
                              value={ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL}
                              control={<Radio />}
                              label={
                                "Checkbook IRA - Custodial IRA & Bank Account"
                              }
                            />
                            {/* Hide business account type here */}
                            {/* <FormControlLabel
                              value={ACCOUNT_TYPE_BUSINESS_ACCOUNT}
                              control={<Radio />}
                              label={ACCOUNT_TYPE_BUSINESS_ACCOUNT}
                            /> */}
                            {/* Here if the user comes to app without the deep link for some reason, we still */}
                            {/* need to ensure that if they select Dorado, they still get this account option */}
                            {/* {SPECIAL_CASE_REFERRERS[
                              localProps.referralSource
                            ] === ACCOUNT_TYPE_CUSTODIAL_IRA && (
                              <FormControlLabel
                                value={ACCOUNT_TYPE_CUSTODIAL_IRA}
                                control={<Radio />}
                                label={ACCOUNT_TYPE_CUSTODIAL_IRA}
                              />
                            )} */}
                          </>
                        )}
                      </RadioGroup>
                    </FormControl>
                  </QuestionSection>
                )}
                {!!themeDataInit.checkingAccountType ? (
                  <div>
                    With checking account type of{" "}
                    {themeDataInit.checkingAccountType}{" "}
                  </div>
                ) : (
                  <>
                    {localProps &&
                      localProps.accountType &&
                      accountTypesRequiresCheckingQuestion.includes(
                        localProps.accountType
                      ) && (
                        <QuestionSection>
                          <Question text="Which type of checking account would you like to open at this time?" />
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="checkingAccountType"
                              name="checkingAccountType"
                              value={localProps.checkingAccountType}
                              onChange={handleRadioChange}
                            >
                              <FormControlLabel
                                value="LLC"
                                control={<Radio />}
                                label="LLC"
                              />
                              <FormControlLabel
                                value="Trust"
                                control={<Radio />}
                                label="Trust"
                              />
                            </RadioGroup>
                          </FormControl>
                        </QuestionSection>
                      )}
                  </>
                )}

                {isReferrerFromDeepLink ||
                themeDataInit.referralSource ? null : (
                  <QuestionSection>
                    <Question text={referralTitle} />
                    <Autocomplete
                      onChange={handleChange}
                      onInputChange={handleAutocompleteInputChange}
                      id="referralSource"
                      name="referralSource"
                      options={referralOptions}
                      filterOptions={filterOptions}
                      getOptionSelected={() => {
                        return true;
                      }}
                      fullWidth
                      required
                      placeholder="Enter company name"
                      noOptionsText="Enter company name"
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </QuestionSection>
                )}
                <ProgressButtonGroup
                  right={{
                    clickHandler: handleNextStep,
                    disabled: resolveDisabled(),
                  }}
                />
              </Box>
            </Layout>
          </Container>
        )}
      </Box>
    </div>
  );
};

export default AccountType;
