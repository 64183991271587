import { createTheme } from "@material-ui/core/styles";
import { breakpoints } from "./breakpoints";
import palette from "./palette";
import { workSans } from "./font";

const theme = createTheme({
  spacing: 1,
  palette: palette,
  breakpoints: {
    values: {
      ...breakpoints,
    },
  },
  status: {
    danger: "orange",
  },
  typography: {
    fontFamily: "WorkSans, sans-serif",
    h2: {
      fontFamily: "WorkSansSemi",
    },
    h4: {
      [`@media (max-width:${breakpoints.sm}px)`]: {
        fontSize: "1.8rem",
      },
    },
    h6: {
      [`@media (max-width:${breakpoints.sm}px)`]: {
        fontSize: "1.1rem",
      },
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [workSans],
      },
    },
    MuiContainer: {
      root: {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    },
    MuiButton: {
      sizeLarge: {
        height: 36,
        minWidth: 200,
        [`@media (min-width:${breakpoints.sm}px)`]: {
          minWidth: 300,
        },
      },
      label: {
        fontSize: "14px",
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.14",
        letterSpacing: "1.25px",
      },
    },
  },
});

theme.typography.h2 = {
  fontSize: 20,
  // fontFamily: "Roboto",
  // fontWeight: 500,
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: 1.2,
  letterSpacing: 0.15,
  color: "#142b2c",
  [theme.breakpoints.up("md")]: {
    fontSize: 40,
  },
};
export default theme;
