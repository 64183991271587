import React from "react";
import { Route, Redirect } from "react-router-dom";
import useGuard from "components/GuardProvider";

function GuardedRoute({ children, redirectTo = "/", ...rest }) {
  const { shouldProtect } = useGuard();
  return (
    <Route
      {...rest}
      render={() => {
        return (
          <>
            {shouldProtect ? (
              <Redirect
                to={{
                  pathname: redirectTo,
                }}
              />
            ) : (
              children
            )}
          </>
        );
      }}
    />
  );
}

export default GuardedRoute;
