import React, { useContext } from "react";
import { Box, Container, Typography } from "@material-ui/core";

import { ThemeContext } from "components/Theme";
import ScreenTitle from "../ScreenTitle";
import BorderedText from "components/BorderedText";
import ProgressButtonGroup from "../ProgressButtonGroup";
import {
  ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL,
  ACCOUNT_TYPE_CUSTODIAL_IRA,
  CHECKING_ACCOUNT_TYPE_LLC,
} from "../../../../constants";

const TrustBeneficiaryIntro = ({
  currentStep,
  previousStep,
  nextStep,
  goToStep,
  llcWhollyOwnedByIra,
  accountType,
  checkingAccountType,
  shouldNameBeneficiary,
  setFieldValue,
  handleSave,
}) => {
  const copy = useContext(ThemeContext).copy?.form?.beneficiaryIntro;

  const handlePreviousStep = () => {
    if (accountType === ACCOUNT_TYPE_CUSTODIAL_IRA) {
      return goToStep(currentStep - 2);
    }
    if (
      accountType !== ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL &&
      checkingAccountType === CHECKING_ACCOUNT_TYPE_LLC &&
      llcWhollyOwnedByIra
    ) {
      goToStep(currentStep - 2);
    } else {
      previousStep();
    }
  };

  const handleNextStep = () => {
    if (handleSave) {
      handleSave();
    }

    nextStep();
  };

  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle title={copy?.heading1 || "Beneficiary Information"} />
        <Box pt={30} pb={30}>
          <Typography>
            {copy?.body1 ||
              "The next step will be to designate up to three beneficiaries who will inherit your IRA at the time of your death. A beneficiary can be an individual or an estate planning trust."}
          </Typography>
        </Box>
        <Box pb={30}>
          <Typography>
            {copy?.body2 ||
              "Please consult with your licensed estate planning counsel for questions about appropriate IRA beneficiary designations."}
          </Typography>
        </Box>
        <Box pb={30}>
          <BorderedText>
            {copy?.body3 ||
              "If you need to add more beneficiaries or desire to change beneficiary designations in the future, you can do that via a form available from Solera Bank."}
          </BorderedText>
        </Box>

        <ProgressButtonGroup
          left={{
            clickHandler: handlePreviousStep,
            pageCalling: "Back Click: Trust Beneficiary Intro Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: Trust Beneficiary Intro Page",
            disabled: false,
          }}
        />
      </Container>
    </Box>
  );
};

export default TrustBeneficiaryIntro;
