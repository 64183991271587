import React, { useContext, useState } from "react";
import { ThemeContext } from "components/Theme";
import { AuthContext } from "components/Auth";
import {
  Box,
  TextField,
  Typography,
  Container,
  Button,
  Link as MUILink,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import Header from "components/Header";
import Layout from "components/Layout";
import { emailValidator } from "util/validation";

const useStyles = makeStyles((theme) => ({
  spinnerDisabled: {
    color: "transparent",
  },
  spinner: {
    color: theme.palette.primary.main,
  },
}));

const ForgotPassword = () => {
  const {
    body1 = "Enter the email you used to register with us.",
    heading1 = "Forgot Password",
  } = useContext(ThemeContext)?.copy?.forgotPassword || {};
  const { handlePasswordReset } = useContext(AuthContext);
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [emailInputError, setEmailInputError] = useState(null);
  const [resetSent, setResetSent] = useState(false);
  const [resetError, setResetError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function handleEmailChange(e) {
    e.persist();
    setEmail(e.target.value);
    validateEmail(e.target.value);
  }

  async function validateEmail(email) {
    const errors = await emailValidator({ email });
    if (errors) {
      return setEmailInputError(
        (Array.isArray(errors) && errors[0]) || "Invalid input"
      );
    }
    return setEmailInputError(errors);
  }

  function resetPassword() {
    setIsLoading(true);
    setResetError(null);
    setResetSent(false);
    handlePasswordReset(email, (err) => {
      if (err) {
        if (err.code === "auth/user-not-found") {
          setResetError(
            "Sorry we couldn't find a user in our system with that email address. Please check the input and try again."
          );
        } else {
          setResetError(err.message);
        }
      } else {
        setResetSent(true);
      }
      setIsLoading(false);
    });
  }

  return (
    <Box>
      <Header />
      <Layout>
        <Container maxWidth="sm">
          <Box mt={64}>
            <Typography variant="h2" style={{ fontSize: 25 }}>
              {heading1}
            </Typography>
          </Box>
          <Box mb={24} mt={16}>
            <Typography variant="body1">{body1}</Typography>
          </Box>
          <Box>
            <TextField
              id="email"
              name="email"
              required
              type="email"
              value={email || ""}
              onChange={handleEmailChange}
              fullWidth
              placeholder="Enter your email address"
              error={!!emailInputError}
              helperText={!!emailInputError ? emailInputError : ""}
            />
          </Box>
          <Box pt={60} textAlign="center">
            <Button
              size="large"
              variant="contained"
              color="primary"
              disabled={!email || !!emailInputError || isLoading}
              onClick={resetPassword}
              endIcon={
                <CircularProgress
                  size={10}
                  className={
                    isLoading ? classes.spinner : classes.spinnerDisabled
                  }
                />
              }
            >
              Reset Password
            </Button>
          </Box>
          <Box pt={50}>
            {resetSent && (
              <Typography variant="body1">
                Your request has been sent. You should receive an email from{" "}
                <b>noreply@solera-app.firebase.com</b> with password reset
                instructions shortly.
              </Typography>
            )}
            {resetError && (
              <Typography variant="body1" color="error">
                {resetError}
              </Typography>
            )}
          </Box>
          <Box pt={35} textAlign="center">
            <Typography variant="body2">
              <Box component="span">
                <MUILink
                  to="/register?login=true"
                  component={Link}
                  style={{ textDecoration: "underline" }}
                >
                  Back to sign in
                </MUILink>{" "}
              </Box>
            </Typography>
          </Box>
        </Container>
      </Layout>
    </Box>
  );
};

export default ForgotPassword;
