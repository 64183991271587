import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Box,
  Input,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { isFunction } from "lodash";
import { AuthContext } from "components/Auth";
import { ThemeContext } from "components/Theme";
import IntroSlides from "components/IntroSlides";
import TextMaskPhoneNumber from "containers/IRAForm/components/TextMaskPhoneNumber";
import Form from "./components/Form";
import Confirmation from "./components/Confirmation";
import Header from "components/Header";
import { Loading } from "containers/Loading";
import Layout from "components/Layout";

import { ADDITIONAL_PARTICIPANT_STATE } from "util/variableStructure";
import { getAdditionalParticipant } from "util/handleAdditionalParticipant";
const useStyles = makeStyles((theme) => ({
  spinnerDisabled: {
    color: "transparent",
  },
  spinner: {
    color: theme.palette.primary.main,
  },
  form: {
    width: "100%",
  },
  formContainer: {
    maxWidth: "400px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "100px",
  },
}));

const AdditionalParticipant = () => {
  const { signOut } = useContext(AuthContext);
  const { copy = {} } = useContext(ThemeContext);
  const [initState, setInitState] = useState({
    isLoading: false,
    isVerified: false,
    isInit: false,
    hasCompletedIntro: false,
    hasInitFailed: false,
    hasVerifiedFailed: false,
  });

  const [phoneNumber, setPhoneNumber] = useState("");
  const [formData, initFormData] = useState(ADDITIONAL_PARTICIPANT_STATE);
  const [idToken, setToken] = useState(null);

  const classes = useStyles();

  function getParams() {
    const params = new URLSearchParams(window.location.search);
    const pri = params.get("pri");
    const add = params.get("add");
    return { pri, add };
  }

  useEffect(() => {
    function init() {
      if (initState.isInit) return;
      // 1. get url params
      const { pri, add } = getParams();
      // 2. if missing reject
      if (!pri || !add) {
        return setInitState((prev) => ({
          ...prev,
          isInit: true,
          hasInitFailed: true,
        }));
      }
      // 3. if present request phone number
      setInitState((prev) => ({
        ...prev,
        isInit: true,
      }));
    }
    init();
    return () => init(); // clean up
  }, [initState.isInit]);

  useEffect(() => {
    // signout of firebase authentication system, we use a different one for this part of the app
    // while this isn't technically necessary, it doesn help ensure we're not accidentally authenticating a different user
    console.log("signing out of main app");
    isFunction(signOut) && signOut();
  }, [signOut]);

  function cleanPhoneNumber() {
    return phoneNumber.replace(/\D/g, "");
  }

  function validatePhoneNumber() {
    const num = phoneNumber.replace(/\D/g, "");
    return num.length === 10;
  }

  async function verify(e) {
    e.preventDefault();
    const tel = cleanPhoneNumber();
    // 1. take phone number along with url params and send to endpoint
    // 2. if success display next steps for add part
    try {
      setInitState((prev) => ({
        ...prev,
        isLoading: true,
        hasVerifiedFailed: false,
      }));

      const { pri, add } = getParams();
      const { data, token } = await getAdditionalParticipant({
        primaryId: decodeURIComponent(pri),
        additionalId: decodeURIComponent(add),
        phoneNumber: tel,
      });
      setToken(token);

      initFormData((prev) => {
        return {
          ...prev,
          ...data,
        };
      });

      setInitState((prev) => ({
        ...prev,
        isLoading: false,
        hasVerifiedFailed: false,
        isVerified: true,
      }));
    } catch (error) {
      setInitState((prev) => ({
        ...prev,
        isLoading: false,
        hasVerifiedFailed: true,
        isVerified: false,
      }));
    }
  }

  function handleCompleteIntro() {
    setInitState((prev) => ({
      ...prev,
      hasCompletedIntro: true,
    }));
  }

  function handlePhoneNumber(e) {
    e.persist();
    setPhoneNumber(e.target.value);
  }

  return (
    <Box>
      <Header step={1} />
      {initState.hasInitFailed ? (
        <Container maxWidth="sm">
          <h1>Failed</h1>
        </Container>
      ) : initState.isInit ? (
        initState.isVerified ? (
          formData.additionalParticipantSubmittedAt ? (
            <Confirmation {...formData} />
          ) : (
            <Form data={formData} idToken={idToken} />
          )
        ) : initState.hasCompletedIntro ? (
          <Container maxWidth="sm">
            <Layout>
              <form onSubmit={verify} className={classes.form}>
                <Box className={classes.formContainer}>
                  <Input
                    id="phoneNumber"
                    name="phoneNumber"
                    value={phoneNumber}
                    required
                    fullWidth
                    onChange={handlePhoneNumber}
                    placeholder="Please confirm your phone number"
                    inputComponent={TextMaskPhoneNumber}
                  />
                  <Box mt={25}>
                    <Button
                      disabled={!validatePhoneNumber()}
                      size="medium"
                      variant="outlined"
                      fullWidth
                      type="submit"
                      color="primary"
                      endIcon={
                        <CircularProgress
                          size={10}
                          className={
                            initState.isLoading
                              ? classes.spinner
                              : classes.spinnerDisabled
                          }
                        />
                      }
                    >
                      Confirm
                    </Button>
                  </Box>

                  {initState.hasVerifiedFailed && (
                    <Box mt={20}>
                      <Typography color={"error"} variant="body2">
                        Sorry, we couldn't confirm your details. Please check
                        the phone number you entered and try again.
                      </Typography>
                    </Box>
                  )}
                </Box>
              </form>
            </Layout>
          </Container>
        ) : (
          <IntroSlides
            additionalParticipantClickHandler={handleCompleteIntro}
            copy={copy?.additionalParticipant?.home || {}}
          />
        )
      ) : (
        <Container maxWidth="sm">
          <Loading />
        </Container>
      )}
    </Box>
  );
};

export default AdditionalParticipant;
