import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  FormControl,
  Input,
  CircularProgress,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";

import { QuestionSection, Question } from "../Question";
import TextMaskSSN from "../TextMaskSSN";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";

const QuestionSSN = ({
  questionText,
  showError,
  error,
  buttonOnClick,
  shouldShowSSN,
  readOnly,
  showEditIcon,
  required,
  isLoading,
  ...props
}) => {
  const useStyles = makeStyles({
    hideButton: {
      paddingRight: 10,
      cursor: "pointer",
    },
  });
  const classes = useStyles();
  return (
    <QuestionSection>
      <Question text={questionText} required={required} />
      <FormControl fullWidth>
        <Input
          {...props}
          readOnly={readOnly}
          inputComponent={TextMaskSSN}
          endAdornment={
            <Box display="flex">
              <Box className={classes.hideButton} onClick={buttonOnClick}>
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : shouldShowSSN ? (
                  <VisibilityOffIcon />
                ) : (
                  <VisibilityIcon />
                )}
              </Box>
              {showEditIcon && <Box>{!readOnly && <CreateIcon />}</Box>}
            </Box>
          }
        />
        {showError && (
          <FormHelperText error={showError}>{error}</FormHelperText>
        )}
      </FormControl>
    </QuestionSection>
  );
};

QuestionSSN.defaultProps = {
  showEditIcon: true,
};

QuestionSSN.propTypes = {
  questionText: PropTypes.string,
  showError: PropTypes.bool,
  error: PropTypes.string,
  buttonOnClick: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  type: PropTypes.string,
  shouldShowSSN: PropTypes.bool,
  readOnly: PropTypes.bool,
  showEditIcon: PropTypes.bool,
};

export default QuestionSSN;
