import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import { throttle, isEmpty } from "lodash";
import config from "../../config/config";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const placesService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

function GoogleMaps({ label, onSelectAddress, initialValue }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    // if not init, init
    if (!document.querySelector("#google-maps")) {
      const apiKey = config.firebase.apiKey;
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
      placesService.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue, types: ["address"] }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  React.useEffect(() => {
    function getDetails(place_id) {
      placesService.current.getDetails(
        { placeId: place_id },
        ({ address_components: addressArr }) => {
          if (isEmpty(addressArr)) return;
          const addressObj = resolveAddressObj(addressArr);
          addressObj.value = value;
          onSelectAddress(addressObj);
        }
      );
    }
    if (!value) return;
    if (!onSelectAddress) return;
    if (!placesService || !placesService.current) {
      return;
    }
    getDetails(value.place_id);
  }, [value, onSelectAddress]);

  function resolveAddressObj(arr) {
    const obj = {};
    function resolveAddressComponent(compType, shortName) {
      let compObj = arr.find((item) => {
        return Array.isArray(item.types) && item.types[0] === compType;
      });
      if (!compObj) return "";
      const type = shortName ? compObj.short_name : compObj.long_name;
      return type;
    }
    obj.zipCode = resolveAddressComponent("postal_code");
    obj.state = resolveAddressComponent("administrative_area_level_1", true);
    obj.city = resolveAddressComponent("locality");
    obj.country = resolveAddressComponent("country", true);
    const num = resolveAddressComponent("street_number");
    const street = resolveAddressComponent("route");
    obj.address = `${num} ${street}`;
    return obj;
  }

  return (
    <Autocomplete
      id="field1"
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value || initialValue}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          fullWidth
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password",
          }}
        />
      )}
      renderOption={(option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );
        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}

export default function MemoizedAutocomplete({
  label = "Start typing to search and auto-complete below...",
  onSelectAddress,
  initialValue = null,
}) {
  return React.useMemo(() => {
    return (
      <GoogleMaps
        label={label}
        onSelectAddress={onSelectAddress}
        initialValue={initialValue}
      />
    );
  }, [label, onSelectAddress, initialValue]);
}
