import moment from "moment";
import { useCallback, useRef, useEffect } from "react";
import debounce from "lodash/debounce";
import transform from "lodash/transform";
import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";
import { fileUploadValidator, imageUploadValidator } from "./validation";

// import rollbar from "config/rollbar";
import * as Sentry from "@sentry/react";
import firebase from "../config/firebase";

export function maskSsn(ssn) {
  if (!ssn) return "";
  const arr = ssn.toString().replace(/\D/g, "").trim().split("");
  arr.splice(3, 0, "-");
  arr.splice(6, 0, "-");
  return arr.join("");
}

export function scroll(top = 0, left = 0, behavior = "smooth") {
  return window.scrollTo({
    top,
    left,
    behavior,
  });
}

export function deepDiff(object, base) {
  function changes(object, base) {
    return transform(object, function (result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
}

export function createAbortController() {
  let abortController = null;
  let signal = null;
  try {
    abortController = new AbortController();
    signal = abortController.signal;
  } catch (e) {}
  return { abortController, signal };
}

export function useIsMountedRef() {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);
  return isMounted;
}

export function today(format = "MM/DD/YYYY") {
  return moment().format(format);
}

export async function addFile({
  uploadEvent,
  field,
  setFieldHandler,
  successCallback,
  errorCallback,
  fileType = "file",
  file,
}) {
  try {
    if (!uploadEvent) throw Error("no upload event found");
    if (!file) {
      file =
        uploadEvent.currentTarget &&
        uploadEvent.currentTarget.files &&
        uploadEvent.currentTarget.files[0];
    }

    if (file) {
      const fileError =
        fileType === "greenCard"
          ? await imageUploadValidator({ file })
          : await fileUploadValidator({ file });

      if (Array.isArray(fileError) && !!fileError.length) {
        setFieldHandler(field, null);
        successCallback({});
        return errorCallback(fileError[0]);
      }
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        successCallback({
          src: readerEvent.target.result,
          name: file.name,
          type: file.type,
        });
      };
      reader.readAsDataURL(file);
      errorCallback(null);
      setFieldHandler(field, file);
    } else {
      // rollbar.warning("no file found in upload function", uploadEvent);
      Sentry.captureMessage("no file found in upload function", {
        extra: { uploadEvent },
        level: Sentry.Severity.Warning,
      });
    }
  } catch (error) {
    console.error("add file", error);
    // rollbar.error(error, "add file");
    Sentry.captureException(error);
  }
}

export function getFileExtension(file) {
  return file.name.split(".")[1];
}

export async function getFile(path) {
  const storageRef = firebase.storage().ref();
  if (!path) return null;
  try {
    const url = await storageRef.child(path).getDownloadURL();
    return url;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function uploadFile(path, file, metadata) {
  const storageRef = firebase.storage().ref();
  if (!path || !file) return null;
  try {
    // take rel path to create full ref , eg "/applications/[:id]/trust/plan_adoption.pdf"
    let snap;
    if (metadata) {
      snap = await storageRef.child(path).put(file, metadata);
    }
    snap = await storageRef.child(path).put(file);
    return snap.ref.fullPath;
  } catch (error) {
    console.error("err upload", error);
  }
}

export async function handleFileUpload(file, path, fileName, metadata) {
  // upload file to cloud storage and save ref to cloud firestore (in this case for the applications collection
  const fullPath = `${path}/${fileName}`;
  const ref = await uploadFile(fullPath, file, metadata);
  return ref;
}

export const updateApplicationProgress = (values, step) => {
  let tempValues = { ...values };
  // Check whether user has saved their name, if so update isApplicationStarted to true
  if (
    tempValues.firstName &&
    tempValues.lastName &&
    !tempValues.isApplicationStarted
  ) {
    tempValues = { ...tempValues, isApplicationStarted: true };
  }

  const progressStage =
    tempValues.progressStage < step ? step : tempValues.progressStage;
  const lastEditedStep = step > 1 ? step : tempValues.lastEditedStep;

  return {
    ...tempValues,
    progressStage,
    lastEditedStep,
  };
};

export function cleanBeneficiaries(beneficiaries) {
  return beneficiaries.map((item) => {
    let newItem = { ...item };
    if (newItem.entityType === "person") {
      newItem.taxIdRef = "";
    } else {
      newItem.ssnRef = "";
      newItem.gender = "";
      newItem.relationshipToAccountHolder = "";
    }
    switch (newItem.preferredContactMethod) {
      case "email":
        newItem.phoneNumber = "";
        newItem.mailingAddressLine1 = "";
        newItem.mailingAddressApt = "";
        newItem.mailingAddressCity = "";
        newItem.mailingAddressPostcode = "";
        newItem.mailingAddressState = "";
        newItem.mailingAddressCountry = "";
        break;
      case "phone":
        newItem.email = "";
        newItem.mailingAddressLine1 = "";
        newItem.mailingAddressApt = "";
        newItem.mailingAddressCity = "";
        newItem.mailingAddressPostcode = "";
        newItem.mailingAddressState = "";
        newItem.mailingAddressCountry = "";
        break;
      default:
        newItem.email = "";
        newItem.phoneNumber = "";
    }
    return newItem;
  });
}

export function handleFieldLogic(name, value, callback) {
  const data = {};
  if (name === "usaCitizen") {
    // set countryCitizenship && usaPermanentResident
    if (value === true || value === "true") {
      data.countryCitizenship = "US";
      data.hasUSAddress = true;
    } else {
      data.countryCitizenship = "";
      data.hasUSAddress = true;
    }
    data.usaPermanentResident = true;
    data.hasForeignAddress = false;
    data.foreignAddressLine1 = "";
    data.foreignAddressApt = "";
    data.foreignAddressCity = "";
    data.foreignAddressState = "";
    data.foreignAddressPostcode = "";
    data.foreignAddressCountry = "";
  }
  if (name === "usaPermanentResident") {
    if (value === false || value === "false") {
      data.hasUSAddress = true;
    }
  }
  if (name === "foreignAddressLine1" || name === "foreignAddressCountry") {
    if (!!value) {
      data.hasForeignAddress = true;
    }
  }
  callback(data);
}

/**
 * Custom Hook to handle debounce function
 */
export function useDebounce(callback, delay) {
  //eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(debounce(callback, delay), []);
}

export const investments = [
  "Real estate - rental",
  "Real estate - Flip",
  "Precious metals",
  "Private equity/companies",
  "Stocks/Bonds/Securities",
  "Cryptocurrency",
  "Commodities",
  "Purchase Notes",
  "Tax Liens",
];

export const preQualificationText =
  "Before we can setup your account, we need to confirm a few things. Please review the individual items below to continue to the application.";
export const sanctionedCountriesText =
  "Solera does not accept investors that also invest in countries sanctioned by the US. I confirm that I do not invest in sanctioned countries by the US";
export const accreditedInvestorsText =
  "At this time, Solera only accepts accredited investors. By checking this box, I confirm that I am an accredited investor.";
export const confirmText =
  "By checking this box, I confirm that all information provided above is true to my knowledge.";

export function mapIndustriesToOptions() {
  return Object.keys(industries).map((key) => ({
    value: industries[key],
    name: key,
  }));
}

export function mapStatesToOptions() {
  return Object.keys(USStates).map((key) => ({
    value: key,
    name: USStates[key],
  }));
}

export function mapCountriesToOptions() {
  return countries.map(({ name, code }) => ({
    value: code,
    name,
  }));
}

export const industries = {
  "Accommodation and Food Services": 722310,
  "Accounting & Legal": 541200,
  "Agriculture, Forestry, Fishing and Hunting": 111110,
  "Airlines/Aviation": 488190,
  "Architecture & Design": 541310,
  "Arts, Entertainment, and Recreation": 711110,
  Automotive: 811111,
  "Business Services": 561499,
  Cannabis: 111998,
  "Construction, Repair & Maintenance": 811310,
  "Educational Services": 611710,
  Engineering: 541330,
  "Environmental Services": 541620,
  "Financial Services": 523930,
  Insurance: 524210,
  "Health Care and Social Assistance": 621111,
  "Information Technology and Web Development": 541512,
  "Law Enforcement": 922120,
  "Management Consulting": 541611,
  Manufacturing: 322220,
  "Media (inc Marketing and Advertising)": 541840,
  Military: 928110,
  "Non-Profit Organization Management": 813219,
  "Oil & Energy": 213112,
  "Professional, Scientific, and Technical Services": 541690,
  "Public Administration": 921110,
  "Real Estate and Rental and Leasing": 531390,
  "Retail and Ecommerce": 452990,
  "Transportation and Warehousing": 485999,
  Utilities: 221118,
  "Waste Management and Remediation Services": 562998,
  "Wholesale Trade": 423990,
};

export const USStates = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "Andorra", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];
