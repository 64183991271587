import React, { memo, useState, useEffect } from "react";
import proptypes from "prop-types";
import { makeStyles } from "@material-ui/core";

import DocumentSigningLoading from "../DocumentSigningLoading";

const useStyles = makeStyles({
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "85%",
    border: "none",
    visibility: (props) => (props.isLoading ? "hidden" : "visible"),
  },
});

const DocumentSigningEmbed = ({
  url = "",
  onDocumentCompleted,
  isPreparingForDownload = false,
}) => {
  const [isLoading, setLoading] = useState(true);
  const classes = useStyles({ isLoading });
  const onMessage = (event) => {
    if (event && event.data) {
      try {
        var message = JSON.parse(event.data);
        if (message && message.event) {
          if (message.event === "session_view.document.loaded") {
            console.log("the document has been loaded");
            setLoading(false);
          }
          if (message.event === "session_view.document.exception") {
            console.log("an error has occurred with the document");
          }
          if (message.event === "session_view.document.completed") {
            console.log("the document has completed");
            onDocumentCompleted();
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", onMessage, false);
    return () => {
      window.removeEventListener("message", onMessage, false);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <iframe className={classes.iframe} src={url} title="Public View"></iframe>
      {isLoading && (
        <DocumentSigningLoading isCompleting={isPreparingForDownload} />
      )}
    </>
  );
};

DocumentSigningEmbed.propTypes = {
  onDocumentCompleted: proptypes.func,
  url: proptypes.string,
};

export default memo(DocumentSigningEmbed);
