import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  Typography,
  Input,
  Grid,
  TextField,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import TermsCheckbox from "../TermsCheckbox";
import LogInstead from "../LogInstead";
import ForgotPassword from "../ForgotPassword";
import { ThemeContext } from "components/Theme";

export default function EmailPassword({
  classes,
  screenType,
  agreeTerms,
  agreeTerms2,
  email,
  emailError,
  password,
  passwordError,
  setModalOpen,
  handleCheckboxChange,
  handleCheckboxChange2,
  handleEmailChange,
  handlePasswordChange,
  validateEmail,
  signup,
  login,
  loading,
  errorMessage,
}) {
  const [displayPassword, setDisplayPassword] = useState(false);

  const { copy = {}, themeName = "solera" } = useContext(ThemeContext) || {};
  const {
    body3:
      bodyNoAuth = "Enter your email, and we'll send you a link to confirm it's you. Click the provided link in the email to sign into your application.",
    body4:
      bodyLoginNoAuth = "Enter your email and password to sign in to your application.",
  } = copy?.register || {};
  const { companyName = "", termsLink = "" } = copy.contactInformation || {};

  const isButtonDisabled =
    !agreeTerms ||
    !agreeTerms2 ||
    !email ||
    !password ||
    !!emailError ||
    !!passwordError ||
    loading;
  return (
    <Box mt={64}>
      <Box>
        <Typography variant="h2">
          {screenType === "signup" ? "Sign up" : "Log in"}
        </Typography>
      </Box>

      <Box mt={16} mb={24}>
        <Typography variant="body1">
          {screenType === "signup" ? bodyNoAuth : bodyLoginNoAuth}
        </Typography>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            id="email"
            name="email"
            required
            type="email"
            value={email || ""}
            error={!!emailError}
            onBlur={validateEmail}
            helperText={!!emailError ? emailError : ""}
            onChange={handleEmailChange}
            fullWidth
            placeholder="Enter your email address"
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box mt={30}>
            <Input
              id="password"
              name="password"
              required
              type={displayPassword ? "text" : "password"}
              value={password || ""}
              error={!!passwordError}
              onChange={handlePasswordChange}
              fullWidth
              placeholder="Enter your password"
              endAdornment={
                <Box display="flex">
                  <Box
                    className={classes.hideButton}
                    onClick={() => setDisplayPassword(!displayPassword)}
                  >
                    {displayPassword ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </Box>
                </Box>
              }
            />
            <Box height={20}>
              <FormHelperText error={!!passwordError}>
                {passwordError}
              </FormHelperText>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box mt={42}>
        <TermsCheckbox
          classes={classes}
          handleCheckboxChange={handleCheckboxChange}
          handleCheckboxChange2={handleCheckboxChange2}
          agreeTerms={agreeTerms}
          agreeTerms2={agreeTerms2}
          setModalOpen={setModalOpen}
          themeName={themeName}
          companyName={companyName}
          termsLink={termsLink}
        />
      </Box>
      <Box
        mt={78}
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        <Button
          size="large"
          variant="contained"
          color="primary"
          disabled={isButtonDisabled}
          onClick={screenType === "signup" ? signup : login}
        >
          {screenType === "signup" ? "Register" : "Sign in"}
        </Button>
        {loading && (
          <CircularProgress
            size={24}
            color="primary"
            className={classes.buttonProgress}
          />
        )}
      </Box>
      {!!errorMessage && (
        <Box mt={36} textAlign="center">
          <Typography variant="body2" color="error">
            {errorMessage}
          </Typography>
        </Box>
      )}
      {screenType === "signup" ? null : (
        <Box pt={35} textAlign="center">
          <ForgotPassword classes={classes} />
        </Box>
      )}

      <Box pt={screenType === "signup" ? 55 : 25} textAlign="center">
        <LogInstead classes={classes} screenType={screenType} />
      </Box>
    </Box>
  );
}
