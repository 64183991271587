import React, { useContext } from "react";
import { AppBar, makeStyles, Box, Typography } from "@material-ui/core";

import config from "config/config";
import { ThemeContext } from "components/Theme";

const useStyles = makeStyles((theme) => ({
  foot: {
    height: 60,
    color: theme.palette.footerText?.main || theme.palette.text.primary,
    backgroundColor: theme.palette.footer.main,
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontSize: 10,
    [theme.breakpoints.up("sm")]: {
      fontSize: 14,
    },
    paddingRight: 6,
  },
  logoWrap: {
    opacity: 0.5,
  },
  power: {
    fontSize: 8,
    marginTop: 1,
    [theme.breakpoints.up("sm")]: {
      fontSize: 10,
    },
    paddingRight: 4,
  },
}));

const content = {
  title: "IRA Application",
  power: "POWERED BY",
};

const Footer = () => {
  const { companyName = "" } =
    useContext(ThemeContext)?.copy?.contactInformation || {};
  const classes = useStyles();

  return (
    <AppBar
      position="relative"
      component="footer"
      className={classes.foot}
      elevation={0}
      data-component="Footer"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={16}
      >
        <Box display="flex" alignItems="center">
          <Typography className={classes.title}>
            {`${companyName} ${content.title}`}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Box className={classes.logoWrap}>
            <Typography variant="caption">v{config.version}</Typography>
          </Box>
        </Box>
      </Box>
    </AppBar>
  );
};

export default Footer;
