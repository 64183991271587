export function recursiveReplaceStr(objSource, currPattern, newPattern) {
  if (typeof objSource === "string") {
    return objSource.replace(currPattern, newPattern);
  }

  if (typeof objSource === "object") {
    if (objSource === null) {
      return null;
    }

    // dig in deeper
    Object.keys(objSource).forEach((property) => {
      objSource[property] = recursiveReplaceStr(
        objSource[property],
        currPattern,
        newPattern
      );
    });

    return objSource;
  }
}
