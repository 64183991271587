import React, { useContext } from "react";
import { Box, Container, Typography } from "@material-ui/core";
import { CheckCircle as CheckCircleIcon } from "@material-ui/icons";
import copy from "../../copy.json";
import { ThemeContext } from "components/Theme";

export default function Success({ classes, screenType }) {
  const {
    body1 = `We have successfully created an account with your email. You are ready to begin your application.`,
    body2 = `We have successfully verified your account with your email. You are ready to continue your application.`,
  } = useContext(ThemeContext)?.copy?.register || {};
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Box
        className={classes.iconOuter}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CheckCircleIcon className={classes.successIcon} />
      </Box>
      <Box textAlign="center" mt={32}>
        <Typography variant="h2">{copy.headerAuth}</Typography>
      </Box>
      <Container maxWidth="xs">
        <Box textAlign="center" mt={16}>
          <Typography variant="body1">
            {screenType === "signup" ? body1 : body2}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
