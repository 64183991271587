import React, { useState, useRef } from "react";
import { Select, Input, Button, Box } from "@material-ui/core";

function SelectMultiple({ value, options, handleChange, renderValue, name }) {
  const closeButton = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (e) => {
    if (e) {
      e.preventDefault();
    }
    setOpen(false);
  };

  const onChange = (e, child) => {
    if (child?.ref?.current?.id === `button-close-${name}`) return;
    handleChange(e);
  };
  return (
    <>
      <Select
        multiple
        displayEmpty
        value={value}
        onChange={onChange}
        input={<Input />}
        renderValue={renderValue}
        inputProps={{ "aria-label": "Without label", onClick: handleOpen }}
        fullWidth
        MenuProps={{ variant: "menu" }}
        open={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        <Box
          ref={closeButton}
          id={`button-close-${name}`}
          justifyContent="flex-end"
          display="flex"
          pr={10}
        >
          <Button size="small" disableFocusRipple onClick={handleClose}>
            Close
          </Button>
        </Box>

        {options}
      </Select>
    </>
  );
}

export default SelectMultiple;
