import React from "react";
import { Box, Typography, Container } from "@material-ui/core";
import Header from "components/Header";
import Layout from "components/Layout";

const OldTab = () => {
  return (
    <>
      <Header />
      <Container maxWidth="sm">
        <Layout>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            flex={1}
          >
            <Box>
              <Typography variant="h6">
                <b>This tab is no longer active.</b>
              </Typography>
            </Box>
            <Box mt={15}>
              <Typography variant="h6">
                Feel free to close this one and continue your application in the
                new tab.
              </Typography>
            </Box>
          </Box>
        </Layout>
      </Container>
    </>
  );
};

export default OldTab;
