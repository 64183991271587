import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { GuardProvider } from "components/GuardProvider";
import GuardedRoute from "components/GuardedRoute";
import { GRATIS_THEME_NAME, SOLERA_THEME_NAME } from "./constants";
import imagePaths from "util/resolveImagePath";

import ErrorBoundary from "components/ErrorBoundary";
import Loading from "containers/Loading";
import AccountType from "containers/AccountType";
import IRAForm from "containers/IRAForm";
import AppIntro from "containers/AppIntro";
import Register from "containers/Register";
import AdditionalParticipant from "containers/AdditionalParticipant";
import OldTab from "containers/OldTab";
import ForgotPassword from "containers/ForgotPassword";
import { ThemeContext } from "components/Theme";

import Footer from "components/Footer";

// import "./App.scss";

//////////////////////////////////////
function App() {
  const { themeName = "" } = useContext(ThemeContext) || {};
  const { companyName = "" } =
    useContext(ThemeContext)?.copy?.contactInformation || {};

  let welcomeUrl = `/welcome-to-${themeName}`;
  welcomeUrl += themeName === SOLERA_THEME_NAME ? "-banking" : "";

  // Dynamic favicon and <title> based on theme
  useEffect(() => {
    function setDynamicMeta() {
      if (document) {
        const favicon = document.getElementById("favicon");
        const imgPath = imagePaths(themeName).favicon;
        if (favicon && imgPath) favicon.href = imgPath;
        switch (themeName) {
          case GRATIS_THEME_NAME:
            document.title = `${companyName} - Free IRA Application`;
            break;
          case SOLERA_THEME_NAME:
            document.title = `${companyName} App - New Applications`;
            break;
          default:
            // Default title tag is in index.html <title>
            break;
        }
      }
    }
    setDynamicMeta();
  }, [themeName, companyName]);

  return (
    <>
      <ErrorBoundary>
        <Router>
          <div className="App">
            <GuardProvider>
              <Switch>
                <Route exact path="/" component={Loading} />
                <Route path={welcomeUrl} component={AppIntro} />
                <GuardedRoute exact path="/account-setup">
                  <AccountType />
                </GuardedRoute>
                <GuardedRoute exact path="/account-creation">
                  <IRAForm />
                </GuardedRoute>
                <Route path="/register" component={Register} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/tab-inactive" component={OldTab} />
              </Switch>
            </GuardProvider>
            <Switch>
              <Route
                exact
                path="/additional-participant"
                component={AdditionalParticipant}
              />
            </Switch>
            <Footer />
          </div>
        </Router>
      </ErrorBoundary>
    </>
  );
}

export default App;
