import React, { useState, useEffect, useContext } from "react";
import { Box, Container, Typography } from "@material-ui/core";

import ScreenTitle from "../ScreenTitle";
import ProgressButtonGroup from "../ProgressButtonGroup";
import QuestionSSN from "../QuestionSSN";
import { ThemeContext } from "components/Theme";

import { useIsMountedRef, createAbortController } from "util/helpers";
import { ID_SECURITY } from "../../../../constants";
import { getSSN, saveSSN } from "util/manageSSN";

import copy from "./copy.json";

const SecurityQuestions = ({
  previousStep,
  nextStep,
  currentStep,
  goToStep,
  ssnRef,
  usaCitizen,
  usaPermanentResident,
  completedSections,
  handleFormChange,
  errors,
  touched,
  setFieldTouched,
  setFieldValue,
  handleSave,
  addPartAuthToken,
}) => {
  const isMounted = useIsMountedRef();
  const [shouldShowSSN, setShowSSN] = useState(true);
  const [ssnValue, setSsnValue] = useState("");
  const [isLoading, setLoading] = useState(true);

  const { heading1 = copy.heading1, body1 = copy.body1 } =
    useContext(ThemeContext)?.copy?.form?.security || {};

  const handleSSNChange = async (e) => {
    e.persist();
    // Check for length of the value (9 numbers in SSN and 2 '-' masks), and also check for 'X' added as a part of hiding.
    if (e.target.value.length <= 11) {
      // This regex replaces every non digit value so that only number values are accepted and '-' is removed before saving it to field.
      const inputSSN = e.target.value.replace(/\D/g, "").trim();
      setSsnValue(inputSSN);
      if (inputSSN.length === 9) {
        setLoading(true);
        saveSSN({ ssn: inputSSN }, addPartAuthToken)
          .then((str) => setFieldValue("ssnRef", str))
          .catch((error) => console.error(error))
          .finally(() => setLoading(false));
      }

      if (inputSSN.length < 9) {
        setFieldValue("ssnRef", "");
      }
    }
  };

  useEffect(() => {
    const { abortController, signal } = createAbortController();
    async function fetchSSN() {
      if (ssnRef && !ssnValue) {
        isLoading && setLoading(true);
        const str = await getSSN(ssnRef, "ssnRef", signal, addPartAuthToken);
        str && setSsnValue(str);
      }
      isMounted.current && setLoading(false);
    }
    fetchSSN();
    return function cleanup() {
      abortController && abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssnRef]);

  const handlePreviousStep = () => {
    return !usaCitizen && usaPermanentResident
      ? previousStep()
      : goToStep(currentStep - 2);
  };

  const handleNextStep = () => {
    !completedSections.includes(ID_SECURITY) &&
      setFieldValue("completedSections", [...completedSections, ID_SECURITY]);
    if (handleSave) handleSave();
    return nextStep();
  };

  const resolveDisabled = () => {
    return !ssnValue || ssnValue.length !== 9 || !ssnRef;
  };

  return (
    <Box flex={1} pb={30}>
      <Container maxWidth="sm">
        <ScreenTitle title={`Security & Identity Verification`} />
        <Box mt={20}>
          <Typography>{heading1}</Typography>
        </Box>
        <Box mt={20}>
          <Typography>{body1}</Typography>
        </Box>
        <Box mt={30}>
          <QuestionSSN
            value={
              ssnValue === "" && !!ssnRef
                ? "000000000"
                : shouldShowSSN
                ? ssnValue
                : ssnValue.replace(/-/g, "").replace(/.(?=.{4,}$)/g, "X")
            }
            onChange={handleSSNChange}
            name={
              ssnValue === "" && !!ssnRef
                ? "hidden"
                : shouldShowSSN
                ? "ssnRef"
                : "concealed"
            }
            id="ssnRef"
            shouldShowSSN={shouldShowSSN}
            questionText="Your social security number"
            buttonOnClick={() =>
              setShowSSN((prevButtonState) => !prevButtonState)
            }
            required
            isLoading={isLoading}
            readOnly={isLoading || !shouldShowSSN}
            showEditIcon={false}
          />
        </Box>

        <ProgressButtonGroup
          left={{
            clickHandler: handlePreviousStep,
            pageCalling: "Back Click: Security Questions Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: Security Questions Page",
            disabled: resolveDisabled(),
          }}
          hasRequiredInputs
        />
      </Container>
    </Box>
  );
};

export default SecurityQuestions;
