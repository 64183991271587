import { QUERY_PARAM_REFERRER, LOCAL_STORAGE_REFERRER } from "../constants";

function getParams() {
  try {
    const query = window.location.search;
    const params = new URLSearchParams(query);
    return params;
  } catch (e) {
    return null;
  }
}

function getDeepLinkReferrer() {
  const params = getParams();
  if (!params) return;
  const referrer = params.get(QUERY_PARAM_REFERRER);
  if (referrer && LOCAL_STORAGE_REFERRER) {
    localStorage.setItem(LOCAL_STORAGE_REFERRER, referrer);
  }
}

export function saveDeepLinks() {
  getDeepLinkReferrer();
}
