import React, { useState, useEffect } from "react";
import { Container, Box, TextField } from "@material-ui/core";
import { isEmpty } from "lodash";

import { QuestionSection, Question } from "../Question";
import ScreenTitle from "../ScreenTitle";
import ProgressButtonGroup from "../ProgressButtonGroup";
import QuestionSSN from "../QuestionSSN";
import DateField from "../DateField";

import { getSSN, saveSSN } from "util/manageSSN";
import { ACCT_INVEST } from "../../../../constants";
import { singleInputValidator } from "util/validation";
import { useIsMountedRef, createAbortController } from "util/helpers";

import moment from "moment";

const InheritedIRA = ({
  previousStep,
  nextStep,
  currentStep,
  inheritedIraHolderFirstName,
  inheritedIraHolderMiddleName,
  inheritedIraHolderLastName,
  inheritedIraHolderDob,
  inheritedIraHolderDateOfDeath,
  inheritedIraHolderSsnRef,
  completedSections,
  handleFormChange,
  setFieldValue,
  setFieldTouched,
  errors,
  touched,
  addPartAuthToken,
}) => {
  const isMounted = useIsMountedRef();
  const [shouldShowSSN, setShowSSN] = useState(true);
  const [ssnValue, setSsnValue] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [dob, setDob] = useState("");
  const [dobError, setDobError] = useState({});
  const [dod, setDod] = useState("");
  const [dodError, setDodError] = useState({});
  const [disabledDate, setDisabledDate] = useState(true);

  useEffect(() => {
    const { abortController, signal } = createAbortController();
    async function fetchSSN() {
      if (inheritedIraHolderSsnRef && !ssnValue) {
        isLoading && setLoading(true);
        const str = await getSSN(
          inheritedIraHolderSsnRef,
          "inheritedIraHolderSsnRef",
          signal,
          addPartAuthToken
        );
        str && setSsnValue(str);
      }
      isMounted.current && setLoading(false);
    }
    fetchSSN();

    return function cleanup() {
      abortController && abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inheritedIraHolderSsnRef]);

  const handleChange = (e) => {
    e.persist();
    const { name, value } = e.target;
    let val = value.replace(/[^a-zA-Z\s:]/g, "");
    setFieldValue(name, val);
  };

  const handleBlur = (e) => {
    setFieldTouched(e.target.name, true, false);
  };

  const handleDobChange = (value) => {
    setDob(value);
    handleDateValidation(value, "inheritedIraHolderDob");
  };

  const handleDateOfDeathChange = (value) => {
    setDod(value);
    handleDateValidation(value, "inheritedIraHolderDateOfDeath");
  };

  const handleDateValidation = async (value, field) => {
    const obj =
      field === "inheritedIraHolderDob"
        ? { func: setDobError, localField: "dob" }
        : { func: setDodError, localField: "dod" };
    let error = await singleInputValidator(field, value);
    if (!error) {
      // ensure is not before dob

      const format = "MM/DD/YYYY";
      const momentDob = moment(
        field === "inheritedIraHolderDob" ? value : dob,
        format
      );
      const momentDod = moment(
        field === "inheritedIraHolderDob" ? dod : value,
        format
      );
      const check = momentDod.isBefore(momentDob, "day");

      if (check) {
        error = ["Date of death cannot be before date of birth"];
      } else {
        setDobError({ dob: null });
        setDodError({ dod: null });
      }
    }

    obj.func({ [obj.localField]: error });
    if (isEmpty(error)) {
      setDisabledDate(false);
    } else {
      setDisabledDate(true);
    }
  };

  const handleSSNChange = async (e) => {
    e.persist();
    // Check for length of the value (9 numbers in SSN and 2 '-' masks), and also check for 'X' added as a part of hiding.
    if (e.target.value.length <= 11) {
      // This regex replaces every non digit value so that only number values are accepted and '-' is removed before saving it to field.
      const inputSSN = e.target.value.replace(/\D/g, "").trim();

      setSsnValue(inputSSN);
      if (inputSSN.length === 9) {
        setLoading(true);
        saveSSN({ ssn: inputSSN }, addPartAuthToken)
          .then((str) => setFieldValue("inheritedIraHolderSsnRef", str))
          .catch((error) => console.error(error))
          .finally(() => setLoading(false));
      }

      if (inputSSN.length < 9) {
        setFieldValue("inheritedIraHolderSsnRef", "");
      }
    }
  };

  useEffect(() => {
    function setDates() {
      if (inheritedIraHolderDob && dob === "") {
        setDob(inheritedIraHolderDob);
      }
      if (inheritedIraHolderDateOfDeath && dod === "") {
        setDod(inheritedIraHolderDateOfDeath);
      }
      if (
        inheritedIraHolderDob &&
        inheritedIraHolderDateOfDeath &&
        dob === "" &&
        dod === ""
      ) {
        setDisabledDate(false);
      }
    }
    setDates();
    // eslint-disable-next-line
  }, [inheritedIraHolderDob, inheritedIraHolderDateOfDeath]);

  const resolveDisabled = () => {
    return (
      !inheritedIraHolderFirstName ||
      !inheritedIraHolderLastName ||
      disabledDate ||
      !inheritedIraHolderSsnRef ||
      !dob ||
      !dod
    );
  };

  const handleNextStep = () => {
    !completedSections.includes(ACCT_INVEST) &&
      setFieldValue("completedSections", [...completedSections, ACCT_INVEST]);
    setFieldValue("inheritedIraHolderDob", dob);
    setFieldValue("inheritedIraHolderDateOfDeath", dod);

    return nextStep();
  };

  return (
    <Box flex={1}>
      <Container maxWidth="sm">
        <ScreenTitle
          step={currentStep}
          title="Deceased IRA Owner's Personal Information"
        />
        <QuestionSection>
          <Question text="First name" required />
          <TextField
            id="inheritedIraHolderFirstName"
            name="inheritedIraHolderFirstName"
            value={inheritedIraHolderFirstName}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            required
            helperText={touched.firstName ? errors.firstName : ""}
            error={touched.firstName && Boolean(errors.firstName)}
            placeholder="Enter first name"
          />
        </QuestionSection>
        <QuestionSection>
          <Question text="Middle name" />
          <TextField
            id="inheritedIraHolderMiddleName"
            name="inheritedIraHolderMiddleName"
            value={inheritedIraHolderMiddleName}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            required
            helperText={touched.middleName ? errors.middleName : ""}
            error={touched.middleName && Boolean(errors.middleName)}
            placeholder="Optional"
          />
        </QuestionSection>
        <QuestionSection>
          <Question text="Last name" required />
          <TextField
            id="inheritedIraHolderLastName"
            name="inheritedIraHolderLastName"
            value={inheritedIraHolderLastName}
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            required
            helperText={touched.lastName ? errors.lastName : ""}
            error={touched.lastName && Boolean(errors.lastName)}
            placeholder="Enter last name"
          />
        </QuestionSection>
        <QuestionSSN
          value={
            ssnValue === "" && !!inheritedIraHolderSsnRef
              ? "000000000"
              : shouldShowSSN
              ? ssnValue
              : ssnValue.replace(/-/g, "").replace(/.(?=.{4,}$)/g, "X")
          }
          onChange={handleSSNChange}
          name={
            ssnValue === "" && !!inheritedIraHolderSsnRef
              ? "hidden"
              : shouldShowSSN
              ? "inheritedIraHolderSsnRef"
              : "concealed"
          }
          id="inheritedIraHolderSsnRef"
          shouldShowSSN={shouldShowSSN}
          readOnly={isLoading || !shouldShowSSN}
          questionText="IRA holder's Social Security Number"
          buttonOnClick={() =>
            setShowSSN((prevButtonState) => !prevButtonState)
          }
          required
          isLoading={isLoading}
          showEditIcon={false}
        />
        <QuestionSection>
          <Question text="IRA holder's date of birth" required />
          <DateField
            value={dob}
            fieldValue="dob"
            onChange={handleDobChange}
            errors={dobError || {}}
            touched={{ dob: true }}
          />
        </QuestionSection>
        <QuestionSection>
          <Question text="IRA holder's date of death" required />
          <DateField
            value={dod}
            fieldValue="dod"
            onChange={handleDateOfDeathChange}
            errors={dodError || {}}
            touched={{ dod: true }}
          />
        </QuestionSection>
        <ProgressButtonGroup
          left={{
            clickHandler: previousStep,
            pageCalling: "Back Click: InheritedIRA Page",
          }}
          right={{
            clickHandler: handleNextStep,
            pageCalling: "Success: InheritedIRA Page",
            disabled: resolveDisabled(),
          }}
          hasRequiredInputs
        />
      </Container>
    </Box>
  );
};

export default InheritedIRA;
