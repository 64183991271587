import {
  CONTACT_INFO,
  PERSON_INFO,
  ADD_PARTICIPANT,
  ACCT_INVEST,
  LLC,
  TRUST,
  BENEFICIARY,
  ID_SECURITY,
  REVIEW_SUBMIT,
  REVIEW,
  TRUST_CREATION,
  TRI_PARTY_AGREEMENT,
  GRATIS_THEME_NAME,
  ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL,
  ACCOUNT_TYPE_CUSTODIAL_IRA,
  CHECKING_ACCOUNT_TYPE_LLC,
} from "../constants";

export function sections({ accountType, checkingAccountType, providerName }) {
  let sectionsList = [];

  sectionsList.push(CONTACT_INFO);
  sectionsList.push(PERSON_INFO);
  if (
    ![
      ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL,
      ACCOUNT_TYPE_CUSTODIAL_IRA,
    ].includes(accountType)
  ) {
    sectionsList.push(ADD_PARTICIPANT);
  }
  if (accountType !== ACCOUNT_TYPE_CUSTODIAL_IRA) {
    sectionsList.push(ACCT_INVEST);
  }
  if (checkingAccountType === CHECKING_ACCOUNT_TYPE_LLC) {
    sectionsList.push(LLC);
  } else if (accountType !== ACCOUNT_TYPE_CUSTODIAL_IRA) {
    sectionsList.push(TRUST);
  }
  if (
    [
      ACCOUNT_TYPE_CHECKBOOK_BANK_AND_CUSTODIAL,
      ACCOUNT_TYPE_CUSTODIAL_IRA,
    ].includes(accountType)
  ) {
    sectionsList.push(BENEFICIARY);
  }
  sectionsList.push(ID_SECURITY);
  if (providerName === GRATIS_THEME_NAME) {
    sectionsList.push(REVIEW);
    sectionsList.push(TRUST_CREATION);
  } else if (accountType === ACCOUNT_TYPE_CUSTODIAL_IRA) {
    sectionsList.push(TRI_PARTY_AGREEMENT);
  } else {
    sectionsList.push(REVIEW_SUBMIT);
  }
  return [...sectionsList];
}

export function additionalParticipantSections() {
  let sectionsList = [];

  sectionsList.push(CONTACT_INFO);
  sectionsList.push(PERSON_INFO);
  sectionsList.push(ID_SECURITY);
  sectionsList.push(REVIEW_SUBMIT);
  return [...sectionsList];
}
